import React, { Component, Fragment } from "react";
import IntlMessages from "util/IntlMessages";
import MUIDataTable from "mui-datatables";
import { format } from "date-fns";
import cs from "classnames";
import { injectIntl } from "react-intl";
import { connect } from "react-redux";
import {
  searchPage,
  editSearchOnModal,
  postSearch,
  getSearchById,
  updateSearch,
  clearSavedSearchFilter,
  getProjets
} from "redux/actions";
import { Filter } from "./filter";
import { DataListContainer } from "components/DataListContainer";
import { Colxx } from "components/CustomBootstrap";
import { options, getColumns } from "./dataTableOptions";
import { Modal } from "reactstrap";
import { EditSearch } from "./saved/editSearch";
import { NotificationManager } from "components/ReactNotifications";
import { get } from "lodash";
import { CSVLink } from "react-csv";
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core/styles";
import { formatDateSearchInput } from "util/formatDateSearchInput";
import { PHRASE_SEARCH_REGEX } from "constants/defaultValues";
import { arrayToCSVData } from "util/csvDownload";
import Download from "./Download";

class SearchCmp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showFilter: true,
      modalOpen: false,
      errorAdding: false,
      isLoadingAdding: false,
      initialValues: {}
    };
    if (this.props.match.params.id) {
      props.getSearchById(this.props.match.params.id);
      this.searchPage("bb", this.props.savedSearch);
    }
  }
  componentDidMount() {
    this.props.clearSavedSearchFilter();

    // if (!this.props.listProjet || this.props.listProjet.length < 1)
    this.props.getProjets();
  }
  searchPage = values => {
    let { page, resultPerPage } = this.props.filterSearch;
    page = 1;
    const startDate = formatDateSearchInput(values.startDate);
    const endDate = formatDateSearchInput(values.endDate);
    const startDateSeized = formatDateSearchInput(values.startDateSeized);
    const endDateSeized = formatDateSearchInput(values.endDateSeized);

    const project = values.project && values.project.id ? values.project.prefix : null;
    const box = values.box && values.box.id ? values.box.id : null;
    const sort = values.sort && values.sort.id ? values.sort.id : null;
    const boxType = values.boxType && !values.boxType.includes(null) ? values.boxType.map(e => e.value) : null;
    const contentType = values.contentType && !values.contentType.includes(null) ? values.contentType.map(e => e.id) : null;
    const relevant = values.relevant ? values.relevant : null;
    const hasEnTranslation = values.hasEnTranslation ? values.hasEnTranslation : null;
    const language = values.language  && values.language.length > 0? JSON.stringify(values.language) : null;

    this.props.searchPage({
      ...values,
      page,
      resultPerPage,
      startDate,
      endDate,
      project,
      box,
      boxType,
      contentType,
      relevant,
      language,
      hasEnTranslation,
      startDateSeized,
      endDateSeized,
      sort
    });
  };

  changePageSize = size => {
    const { filterSearch } = this.props;
    filterSearch.resultPerPage = size;
    filterSearch.page = 1;
    this.props.searchPage(filterSearch);
  };
  changeSort = (sort) => {
    const { filterSearch } = this.props;
    filterSearch.sort = sort ? sort.id : null;
    this.props.searchPage(filterSearch);
  }
  onChangePage = page => {
    const { filterSearch } = this.props;
    filterSearch.page = page;
    this.props.searchPage(filterSearch);
  };

  toggleModal = () => {
    this.setState({
      modalOpen: !this.state.modalOpen,
      errorAdding: false
    });
  };

  onAddUItem = () => {
    this.props.editSearchOnModal(this.props.savedSearch);
    this.toggleModal();
  };

  createNotif = message => {
    NotificationManager.success(
      this.props.intl.messages[message],
      "",
      3000,
      null,
      null,
      "filled"
    );
  };
  getDate(dateIn) {
    const dt = new Date(dateIn);
    // const dtDateOnly = new Date(
    //   dt.getTime() + dt.getTimezoneOffset() * 60 * 1000
    // );
    const pattern = "dd/MM/yyyy hh:mm:ss a";
    return format(dt, pattern);
  }
  onSubmitItem = search => {
    this.setState({ errorAdding: false, isLoadingAdding: true });
    const successCallback = () => {
      this.setState({ isLoadingAdding: false });
      this.createNotif("search.add-success-message");
      this.toggleModal();
    };
    const errorCallback = () => {
      this.setState({ errorAdding: true, isLoadingAdding: false });
    };
    const startDate = formatDateSearchInput(this.props.filterValue.startDate);
    const endDate = formatDateSearchInput(this.props.filterValue.endDate);
    const project = this.props.filterValue.project && this.props.filterValue.project.id ? this.props.filterValue.project.name : null;
    const searchToSave = {
      searchType: "BASIC",

      ...this.props.filterValue,
      project,
      ...{
        title: search.title,
        startDate: startDate,
        endDate: endDate
      },

    };
    if (searchToSave.id) {
      this.props.updateSearch(searchToSave, successCallback, errorCallback);
    } else {
      this.props.postSearch(searchToSave, successCallback, errorCallback);
    }
  };

  componentWillReceiveProps(nextProps) {
    if (nextProps.savedSearch.id !== this.props.savedSearch.id) {
      nextProps.getSearchById(nextProps.match.params.id);
      this.searchPage(nextProps.savedSearch);
    }
  }

  getMuiTheme = () =>
    createMuiTheme({
      overrides: {
        MUIDataTable: {
          responsiveScroll: {
            maxHeight: this.state.showFilter ? "570px" : "800px"
          }
        },
        MUIDataTableSelectCell: {
          fixedHeader: {
            position: 'relative'
          },
          headerCell: {
            position: 'sticky'
          }
        },
      }
    });

  render() {
    let { loading, listData, filterSearch, listProjet, initProject } = this.props;
    return (
      <Fragment>
        <DataListContainer
          title={"menu.search"}
          addNewText="Show Filter"
          hideButtons={this.state.showFilter}
          onAddClick={() => this.setState({ showFilter: true })}
          dataLength={listData && listData.total || 0}
          pageSize={filterSearch.resultPerPage}
          changePageSize={this.changePageSize}
          onChangePage={this.onChangePage}
          currentPage={filterSearch.page}
          showChildrenForEmpty
        >
          {listProjet && !listProjet.result && <Filter
            onSubmit={this.searchPage}
            onAddUItem={this.onAddUItem}
            changeSort={this.changeSort}
            onClose={() => this.setState({ showFilter: false })}
            className={cs({ "d-none": !this.state.showFilter })}
            searchId={this.props.match.params.id}
            searchType={this.props.savedSearch && this.props.savedSearch.id ? this.props.savedSearch.searchType : JSON.parse(localStorage.getItem("SET_SEARCH_PAGE")) && JSON.parse(localStorage.getItem("SET_SEARCH_PAGE")).searchType ? JSON.parse(localStorage.getItem("SET_SEARCH_PAGE")).searchType : this.props.savedSearch.searchType}
            changeTab={this.changeTab}
            listProjet={listProjet}
            initProject={initProject}
          />}
          <Colxx xxs="12" className="text-center h6">
            {loading && <div className="loading" />}
            {listData.total === 0 && <IntlMessages id="no-data-found" />}
          </Colxx>
          {listData.total > 0 && (
            <Colxx xxs="12">
              <div className="text-center h6">
                <Download data={listData.result} filter={this.props.filterValue} />
                <MuiThemeProvider theme={this.getMuiTheme()}>
                  <MUIDataTable
                    data={listData.result}
                    columns={getColumns(getSearchTerms(filterSearch.term))}
                    options={{ ...options, download: false }}
                  />
                </MuiThemeProvider>
              </div>
            </Colxx>
          )}
        </DataListContainer>
        <Modal
          isOpen={this.state.modalOpen}
          toggle={this.toggleModal}
          wrapClassName="modal-right"
          backdrop="static"
        >
          <EditSearch
            errorAdding={this.state.errorAdding}
            isLoadingAdding={this.state.isLoadingAdding}
            msgError={"errors.error-adding-user"}
            toggleModal={this.toggleModal}
            onSubmit={this.onSubmitItem}
          />
        </Modal>
      </Fragment>
    );
  }
}

const mapStateToProps = state => {
  const { appData } = state;
  let project = appData.searchs.savedSearch && appData.searchs.savedSearch.id && appData.searchs.savedSearch.project ? appData.searchs.savedSearch.project : JSON.parse(localStorage.getItem("SET_SEARCH_PAGE")) && JSON.parse(localStorage.getItem("SET_SEARCH_PAGE")).project ? JSON.parse(localStorage.getItem("SET_SEARCH_PAGE")).project : null;
  let initProject = appData.projets.data && project && !appData.projets.data.result ? appData.projets.data.find(element => element.name === project) : null;
  return {
    listData: appData.pages.search.data,
    loading: appData.pages.search.loading,
    filterSearch: appData.pages.filterSearch,
    listProjet: appData.projets.data,
    filterValue: get(state, "form.searchPages.values"),
    savedSearch: appData.searchs.savedSearch,
    initProject: initProject
  };
};

export const Search = connect(
  mapStateToProps,
  {
    searchPage,
    editSearchOnModal,
    postSearch,
    getSearchById,
    updateSearch,
    clearSavedSearchFilter,
    getProjets
  }
)(injectIntl(SearchCmp));

const getSearchTerms = query => {
  if (query) {
    const tag = query.match(PHRASE_SEARCH_REGEX);
    if (tag) return [tag[1]];
    return query.split(" ");
  }
  return [];
};
