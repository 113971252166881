export const BOX = "BOX";
export const DOCUMENT = "DOCUMENT";
export const VIDEO = "VIDEO";
export const IMAGE = "IMAGE";
export const AUDIO = "AUDIO";
export const WEBPAGES = "WEBPAGES";
export const SOCIALMEDIA = "SOCIALMEDIA";
export const PAGE = "PAGE";

export const CONTENT_TYPES = [
    { id: 1, label: VIDEO },
    { id: 2, label: DOCUMENT },
    { id: 3, label: IMAGE },
    { id: 4, label: AUDIO },
    { id: 5, label: WEBPAGES },
    { id: 6, label: SOCIALMEDIA },
]

export const getBoxTypes = (isVoxvex) => {
    let boxtType = [
        { label: "OS", value: "OS" },
        { label: "E", value: "E" },
        { label: "D", value: "D" },
    ];

    if (isVoxvex) {
        boxtType = [
            ...boxtType,
            { label: "A", value: "A" },
        ];
    }

    return boxtType;
};
