import { all, fork, put, takeEvery, call, select } from "redux-saga/effects";
import * as types from "redux/actionTypes";
import {
  getPageMetadataApi,
  updatePageMetadataApi,
  uploadPageApi,
  downloadPageApi,
  searchPagesApi,
  auditSearchApi,
  getNextPage,
  getPreviousPage,
  getFirstAndLast,
  deletePageApi,
  sendRequestDeletePageApi,
  auditSearchTotalMetadataApi,
  downloadPageTranslationApi
} from "./apis";
import { formatSearchData, formatAudirSearchData } from "./selectors";
import { downloadPageSuccess, downloadPageError, downloadPageTranslationSuccess, downloadPageTranslationError } from "./actions";

function* getPageMetadataSaga({ payload }) {
  const { id } = payload;
  try {
    const response = yield call(getPageMetadataApi, id);

    yield put({ type: types.SET_PAGE_METADATA, payload: response.data });
    yield put({ type: types.SET_DOCS_PAGES_URI, payload: null });

  } catch (error) {
    yield put({ type: types.ERROR_GETTING_PAGE_META, payload: error });
  }
}

function* updatePageMetadataSaga({ payload }) {
  const { id, data, success, error } = payload;
  try {
    yield call(updatePageMetadataApi, id, data);
    if (success) success();

    yield call(getPageMetadataSaga, { payload });
  } catch (err) {
    if (error) error(err);
  }
}

function* downloadPageSaga({ payload }) {
  const { id, typeDoc } = payload;
  try {
    const response = yield call(downloadPageApi, id, typeDoc);

    if (response.status < 200 || response.status > 300)
      throw new Error(
        "status: " + response.status + ", message: " + response.statusText
      );

    const contentDisposition = response.headers[
      "content-disposition"
    ].toLowerCase();

    let type = "";
    if (contentDisposition.includes(".pdf")) {
      type = "application/pdf";
    }

    const file = new Blob([response.data], { type });
    if (contentDisposition.includes(".mp4")) {
      type = "mp4";
    } else if(contentDisposition.includes(".mp3")){
      type = "mp3";
    }
    const fileURL = URL.createObjectURL(file);

    yield put(downloadPageSuccess(fileURL, type));
  } catch (err) {
    yield put(downloadPageError(err));
  }
}

function* downloadPageTranslationSaga({ payload }) {
  const { id, typeDoc } = payload;
  try {
    const response = yield call(downloadPageTranslationApi, id, typeDoc);

    if (response.status < 200 || response.status > 300)
      throw new Error(
        "status: " + response.status + ", message: " + response.statusText
      );
    const contentDisposition = response.headers[
      "content-disposition"
    ].toLowerCase();

    let type = "";
    if (contentDisposition.includes(".pdf")) {
      type = "application/pdf";
    }

    const file = new Blob([response.data], { type });
    const fileURL = URL.createObjectURL(file);
    yield put(downloadPageTranslationSuccess(fileURL, type));
  } catch (err) {
    yield put(downloadPageTranslationError(err));
  }
}
function* uploadPageSaga({ payload }) {
  const {
    data,
    success,
    error,
    onUploadProgress,
    cancelToken,
    isOCR
  } = payload;
  try {
    const response = yield call(
      uploadPageApi,
      data,
      onUploadProgress,
      cancelToken,
      isOCR
    );
    if (response.status !== 200) {
      throw new Error(response.data.message);
    }
    if (success) success(response);
  } catch (err) {
    if (error) error(err.message);
  }
}

function* searchPageSaga() {
  try {
    const filter = yield select(state => state.appData.pages.filterSearch);
    const response = yield call(searchPagesApi, filter);
    if (response.status !== 200) {
      throw new Error("Error !");
    }
    localStorage.setItem("SET_SEARCH_PAGE", JSON.stringify(filter));
    yield put({
      type: types.SET_SEARCH_PAGE,
      payload: formatSearchData(response.data)
    });
  } catch (err) {
    yield put({ type: types.ERROR_GETTING_SEARCH_PAGE, payload: err });
  }
}

function* auditSearchSaga() {
  try {
    const filter = yield select(state => state.appData.pages.filterAudit);
    if (!filter.isNotSearch) {
      const response = yield call(auditSearchApi, filter);
      if (response.status !== 200) {
        throw new Error("Error !");
      }
      yield put({
        type: types.SET_AUDIT_SEARCH,
        payload: formatAudirSearchData(response.data)
      });
    }



  } catch (err) {
    yield put({ type: types.ERROR_AUDIT_SEARCH, payload: err });
  }
}

function* auditSearchTotalMetadataSaga() {
  try {
    const filter = yield select(state => state.appData.pages.filterAudit);
    if (!filter.isNotSearch) {
      const response = yield call(auditSearchTotalMetadataApi, filter);
      if (response.status !== 200) {
        throw new Error("Error !");
      }
      yield put({
        type: types.SET_AUDIT_SEARCH_TOTAL_METADATA,
        payload: response.data
      });
    }



  } catch (err) {
    yield put({ type: types.ERROR_AUDIT_SEARCH_TOTAL_METADATA, payload: err });
  }
}


function* getSiblingPageSaga({ payload }) {
  try {
    const nextPage = yield call(getNextPage, payload.id);
    const previousPage = yield call(getPreviousPage, payload.id);
    const firstAndLast = yield call(getFirstAndLast, payload.id);
    if (nextPage.status !== 200 || previousPage.status !== 200 || firstAndLast.status !== 200) {
      throw new Error("Error !");
    }
    yield put({
      type: types.SET_SIBLINGS_PAGES,
      payload: { nextPage: nextPage.data, previousPage: previousPage.data, firstAndLast: firstAndLast.data }
    });
  } catch (err) {
    yield put({ type: types.ERROR_AUDIT_SEARCH, payload: err });
  }
}

function* deletePageSaga(action) {
  const { pageId, success, error } = action.payload;
  try {
    const response = yield call(deletePageApi, pageId);
    if (response.status === 200) {
      if (success) success();
    } else {
      if (error) error();
    }
  } catch (err) {
    if (error) error();
  }
}

function* sendRequestDeletePageSaga(action) {
  const { pageId, success, error } = action.payload;
  try {
    const response = yield call(sendRequestDeletePageApi, pageId);
    if (response.status === 200) {
      if (success) success();
    } else {
      if (error) error();
    }
  } catch (err) {
    if (error) error();
  }
}

export function* watchPagesSaga() {
  yield takeEvery(types.UPDATE_PAGE_METADATA, updatePageMetadataSaga);
  yield takeEvery(types.GET_PAGE_METADATA, getPageMetadataSaga);
  yield takeEvery(types.DOWNLOAD_PAGE, downloadPageSaga);
  yield takeEvery(types.DOWNLOAD_PAGE_TRANSLATION, downloadPageTranslationSaga);
  yield takeEvery(types.UPLOAD_PAGE, uploadPageSaga);
  yield takeEvery(types.SEARCH_PAGE, searchPageSaga);
  yield takeEvery(types.AUDIT_SEARCH, auditSearchSaga);
  yield takeEvery(types.AUDIT_SEARCH_TOTAL_METADATA, auditSearchTotalMetadataSaga);
  yield takeEvery(types.GET_SIBLINGS_PAGES, getSiblingPageSaga);
  yield takeEvery(types.DELETE_PAGE, deletePageSaga);
  yield takeEvery(types.SEND_REQUEST_DELETE_PAGE, sendRequestDeletePageSaga);
}

export default function* rootSaga() {
  yield all([fork(watchPagesSaga)]);
}
