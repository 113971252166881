import * as types from "redux/actionTypes";

export const getDeleteRequestsData = () => ({
  type: types.GET_DELETE_REQUESTS
});
export const changeDeleteRequestsFilter = ({ page, pageSize }) => ({
  type: types.CHANGE_DELETE_REQUESTS_FILTER,
  payload: { page, pageSize }
});
export const confirmDeleteRequest = (ids, success, error) => ({
  type: types.CONFIRM_DELETE_REQUEST,
  payload: { ids, success, error }
});
export const cancelDeleteRequest = (ids, success, error) => ({
  type: types.CANCEL_DELETE_REQUEST,
  payload: { ids, success, error }
});
export const setDeleteRequestsData = data => ({
  type: types.SET_DELETE_REQUESTS_DATA,
  payload: data
});
export const errorDeleteRequestsData = err => ({
  type: types.ERROR_DELETE_REQUESTS_DATA,
  payload: err
});
