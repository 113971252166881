import React, { Fragment, Component } from "react";
import IntlMessages from "util/IntlMessages";
import {
  Row,
  Card,
  CardBody,
  Breadcrumb,
  BreadcrumbItem,
  Button,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink
} from "reactstrap";
import { NavLink as RouterNavLink } from "react-router-dom";
import { connect } from "react-redux";
import Cropper from "components/ReactCropper";
import "cropperjs/dist/cropper.css";
import { Colxx, Separator } from "components/CustomBootstrap";
import { MetaData } from "../metadata";
import {
  getPageMetadata,
  downloadPage,
  downloadPageTranslation,
  getSiblingsPages,
  getDocsMetadata,
  getBoxMetadata,
  getDocsPages,
  deletePage,
  sendRequestDeletePage
} from "redux/actions";
import { saveAs } from "file-saver";
import instance from "util/instances";
import { PAGES_ENDPOINT } from "redux/pages/endpoints";
import StateButton from "components/StateButton";
import cs from "classnames";
import get from "lodash/get";
import EnglishTranslation from "./EnglishTranslation";
import HideMetadataButton from "components/HideMetadataButton";
import Navigation from "./Navigation";
import DeleteResourceModal from "components/DeleteResourceModal";
import { NotificationManager } from "components/ReactNotifications";
import {
  downloadDocsZip,
  canDeletePage,
  canRequestDeletePage
} from "util/permissions";

const PAGE = "PAGE";
const PAGE_ET = "PAGE_ET";

class PageDetailsCmp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      _isMounted: false,
      isLoadingDownloadPage: false,
      activeTab: PAGE,
      hideMetaDataCol: false,
      isDeleting: false,
      deletingError: undefined,
      isSendingDeleteRequest: false,
      sendDeleteRequestError: undefined,
      deleteResourceModal: false,
      numberBoxMetada: "",
      numberDocMetada: ""
    };
    props.getPageMetadata(props.match.params.pageId);
    props.downloadPage(props.match.params.pageId, "Document");
    props.downloadPageTranslation(props.match.params.pageId, "Document");

    props.getSiblingsPages(props.match.params.pageId);
  }

  componentDidMount = () => {
    this.setState({ _isMounted: true });
    const docId = this.props.match.params.documentId;
    const docPages = this.props.documentPages;
    if (
      !docPages ||
      docPages.length < 1 ||
      docPages[0].documentsId !== Number.parseInt(docId)
    ) {
      this.props.getDocsPages(docId);
    }
  };

  componentWillUnmount = () => {

    this.setState({ _isMounted: false });
  };

  componentDidUpdate = prevProps => {
    if (this.props.boxMetadata && this.props.boxMetadata.number && this.state.numberBoxMetada === "") {
      this.setState({ numberBoxMetada: this.props.boxMetadata.number })
    }
    if (this.props.docMetadata && this.props.docMetadata.number && this.state.numberDocMetada === "") {
      this.setState({ numberDocMetada: this.props.docMetadata.number })
    }
    const { pageId, documentId } = this.props.match.params;
    const boxId = this.props.match.params.id;
    if (pageId !== prevProps.match.params.pageId) {
      //UPDATE box, doc, page metadata
      this.props.getBoxMetadata(boxId);
      this.props.getDocsMetadata(documentId);
      this.props.getPageMetadata(pageId);

      this.props.downloadPage(pageId, "Document");
      this.props.downloadPageTranslation(pageId, "Document");;

      this.props.getSiblingsPages(pageId);
    }
  };

  setIsLoadingDownloadPage = booleanVal => {
    if (this.state._isMounted)
      this.setState({ isLoadingDownloadPage: booleanVal });
  };

  conditionRender = () =>
    this.props.pageDownload.loading  || this.props.pageDownloadTranslation.loading ? <div className="loading" /> : false;

  goBack = () => {
    this.props.history.goBack();
  };
  goForward = () => {
    this.props.history.goForward();
  };
  handleChangePage = page => {
    if (page) {
      this.props.getPageMetadata(page.id);
      this.props.downloadPage(page.id, "Document");
      this.props.downloadPageTranslation(page.id, "Document");
      this.props.getSiblingsPages(page.id);
      this.props.history.push(this.getPageUrl(page));
    }
  };
  handleChangePageSearch = page => {
    if (page) {
      this.props.getPageMetadata(page.id);
      this.props.downloadPage(page.id, "Document");
      this.props.downloadPageTranslation(page.id, "Document");
      this.props.getSiblingsPages(page.id);
      this.props.history.push(this.getPageUrlSearch(page));
    }
  };
  getPreviousSearchPage = () => {
    if (this.props.listData && this.props.listData.result && this.props.listData.result.length > 0) {
      const index = this.props.listData.result.findIndex((element) => element.id === this.props.pageMetadata.id);
      return index > 0 ? this.props.listData.result[index - 1] : null
    }
    return null;


  }
  getNextSearchPage = () => {
    if (this.props.listData && this.props.listData.result && this.props.listData.result.length > 0) {
      const index = this.props.listData.result.findIndex((element) => element.id === this.props.pageMetadata.id);
      return index >= 0 && this.props.listData.result.length >= 1 ? this.props.listData.result[index + 1] : null
    }
    return null;


  }
  goToSearchPage = ()=>{
   this.props.history.push(
      `/app/searchs/search`
    );
  }

  getPageUrl = page =>
    `/app/documents/list/box/${page.documents.boxId}/${page.documents.id}/${page.id}`;

  getPageUrlSearch = page =>
    `/app/documents/list/box/${page.boxId}/${page.documentsId}/${page.id}/search`;


  toggle = tab => {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab
      });
    }
  };

  toggleMetaDataCol = () => {
    this.setState({ hideMetaDataCol: !this.state.hideMetaDataCol });
  };

  renderTypes = () => {
    const { type, fileURL, error = undefined } = this.props.pageDownload;
    if (error)
      return (
        <div className="text-center">
          <IntlMessages id="file-missing" />
        </div>
      );

    switch (type) {
      case "application/pdf":
        return (
          <iframe
            title="page"
            width="100%"
            height="800"
            style={{ border: "none" }}
            src={fileURL}
          />
        );
      case "mp4":
        return (
          <Fragment>
            {downloadDocsZip() && (
              <StateButton
                color="primary"
                className="mb-3 d-flex ml-auto"
                onClick={()=>this.downloadPageImage(false)}
                isLoading={this.state.isLoadingDownloadPage}
              >
                <IntlMessages id="pages.download" />
              </StateButton>
            )}
            <video src={fileURL} controls className="w-100">
              Your browser does not support the video tag.
            </video>
          </Fragment>
        );
        case "mp3":
        return (
          <Fragment>
            {downloadDocsZip() && (
              <StateButton
                color="primary"
                className="mb-3 d-flex ml-auto"
                onClick={()=>this.downloadPageImage(false)}
                isLoading={this.state.isLoadingDownloadPage}
              >
                <IntlMessages id="pages.download" />
              </StateButton>
            )}
              <audio src={fileURL} controls className="w-100">
                    Your browser does not support the audio tag.
              </audio> 
          </Fragment>
        );
      default:
        return (
          fileURL && (
            <Fragment>
              {downloadDocsZip() && (
                <StateButton
                  color="primary"
                  className="mb-3 d-flex ml-auto"
                  onClick={()=>this.downloadPageImage(false)}
                  isLoading={this.state.isLoadingDownloadPage}
                >
                  <IntlMessages id="pages.download" />
                </StateButton>
              )}
                <Cropper ref="cropper" dragMode="move" src={fileURL} autoCropArea={1} />
            </Fragment>
          )
        );
    }
  };

  renderTranslation = () => {
    const { type, fileURL, error = undefined } = this.props.pageDownloadTranslation;
    if (error)
      return (
        <div className="text-center">
          <IntlMessages id="file-missing" />
        </div>
      );

    switch (type) {
      case "application/pdf":
        return (
          <iframe
            title="page"
            width="100%"
            height="800"
            style={{ border: "none" }}
            src={fileURL}
          />
        );
      default:
        return (
          fileURL && (
            <Fragment>
              {downloadDocsZip() && (
                <StateButton
                  color="primary"
                  className="mb-3 d-flex ml-auto"
                  onClick={()=>this.downloadPageImage(true)}
                  isLoading={this.state.isLoadingDownloadPage}
                >
                  <IntlMessages id="pages.download" />
                </StateButton>
              )}
                <Cropper ref="cropper" dragMode="move" src={fileURL} autoCropArea={1} />
            </Fragment>
          )
        );
    }
  };

  downloadPageImage = (isTranslation) => {
    this.setIsLoadingDownloadPage(true);
    instance
      .get(
        `${PAGES_ENDPOINT}${this.props.pageMetadata.documents.id}/downloadzip`,
        {
          responseType: "blob",
          params: { pageIds: this.props.pageMetadata.id, isTranslation: isTranslation }
        }
      )
      .then(res => {
        if (res && res.status === 200) {
          const blob = new Blob([res.data], {
            type: "application/zip"
          });

          saveAs(blob, `${this.props.pageMetadata.name}.zip`);
        } else {
          alert("An error occured, try again later.");
        }
        this.setIsLoadingDownloadPage(false);
      });
  };

  handlePageNavgationSelect = page => {
    this.props.history.push(
      `/app/documents/list/box/${page.boxId}/${page.documentsId}/${page.id}`
    );
  };

  handleDelete = () => {
    const { id, documentId, pageId } = this.props.match.params;
    this.setDeletingError(undefined);
    this.setIsDeleting(true);
    const successCallback = () => {
      this.setIsDeleting(false);
      this.createNotif("page.delete-success-message");
      this.props.history.push(`/app/documents/list/box/${id}/${documentId}`);
    };
    const errorCallback = () => {
      this.setIsDeleting(false);
      this.setDeletingError(true);
    };
    this.props.deletePage(pageId, successCallback, errorCallback);
  };

  setIsDeleting = boolVal => {
    if (this.state._isMounted) {
      this.setState({ isDeleting: boolVal });
    }
  };

  setDeletingError = val => {
    if (this.state._isMounted) {
      this.setState({ deletingError: val });
    }
  };

  createNotif = message => {
    NotificationManager.success(
      <IntlMessages id={message} />,
      "",
      3000,
      null,
      null,
      "filled"
    );
  };

  handleSendDeleteRequest = () => {
    if (this.state._isMounted) {
      this.setState({ isSendingDeleteRequest: true });
    }
    const pageId = this.props.match.params.pageId;
    const successCallback = () => {
      this.createNotif("delete-request-sent");
      if (this.state._isMounted) {
        this.setState({
          isSendingDeleteRequest: false,
          deleteResourceModal: false
        });
      }
    };
    const errorCallback = () => {
      if (this.state._isMounted) {
        this.setState({
          isSendingDeleteRequest: false,
          sendDeleteRequestError: true
        });
      }
    };
    this.props.sendRequestDeletePage(pageId, successCallback, errorCallback);
  };

  toggleDeleteResourceModal = () => {
    this.setState({ deleteResourceModal: !this.state.deleteResourceModal });
  };

  render() {
    const {
      match,
      boxMetadata,
      docMetadata,
      pageMetadata,
      siblings,
      documentPages,
      pagesBox
    } = this.props;
    const showDeleteModal = canRequestDeletePage() || canDeletePage();
    return (
      <Fragment>
        {this.conditionRender() ? (
          this.conditionRender()
        ) : (
          <Row>
            <Colxx xxs="12">
              <h1>{" " + pageMetadata.name}</h1>

              <Breadcrumb className="pt-0 breadcrumb-container d-none d-sm-block d-lg-inline-block">
                <BreadcrumbItem>
                  <RouterNavLink to="/app/documents/list"><IntlMessages id={"Boxes"} /></RouterNavLink>
                </BreadcrumbItem>
                <BreadcrumbItem>
                  <RouterNavLink
                    to={`/app/documents/list/box/${match.params.id}`}
                  >
                    {pagesBox.box}
                  </RouterNavLink>
                </BreadcrumbItem>
                <BreadcrumbItem>
                  <RouterNavLink
                    to={`/app/documents/list/box/${match.params.id}/${match.params.documentId}`}
                  >
                    {pagesBox.number}
                  </RouterNavLink>
                </BreadcrumbItem>
                <BreadcrumbItem active>{pageMetadata.number}{pageMetadata.suffix && pageMetadata.suffix != null ? pageMetadata.suffix : ""}</BreadcrumbItem>
              </Breadcrumb>
              <Separator className="mb-3" />
            </Colxx>
            <Colxx
              xxs="12"
              className="d-flex align-items-center  flex-column flex-lg-row"
            >
              {this.props.match.params.type && this.props.listData && this.props.listData.result && this.props.listData.result.length > 0 &&
              <Button
                  outline
                  color="primary"
                  className="mr-2 mb-3"
                  onClick={this.goToSearchPage}
                 >
                  <IntlMessages id="Home.search" />
                </Button>
              }
              <Button
                outline
                color="primary"
                className="mr-2 mb-3"
                onClick={this.goBack}
              >
                <i className="simple-icon-arrow-left" />
                <IntlMessages id="Back" />
              </Button>

              <HideMetadataButton
                className="mb-3 mr-2"
                onClick={this.toggleMetaDataCol}
                active={this.state.hideMetaDataCol}
              />
              {showDeleteModal && (
                <DeleteResourceModal
                  buttonLabel={`Delete page`}
                  idResource={pageMetadata.name}
                  title={`Delete page`}
                  buttonClassName="mb-3 mr-2"
                  onDelete={this.handleDelete}
                  canDelete={canDeletePage()}
                  isDeleting={this.state.isDeleting}
                  deletingError={this.state.deletingError}
                  sendDeleteRequestError={this.state.sendDeleteRequestError}
                  onSendDeleteRequest={this.handleSendDeleteRequest}
                  isSendingDeleteRequest={this.state.isSendingDeleteRequest}
                  toggleDeleteResourceModal={this.toggleDeleteResourceModal}
                  deleteResourceModal={this.state.deleteResourceModal}
                />
              )}
              <Button
                outline
                color="primary"
                className="mb-3 mr- ml-2"
                onClick={this.goForward}
              >
                <IntlMessages id="Forward" />
                <i className="simple-icon-arrow-right" />

              </Button>
              <Navigation
                className="mb-3"
                pages={documentPages}
                onChange={this.handlePageNavgationSelect}
                current={parseInt(this.props.match.params.pageId)}
              />
            </Colxx>
            <Colxx xxs="12" className="d-flex justify-content-between">
              {this.props.match.params.type && this.props.listData && this.props.listData.result && this.props.listData.result.length > 0 ? <Colxx xxs="6" style={{ "display": "flex", "justify-content": "flex-start" }}>
                <Button
                  outline
                  color="primary"
                  className="mb-2"
                  disabled={!this.props.listData || this.props.listData.result.length == 0 || this.props.listData.result[0].id === pageMetadata.id}
                  onClick={this.handleChangePageSearch.bind(
                    this,
                    this.props.listData && this.props.listData.result.length > 0 ? this.props.listData.result[0] : null
                  )}
                >
                  <i className="simple-icon-arrow-left" /><i className="simple-icon-arrow-left" />
                  <IntlMessages id="First Result" />
                </Button>
                <Button
                  outline
                  color="primary"
                  className="mb-2"
                  disabled={!this.getPreviousSearchPage()}
                  onClick={this.handleChangePageSearch.bind(
                    this,
                    this.props.listData && this.props.listData.result.length > 0 ? this.getPreviousSearchPage() : null)}
                >
                  <i className="simple-icon-arrow-left" />
                  <IntlMessages id="Previous Result" />
                </Button>
              </Colxx> : <Colxx xxs="6" style={{ "display": "flex", "justify-content": "flex-start" }}>
                <Button
                  outline
                  color="primary"
                  className="mb-2"
                  disabled={!siblings.firstAndLast || siblings.firstAndLast.length == 0 || siblings.firstAndLast[0].id === pageMetadata.id}
                  onClick={this.handleChangePage.bind(
                    this,
                    siblings.firstAndLast && siblings.firstAndLast.length > 0 ? siblings.firstAndLast[0] : null
                  )}
                >
                  <i className="simple-icon-arrow-left" /><i className="simple-icon-arrow-left" />
                  <IntlMessages id="First Page" />
                </Button>
                <Button
                  outline
                  color="primary"
                  className="mb-2"
                  disabled={!siblings.previousPage}
                  onClick={this.handleChangePage.bind(
                    this,
                    siblings.previousPage
                  )}
                >
                  <i className="simple-icon-arrow-left" />
                  <IntlMessages id="Previous Page" />
                </Button>
              </Colxx>}
              {this.props.match.params.type && this.props.listData && this.props.listData.result && this.props.listData.result.length > 0 ? <Colxx xxs="6" style={{ "display": "flex", "justify-content": "flex-end" }}>

                <Button
                  outline
                  color="primary"
                  className="mb-2"
                  disabled={!this.getNextSearchPage()}
                  onClick={this.handleChangePageSearch.bind(
                    this,
                    this.props.listData && this.props.listData.result.length > 0 ? this.getNextSearchPage() : null)}
                >

                  <IntlMessages id="Next Result" />
                  <i className="simple-icon-arrow-right" />
                </Button>
                <Button
                  outline
                  color="primary"
                  className="mb-2"
                  disabled={!this.props.listData || this.props.listData.result.length == 0 || this.props.listData.result[this.props.listData.result.length - 1].id === pageMetadata.id}
                  onClick={this.handleChangePageSearch.bind(
                    this,
                    this.props.listData && this.props.listData.result.length > 0 ? this.props.listData.result[this.props.listData.result.length - 1] : null
                  )}
                >
                  <IntlMessages id="Last Result" />
                  <i className="simple-icon-arrow-right" />
                  <i className="simple-icon-arrow-right" />

                </Button>
              </Colxx> : <Colxx xxs="6" style={{ "display": "flex", "justify-content": "flex-end" }}>

                <Button
                  outline
                  color="primary"
                  className="mb-2"
                  disabled={!siblings.nextPage}
                  onClick={this.handleChangePage.bind(this, siblings.nextPage)}
                >
                  <IntlMessages id="Next Page" />
                  <i className="simple-icon-arrow-right" />
                </Button>
                <Button
                  outline
                  color="primary"
                  className="mb-2"
                  disabled={!siblings.firstAndLast || siblings.firstAndLast.length == 1 || siblings.firstAndLast[1].id === pageMetadata.id}
                  onClick={this.handleChangePage.bind(
                    this,
                    siblings.firstAndLast && siblings.firstAndLast.length > 0 ? siblings.firstAndLast[1] : null
                  )}
                >
                  <IntlMessages id="Last Page" />
                  <i className="simple-icon-arrow-right" />
                  <i className="simple-icon-arrow-right" />

                </Button>
              </Colxx>}
            </Colxx>
            <Colxx xxs="12">
              <Row>
                <Colxx
                  xxs="12"
                  md="4"
                  className={cs("mb-4", {
                    "d-none": this.state.hideMetaDataCol
                  })}
                >
                  <MetaData
                    pageId={match.params.pageId}
                    boxId={match.params.id}
                    documentId={match.params.documentId}
                    showMetadata
                    showPageMetadata
                  />
                </Colxx>
                <Colxx
                  xxs="12"
                  md={this.state.hideMetaDataCol ? "12" : "8"}
                  className="mb-4"
                >
                  <Nav tabs>
                    <NavItem>
                      <NavLink
                        className={cs({
                          active: this.state.activeTab === PAGE
                        })}
                        onClick={() => {
                          this.toggle(PAGE);
                        }}
                      >
                        <IntlMessages id="page.page-tab" />
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={cs({
                          active: this.state.activeTab === PAGE_ET
                        })}
                        onClick={() => {
                          this.toggle(PAGE_ET);
                        }}
                      >
                        <IntlMessages id="page.page-english-translation-tab" />
                      </NavLink>
                    </NavItem>
                  </Nav>
                  <TabContent activeTab={this.state.activeTab}>
                    <TabPane tabId={PAGE}>
                      <Card className="mb-4">
                        <CardBody>{this.renderTypes()}</CardBody>
                      </Card>
                    </TabPane>
                    <TabPane tabId={PAGE_ET}>
                      <Card className="mb-4">
                      <CardBody>{this.renderTranslation()}</CardBody>
                      </Card>
                    </TabPane>
                  </TabContent>
                </Colxx>
              </Row>
            </Colxx>
          </Row>
        )
        }
      </Fragment>
    );
  }
}

const mapStateToProps = state => {
  const { appData } = state;
  const hasValues = Object.keys(appData.pages.metadata).length > 0;
  return {
    docMetadata: appData.docs.metadata,
    boxMetadata: appData.boxs.metadata,
    pagesBox: hasValues ? appData.pages.metadata.documents : {},
    siblings: appData.pages.siblings,
    pageMetadata: appData.pages.metadata,
    pageDownload: appData.pages.download,
    pageDownloadTranslation: appData.pages.downloadTranslation,
    documentPages: appData.docs.pages,
    listData: appData.pages.search.data
  };
};

export const PageDetails = connect(
  mapStateToProps,
  {
    getPageMetadata,
    downloadPage,
    downloadPageTranslation,
    getSiblingsPages,
    getDocsMetadata,
    getBoxMetadata,
    getDocsPages,
    deletePage,
    sendRequestDeletePage
  }
)(PageDetailsCmp);
