/* Gogo Language Texts

Table of Contents

01.General
02.User Login, Logout, Register
03.Menu
04.Error Page
*/

module.exports = {
  /* 01.General */
  "app.title": "نظام إدارة الأدلة",
  "general.copyright": "Gogo React © 2018 All Rights Reserved.",

  /* 02.User Login, Logout, Register */
  "user.logout": "تسجيل خروج",
  "user.login-intro": "الرجاء إدخال بيانات الاعتماد الخاصة بك",
  "user.username": "اسم المستخدم",
  "user.password": "كلمة المرور",
  "user.login-button": "تسجيل الدخول",
  "user.error-gettings-users": "خطأ في التعرف على المستخدمين",

  /* 03.Menu */
  "menu.app": "الصفحة الرئيسية",
  "menu.users": "المستخدمين",
  "menu.documents": "مستندات",
  "menu.search": "بحث",
  "menu.saved-search": "الابحاث المحفوظة",
  "menu.audit": "مراجعة",
  "menu.restrictions": "قيود",
  "menu.reporting": "الإبلاغ",
  "menu.savesearch": "حفظ البحث",
  "menu.deleteRequests": "حذف الطلبات",
  "menu.ips": "Ips محاولات ",

  /* 04.Error Page */
  "layouts.error-title": "عفوًا ... يبدو أنه حدث خطأ!",
  "layouts.error-code": "خطا بالكود",
  "layouts.go-back-home": "ارجع للصفحة الرئيسية",

  /* 04.DataList : Users */
  "user.Manage-Uses": "ادارة المستخدمين",
  "user.Archived-Users": "المؤرشفون",
  "user.Manage-Teams": "إدارة الفرق",
  "user.Manage-IpAttempts": "IP إدارة عنوان ",
  teams: "الفرق",
  "user.Add-User": "أضف مستخدمين",
  "user.Edit-User": "تعديل مستخدمين",
  "user.role": "دور",
  "users.add-success-message": "تم حفظ المستخدم بنجاح!",
  "users.deleted-success-message": "تم حذف المستخدم بنجاح!",
  "users.enabled-success-message": "تم تمكين المستخدم بنجاح!",
  "users.error-authentification": "فشلت عملية الدخول !",
  "users.error-locked": "تم حظر حسابك يرجى المحاولة مرة أخرى فيما بعد",
  "users.error-locked-minute": "الدقائق!",
  "users.confirm-delete-title": "تأكيد",
  "users.confirm-delete": "هل أنت متأكد أنك تريد أرشفة هذا المستخدم؟",
  "users.blocked": "مستخدم محظور",
  "users.unlocked": "مستخدم ملغى حظره",

  /* DataList : Teams */
  "teams.Add-Teams": "اضافة الفرق",
  "teams.Edit-Team": "تعديل الفرق",
  "teams.add-success-message": "تم حفظ الفريق بنجاح!",
  "teams.deleted-success-message": "تم حذف الفريق بنجاح!",
  "teams.confirm-delete": " هل أنت متأكد أنك تريد حذف الفريق؟",
  "teams.error-gettings-teams": "خطأ في الحصول على فرق",
  "teams.users": "مستخدم (ون)",

  /* DataList : restrictions */
  "restrictions.Add-Restrictions": "أضف قيودًا",
  "restrictions.add-success-message": "تم حفظ القيد بنجاح!",
  "restrictions.deleted-success-message": "تم حذف القيد بنجاح!",
  "restrictions.confirm-delete": "هل أنت متأكد أنك تريد حذف القيد؟",
  "restrictions.error-gettings": "خطأ في الحصول على القيود",

  /* DataList : projets */
  "projets.Add-Projets": "أضف المشاريع",
  "projets.add-success-message": "! تم حفظ المشروع بنجاح",
  "projets.deleted-success-message": "! تم حذف المشروع بنجاح",
  "projets.confirm-delete": " هل أنت متأكد أنك تريد حذف المشروع؟ ",
  "projets.error-gettings": "خطأ في الحصول على المشاريع",
  "projets.error-delete": "الملفات متواجدة. يرجى حذف جميع محتويات المشروع قبل حذف المشروع",
  "projects.description": "وصف المشروع",
  "projects.prefix":"بادئة المشروع",

  /* Documents */
  "doc.list-document": "إدارة المستندات",
  "doc.list-projets": "إدارة المشاريع",
  "doc.import-documents": " استيراد الوثائق",
  "doc.download-document-images": "تحميل صور الوثيقة",
  "doc.import-ocr": "OCR استيراد ملفات",
  "doc.import-metadata": "استيراد البيانات الوصفية",
  "doc.document-boxes": "صناديق المستندات",
  "doc.video-boxes": "صناديق الفيديو",
  "doc.files in": "الملفات الموجودة في",
  "doc.file": " الملف:",
  "doc.download": "تحميل",
  "doc.Pages in": "الصفحات في",
  "doc.Box Information Sheet": "ورقة معلومات الصندوق",
  "set-total-docs": "إجمالي عدد المجلدات للصندوق",
  "set-total-pages": "إجمالي عدد الصفحات للمجلدات",
  "Changes saved successfully": "! التغييرات التي تم حفظها بنجاح",
  "documents.hide-metadata": "إخفاء البيانات الوصفية",
  "document.delete-success-message": "! تم حذف المستند بنجاح",
  "doc.no.missings": "لا توجد مستندات مفقودة",

  /* DataList : IpAttempts */
  "ipAttempts.deleted-success-message": "! تم حذف الملكية الفكرية بنجاح",
  "ipAttempts.confirm-delete": "هل تريد حقًا حذف الملكية الفكرية؟",
  "ipAttempts.error-gettings-ipAttempts": "ips خطأ في الحصول على",


  // Boxes
  "box.delete-success-message": "! تم حذف الصندوق بنجاح",

  /* Commans */
  Cancel: "الغاء",
  Close: "اغلاق",
  Enable: "تمكين",
  Confirm: "تاكيد",
  Save: " حفظ",
  Submit: "رفع",
  Edit: " تعديل ",
  "First Name": "الاسم الاول",
  "Last Name": "الكنية",
  Name: "الاسم",
  OCR: "التعرف البصري على الاحرف",
  Email: "الايميل",
  Username: "اسم المستخدم",
  User: "المستخدم",
  Users: "المستخدمون",
  Team: "الفريق",
  Box: "الصندوق",
  Boxs: "الصناديق",
  Action: "نفذ",
  Document: "وثيقة",
  Image : "صورة",
  WebPages: "صفحات الويب",
  SocialMedia : "وسائل التواصل الاجتماعي / الدردشات",
  Audio :"الصوت",
  Page: "صفحة",
  "confirm-password": "تأكيد كلمة المرور",
  "order-by": "ترتيب حسب",
  Password: "كلمة المرور",
  Delete: "حذف",
  Videos: "فيديو",
  Pages: "صفحات",
  Images: "صور",
  Metadata: "البيانات الوصفية",
  Video: "فيديو",
  Documents: "وثائق",
  Prefix: "اختصار",
  "Add files...": "إضافة ملفات",
  "Cancel upload": "إلغاء التحميل",
  "Start upload": "أبدا التحميل",
  "Page Metadata": "البيانات الوصفية للصفحة",
  "Folder Metadata": "البيانات الوصفية للمجلد",
  "Box Metadata": "مربع البيانات الوصفية ",
  "Box Name": "اسم الصندوق",
  "Authorized Ips": " Ips معتمد",
  "Metadata not saved": "لم يتم حفظ البيانات الوصفية",
  "Missings Pages": "الصفحات المفقودة",
  Selection: "اختيار",
  Download: "تحميل",
  "Total Document": "إجمالي المجلد",
  "Save changes": "احفظ التغييرات",
  Generate: "انشاء",

  /* Search */
  "search.basic-search": "البحث الأساسي",
  "search.specific-search": "بحث محدد",
  "search.advanced-search": "البحث المتقدم",
  "Activate fuzzy search (search for terms that are similar to, but not exactly like our search term)":
    "قم بتنشيط البحث الغامض (ابحث عن المصطلحات المشابهة لمصطلح البحث الخاص بنا ولكن ليس تمامًا مثله)",

  /* Errors */
  "error.required": "الخانة المطلوبة",
  "error.invalide Email": "بريد إلكتروني خاطئ ",
  "error.pwd-not-match": "كلمة المرور غير متطابقة",
  "errors.error-adding-user": "حدث خطأ يرجى المحاولة مرة أخرى",
  "error.password-to-short": "كلمة المرور أقصر مما يجب",
  "error.name-to-short": "يجب أن يتكون اسم المشروع من 3 أحرف",
  "no-data-found": "! لا توجد بيانات",
  "file-missing": " .يبدو أن هذا الملف مفقود",
  "Download document images error : Invalid range": "يجب أن يتبع التحديد هذا النمط: SYR.D0001، SYR.D0002.001، SYR.D0002.002.001، SYR.D0002.001.004-SYR.D0002.001.050. استخدم فاصلة (،) للفصل بين المربعات والمستندات والصفحات ونطاقات الصفحات وشرطة (-) للنطاقات.",
  "No files found": "لا توجد ملفات",
  "la donnée recherchée n'existe pas": "البيانات المطلوبة غير موجودة",
  "general.exception.interne": "خطأ في الخادم الداخلي",
  /* Search */
  "Search term": "مصطلح البحث",
  "Start Date": "تاريخ البدء",
  "End Date": "تاريخ الانتهاء  ",
  "Location seized": "موقع المصادرة",
  "Digital forensics ID":"رقم المعرف الرقمي للتحليل الجنائي",
  "Date seized": "تاريخ المصادرة",
  "Seized By": "من استولى على المصادرة",
  Notes: "ملاحظات",
  "Document From": "صفحة من",
  "Document To": "صفحة إلى",
  "Document CC": " CC صفحة  النسخ ",
  "Document summary": "ملخص المجلد",
  "Names of people": "أسماء الناس",
  "Names of places": "أسماء الأماكن",
  "Names of entities": "أسماء الكيانات",
  To: "إلى",
  From: "من",
  Cc: "نسخ",
  Tags: "ممنوع التوقيع",
  "Location seized Box": "موقع مصادرة صندوق البيانات الوصفية",
  "Notes Box": "ملاحظات عن صندوق البيانات الوصفية",
  "Document From Folder": "صفحة من مجلد بيانات الوصفية ",
  "Document To Folder": "صفحة الى مجلد البيانات الوصفية ",
  "Document CC Folder": "صفحة نسخ لمجلد البيانات الوصفية ",
  "Document summary Folder": "صفحة ملخص لمجلد البيانات الوصفية",
  "Names of people Page": "أسماء الاشخاص في صفحة البيانات الوصفية",
  "Names of places Page": "أسماء الأماكن في صفحة البيانات الوصفية",
  "Names of entities Page": "أسماء الكيانات في صفحة البيانات الوصفية",
  "To Page": "إلى صفحة البيانات الوصفية",
  "From Page": "من صفحة البيانات الوصفية",
  "Cc Page": "نسخ لصفحة البيانات الوصفية",
  "Tags Page": "مكان التوقيع في صفحة البيانات الوصفية",
  "Start Date Page": "تاريخ البدء في صفحة البيانات الوصفية",
  "End Date Page": "تاريخ الانتهاء في صفحة البيانات الوصفية",
  "All these words": "كل هذه الكلمات",
  "Any of these words": "أي من هذه الكلمات",
  "None of these words": "ولا واحدة من هذه الكلمات",
  "Find pages with": " ... بحث عن صفحات مع",
  "Show Filter": "إظهار عامل التصفية",

  "searches.saved": "الابحاث المحفوظة",
  "search.edit": "تحرير البحث",
  "search.title": "عنوان",
  "search.add-success-message": "!تم حفظ البحث بنجاح",
  "search.confirm-delete": "هل أنت متأكد أنك تريد حذف البحث؟",
  "search.name": "ماذا تريد أن تسمي هذا البحث ؟",

  /** Audit Page */
  "Action types": "أنواع الإجراءات",
  "Show all action types": "إظهار كافة أنواع الإجراءات",
  "Show only logon actions": "إظهار إجراءات تسجيل الدخول فقط",
  "Show only logoff actions": "إظهار إجراءات تسجيل الخروج فقط",

  totalDocuments: "العدد الإجمالي للوثائق في نظام أدارة الأدلة",
  totalEnglishTranslations: "مجموع الترجمات الإنجليزية",
  totalImages: "إجمالي الصور",
  totalMetadatas: "إجمالي عدد البيانات الوصفية",
  totalOcrs: "إجمالي المستندات التي خضعت لعملية التعرف على الحروف بالمسح الضوئي",
  totalVideos: "إجمالي مقاطع الفيديو",
  "Next Page": "الصفحة التالية",
  "Previous Page": "الصفحة السابقة",
  Archive: "أرشيف",

  "Records without metadata": "السجلات بدون بيانات التعريف",
  "Search for documents box having no metadata for fields":
    "ابحث عن صندوق المستندات الذي لا يحتوي على بيانات وصفية للحقول",
  Back: "عد للخلف",
  Forward: "تقدم للأمام",
  "Home.search": "الصفحة الرئيسية",

  //Pages
  "pages.download": "تحميل",
  "pages.no-english-translation":
    "لم تتم ترجمة هذه الصفحة إلى اللغة الإنجليزية",
  "page.page-tab": "صفحة",
  "page.page-english-translation-tab": "الترجمة الإنجليزية",
  "page.delete-success-message": "!تم حذف الصفحة بنجاح",
  "page.no.missings": "لا توجد صفحات مفقودة",


  // Audit
  "audit.download-audit-search-results": "تنزيل نتائج بحث التدقيق",
  "audit.download-audit-search-results-body":
    "هل تريد تنزيل هذه الصفحة فقط أم كل النتائج؟ (قد يستغرق تنزيل جميع النتائج وقتًا أكبر بكثير)",
  "audit.this-page-only": "هذه الصفحة فقط",
  "audit.all-results": "جميع النتائج",
  "audit.of-action": "# من الإجراءات",
  "audit.progress-metadata": "استمرار إضافة البيانات الوصفية",
  "audit.type-search.users": "... اكتب للبحث عن المستخدمين ",
  "audit.type-search.teams": "... اكتب للبحث عن الفرق",
  "audit.type-search.documents": "... اكتب للبحث في المستندات",
  "audit.type-search.boxes": "... اكتب للبحث في الصندوق",
  "audit.type-search.pages": "... اكتب للبحث في الصفحات",


  // Delete Requests
  "delete-requests": "طلبات الحذف",
  "delete-requests.confirm-delete-title": "تأكيد",
  "delete-requests.confirm-cancel-title": "تأكيد",
  "delete-requests.confirm-delete-content":
    "هل أنت متأكد أنك تريد حذف المصدر؟",
  "delete-requests.confirm-cancel-content":
    "هل أنت متأكد أنك تريد إلغاء الطلب ؟",
  "delete-requests.deleted-success-message": "!تم حذف المصادر بنجاح",
  "delete-requests.canceled-success-message": "! تم إلغاء الطلبات",
  "delete-request-sent": "! تم إرسال طلب الحذف",
  "Total number of pages": "المجموع الإجمالي للصفحات",
  "Original Url": "الروابط الاصلية",
  "Summary": "ملخص",
  "Upload Date": "تاريخ التحميل",
  "Youtube Id": "معرف يوتيوب",
  "Youtube Title": "عنوان يوتيوب",
  "Missings Documents": "المستندات المفقودة",
  "Boxes": "صناديق",
  "Date": "تاريخ",
  "Go to page": "انتقل إلى صفحة",
  "Signature block": "مكان التوقيع ",
  "Delete page": "حذف الصفحة",
  "Show Document boxes": "إظهارصناديق المستندات",
  "Show Videos boxes": "إظهار صناديق الفيديو",
  "Show Only failed uploads": "إظهار عمليات التحميل الفاشلة فقط",
  "Delete document": "حذف الوثيقة",
  "Delete box": "حذف الصندوق",

  //Delete Ressources
  "delete-ressource.msg": "هل أنت متأكد أنك تريد إرسال طلب لحذف هذا المصدر؟",
  "delete-ressource.error": "حدث خطأ أثناء محاولة الحذف. الرجاء معاودة المحاولة في وقت لاحق",
  "Send": "إرسال",
  "of": "من",
  "Folders": "المجلدات",
  "Sort": "صنف حسب",
  "asc": "الاسم (تصاعديا)",
  "desc": "الاسم (تنازلي)",

  "Title": "عنوان",
  "Hijri Date": "التاريخ الهجري",
  "Start Hijri Date":"تاريخ البدء الهجري",
  "End Hijri Date":"تاريخ الانتهاء الهجري",
  "PhoneOrEmail": "أرقام الهاتف / الفاكس أو البريد الإلكتروني",
  "DocumentReferenceCommunication": "الرقم المرجعي للوثيقة / رقم الاتصال",
  "DocumentReferenceMentioned": "ذكر أرقام مرجعية الوثيقة",
  "Languages": "اللغات",
  "Format": "شكل",
  "Subject": "موضوعات",
  "Description": "وصف",
  "ReferencedInBrief": "تمت الإشارة إليه في [ملخصات]",
  "DuplicateDocNo": "مستند مكرر. رقم",
  "Previously labelled under":"المسمى سابقا تحت",
  "First Page": "الصفحة الأولى",
  "Last Page": "آخر صفحة",
  "First Result": "النتيجة الأولى",
  "Previous Result": "النتيجة السابقة",
  "Next Result":"النتيجة التالية",
  "Last Result":"اخر نتيجة",
  "Original Filename":"اسم الملف الأصلي",
  "MD5 Hash Value":"قيمة تشفير تقنية MD5",
  "Box type":"نوع الصندوق",
  "Content type":"نوع المحتوى",
  "SHA1 Hash Value":"قيمة تشفير تقنية SHA1",
  "Relevant":"ذو صلة",
  "Translation ": "ترجمة",
  "Total Documents (Scanned, E-files and Open-Source)":"إجمالي المستندات (الممسوحة ضوئياً , الملفات الإلكترونية و مفتوحة المصدر)",
  "Total Documents (Scanned)":"إجمالي المستندات (الممسوحة ضوئيًا)",
  "Total Documents (E-files)":"إجمالي المستندات (الملفات الإلكترونية)",
  "Total Documents (Open-Source)":"إجمالي المستندات (مفتوحة المصدر)",
  "Total Images (E-files and Open-Source)":"إجمالي الصور (الملفات الالكترونية و الملفات مفتوحة المصدر)",
  "Total Images (E-files)":"إجمالي الصور (الملفات الإلكترونية)",
  "Total Images (Open-Source)":"إجمالي الصور (مفتوحة المصدر)",
  "Total Videos (E-files and Open-Source)":"إجمالي مقاطع الفيديو (الملفات الإلكترونية و الملفات مفتوحة المصدر)",
  "Total Videos (E-files)":"اجمالي الفيديوهات (الملفات الإلكترونية)",
  "Total Videos (Open-Source)":"اجمالي الفيديوهات (مفتوحة المصدر)",
  "Total Audio (E-files and Open-Source)":"إجمالي الملفات الصوتية (الملفات الإلكترونية و الملفات مفتوحة المصدر)",
  "Total Audio (E-files)":"اجمالي الاصوات (الملفات الإلكترونية)",
  "Total Audio (Open-Source)":"اجمالي الاصوات (مفتوحة المصدر)",
  "Total Social Media (E-files and Open-Source)":"إجمالي منصات التواصل الإجتماعي (الملفات الإلكترونية و الملفات مفتوحة المصدر)",
  "Total Social Media (E-files)":"إجمالي مواقع التواصل الاجتماعي (الملفات الإلكترونية)",
  "Total Social Media (Open-Source)":"إجمالي مواقع التواصل الاجتماعي (مفتوحة المصدر)",
  "Total URLs (E-files and Open-Source)":"إجمالي الروابط (الملفات الإلكترونية المفتوحة المصدر)",
  "Total URLs (E-files)":"إجمالي الروابط (الملفات الإلكترونية)",
  "Total URLs (Open-Source)":"إجمالي الروابط (مفتوحة المصدر)",
  "Arabic":"العربية",
  "English":"الانجليزية",
  "Russian":"الروسية",
  "French":"الفرنسية",
  "Other":"اخرى",
  "Scanned boxes":"صناديق الملفات الممسوحة ضوئياً",
  "E-files boxes":"صناديق الملفات الإلكترونية",
  "Open-Source boxes":"صناديق الملفات مفتوحة المصدر",
  "Total evidence in EMS (scanned , e-files , open source/ excluding translations)":"اجمالي الأدلة في نظام إدارة الأدلة (الممسوحة ضوئياً, الملفات الالكترونية , مفتوحة المصدر بدون الترجمات (غير مترجمة)",
  "Total Videos (A boxes)":"إجمالي مقاطع الفيديو ",
  "Total evidence in EMS (Scanned, A-videos, E-files , Open-Source/ excluding translations)":"اجمالي الأدلة في نظام إدارة الأدلة (الممسوحة ضوئياً,  مقاطع الفيديو, الملفات الالكترونية, مفتوحة المصدر بدون الترجمات (غير مترجمة)",
};
