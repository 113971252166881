import React from "react";
import { Input } from "reactstrap";
import cs from "classnames";
import { FormFields } from "..";
import { injectIntl } from "react-intl";

const ValidatedInputCmp = props => {
  const {
    input,
    type,
    inputClassName,
    disabled,
    autoComplete,
    placeholder,
    meta: { touched, error },
    intl: { messages }
  } = props;

  return (
    <FormFields {...props}>
      <Input
        disabled={disabled}
        {...input}
        type={type}
        autoComplete={autoComplete}
        className={cs(inputClassName, { "is-invalid": touched && error })}
        placeholder={
          messages[placeholder] ? messages[placeholder] : placeholder
        }
      />
    </FormFields>
  );
};

export const ValidatedInput = injectIntl(ValidatedInputCmp);
