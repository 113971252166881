import { all, fork, takeEvery, call, put, select } from "redux-saga/effects";
import {
  setBoxesNoMetadata,
  errorBoxesNoMetadata,
  errorDocsNoMetadata,
  setDocsNoMetadata,
  setPagesNoMetadata,
  errorPagesNoMetadata
} from "./actions";
import * as types from "redux/actionTypes";
import {
  searchPagesMetaApi,
  searchDocsMetaApi,
  searchBoxsMetaApi
} from "./apis";

function* searchPageSaga() {
  try {
    const { page, size, filter } = yield select(
      ({ appData }) => appData.metadata.noMetadataPages
    );
    const response = yield call(searchPagesMetaApi, page, size, filter);
    if (response.status !== 200) {
      yield put(errorPagesNoMetadata(response.data.message));
    } else {
      yield put(setPagesNoMetadata(response.data));
    }
  } catch (err) {
    yield put(errorPagesNoMetadata(err.response.data.message));
  }
}

function* searchDocsSaga() {
  try {
    
    const { page, size, filter } = yield select(
      ({ appData }) => appData.metadata.noMetadataDocs
    );
    const response = yield call(searchDocsMetaApi, page, size, filter);
    if (response.status !== 200) {
      yield put(errorDocsNoMetadata(response.data.message));
    } else {
      yield put(setDocsNoMetadata(response.data));
    }
  } catch (err) {
    yield put(errorDocsNoMetadata(err.response.data.message));
  }
}

function* searchBoxsSaga() {
  try {
    const { page, size, filter } = yield select(
      ({ appData }) => appData.metadata.noMetadataBoxes
    );
    const response = yield call(searchBoxsMetaApi, page, size, filter);
    if (response.status !== 200) {
      yield put(errorBoxesNoMetadata(response.data.message));
    } else {
      yield put(setBoxesNoMetadata(response.data));

    }
  } catch (err) {
    yield put(errorBoxesNoMetadata(err.response.data.message));
  }
}

export function* watchBoxsSaga() {
  yield takeEvery(types.GET_BOX_WITHOUT_META, searchBoxsSaga);
  yield takeEvery(types.CHANGE_BOX_WITHOUT_META_PAGE_SIZE, searchBoxsSaga);
  yield takeEvery(types.GET_DOC_WITHOUT_META, searchDocsSaga);
  yield takeEvery(types.CHANGE_DOC_WITHOUT_META_PAGE_SIZE, searchDocsSaga);
  yield takeEvery(types.GET_PAGE_WITHOUT_META, searchPageSaga);
  yield takeEvery(types.CHANGE_PAGE_WITHOUT_META_PAGE_SIZE, searchPageSaga);
}

export default function* rootSaga() {
  yield all([fork(watchBoxsSaga)]);
}
