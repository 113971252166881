import * as types from "redux/actionTypes";

export const getSearchs = filter => ({
  type: types.GET_SEARCHS,
  payload: { ...filter }
});

export const postSearch = (search, success, error) => {
  return {
    type: types.POST_SEARCH,
    payload: { search, success, error }
  };
};

export const updateSearch = (search, success, error) => {
  return {
    type: types.EDIT_SEARCH,
    payload: { search, success, error }
  };
};
export const deleteSearch = (id, success, error) => {
  return {
    type: types.DELETE_SEARCH,
    payload: { id, success, error }
  };
};

export const editSearchOnModal = search => ({
  type: types.EDIT_SEARCH_ON_MODAL,
  payload: search
});

export const getSearchById = id => ({
  type: types.GET_SEARCH,
  payload: { id }
});

export const clearSavedSearchFilter = () => ({
  type: types.CLEAR_SAVED_SEARCH_FILTER
});
