import React from "react";
import { connect } from "react-redux";
import { ModalHeader, ModalBody, ModalFooter, Button } from "reactstrap";
import { Field, reduxForm } from "redux-form";

import IntlMessages from "util/IntlMessages";
import { ValidatedInput } from "components/FormFields/Input";
import StateButton from "components/StateButton";

const required = value =>
  value || typeof value === "number" ? undefined : "error.required";

const EditSearchCmp = ({
  toggleModal,
  handleSubmit,
  errorAdding,
  isLoadingAdding,
  msgError,
  ...props
}) => {
  return (
    <form onSubmit={handleSubmit}>
      <ModalHeader toggle={toggleModal}>
        <IntlMessages id={"search.edit"} />
      </ModalHeader>
      <ModalBody className="pb-0">
        <Field name="id" component="input" type="hidden" />
        <Field
          className="mb-4"
          name="title"
          component={ValidatedInput}
          type="text"
          label="search.name"
          validate={required}
        />

        {errorAdding && (
          <div className="d-block text-danger text-center">
            <IntlMessages id={msgError} />
          </div>
        )}
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" outline onClick={toggleModal}>
          <IntlMessages id="Cancel" />
        </Button>
        <StateButton
          id="successButton"
          color="primary"
          type="submit"
          isLoading={isLoadingAdding}
        >
          <IntlMessages id="Submit" />
        </StateButton>
      </ModalFooter>
    </form>
  );
};

const mapStateToProps = state => {
  const { appData } = state;
  return {
    initialValues: appData.searchs.editedSearch
  };
};
export const EditSearch = connect(mapStateToProps)(
  reduxForm({
    form: "saveSearch"
  })(EditSearchCmp)
);
