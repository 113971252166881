import { parse } from "json2csv";

export const arrayToCSVData = (rawData, fields) => {
  const opts = { fields };
  try {
    return parse(rawData, opts);
  } catch (err) {
    return [];
  }
};
