import React, { Component, Fragment } from "react";
import IntlMessages from "util/IntlMessages";
import {
  Row,
  Card,
  CardBody,
  CardTitle,
  Breadcrumb,
  BreadcrumbItem,
  Button,
  Input
} from "reactstrap";
import PerfectScrollbar from "react-perfect-scrollbar";
import { NavLink } from "react-router-dom";
import { connect } from "react-redux";
import { get } from "lodash";
import { Player } from "video-react";

import { Colxx, Separator } from "components/CustomBootstrap";
import { MetaData } from "../metadata";
import {
  getDocsPages,
  getDocsMetadata,
  getBoxMetadata,
  downloadPage,
  deleteDocument,
  sendRequestDeleteDocument
} from "redux/actions";
import instance from "util/instances";
import { saveAs } from "file-saver";
import { PAGES_ENDPOINT } from "redux/pages/endpoints";
import LightSpinner from "components/LightSpinner";
import cs from "classnames";
import HideMetadataButton from "components/HideMetadataButton";
import DeleteResourceModal from "components/DeleteResourceModal";
import { canRequestDeleteDocument, canDeleteDocument } from "util/permissions";
import { NotificationManager } from "components/ReactNotifications";
import { downloadDocsZip } from "util/permissions";
import * as resourceTypes from "constants/resourceTypes";

import "../../../../../node_modules/video-react/dist/video-react.css";
import { Tooltip } from "@material-ui/core";
import { getTypesOfPages } from "../../../../util/documentTypes";

class DocumentDetailsCmp extends Component {
  constructor(props) {
    super(props);

    this.state = {
      _isMounted: false,
      checkedPages: [],
      isLoadingDownloadZip: false,
      hideMetaDataCol: false,
      isDeleting: false,
      deletingError: undefined,
      sendDeleteRequestError: undefined,
      isSendingDeleteRequest: false,
      deleteResourceModal: false
    };
  }

  componentDidMount = () => {
    this.setState({ _isMounted: true });
    const docId = this.props.match.params.documentId;
    const boxId = this.props.match.params.id;
    this.props.getDocsPages(docId);
    this.props.getDocsMetadata(docId);
    this.props.getBoxMetadata(boxId);
  };

  componentWillUnmount = () => {
    this.setState({ _isMounted: false });
  };

  componentDidUpdate = prevProps => {
    const docId = this.props.match.params.documentId;
    const boxId = this.props.match.params.id;
    if (docId !== prevProps.match.params.documentId) {
      this.props.getDocsPages(docId);
      this.props.getDocsMetadata(docId);
      this.props.getBoxMetadata(boxId);
    }
  };

  goBack = () => {
    this.props.history.goBack();
  };
  goForward = () => {
    this.props.history.goForward();
  };

  isChecked = itemId => this.state.checkedPages.indexOf(itemId) > -1;

  isAllChecked = () =>
    this.props.pages
      ? this.state.checkedPages.length === this.props.pages.length &&
      this.props.pages.length > 0
      : false;

  handleChangeCheck = itemId => {
    let checked = [...this.state.checkedPages];
    if (checked.indexOf(itemId) > -1)
      checked = checked.filter(it => it !== itemId);
    else checked.push(itemId);
    this.setState({ checkedPages: checked });
  };

  handleChangeCheckAll = () => {
    if (!this.props.pages) return;
    if (this.state.checkedPages.length < this.props.pages.length)
      this.setState({ checkedPages: this.props.pages.map(doc => doc.id) });
    else this.setState({ checkedPages: [] });
  };

  setIsLoadingDownloadZip = booleanVal => {
    if (this.state._isMounted)
      this.setState({ isLoadingDownloadZip: booleanVal });
  };

  downloadPagesZip = () => {
    if (this.state.checkedPages.length < 1) return;

    this.setIsLoadingDownloadZip(true);
    const { docMetadata } = this.props;
    instance
      .get(
        `${PAGES_ENDPOINT}${this.props.match.params.documentId}/downloadzip`,
        {
          responseType: "blob",
          params: { pageIds: this.state.checkedPages.join(",") }
        }
      )
      .then(res => {
        if (res && res.status === 200) {
          const blob = new Blob([res.data], {
            type: "application/zip"
          });

          saveAs(
            blob,
            `${docMetadata.project}_${docMetadata.box}_${docMetadata.number}_pages.zip`
          );
        } else {
          alert("The selected page(s) are missing !");
        }
        this.setIsLoadingDownloadZip(false);
      });
  };

  downloadVideo = () => {
    saveAs(
      this.props.pageDownload.fileURL,
      `${this.props.boxMetadata.number}_${this.props.docMetadata.number}.mp4`
    );
  };

  toggleMetaDataCol = () => {
    this.setState({ hideMetaDataCol: !this.state.hideMetaDataCol });
  };

  conditionRender = () =>
    this.props.pageDownload.loading || this.props.loading ? <div className="loading" /> : false;

  renderDocumentPages = () => {
    const { match, docMetadata, pages } = this.props;
    const { isLoadingDownloadZip } = this.state;

    if (docMetadata.documentType !== resourceTypes.VIDEO)
      return (
        <Fragment>
          {downloadDocsZip() && (
            <div className="d-flex justify-content-between ems-check-all-download-row">
              <div>
                <Input
                  type="checkbox"
                  checked={this.isAllChecked()}
                  onChange={() => this.handleChangeCheckAll()}
                />
              </div>
              <div className="mr-3">
                <Button
                  className="mr-4 ems-button-without-style"
                  size="sm"
                  onClick={this.downloadPagesZip}
                  disabled={
                    this.state.checkedPages.length < 1 || isLoadingDownloadZip
                  }
                >
                  {!isLoadingDownloadZip && (
                    <i className="simple-icon-cloud-download" />
                  )}
                  {isLoadingDownloadZip && <LightSpinner />}
                </Button>
              </div>
            </div>
          )}
          {pages && pages.map((page, index) => {
            return (
              <div
                key={index}
                className="align-self-center d-flex flex-column flex-xs-row justify-content-between align-items-lg-center border-bottom"
              >
                <div className="pr-2 ems-check-list-padding-left">
                  {downloadDocsZip() && (
                    <Input
                      type="checkbox"
                      checked={this.isChecked(page.id)}
                      onChange={() => this.handleChangeCheck(page.id)}
                    />
                  )}
                  <NavLink
                    to={`/app/documents/list/box/${match.params.id}/${match.params.documentId}/${page.id}`}
                  >
                    <p className="font-weight-medium mb-1 ">
                      Page {page.number} {page.suffix && page.suffix !== null ? page.suffix : ""}
                    </p>
                  </NavLink>
                </div>
                <div className="align-self-center pr-3">
                  <Tooltip className="mr-1" title={<IntlMessages id={getTypesOfPages(page.documentType).label} />}>
                    {getTypesOfPages(page.documentType).icon}
                  </Tooltip>
                  <NavLink
                    className="mr-4 ml-2"
                    to={`/app/documents/list/box/${match.params.id}/${match.params.documentId}/${page.id}`}
                  >
                    <i className="simple-icon-eye" />
                  </NavLink>
                </div>
              </div>
            );
          })}
        </Fragment>
      );
    return null;
  };

  handleDelete = () => {
    const { id, documentId } = this.props.match.params;
    this.setDeletingError(undefined);
    this.setIsDeleting(true);
    const successCallback = () => {
      this.setIsDeleting(false);
      this.createNotif("document.delete-success-message");
      this.props.history.push(`/app/documents/list/box/${id}`);
    };
    const errorCallback = () => {
      this.setIsDeleting(false);
      this.setDeletingError(true);
    };
    this.props.deleteDocument(documentId, successCallback, errorCallback);
  };

  setIsDeleting = boolVal => {
    if (this.state._isMounted) {
      this.setState({ isDeleting: boolVal });
    }
  };

  setDeletingError = val => {
    if (this.state._isMounted) {
      this.setState({ deletingError: val });
    }
  };

  createNotif = message => {
    NotificationManager.success(
      <IntlMessages id={message} />,
      "",
      3000,
      null,
      null,
      "filled"
    );
  };

  handleSendDeleteRequest = () => {
    if (this.state._isMounted) {
      this.setState({ isSendingDeleteRequest: true });
    }
    const { documentId } = this.props.match.params;
    const successCallback = () => {
      this.createNotif("delete-request-sent");
      if (this.state._isMounted) {
        this.setState({
          isSendingDeleteRequest: false,
          deleteResourceModal: false
        });
      }
    };
    const errorCallback = () => {
      if (this.state._isMounted) {
        this.setState({
          isSendingDeleteRequest: false,
          sendDeleteRequestError: true
        });
      }
    };
    this.props.sendRequestDeleteDocument(
      documentId,
      successCallback,
      errorCallback
    );
  };

  toggleDeleteResourceModal = () => {
    this.setState({ deleteResourceModal: !this.state.deleteResourceModal });
  };

  render() {
    const { match, docMetadata, pageDownload } = this.props;
    const isVideo = docMetadata.documentType === resourceTypes.VIDEO;
    const docTypeName = isVideo ? "video" : "document";
    const showDeleteModal = canRequestDeleteDocument() || canDeleteDocument();
    return this.conditionRender() ? (
      this.conditionRender()
    ) : (
      <Row>
        <Colxx xxs="12">
          <h1>
            {!isVideo && docMetadata && <IntlMessages id={"doc.Pages in"} />} {docMetadata && `${docMetadata.project} ${docMetadata.box}`}
          </h1>
          <Breadcrumb className="pt-0 breadcrumb-container d-none d-sm-block d-lg-inline-block">
            <BreadcrumbItem>
              <NavLink to="/app/documents/list"><IntlMessages id={"Boxes"} /></NavLink>
            </BreadcrumbItem>
            <BreadcrumbItem>
              <NavLink to={`/app/documents/list/box/${match.params.id}`}>
                {docMetadata.box}
              </NavLink>
            </BreadcrumbItem>
            <BreadcrumbItem active>{docMetadata.number}</BreadcrumbItem>
          </Breadcrumb>
          {!isVideo && (
            <h5>
              <IntlMessages id="Total number of pages" />
              : {get(this.props, "pages", []).length}
            </h5>
          )}
          <Separator className="mb-3" />
          <Button
            outline
            color="primary"
            className="mb-3 mr-2"
            onClick={this.goBack}
          >
            <i className="simple-icon-arrow-left" />
            <IntlMessages id="Back" />
          </Button>
          <HideMetadataButton
            className="mb-3 mr-2"
            onClick={this.toggleMetaDataCol}
            active={this.state.hideMetaDataCol}
          />
          {showDeleteModal && (
            <DeleteResourceModal
              buttonLabel={`Delete ${docTypeName}`}
              idResource={` ${docMetadata.box}.${docMetadata.number}`}
              title={`Delete document`}
              buttonClassName="mb-3"
              onDelete={this.handleDelete}
              canDelete={canDeleteDocument()}
              isDeleting={this.state.isDeleting}
              deletingError={this.state.deletingError}
              sendDeleteRequestError={this.state.sendDeleteRequestError}
              onSendDeleteRequest={this.handleSendDeleteRequest}
              isSendingDeleteRequest={this.state.isSendingDeleteRequest}
              toggleDeleteResourceModal={this.toggleDeleteResourceModal}
              deleteResourceModal={this.state.deleteResourceModal}
            />
          )}
          <Button
            outline
            color="primary"
            className="mb-3 mr- ml-2"
            onClick={this.goForward}
          >
            <IntlMessages id="Forward" />
            <i className="simple-icon-arrow-right" />

          </Button>
        </Colxx>
        <Colxx xxs="12">
          {this.props.loading ? (
            <div className="loading" />
          ) : (
            <Row>
              <Colxx
                xxs="12"
                md="4"
                className={cs("mb-4", {
                  "d-none": this.state.hideMetaDataCol
                })}
              >
                <MetaData
                  boxId={match.params.id}
                  documentId={match.params.documentId}
                  showMetadata
                />
              </Colxx>
              <Colxx
                xxs="12"
                md={this.state.hideMetaDataCol ? "12" : "8"}
                className="mb-4"
              >
                <Card className="mb-4">
                  <CardBody>
                    <CardTitle>
                      <h5>
                        <IntlMessages
                          id={
                            docMetadata.documentType === resourceTypes.VIDEO
                              ? "Video"
                              : "Pages"
                          }
                        />
                      </h5>
                    </CardTitle>
                    <div className="ems-documents-list">
                      <PerfectScrollbar
                        options={{
                          suppressScrollX: true
                        }}
                      >
                        {this.renderDocumentPages()}
                        {docMetadata.documentType === resourceTypes.VIDEO &&
                          (pageDownload.fileURL ? (
                            <Fragment>
                              <Button
                                color="primary"
                                className="mb-3 d-flex ml-auto"
                                onClick={this.downloadVideo}
                              >
                                <IntlMessages id="doc.download" />
                              </Button>
                              <Player
                                className="ems-video-player"
                                playsInline
                                src={pageDownload.fileURL}
                                fluid={false}
                                width={800}
                                height={450}
                              />
                            </Fragment>
                          ) : (
                            <iframe
                              title="Youtube Video"
                              width="800"
                              height="450"
                              src={
                                `https://www.youtube.com/embed/` +
                                (get(docMetadata, "videoMetadata.originalUrl")
                                  ? get(
                                    docMetadata,
                                    "videoMetadata.originalUrl"
                                  ).split("v=")[1]
                                  : get(docMetadata, "videoMetadata.youtubeId"))
                              }
                            />
                          ))}
                      </PerfectScrollbar>
                    </div>
                  </CardBody>
                </Card>
              </Colxx>
            </Row>
          )}
        </Colxx>
      </Row>
    );
  }
}

const mapStateToProps = state => {
  const { appData } = state;
  return {
    docMetadata: appData.docs.metadata,
    boxMetadata: appData.boxs.metadata,
    pages: appData.docs.pages,
    loading: appData.docs.loading,
    pageDownload: appData.pages.download
  };
};

export const DocumentDetails = connect(
  mapStateToProps,
  {
    getDocsPages,
    getDocsMetadata,
    getBoxMetadata,
    downloadPage,
    deleteDocument,
    sendRequestDeleteDocument
  }
)(DocumentDetailsCmp);
