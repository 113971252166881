import React, { Component } from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { CSVLink } from "react-csv";
import { pick } from "lodash";

import { getColumnsCSVDocument, getColumnsCSVBox, getColumnsCSVPage } from "./dataTableOptions";
import { searchResultsDownloadApi } from "redux/pages/apis";
import { saveAs } from "file-saver";
import StateButton from "components/StateButton";
import IntlMessage from "util/IntlMessages";
import { arrayToCSVData } from "util/csvDownload";
import { formatDateSearchInput } from "util/formatDateSearchInput";
import { downloadBoxWithoutMetadata, downloadDocWithoutMetadata, downloadPageWithoutMetadata } from "../../../redux/metadata/apis";

class Download extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      isDownloadingAll: false,
      _isMounted: false
    };
  }

  componentDidMount() {
    this.setState({ _isMounted: true });
  }

  componentWillUnmount() {
    this.setState({ _isMounted: false });
  }

  setIsDownloadingAll = boolVal => {
    if (this.state._isMounted) this.setState({ isDownloadingAll: boolVal });
  };

  toggle = () => {
    this.setState({ modal: !this.state.modal });
  };

  getCsvData = (data) => {
    const type = this.props.type;
    const cols = type === 'box' ? getColumnsCSVBox().map(col => col.name) : type === 'document' ? getColumnsCSVDocument().map(col => col.name) : getColumnsCSVPage().map(col => col.name);
    const result = [...data];
    result.forEach(row => {
      row.pageNumber = row.name
    });
    const csvData = arrayToCSVData(result, cols);
    return csvData;
  };

  downloadAllResults = () => {
    this.setIsDownloadingAll(true);
    const { filter } = this.props;
    const type = this.props.type;
    if (type === "box") {

      downloadBoxWithoutMetadata(filter).then(response => {
        if (response && response.status === 200) {
          const blob = new Blob([response.data], {
            type: "text/csv"
          });

          saveAs(blob, "ems-reporting-box-results.csv");
        }
        this.setIsDownloadingAll(false);
      });
    }
    else if (type === "document") {
      downloadDocWithoutMetadata(filter).then(response => {
        if (response && response.status === 200) {
          const blob = new Blob([response.data], {
            type: "text/csv"
          });

          saveAs(blob, "ems-reporting-document-results.csv");
        }
        this.setIsDownloadingAll(false);
      });

    }
    else {
      downloadPageWithoutMetadata(filter).then(response => {
        if (response && response.status === 200) {
          const blob = new Blob([response.data], {
            type: "text/csv"
          });

          saveAs(blob, "ems-reporting-page-results.csv");
        }
        this.setIsDownloadingAll(false);
      });

    }
    // searchResultsDownloadApi(filter, filter.searchType).then(response => {
    //   if (response && response.status === 200) {
    //     const blob = new Blob([response.data], {
    //       type: "text/csv"
    //     });

    //     saveAs(blob, "ems-reporting-box-results.csv");
    //   }
    //   this.setIsDownloadingAll(false);
    // });
  };

  render() {
    return (
      <div className="text-right">
        <Button color="primary" className="mb-2" onClick={this.toggle}>
          <IntlMessage id="Download" />
        </Button>
        <Modal
          isOpen={this.state.modal}
          toggle={this.toggle}
          className={this.props.className}
        >
          <ModalHeader toggle={this.toggle}>
            <IntlMessage id="audit.download-audit-search-results" />
          </ModalHeader>
          <ModalBody>
            <IntlMessage id="audit.download-audit-search-results-body" />
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={this.toggle}>
              <CSVLink
                data={this.getCsvData(this.props.data)}
                filename={"ems-reporting-" + this.props.type + "-results.csv"}
              >
                <span className="text-white">
                  <IntlMessage id="audit.this-page-only" />
                </span>
              </CSVLink>
            </Button>
            <StateButton
              color="primary"
              onClick={this.downloadAllResults}
              isLoading={this.state.isDownloadingAll}
            >
              <IntlMessage id="audit.all-results" />
            </StateButton>
            <Button color="secondary" onClick={this.toggle}>
              <IntlMessage id="Cancel" />
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}
export default Download;
