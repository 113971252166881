import { get } from "lodash";
import { store } from "../App";

const INITIAL_PATH = "appData.users.userPermissions.data.userPermissions";

const permission = path => {
  return store
    ? get(store.getState(), INITIAL_PATH + "." + path, false)
    : false;
};

export const readListAudit = () =>
  permission("auditManagementPermissions.listAuditEntries");

export const readListReporting = () =>
  permission("reportingManagementPermissions.listStatistics");

export const readListSearch = () =>
  permission("searchManagementPermissions.listSearch");

export const readProjets = () =>
  permission(
    "documentManagementPermissions.manageProjectPermissionsDto.addProject"
  );

export const readDocs = () =>
  permission("documentManagementPermissions.manageDocuments");

export const downloadDocsZip = () =>
  permission(
    "documentManagementPermissions.manageDocumentPermissions.downloadZip"
  );

export const downloadDocImages = () =>
  permission("documentManagementPermissions.downloadDocumentImage");

export const addDocs = () =>
  permission("documentManagementPermissions.importDocuments");

export const readMissingsDocs = () =>
  permission(
    "documentManagementPermissions.manageDocumentPermissions.showMissingDocuments"
  );
  export const readMissingsPages = () =>
  permission(
    "documentManagementPermissions.manageDocumentPermissions.showMissingPages"
  ); 

export const readPagesDocs = () =>
  permission(
    "documentManagementPermissions.manageDocumentPermissions.download"
  );
export const updateMetada = () =>
  permission(
    "documentManagementPermissions.manageDocumentPermissions.updateMetada"
  );
export const setTotalDocuments=()=>
permission(
  "documentManagementPermissions.manageDocumentPermissions.setTotalDocuments"
);
export const manageUsers = () =>
  permission("userManagementPermissions.manageUsers");

export const readUsers = () =>
  permission("userManagementPermissions.manageUsersPermission.listUsers");

export const readArchivedUsers = () =>
  permission(
    "userManagementPermissions.manageUsersPermission.listArchivedUsers"
  );
export const readTeams = () =>
  permission("userManagementPermissions.manageTeamPermission.listTeams");

export const manageRestrictions = () =>
  permission("userManagementPermissions.manageRestrictions");

export const canDeleteBox = () =>
  permission(
    "documentManagementPermissions.manageDocumentPermissions.deleteBox"
  );
export const canDeleteDocument = () =>
  permission(
    "documentManagementPermissions.manageDocumentPermissions.deleteDocument"
  );
export const canDeletePage = () =>
  permission(
    "documentManagementPermissions.manageDocumentPermissions.deletePage"
  );

export const canRequestDeleteBox = () =>
  permission(
    "documentManagementPermissions.manageDocumentPermissions.requestDeleteBox"
  );
export const canRequestDeleteDocument = () =>
  permission(
    "documentManagementPermissions.manageDocumentPermissions.requestDeleteDocument"
  );
export const canRequestDeletePage = () =>
  permission(
    "documentManagementPermissions.manageDocumentPermissions.requestDeletePage"
  );

export const viewDeleteRequests = () =>
  permission(
    "documentManagementPermissions.manageDocumentPermissions.viewDeleteRequests"
  );
