import * as types from "redux/actionTypes";

export const changeReportingTab = tab => ({
  type: types.CHANGE_REPORTING_TAB,
  payload: tab
});

export const searchBoxNoMetadata = (filter, page, size, success) => ({
  type: types.GET_BOX_WITHOUT_META,
  payload: { filter, page, size, success }
});
export const setBoxesNoMetadata = data => ({
  type: types.SET_BOX_WITHOUT_META,
  payload: data
});
export const errorBoxesNoMetadata = error => ({
  type: types.ERROR_BOX_WITHOUT_META,
  payload: error
});
export const changeBoxNoMetadataFilter = filter => ({
  type: types.CHANGE_BOX_WITHOUT_META_FILTER,
  payload: filter
});
export const changeBoxNoMetadataPageAndOrSize = pageAndOrSize => ({
  type: types.CHANGE_BOX_WITHOUT_META_PAGE_SIZE,
  payload: pageAndOrSize
});

export const searchDocNoMetadata = (filter, page, size, success) => ({
  type: types.GET_DOC_WITHOUT_META,
  payload: { filter, page, size, success }
});
export const setDocsNoMetadata = data => ({
  type: types.SET_DOC_WITHOUT_META,
  payload: data
});
export const errorDocsNoMetadata = error => ({
  type: types.ERROR_DOC_WITHOUT_META,
  payload: error
});
export const changeDocNoMetadataFilter = filter => ({
  type: types.CHANGE_DOC_WITHOUT_META_FILTER,
  payload: filter
});
export const changeDocNoMetadataPageAndOrSize = pageAndOrSize => ({
  type: types.CHANGE_DOC_WITHOUT_META_PAGE_SIZE,
  payload: pageAndOrSize
});

export const searchPageNoMetadata = (filter, page, size, success) => ({
  type: types.GET_PAGE_WITHOUT_META,
  payload: { filter, page, size, success }
});
export const setPagesNoMetadata = data => ({
  type: types.SET_PAGE_WITHOUT_META,
  payload: data
});
export const errorPagesNoMetadata = error => ({
  type: types.ERROR_PAGE_WITHOUT_META,
  payload: error
});
export const changePageNoMetadataFilter = filter => ({
  type: types.CHANGE_PAGE_WITHOUT_META_FILTER,
  payload: filter
});
export const changePageNoMetadataPageAndOrSize = pageAndOrSize => ({
  type: types.CHANGE_PAGE_WITHOUT_META_PAGE_SIZE,
  payload: pageAndOrSize
});
