import instance from "util/instances";
import { IPATTEMPTS_ENDPOINT } from "./endpoints";

export const getIpAttemptsApi = (page, resultPerPage) =>
  instance.get(IPATTEMPTS_ENDPOINT, {
    params: { page, resultPerPage }
  });
export const getAutoCompleteIpAttempts = text =>
  instance.get(IPATTEMPTS_ENDPOINT + "search", {
    params: { text, page: 1, resultPerPage: 10 }
  });
export const getByUserId = userId =>
  instance.get(IPATTEMPTS_ENDPOINT + "search", {
    params: { userId, page: 1, resultPerPage: 10 }
  });

export const postIpAttemptsApi = ipAttempt => instance.post(IPATTEMPTS_ENDPOINT, ipAttempt);
export const putIpAttemptsApi = ipAttempt => instance.put(IPATTEMPTS_ENDPOINT, ipAttempt);
export const deleteIpAttempts = ipAttemptIds => {
  const params = new URLSearchParams();
  ipAttemptIds.forEach(id => params.append("ipAttemptIds", id));
  return instance.delete(`${IPATTEMPTS_ENDPOINT}`, { params });
};
