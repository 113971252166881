import { all, fork, put, takeEvery, call } from "redux-saga/effects";

import * as types from "redux/actionTypes";
import {
  getBoxsApi,
  getBoxMetadataApi,
  getBoxDocsApi,
  updateBoxMetadata,
  getMissingsPagesApi,
  getTotalDocsApi,
  updateBoxTotalDocs,
  deleteBoxApi,
  sendRequestDeleteBoxApi
} from "./apis";

function* getBoxsSaga() {
  try {
    const response = yield call(getBoxsApi);
    if (response.status !== 200) {
      throw new Error(response.data.message);
    }
    yield put({ type: types.SET_BOXS, payload: response.data });
  } catch (error) {
    yield put({ type: types.ERROR_GETTING_BOXS, payload: error });
  }
}

function* getBoxMetadataSaga({ payload }) {
  const { id } = payload;
  try {
    const response = yield call(getBoxMetadataApi, id);
    if (response.status !== 200) {
      throw new Error(response.data.message);
    }
    yield put({ type: types.SET_BOX_METADATA, payload: response.data });
    yield put({ type: types.SET_DOCS_PAGES_URI, payload: null });
  } catch (error) {
    yield put({ type: types.ERROR_GETTING_BOX_META, payload: error });
  }
}

function* getBoxDocsSaga({ payload }) {
  const { id } = payload;
  try {
    const response = yield call(getBoxDocsApi, id);
    if (response.status !== 200) {
      throw new Error(response.data.message);
    }
    yield put({ type: types.SET_BOX_DOCS, payload: response.data });
  } catch (error) {
    yield put({ type: types.ERROR_GETTING_BOX_DOCS, payload: error });
  }
}

function* getTotalDocsSaga({ payload }) {
  const { id } = payload;
  try {
    const response = yield call(getTotalDocsApi, id);
    if (response.status !== 200) {
      throw new Error(response.data.message);
    }
    yield put({ type: types.SET_TOTAL_DOCS, payload: response.data });
  } catch (error) {
    yield put({ type: types.ERROR_GETTING_TOTAL_DOCS, payload: error });
  }
}

function* updateBoxMetadataSaga({ payload }) {
  const { id, data, success, error } = payload;
  try {
    yield call(updateBoxMetadata, id, data);
    if (success) success();

    yield call(getBoxMetadataSaga, { payload });
  } catch (err) {
    if (error) error(err);
  }
}

function* getMissingsPagesSaga({ payload }) {
  const { project, box, document, page } = payload;
  try {
    const response = yield call(
      getMissingsPagesApi,
      project,
      box,
      document,
      page
    );
    if (response.status !== 200) {
      throw new Error(response.data.message);
    }
    yield put({ type: types.SET_MISSINGS_PAGES, payload: response.data });
  } catch (error) {
    yield put({ type: types.ERROR_MISSINGS_PAGES, payload: error });
  }
}

function* updateTotalDocsSaga({ payload }) {
  const { id, data, success, error } = payload;
  try {
    yield call(updateBoxTotalDocs, id, data);
    if (success) success();

    yield call(getTotalDocsApi, id);
  } catch (err) {
    if (error) error(err);
  }
}

function* deleteBoxSaga({ payload }) {
  const { id, success, error } = payload;
  try {
    const response = yield call(deleteBoxApi, id);
    if (response.status === 200) {
      if (success) success();
    } else {
      if (error) error();
    }
  } catch (err) {
    if (error) error();
  }
}

function* sendRequestDeleteBoxSaga(action) {
  const { boxId, success, error } = action.payload;
  try {
    const response = yield call(sendRequestDeleteBoxApi, boxId);
    if (response.status === 200) {
      if (success) success();
    } else {
      if (error) error();
    }
  } catch (err) {
    if (error) error();
  }
}

export function* watchBoxsSaga() {
  yield takeEvery(types.GET_BOXS, getBoxsSaga);
  yield takeEvery(types.GET_BOX_METADATA, getBoxMetadataSaga);
  yield takeEvery(types.GET_BOX_DOCS, getBoxDocsSaga);
  yield takeEvery(types.GET_TOTAL_DOCS, getTotalDocsSaga);
  yield takeEvery(types.UPDATE_BOX_METADATA, updateBoxMetadataSaga);
  yield takeEvery(types.MISSINGS_PAGES, getMissingsPagesSaga);
  yield takeEvery(types.UPDATE_BOX_TOTAL_DOCS, updateTotalDocsSaga);
  yield takeEvery(types.DELETE_BOX, deleteBoxSaga);
  yield takeEvery(types.SEND_REQUEST_DELETE_BOX, sendRequestDeleteBoxSaga);
}

export default function* rootSaga() {
  yield all([fork(watchBoxsSaga)]);
}
