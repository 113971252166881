import * as types from "redux/actionTypes";

const INIT_STATE = {
  loading: false,
  data: {
    result: []
  },
  savedSearch: {},
  editedSearch: {},
  filter: {
    page: 1,
    pageSize: 10
  }
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case types.SET_SEARCHS:
      return {
        ...state,
        data: action.payload,
        loading: false
      };
    case types.GET_SEARCH:
      return {
        ...state,
        loading: true,
        error: undefined
      };
    case types.ERROR_GETTING_SEARCH:
      return {
        ...state,
        loading: false,
        error: action.payload
      };
    case types.SET_SEARCH:
      return {
        ...state,
        savedSearch: action.payload,
        loading: false
      };
    case types.GET_SEARCHS:
      return {
        ...state,
        loading: true,
        filter: action.payload.page
          ? action.payload
          : {
              page: 1,
              pageSize: 10
            },
        error: undefined
      };
    case types.ERROR_GETTING_SEARCHS:
      return {
        ...state,
        loading: false,
        error: action.payload
      };
    case types.EDIT_SEARCH_ON_MODAL:
      return { ...state, editedSearch: action.payload };
    case types.CLEAR_SAVED_SEARCH_FILTER:
      return { ...state, savedSearch: {} };
    default:
      return { ...state };
  }
};
