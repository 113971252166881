import { all, fork, put, takeEvery, call, select } from "redux-saga/effects";
import * as types from "redux/actionTypes";
import {
  getSearchsApi,
  postSearchsApi,
  putSearchsApi,
  deleteSearchs,
  getSearchByIdApi
} from "./apis";

function* getSearchsSaga() {
  try {
    const filter = yield select(state => state.appData.searchs.filter);

    const response = yield call(getSearchsApi, filter.page, filter.pageSize);
    yield put({ type: types.SET_SEARCHS, payload: response.data });
  } catch (error) {
    yield put({ type: types.ERROR_GETTING_SEARCHS, payload: error });
  }
}

function* postSearchSaga({ payload }) {
  const { search, success, error } = payload;
  try {
    const value = yield call(postSearchsApi, search) || {};
    if ([200, 201].includes(value.status)) {
      if (success) success();
      yield call(getSearchsSaga);
    } else {
      if (error) error();
    }
  } catch (err) {
    if (error) error(err);
  }
}

function* editSearchSaga({ payload }) {
  const { search, success, error } = payload;
  try {
    const value = yield call(putSearchsApi, search) || {};
    if ([200, 201].includes(value.status)) {
      if (success) success();
      yield call(getSearchsSaga);
    } else {
      if (error) error();
    }
  } catch (err) {
    if (error) error(err);
  }
}

function* deleteSearchSaga({ payload }) {
  const { id, success, error } = payload;
  try {
    const value = yield call(deleteSearchs, id) || {};
    if ([200, 201].includes(value.status)) {
      if (success) success();
      yield call(getSearchsSaga);
    } else {
      if (error) error();
    }
  } catch (err) {
    if (error) error(err);
  }
}

function* getSearchByIdSaga({ payload }) {
  const { id } = payload;
  try {
    const response = yield call(getSearchByIdApi, id);
    yield put({ type: types.SET_SEARCH, payload: response.data });
  } catch (error) {
    yield put({ type: types.ERROR_GETTING_SEARCH, payload: error });
  }
}

export function* watchSearchssSaga() {
  yield takeEvery(types.GET_SEARCHS, getSearchsSaga);
  yield takeEvery(types.DELETE_SEARCH, deleteSearchSaga);
  yield takeEvery(types.EDIT_SEARCH, editSearchSaga);
  yield takeEvery(types.POST_SEARCH, postSearchSaga);
  yield takeEvery(types.GET_SEARCH, getSearchByIdSaga);
}

export default function* rootSaga() {
  yield all([fork(watchSearchssSaga)]);
}
