import React, { Component, Fragment } from "react";
import { get } from "lodash";
import cs from "classnames";
import { injectIntl } from "react-intl";
import { connect } from "react-redux";
import {
  auditSearch,
  auditSearchTotalMetadata,
  getAuditSearchTotalActions,
  getRtUriNames,
  getDocUriApi
} from "redux/actions";
import { Filter } from "./filter";
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core/styles";
import LightSpinner from "components/LightSpinner";
import { LineChart } from "./LineChart";

class TotalMetadataCmp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showFilter: true,
      showData: true,
      options: {
        filterType: "checkbox",
        pagination: false,
        responsive: "scroll",
        filter: false,
        search: false,
        sort: false,
        selectableRows: "none",
        onRowClick: (v, u) => this.onRowClick(v, u),
      },
      changePageSize: false,
      listData: null
    };
  }

  auditSearch = values => {
    const { resultPerPage } = this.props.filterAudit;
    const page = 1;
    const data = getFilterValuesForSubmit(values);
    
    this.props.auditSearch({ ...data, page, resultPerPage });
    this.props.auditSearchTotalMetadata({ ...data });
  };


  getMuiTheme = () =>
    createMuiTheme({
      overrides: {
        MUIDataTable: {
          responsiveScroll: {
            maxHeight: this.state.showFilter ? "800px" : "1200px"
          }
        }
      }
    });

  render() {
    let {
      auditTotalMetadata,
      
    } = this.props;
    return (
      <Fragment>
      
          <Filter
            onSubmit={this.auditSearch}
            onClose={() => this.setState({ showFilter: false })}
            className={cs({ "d-none": !this.state.showFilter })}
          />
    
          {!this.state.showData && <LightSpinner />}
        <div className="mt-5" />
        <LineChart auditTotalMetadata={auditTotalMetadata} />
      </Fragment>
    );
  }
}
const mapStateToProps = state => {
  const {
    appData,
    form: { auditSearch = {} }
  } = state;
  return {
    auditTotalMetadata:appData.pages.auditTotalMetadata,
    listData: appData.pages.audit.data,
    loading: appData.pages.audit.loading,
    filterAudit: appData.pages.filterAudit,
    filterValues: getFilterValuesForSubmit(auditSearch.values),

  };
};

export const TotalMetadata = connect(
  mapStateToProps,
  { auditSearch, auditSearchTotalMetadata, getAuditSearchTotalActions, getRtUriNames, getDocUriApi }
)(injectIntl(TotalMetadataCmp));

const getFilterValuesForSubmit = values => {
  const data = Object.assign({}, values);
  data.rtUriNameId = get(data, "rtUriNameId.id");
  data.userName = get(data, "userName.username");
  data.teamId = get(data, "teamId.id");
  data.boxeId = get(data, "boxeId.id");
  data.documentId = get(data, "documentId.id");
  data.pageId = get(data, "pageId.id");

  return data;
};
