import React, { Component, Fragment } from "react";
import { injectIntl } from "react-intl";
import { Modal, Card, CustomInput, UncontrolledTooltip } from "reactstrap";
import classnames from "classnames";
import IntlMessages from "util/IntlMessages";
import { connect } from "react-redux";
import { get, range } from "lodash";
import { ContextMenu, MenuItem, ContextMenuTrigger } from "react-contextmenu";
import Switch from "react-switch";

import { Colxx } from "components/CustomBootstrap";
import mouseTrap from "react-mousetrap";
import { DataListContainer } from "components/DataListContainer";
import {
  getUsersData,
  changeUsersFilter,
  editUserOnModal,
  postUser,
  getUserRoles,
  deleteUser,
  updateUser,
  enableUser,
  getTeamsUser
} from "redux/actions";
import { AddUser } from "./addUser";
import { NotificationManager } from "components/ReactNotifications";
import { ConfirmationModal } from "../../../components/ConfirmationModal";
import { getTeams } from "redux/actions";

class Users extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedItems: [],
      errorAddingUser: false,
      isLoadingAddingUser: false,
      modalOpen: false
    };
  }

  componentWillMount() {
    this.props.getUsersData(this.props.isArchivedUsers);
    this.props.getTeams();
    if (!this.props.roles.length) {
      this.props.getUserRoles();
    }
    this.props.bindShortcut(["ctrl+a", "command+a"], () =>
      this.handleChangeSelectAll(false)
    );
    this.props.bindShortcut(["ctrl+d", "command+d"], () => {
      this.setState({
        selectedItems: []
      });
      return false;
    });
  }

  toggleModal = () => {
    this.setState({
      modalOpen: !this.state.modalOpen,
      errorAddingUser: false
    });
  };

  changeOrderBy = column => {
    const { usersFilter } = this.props;
    usersFilter.orderBy = column;
    usersFilter.page = 1;
    this.props.changeUsersFilter(usersFilter, this.props.isArchivedUsers);
  };

  changePageSize = size => {
    const { usersFilter } = this.props;
    usersFilter.pageSize = size;
    usersFilter.page = 1;
    this.props.changeUsersFilter(usersFilter, this.props.isArchivedUsers);
  };

  onChangePage = page => {
    const { usersFilter } = this.props;
    usersFilter.page = page;
    this.props.changeUsersFilter(usersFilter, this.props.isArchivedUsers);
  };

  handleCheckChange = (event, id) => {
    if (
      event.target.tagName === "A" ||
      (event.target.parentElement && event.target.parentElement.tagName === "A")
    ) {
      return true;
    }

    let { selectedItems } = this.state;
    if (selectedItems.includes(id)) {
      selectedItems = selectedItems.filter(x => x !== id);
    } else {
      selectedItems.push(id);
    }
    this.setState({
      selectedItems
    });
  };

  handleChangeSelectAll(isToggle) {
    let { selectedItems } = this.state;
    if (selectedItems.length >= this.props.usersData.result.length) {
      if (isToggle) {
        selectedItems = [];
      }
    } else {
      selectedItems = range(this.props.usersData.result.length);
    }
    this.setState({ selectedItems });
    return false;
  }

  onEnableUser = () => {
    const index = this.state.selectedItems[0];
    const user = this.props.usersData.result[index];
    this.props.enableUser(user.id, () => {
      this.createNotif("users.enabled-success-message");
    });
    return true;
  };

  onDeleteUsers = isConfirmed => {
    if (this.state.selectedItems.length > 0) {
      if (isConfirmed) {
        const userIds = this.state.selectedItems.map(
          index => this.props.usersData.result[index].id
        );
        this.props.deleteUser(userIds, () => {
          this.createNotif("users.deleted-success-message");
          this.setState({ isConfirmDelete: false, selectedItems: [] });
        });
        return true;
      }
      this.setState({ isConfirmDelete: true });
    }
  };

  onEditUser = (index, event) => {
    let user = this.props.usersData.result[index];
    this.teamUser(user.id);
    this.props.editUserOnModal(user);
    this.toggleModal();
  };

  teamUser = id => {
    this.props.getTeamsUser(id);
  };

  onAddUser = () => {
    this.props.editUserOnModal({});
    this.toggleModal();
  };

  createNotif = message => {
    NotificationManager.success(
      this.props.intl.messages[message],
      "",
      3000,
      null,
      null,
      "filled"
    );
  };

  onSubmitUser = ({ confirmPassword, teams, role, ...others }) => {
    this.setState({ errorAddingUser: false, isLoadingAddingUser: true });
    if (Array.isArray(teams)) {
      others.teamIds = teams.map(team => team.id);
    }
    if (role) {
      delete role.rolePermissions;
      others.role = role;
    }
    const successCallback = () => {
      this.setState({ isLoadingAddingUser: false });
      this.props.getUsersData();
      this.createNotif("users.add-success-message");
      this.toggleModal();
    };
    const errorCallback = errorMsg => {
      this.setState({
        errorAddingUser: true,
        isLoadingAddingUser: false,
        errorMsg
      });
    };
    if (others.id) {
      this.props.updateUser(others, successCallback, errorCallback);
    } else {
      this.props.postUser(others, successCallback, errorCallback);
    }
  };

  onContextMenu = ({ detail }) => {
    const selectedItems = [detail.data.data];
    this.setState({ selectedItems });
  };

  onChangePage = page => {
    const { usersFilter } = this.props;
    usersFilter.page = page;
    this.props.changeUsersFilter(usersFilter, this.props.isArchivedUsers);
  };

  handleChangeVisibility = (e, user) => {
    user.blocked = e ? 1 : 0;

    const successCallback = () => {
      this.setState({ isLoadingAddingUser: false });
      this.props.getUsersData();
    };
    const errorCallback = errorMsg => {
      this.setState({
        errorAddingUser: true,
        isLoadingAddingUser: false,
        errorMsg
      });
    };
    this.props.updateUser(user, successCallback, errorCallback);
  };

  conditionRender = () => {
    if (this.props.usersLoading) {
      return <div className="loading" />;
    }
    if (this.props.usersError) {
      return (
        <div className="col-12 text-center h6">
          <IntlMessages id={"user.error-gettings-users"} />
        </div>
      );
    }
    return false;
  };
  url = window.location;

  render() {
    const { usersFilter, usersData, isArchivedUsers } = this.props;
    return (
      <Fragment>
        <DataListContainer
          title={isArchivedUsers ? "user.Archived-Users" : "user.Manage-Uses"}
          addNewText="user.Add-User"
          hideButtons={isArchivedUsers}
          onAddClick={this.onAddUser}
          dataLength={usersData.total || 0}
          pageSize={usersFilter.pageSize}
          changePageSize={this.changePageSize}
          onChangeOrderBy={this.changeOrderBy}
          orderBy={usersFilter.orderBy}
          onChangePage={this.onChangePage}
          currentPage={usersFilter.page}
          isAllSelected={
            usersData.result.length === this.state.selectedItems.length
          }
          onAllSelectedClick={() => this.handleChangeSelectAll(true)}
          onDeleteSelected={() => this.onDeleteUsers()}
          deleteMessage="Archive"
        >
          {this.conditionRender()
            ? this.conditionRender()
            : usersData.result.map((user, index) => {
              return (
                <Colxx xxs="12" key={index} className="mb-3">
                  <ContextMenuTrigger
                    id="menu_id"
                    data={index}
                    collect={p => ({ data: p.data })}
                  >
                    <Card
                      onClick={(e) =>
                        e.target.className.includes("custom-control-input")
                          ? this.handleCheckChange(e, index)
                          : null
                      }
                      className={classnames("d-flex flex-row", {
                        active: this.state.selectedItems.includes(index)
                      })}
                    >
                      <div className="pl-2 d-flex flex-grow-1 min-width-zero">
                        <div className="card-body align-self-center d-flex flex-column flex-lg-row min-width-zero align-items-lg-center">
                          <p className="mb-1 ml-1 mr-2 text-muted text-small  w-sm-100">
                            {user.username}

                          </p>
                          <p className="mb-1 ml-2 text-muted text-small  w-sm-100">
                            {user.teamNamesList.join(" - ")}
                          </p>
                          <p className="mb-1 ml-2 text-muted text-small  w-sm-100">
                            {user.email}
                          </p>
                        </div>
                        <div className="card-body align-self-center d-flex flex-column flex-lg-row justify-content-between min-width-zero align-items-lg-center">
                          <p className="list-item-heading mb-1 truncate">
                            {this.url.href.indexOf("archived") !== -1 ? (
                              <span className="ems-users-griserArchive">
                                {user.name}
                              </span>
                            ) : (
                              user.name
                            )}
                          </p>

                          <p className="mb-1 text-muted text-small  w-sm-100">
                            {get(user, "role.name")}
                          </p>

                          <p className="mb-1 text-muted text-small  w-sm-100">
                            {user.authorizedIps}
                          </p>
                          <p className="mb-1 text-muted text-small  w-sm-100 d-flex align-items-center">
                            <Switch
                              onColor='#d93025'
                              height={20}
                              width={43}
                              index={user.id}
                              onChange={(e) =>
                                this.handleChangeVisibility(e, user)
                              }
                              checked={user.blocked === 1 || false}
                            />
                            <span href="#" id={`tooltipRef-${index}`} className="text-info ml-2">
                              <i className="simple-icon-exclamation" />
                            </span>
                            <UncontrolledTooltip target={`tooltipRef-${index}`}>
                              {user.blocked === 1 ? <IntlMessages id="users.blocked"/> : <IntlMessages id="users.unlocked"/>}
                            </UncontrolledTooltip>
                          </p>
                        </div>
                        {!isArchivedUsers && (
                          <Fragment>
                            <div className="align-self-center pr-3">
                              <a
                                href=" "
                                onClick={e => {
                                  e.preventDefault();
                                  this.onEditUser(index);
                                }}
                              >
                                <i className="simple-icon-pencil" />
                              </a>
                            </div>
                            <div className="align-self-center pr-4">
                              <a
                                href=" "
                                onClick={e => {
                                  e.preventDefault();
                                  this.setState(
                                    { selectedItems: [index] },
                                    () => this.onDeleteUsers()
                                  );
                                }}
                              >
                                <i className="simple-icon-trash" />
                              </a>
                            </div>
                            <div className="align-self-center pr-4">
                              <CustomInput
                                className="itemCheck mb-0"
                                type="checkbox"
                                id={`check_${index}`}
                                checked={this.state.selectedItems.includes(
                                  index
                                )}
                                label=""
                                onChange={() => { }}
                              />
                            </div>
                          </Fragment>
                        )}
                        {isArchivedUsers && (
                          <div className="align-self-center pr-4">
                            <a
                              href=" "
                              onClick={e => {
                                e.preventDefault();
                                this.setState(
                                  { selectedItems: [index] },
                                  this.onEnableUser
                                );
                              }}
                            >
                              <i className="simple-icon-check" />
                            </a>
                          </div>
                        )}
                      </div>
                    </Card>
                  </ContextMenuTrigger>
                </Colxx>
              );
            })}
        </DataListContainer>
        <ConfirmationModal
          isOpen={this.state.isConfirmDelete}
          toggle={() =>
            this.setState({ isConfirmDelete: !this.state.isConfirmDelete })
          }
          title={"users.confirm-delete-title"}
          content={"users.confirm-delete"}
          onConfirmClick={() => this.onDeleteUsers(true)}
        />
        <Modal
          isOpen={this.state.modalOpen}
          toggle={this.toggleModal}
          wrapClassName="modal-right"
          backdrop="static"
        >
          <AddUser
            errorAddingUser={this.state.errorAddingUser}
            isLoadingAddingUser={this.state.isLoadingAddingUser}
            msgError={this.state.errorMsg}
            toggleModal={this.toggleModal}
            onSubmit={this.onSubmitUser}
          />
        </Modal>

        <ContextMenu id="menu_id" onShow={this.onContextMenu}>
          {!isArchivedUsers && (
            <Fragment>
              <MenuItem onClick={() => this.onDeleteUsers()}>
                <i className="simple-icon-trash" />
                <span>
                  <IntlMessages id="Archive" />
                </span>
              </MenuItem>
              <MenuItem
                onClick={() => this.onEditUser(this.state.selectedItems[0])}
              >
                <i className="simple-icon-pencil" />
                <span>
                  <IntlMessages id="Edit" />
                </span>
              </MenuItem>
            </Fragment>
          )}
          {isArchivedUsers && (
            <MenuItem onClick={() => this.onEnableUser()}>
              <i className="simple-icon-check" />
              <span>
                <IntlMessages id="Enable" />
              </span>
            </MenuItem>
          )}
        </ContextMenu>
      </Fragment >
    );
  }
}

const mapStateToProps = ({ appData }) => ({
  usersData: appData.users.data,
  usersLoading: appData.users.loading,
  usersError: appData.users.error,
  usersFilter: appData.users.filter,
  roles: appData.users.roles
});

export default connect(
  mapStateToProps,
  {
    getUsersData,
    changeUsersFilter,
    editUserOnModal,
    postUser,
    getUserRoles,
    updateUser,
    deleteUser,
    enableUser,
    getTeams,
    getTeamsUser
  }
)(injectIntl(mouseTrap(Users)));
