import React, { Component } from "react";
import { connect } from "react-redux";

import IntlMessages from "util/IntlMessages";
import { injectIntl } from "react-intl";
import {
  Card,
  CardBody,
  CardTitle,
  CustomInput,
  Button,
  Row
} from "reactstrap";
import { Colxx } from "components/CustomBootstrap";
import {
  searchPageNoMetadata,
  changePageNoMetadataFilter,
  changePageNoMetadataPageAndOrSize
} from "redux/actions";
import { DataListContainer } from "components/DataListContainer";
import Download from "./Download";

class PageCmp extends Component {
  search = () => {
    this.props.searchPageNoMetadata();
  };
  toggleCheckbox = e => {
    const { id } = e.target;
    const { filter } = this.props.noMetadataPages;
    filter[id] = !filter[id];
    this.props.changePageNoMetadataFilter(filter);
  };
  changePageSize = size => {
    this.props.changePageNoMetadataPageAndOrSize({ page: 1, size });
  };
  onChangePage = page => {
    this.props.changePageNoMetadataPageAndOrSize({ page });
  };
  handleBoxClick = index => {
    const value = this.props.noMetadataPages.data.result[index];

    this.props.history.push(
      "/app/documents/list/box/" +
      value.documents.boxId +
      "/" +
      value.documents.id +
      "/" +
      value.id
    );
  };
  render() {
    const { noMetadataPages } = this.props;
    const { loading, data, page, size, filter } = noMetadataPages;

    return (
      <Colxx xxs="12">
        <Card>
          <CardBody>
            <CardTitle>
              <IntlMessages id="Search for documents box having no metadata for fields" />
            </CardTitle>
            <Row>
              <Colxx xxs="6">
                <CustomInput
                  className="itemCheck mb-1"
                  type="checkbox"
                  id="withoutCc"
                  checked={filter.withoutCc || false}
                  onChange={this.toggleCheckbox}
                >
                  <IntlMessages id="Cc" />

                </CustomInput>

                <CustomInput
                  className="itemCheck mb-1"
                  type="checkbox"
                  id="withoutFrom"
                  checked={filter.withoutFrom || false}
                  onChange={this.toggleCheckbox}
                >
                  <IntlMessages id="From" />
                </CustomInput>
                <CustomInput
                  className="itemCheck mb-1"
                  type="checkbox"
                  id="withoutTo"
                  checked={filter.withoutTo || false}
                  onChange={this.toggleCheckbox}
                >
                  <IntlMessages id="To" />
                </CustomInput>
                <CustomInput
                  className="itemCheck mb-4"
                  type="checkbox"
                  id="withoutSigntatureBlock"
                  checked={filter.withoutSigntatureBlock || false}
                  onChange={this.toggleCheckbox}
                >
                  <IntlMessages id="Tags" />
                </CustomInput>
              </Colxx>
              <Colxx xxs="6">
                <CustomInput
                  className="itemCheck mb-1"
                  type="checkbox"
                  id="withoutNameOfEntities"
                  checked={filter.withoutNameOfEntities || false}
                  onChange={this.toggleCheckbox}
                >
                  <IntlMessages id="Names of entities" />
                </CustomInput>
                <CustomInput
                  className="itemCheck mb-1"
                  type="checkbox"
                  id="withoutNameOfPeople"
                  checked={filter.withoutNameOfPeople || false}
                  onChange={this.toggleCheckbox}
                >
                  <IntlMessages id="Names of people" />
                </CustomInput>
                <CustomInput
                  className="itemCheck"
                  type="checkbox"

                  id="withoutNameOfPlaces"
                  checked={filter.withoutNameOfPlaces || false}
                  onChange={this.toggleCheckbox}
                >
                  <IntlMessages id="Names of places" />
                </CustomInput>
              </Colxx>
            </Row>
            <Button color="primary" className="ml-2 mb-2" onClick={this.search}>
              <IntlMessages id="menu.search" />
            </Button>
          </CardBody>
        </Card>
        {data.result && (
          <>{data.result.length !== undefined && data.result.length > 0 && <Download type="page" data={data.result} filter={filter} />}
            <DataListContainer
              hideButtons
              dataLength={data.total || 0}
              pageSize={size}
              changePageSize={this.changePageSize}
              onChangePage={this.onChangePage}
              currentPage={page}
            >
              {loading ? (
                <div className="loading" />
              ) : (
                data.result.map((page, index) => {
                  return (
                    <Colxx xxs="12" key={index} className="mb-3">
                      <Card
                        onClick={() => this.handleBoxClick(index)}
                        className={"d-flex flex-row"}
                      >
                        <div className="pl-2 d-flex flex-grow-1 min-width-zero">
                          <div className="card-body align-self-center d-flex flex-column flex-lg-row justify-content-between min-width-zero align-items-lg-center">
                            <p className="list-item-heading mb-1 truncate">
                              {page.documents.project +
                                "." +
                                page.documents.box +
                                "." +
                                page.documents.number +
                                "." +
                                page.number}
                            </p>
                            <p className="mb-1 text-muted text-small w-15 w-sm-100">
                              Page ID: {page.id}
                            </p>
                          </div>
                        </div>
                      </Card>
                    </Colxx>
                  );
                })
              )}
            </DataListContainer>
          </>
        )}
      </Colxx>
    );
  }
}

const mapStateToProps = ({ appData }) => ({
  noMetadataPages: appData.metadata.noMetadataPages
});

export const PageSearch = connect(
  mapStateToProps,
  {
    searchPageNoMetadata,
    changePageNoMetadataFilter,
    changePageNoMetadataPageAndOrSize
  }
)(injectIntl(PageCmp));
