import React, { Component } from "react";
import { connect } from "react-redux";
import { saveTotalDocuments, getTotalDocs } from "redux/actions";
import { reduxForm } from "redux-form";
import IntlMessages from "util/IntlMessages";
import StateButton from "components/StateButton";
import { renderDelay } from "../metadata/renderDelay";
import { Row } from "reactstrap";
import { isEmpty } from "lodash";
import { Colxx, Separator } from "components/CustomBootstrap";
import { NotificationManager } from "components/ReactNotifications";
import { injectIntl } from "react-intl";

class TotalDocumentCmp extends Component {
  constructor(props) {
    super(props);
    props.getTotalDocs(props.match.params.boxId);
    this.state = {
      totalDocument: {},
      totalPages: []
    };
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.totalDocument !== this.props.totalDocument) {
      this.setState({
        totalPages: nextProps.totalPages ? nextProps.totalPages : [],
        totalDocument: nextProps.totalDocument
      });
    }
  }

  setPagesUi() {
    if (this.props.totalPages && !isEmpty(this.props.totalPages))
      return (
        <Row>
          <Colxx xxs="12">
            <h2>
              <IntlMessages id="set-total-pages" />
            </h2>
            <Separator className="mb-5" />
          </Colxx>
          {this.createUI()}

        </Row>
      );
  }

  createUI() {
    return this.props.totalPages.map((el, i) => (
      <div key={i} className="col-3">
        <p className="text-muted text-small  col-1">{el.number}</p>
        <input
          disabled
          className="form-control  mb-2 ml-2"
          type="number"
          value={el.total}
          onChange={this.handleChangeTotalPages.bind(this, i)}
        />
      </div>
    ));
  }

  handleChangeTotalPages(i, event) {
    let totalPages = [...this.props.totalPages];
    totalPages[i] = { ...totalPages[i], total: parseInt(event.target.value) };
    this.setState({ totalPages });
  }

  handleChangeTotalDocs(event) {
    const newtotalDocument = {
      ...this.props.totalDocument,
      ...{ total: parseInt(event.target.value) }
    };
    const newState = { ...this.state, ...{ totalDocument: newtotalDocument } };
    this.setState(newState);
  }

  addClick() {
    const setedTotalDocument = this.state.totalDocument.total;
    const vaulesLength = this.state.totalPages.length;

    if (setedTotalDocument > vaulesLength) {
      const newtotalPages = this.state.totalPages;
      for (var i = vaulesLength; i < setedTotalDocument; i++) {
        newtotalPages.push({
          total: 0,
          number:
            this.state.totalDocument.number + "." + this.pageNumberFromIndex(i)
        });
      }
      this.setState({ totalPages: newtotalPages });
    } else if (setedTotalDocument < vaulesLength) {
      const newtotalPages = this.state.totalPages.splice(0, setedTotalDocument);
      this.setState({ totalPages: newtotalPages });
    }
  }

  pageNumberFromIndex = index => {
    index++;
    let number = "";
    if (index.toString().length === 1) number = "00" + index.toString();
    else if (index.toString().length === 2) number = "0" + index.toString();
    else number = index.toString();
    return number;
  };

  createNotif = message => {
    NotificationManager.success(
      this.props.intl.messages[message],
      "",
      1000,
      null,
      null,
      "filled"
    );
  };
  handleSubmit(event) {
    const toSave = this.state;
    this.props.saveTotalDocuments(this.props.match.params.boxId, toSave);
    this.createNotif("Changes saved successfully");
    event.preventDefault();
  }

  render() {
    const { totalDocument } = this.props;
    return (
      <Row>
        <Colxx xxs="12">
          <h1>
            <IntlMessages id="set-total-docs" /> {totalDocument ? totalDocument.number : ""}
          </h1>
          <Separator className="mb-5" />
        </Colxx>
        <Colxx xxs="12">
          <form onSubmit={this.handleSubmit}>
            <Row>
              <div className="col-6 mb-4">
                <input
                  disabled
                  className="form-control   mb-2 ml-2"
                  value={totalDocument ? totalDocument.total : ""}
                  type="number"
                  onChange={this.handleChangeTotalDocs.bind(this)}
                />
              </div>

            </Row>
            {this.setPagesUi()}
          </form>
        </Colxx>
      </Row>
    );
  }
}

const mapStateToProps = state => {
  const { appData } = state;
  return {
    totalDocument: appData.boxs.totalDocs.totalDocument,
    totalPages: appData.boxs.totalDocs.totalPages
  };
};
export const TotalDocument = connect(
  mapStateToProps,
  { getTotalDocs, saveTotalDocuments }
)(
  injectIntl(
    renderDelay(
      reduxForm({
        form: "setTotalDocs",
        enableReinitialize: true
      })(TotalDocumentCmp)
    )
  )
);
