import React, { Component, Fragment } from "react";
import IntlMessages from "util/IntlMessages";
import { Button, Card, CardBody, CardTitle } from "reactstrap";
import { connect } from "react-redux";
import { isEmpty } from "lodash";
import cs from "classnames";
import { Box } from "./box";
import { Document } from "./document";
import {
  updateBoxMetadata,
  updateDocsMetadata,
  getDocsMetadata,
  getBoxMetadata,
  getPageMetadata,
  updatePageMetadata
} from "redux/actions";
import { updateMetada } from "../../../../util/permissions"
import { Page } from "./page";
import { Video } from "./video";
import * as moment from "moment";
import { DATE_FORMAT } from "constants/defaultValues";

class MetaDataCmp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isBoxEdited: false,
      boxState: {
        error: undefined,
        loading: false
      },
      isDocumentEdited: false,
      docState: {
        error: undefined,
        loading: false
      },
      isPageEdited: false,
      pageState: {
        error: undefined,
        loading: false
      }
      , pageMetadata: {
        languageEmpty: false,
        formatEmpty: false,
        subjectEmpty: false
      }
    };

    if (this.props.showPageMetadata && isEmpty(this.props.pageMetadata)) {
      this.props.getPageMetadata(this.props.pageId);
    }

    if (this.props.showMetadata && isEmpty(this.props.docMetadata)) {
      this.props.getDocsMetadata(this.props.documentId);
    }

    if (isEmpty(this.props.boxMetadata)) {
      this.props.getBoxMetadata(this.props.boxId);
    }
  }

  saveBoxMetaData = values => {
    this.setState({ boxState: { error: undefined, loading: true } });

    if (values.dateSeized) {
      values.dateSeized = formatDate(values.dateSeized);
    }

    this.props.updateBoxMetadata(
      this.props.boxId,
      values,
      () => {
        this.setState({
          boxState: { error: undefined, loading: false },
          isBoxEdited: false
        });
      },
      () => {
        this.setState({
          boxState: { error: "errors.error-adding-user", loading: false }
        });
      }
    );
  };

  saveDocMetaData = values => {
    this.setState({ docState: { error: undefined, loading: true } });
    this.props.updateDocsMetadata(
      this.props.documentId,
      values,
      () => {
        this.setState({
          docState: { error: undefined, loading: false },
          isDocumentEdited: false
        });
      },
      () => {
        this.setState({
          docState: { error: "errors.error-adding-user", loading: false }
        });
      },
      this.props.docMetadata.documentType === "VIDEO"
    );
  };

  savePageMetaData = values => {
    this.setState({ pageState: { error: undefined, loading: true } });
    if (Array.isArray(values.signatureBlock)) {
      values.signatureBlock = values.signatureBlock.join(",");
    }
    if (values.date) {
      values.date = formatDate(values.date);
    }
    if (values.format && values.format !== undefined) {
      if (this.state.pageMetadata.formatEmpty)
        values.format = null;
      else
        values.format = values.format.name ? values.format.name : values.format;
    }

    if (values.subject && values.subject !== undefined) {
      if (this.state.pageMetadata.subjectEmpty)
        values.subject = null;
      else
        values.subject = JSON.stringify(values.subject);
    }

    if (values.language && values.language !== undefined) {
      if (this.state.pageMetadata.languageEmpty)
        values.language = null;
      else
        values.language = JSON.stringify(values.language);
    }



    this.props.updatePageMetadata(
      this.props.pageId,
      values,
      () => {
        this.setState({
          pageState: { error: undefined, loading: false },
          isPageEdited: false
        });
      },
      () => {
        this.setState({
          pageState: { error: "errors.error-adding-user", loading: false }
        });
      }
    );
  };



  render() {
    const { showMetadata, showPageMetadata, pageMetadata} = this.props;
    const { isBoxEdited, isDocumentEdited, isPageEdited } = this.state;

    return (
      <Fragment>
        {showPageMetadata && (
          <Card
            className={cs("ems-metadata", "mb-4", {
              "ems-metadata-edited": isPageEdited
            })}
          >
            <div className="position-absolute card-top-buttons">
              <Button
                outline
                disabled={!updateMetada()}
                color={"primary"}
                className={cs("icon-button", { active: isPageEdited })}
                onClick={() => this.setState({ isPageEdited: !isPageEdited })}
              >
                <i className="simple-icon-pencil" />
              </Button>
            </div>

            <CardBody>
              <CardTitle>
                <h5>
                  <IntlMessages id="Page Metadata" />
                </h5>
              </CardTitle>
              <Page
                isEdited={isPageEdited}
                onSubmit={this.savePageMetaData}
                onChangeLanguage={e => this.setState({ pageMetadata: { languageEmpty: e.language === null } })}
                onChangeFormat={e => this.setState({ pageMetadata: { languageEmpty: e.format === null } })}
                onChangeSubject={e => this.setState({ pageMetadata: { languageEmpty: e.subject === null } })}
                saveState={this.state.pageState}
              />
            </CardBody>
          </Card>
        )}
        {showMetadata && (
          <Card
            className={cs("ems-metadata", "mb-4", {
              "ems-metadata-edited": isDocumentEdited
            })}
          >
            <div className="position-absolute card-top-buttons">
              <Button
                outline
                disabled={!updateMetada()}
                color={"primary"}
                className={cs("icon-button", { active: isDocumentEdited })}
                onClick={() =>
                  this.setState({ isDocumentEdited: !isDocumentEdited })
                }
              >
                <i className="simple-icon-pencil" />
              </Button>
            </div>

            <CardBody>
              <CardTitle>
                <h5>
                  {pageMetadata.documentType === "VIDEO" && (
                    <IntlMessages id="Video" />
                  )}{" "}
                  <IntlMessages id="Folder Metadata" />
                </h5>
              </CardTitle>
              {pageMetadata.documentType === "VIDEO" ? (
                <Video
                  isEdited={isDocumentEdited}
                  onSubmit={this.saveDocMetaData}
                  saveState={this.state.docState}
                />
              ) : (
                <Document
                  isEdited={isDocumentEdited}
                  onSubmit={this.saveDocMetaData}
                  saveState={this.state.docState}
                />
              )}
            </CardBody>
          </Card>
        )}

        {<Card
          className={cs("ems-metadata", "mb-4", {
            "ems-metadata-edited": isBoxEdited
          })}
        >
          <div className="position-absolute card-top-buttons">
            <Button
              outline
              disabled={!updateMetada()}
              color={"primary"}
              className={cs("icon-button", { active: isBoxEdited })}
              onClick={() => this.setState({ isBoxEdited: !isBoxEdited })}
            >
              <i className="simple-icon-pencil" />
            </Button>
          </div>

          <CardBody>
            <CardTitle>
              <h5>
                <IntlMessages id="Box Metadata" />
              </h5>
            </CardTitle>
            <Box
              boxId={this.props.boxId}
              isBoxEdited={isBoxEdited}
              onSubmit={this.saveBoxMetaData}
              saveState={this.state.boxState}
            />
          </CardBody>
        </Card>}
      </Fragment>
    );
  }
}
const mapStateToProps = state => {
  const { appData } = state;
  return {
    docMetadata: appData.docs.metadata,
    boxMetadata: appData.boxs.metadata,
    pageMetadata: appData.pages.metadata
  };
};

export const MetaData = connect(
  mapStateToProps,
  {
    updateBoxMetadata,
    updateDocsMetadata,
    getDocsMetadata,
    getBoxMetadata,
    getPageMetadata,
    updatePageMetadata
  }
)(MetaDataCmp);

const formatDate = date => {
  if (typeof date === "string") return date;
  return moment(new Date(date)).format(DATE_FORMAT);
};

