import React from "react";
import { faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "reactstrap";
import IntlMessages from "util/IntlMessages";
import throttle from "lodash/throttle";

const HideMetadataButton = ({ onClick, ...props }) => (
  <Button outline color="primary" onClick={throttle(onClick, 500)} {...props}>
    <FontAwesomeIcon icon={faEyeSlash} className="mr-1" />
    <IntlMessages id="documents.hide-metadata" />
  </Button>
);

export default HideMetadataButton;
