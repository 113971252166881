import { addLocaleData } from "react-intl";
import enLang from "./entries/en-US";
import arLang from "./entries/ar-MA";
import frLang from "./entries/fr-FR";
import ckbLang from "./entries/ckb-IQ";
import kuLang from "./entries/ckb-IQQ";

const AppLocale = {
  en: enLang,
  ar: arLang,
  fr: frLang,
  so: ckbLang,
  ku: kuLang
};
addLocaleData([
  ...AppLocale.en.data,
  ...AppLocale.ar.data,
  ...AppLocale.fr.data,
  ...AppLocale.so.data,
  ...AppLocale.ku.data
]);

export default AppLocale;
