import React, { Component, Fragment } from "react";
import IntlMessages from "util/IntlMessages";
import { Button, Row } from "reactstrap";
import { connect } from "react-redux";
import Tree from "react-animated-tree";

import { Colxx, Separator } from "components/CustomBootstrap";
import { getMissingsPages } from "redux/actions";

const config = open => ({
  from: { height: 0, opacity: 0, transform: "none" },
  to: {
    height: open ? "auto" : 0,
    opacity: open ? 1 : 0,
    transform: "none"
  }
});

const SpecialTree = props => <Tree {...props} springConfig={config} />;

class MissingBoxCmp extends Component {
  constructor(props) {
    super(props);
    this.goBack = this.goBack.bind(this); // i think you are missing this

    const { boxMetadata = {}, documentMetadata = {} } = this.props;
    
    if (props.match.params.type === 'pages-in-box') {
      props.getMissingsPages(boxMetadata.project, boxMetadata.number, "isBox");
    }
    else if (props.match.params.type === 'pages') {
      props.getMissingsPages(documentMetadata.project, documentMetadata.box, documentMetadata.number);
    }
    else if (this.props.match.params.type === 'docs') {
      props.getMissingsPages(boxMetadata.project, boxMetadata.number);
    } else {
      this.props.history.push("/app/documents/list");
    }
  }
  goBack() {
    this.props.history.goBack();
  }
  goForward = () => {
    this.props.history.goForward();
  };
  render() {
    // const { boxMetadata = {} } = this.props;
    const { boxMetadata = {}, documentMetadata = {} } = this.props;
    const { missingsPages } = this.props;
    const missingDocuments = missingsPages.data.missingDocuments || [];
    const missingFolders = missingsPages.data.missingFolders || [];
    const missingPagess = missingsPages.data.missingPages;
    return (
      <Row>
        <Colxx xxs="12">
          <h1>
            <IntlMessages id={this.props.match.params.type === 'pages'||this.props.match.params.type === 'pages-in-box' ? "Missing Pages" : "Missings Documents"} />
          </h1>
          <Separator className="mb-3" />
          <div>
            <Button
              outline
              color="primary"
              className="mb-3"
              onClick={this.goBack}
            >
              <i className="simple-icon-arrow-left" />
              <IntlMessages id="Back" />
            </Button>
            <Button
              outline
              color="primary"
              className="mb-3 mr- ml-2"
              onClick={this.goForward}
            >
              <IntlMessages id="Forward" />
              <i className="simple-icon-arrow-right" />

            </Button>
          </div>
        </Colxx>
        {missingsPages.loading ? (
          <div className="loading" />
        ) : (
          <Fragment>
            {this.props.match.params.type === 'pages'||this.props.match.params.type === 'pages-in-box' && missingPagess ?
              missingPagess.length > 0 ?
                missingPagess.map((item, key) => (
                  <Colxx xxs="12" md="4" key={key}>

                    <SpecialTree
                      content={item.pageName}
                      canHide
                      open
                      onClick={() => {
                        this.props.history.push("/app/documents/importDoc");
                      }}
                    />

                  </Colxx>
                )) : <h5 style={{ color: 'red', 'margin-left': "30px" }}><IntlMessages id="page.no.missings" /></h5>
              : missingFolders && missingFolders.length > 0 ? missingFolders.map((item, key) => (
                <Colxx xxs="12" md="4" key={key}>
                  <SpecialTree
                    content={item.documentName.docName}
                    canHide
                    open
                    onClick={() => {
                      this.props.history.push("/app/documents/importDoc");
                    }}
                  />

                </Colxx>
              )) : <h5 style={{ color: 'red', 'margin-left': "30px" }}><IntlMessages id="doc.no.missings" /> </h5>}
          </Fragment>
        )}
      </Row>
    );
  }
}

const mapStateToProps = state => {
  const { appData } = state;
  return {
    missingsPages: appData.boxs.missingsPages,
    boxMetadata: appData.boxs.metadata,
    documentMetadata: appData.docs.metadata
  };
};

export const MissingBox = connect(
  mapStateToProps,
  { getMissingsPages }
)(MissingBoxCmp);
