import React, { Component } from "react";
import { connect } from "react-redux";
import IntlMessages from "util/IntlMessages";
import { injectIntl } from "react-intl";
import { NavLink } from "react-router-dom";
import get from "lodash/get";
import { Row, Card, CardBody } from "reactstrap";
import { Colxx, Separator } from "components/CustomBootstrap";
import { getReportingData } from "../../../redux/reproting/apis";
import * as roles from "constants/roles";
import { Redirect } from "react-router-dom";
import { totalCountField } from "../../../constants/totalCount";
import { optionsByInstance } from "../../../util/instanceTypesHelper";

class ReportingCmp extends Component {
  constructor(props) {
    super(props);
    this.optionsToDisplay = optionsByInstance(props.locale)

    this.state = {
      data: {}
    };
    this._isMounted = false;
  }
  componentDidMount = () => {
    this._isMounted = true;
    this.getReportingData();
  };

  getReportingData = async () => {
    const response = await getReportingData();
    this._isMounted && this.setState({ data: get(response, "data") });
  };

  componentWillUnmount = () => {
    this._isMounted = false;
  };


  render() {
    const totalsCount = this.state.data;
    const { userRole } = this.props;

    return (
      <div className="mb-2">
        <h1>
          <IntlMessages id={"menu.app"} />
        </h1>
        <Separator className="mb-5" />
        <Row className="icon-cards-row mb-2">
          {totalCountField.map((totalCount) => (<>
            <Colxx xxs="6" sm="4">
              <NavLink to="/app/documents/list">
                <Card className="mb-4">
                  <CardBody className="text-center">
                    {totalCount.icon}
                    <p className="card-text font-weight-semibold mb-3">
                      <IntlMessages id={totalCount.value === "totalEvidence" && this.optionsToDisplay.videosBoxes ? "Total evidence in EMS (Scanned, A-videos, E-files , Open-Source/ excluding translations)" : totalCount.id} />
                    </p>
                    <p className="lead text-center">{totalCount.value === "totalEvidence" && this.optionsToDisplay.videosBoxes ? totalsCount[totalCount.value] + totalsCount["totalAWithoutTranslation"] : totalsCount[totalCount.value]}</p>
                  </CardBody>
                </Card>
              </NavLink>
            </Colxx>
            {totalCount.value === "totalOSDocuments" && this.optionsToDisplay.videosBoxes
              && <Colxx xxs="6" sm="4">
                <NavLink to="/app/documents/list">
                  <Card className="mb-4">
                    <CardBody className="text-center">
                      <i className="simple-icon-camrecorder" />
                      <p className="card-text font-weight-semibold mb-3">
                        <IntlMessages id="Total Videos (A boxes)" />
                      </p>
                      <p className="lead text-center">{totalsCount["totalA"]}</p>
                    </CardBody>
                  </Card>
                </NavLink>
              </Colxx>
            }
          </>
          ))
          }
          <Colxx xxs="6" sm="4">
            <NavLink to="/app/totalMetadata">
              <Card className="mb-4">

                <CardBody className="text-center">
                  <i className="simple-icon-note" />
                  <p className="card-text font-weight-semibold mb-3">
                    <IntlMessages id="totalMetadatas" />
                  </p>
                  <p className="lead text-center">{totalsCount['totalMetadatas']}</p>
                </CardBody>
              </Card>
            </NavLink>
          </Colxx>
          <Colxx xxs="6" sm="4">
            <Card className="mb-4">
              <CardBody className="text-center">
                <i className="simple-icon-globe" />
                <p className="card-text font-weight-semibold mb-3">
                  <IntlMessages id="totalEnglishTranslations" />
                </p>
                <p className="lead text-center">{totalsCount['totalEnglishTranslations']}</p>
              </CardBody>
            </Card>
          </Colxx>
          <Colxx xxs="6" sm="4">
            <Card className="mb-4">
              <CardBody className="text-center">
                <i className="simple-icon-eye" />
                <p className="card-text font-weight-semibold mb-3">
                  <IntlMessages id="totalOcrs" />
                </p>
                <p className="lead text-center">{totalsCount['totalOcrs']}</p>
              </CardBody>
            </Card>
          </Colxx>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = ({ appData }) => {
  return {
    userRole: appData.users.userPermissions.data.role
  };
};

export const home = connect(mapStateToProps)(injectIntl(ReportingCmp));
