import instance from "util/instances";
import {
  GET_USERS_ENDPOINT,
  USERS_ENDPOINT,
  ROLES_ENDPOINT,
  LOGIN_ENDPOINT,
  ENABLE_USER_ENDPOINT,
  LOGOUT_ENDPOINT,
  GET_ARCHIVED_USERS_ENDPOINT,
  USER_PERMISSIONS_ENDPOINT
} from "./endpoints";

export const loginApi = user => {
  const data = new FormData();
  data.append("username", user.username);
  data.append("password", user.password);
  return instance.post(LOGIN_ENDPOINT, data);
};

export const logoutApi = () => instance.get(LOGOUT_ENDPOINT);

export const getUsersApi = (isArchivedUsers, page, resultPerPage) =>
  instance.get(
    isArchivedUsers ? GET_ARCHIVED_USERS_ENDPOINT : GET_USERS_ENDPOINT,
    {
      params: { page, resultPerPage }
    }
  );

export const getAutoCompleteUsers = text =>
  instance.get(USERS_ENDPOINT, {
    params: { text }
  });

export const postUser = user => instance.post(USERS_ENDPOINT, user);
export const getUserPermissionsApi = () =>
  instance.get(USER_PERMISSIONS_ENDPOINT);
export const putUser = user => instance.put(USERS_ENDPOINT, user);
export const deleteUserApi = userIds => {
  const params = new URLSearchParams();
  userIds.forEach(id => params.append("userIds", id));
  return instance.delete(`${USERS_ENDPOINT}`, {
    params
  });
};

export const enableUser = id =>
  instance.put(ENABLE_USER_ENDPOINT.replace("{id}", id));

export const getRoleApi = () => instance.get(ROLES_ENDPOINT);
