import instance from "util/instances";
import {
  GET_DELETE_REQUESTS_ENDPOINT,
  CONFIRM_DELETE_REQUESTS_ENDPOINT,
  CANCEL_DELETE_REQUESTS_ENDPOINT
} from "./endpoints";

export const getDeleteRequestsApi = (page, size) =>
  instance.get(
    GET_DELETE_REQUESTS_ENDPOINT + `/?page=${page}&resultPerPage=${size}`
  );
export const confirmDeleteRequestesApi = ids =>
  instance.delete(CONFIRM_DELETE_REQUESTS_ENDPOINT, {
    params: { deleteRequestIds: ids.join(",") }
  });
export const cancelDeleteRequestesApi = ids =>
  instance.delete(CANCEL_DELETE_REQUESTS_ENDPOINT, {
    params: { deleteRequestIds: ids.join(",") }
  });
