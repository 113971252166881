import React, { Component } from "react";
import IntlMessages from "util/IntlMessages";
import { Field, change, reduxForm } from "redux-form";
import {
  Row,
  Nav,
  NavItem,
  TabContent,
  TabPane,
  Button,
  FormGroup,
  Label
} from "reactstrap";
import { NavLink } from "react-router-dom";
import { optionsByInstance } from "util/instanceTypesHelper";
import classnames from "classnames";
import { ValidatedInput } from "components/FormFields/Input";
import { ValidatedAutocomplete } from "components/FormFields/Autocomplete";
import { Colxx, Separator } from "components/CustomBootstrap";
import { connect } from "react-redux";
import { renderDelay } from "../documents/metadata/renderDelay";
import DatePicker from "components/FormFields/DatePicker";
import { ValidatedSelected } from "components/FormFields/Select";
import { getAutoCompleteBox } from "redux/boxs/apis";
import { logger } from "util/Logger";
import { getSorts } from "./dataTableOptions";
import { getBoxTypes, CONTENT_TYPES } from "../../../constants/resourceTypes";
import { YesNoField } from "../../../components/FormFields/YesNoField";
import HijriDatePicker from "../../../components/FormFields/HijriDatePicker";
import { getLanguages } from "../documents/metadata/page";
import { renderMultiselect } from "../../../components/FormFields/MultiSelect";

const tabs = ["BASIC", "SPECIFIC", "ADVANCED"];


const onLoadBoxOptions = async (query, callback) => {
  try {
    await getAutoCompleteBox(query).then(
      response =>
        response &&
        response.status === 200 &&
        callback(response.data.result || [])
    );
  } catch (error) {
    logger("error getting Boxs", error);
  }
};
class FilterCmp extends Component {
  constructor(props) {
    super(props);
    this.optionsToDisplay = optionsByInstance(props.locale)

    this.state = {
      activeFirstTab: "BASIC",
      startDateSeized: "",
      endDateSeized: "",
      startDate: "",
      endDate: "",
      startHijriDate: null,
      endHijriDate: null,
    };
  }

  changeTab = (e, index) => {
    if (this.props.initialValues && this.props.initialValues.id) {
      let initialValues = this.props.initialValues;
      if (index !== initialValues.searchType) {
        if (initialValues.startDate && initialValues.startDate !== "")
          this.setState({ startDate: initialValues.startDate })
        if (initialValues.endDate && initialValues.endDate !== "")
          this.setState({ endDate: initialValues.endDate })

        if (initialValues.startDateSeized && initialValues.startDateSeized !== "")
          this.setState({ startDateSeized: initialValues.startDateSeized })
        if (initialValues.endDateSeized && initialValues.endDateSeized !== "")
          this.setState({ endDateSeized: initialValues.endDateSeized })

        if (initialValues.startHijriDate && initialValues.startHijriDate !== "")
          this.setState({ startHijriDate: initialValues.startHijriDate })
        if (initialValues.endHijriDate && initialValues.endHijriDate !== "")
          this.setState({ endHijriDate: initialValues.endHijriDate })
        
        initialValues.startDateSeized = "";
        initialValues.endDateSeized = "";

        initialValues.startDate = "";
        initialValues.endDate = "";
        initialValues.startHijriDate = null;
        initialValues.endHijriDate = null;
      } else {
        initialValues.startDate = this.state.startDate;
        initialValues.endDate = this.state.endDate;
        initialValues.startDateSeized = this.state.startDateSeized;
        initialValues.endDateSeized = this.state.endDateSeized;
        initialValues.startHijriDate = this.state.startHijriDate;
        initialValues.endHijriDate = this.state.endHijriDate;
      }
      this.props.initialize(initialValues)
    }
    else {
      this.props.initialize()
    }
    e.preventDefault();
    this.setState({ activeFirstTab: index });
    const searchFilter = JSON.parse(localStorage.getItem("SET_SEARCH_PAGE"));
    if (searchFilter) {
      const page = searchFilter.page;
      const resultPerPage = searchFilter.resultPerPage;
      const searchFilterStore = { "page": page, "resultPerPage": resultPerPage, "searchType": index };
      localStorage.setItem("SET_SEARCH_PAGE", JSON.stringify(searchFilterStore));
    }

    this.props.change("searchType", index);
  };

  componentWillReceiveProps(nextProps) {
    const setSearchPage = localStorage.getItem("SET_SEARCH_PAGE");
    if (nextProps.searchType !== this.props.searchType) {
      const selectedType = nextProps.searchType
        ? nextProps.searchType
        : tabs[0];
      this.setState({ activeFirstTab: selectedType });
    }
    else if ((setSearchPage !== null && this.props.searchType === undefined) || (setSearchPage !== null && JSON.parse(localStorage.getItem("SET_SEARCH_PAGE")) && JSON.parse(localStorage.getItem("SET_SEARCH_PAGE")).searchType && JSON.parse(localStorage.getItem("SET_SEARCH_PAGE")).searchType !== this.state.activeFirstTab)) {
      if (JSON.parse(setSearchPage).searchType !== undefined && JSON.parse(setSearchPage).searchType !== null)
        this.setState({ activeFirstTab: JSON.parse(localStorage.getItem("SET_SEARCH_PAGE")).searchType });
    }

  }


  render() {
    const { handleSubmit, onClose, className, onAddUItem, listProject, initProject, changeSort } = this.props;
    return (
      <Colxx xxs="12" className={className}>
        <form onSubmit={handleSubmit}>
          <Nav tabs className="separator-tabs ml-0 mb-5">
            <NavItem>
              <NavLink
                className={classnames({
                  active: this.state.activeFirstTab === "BASIC",
                  "nav-link": true
                })}
                onClick={e => { this.changeTab(e, "BASIC") }}
                to=" "
              >
                <IntlMessages id="search.basic-search" />
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={classnames({
                  active: this.state.activeFirstTab === "SPECIFIC",
                  "nav-link": true
                })}
                onClick={e => { this.changeTab(e, "SPECIFIC") }}
                to=" "
              >
                <IntlMessages id="search.specific-search" />
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={classnames({
                  active: this.state.activeFirstTab === "ADVANCED",
                  "nav-link": true
                })}
                onClick={e => { this.changeTab(e, "ADVANCED") }}
                to="#"
              >
                <IntlMessages id="search.advanced-search" />
              </NavLink>
            </NavItem>
          </Nav>
          <TabContent activeTab={this.state.activeFirstTab}>
            <TabPane tabId="BASIC">
              <Row>

                <Colxx xs={12} sm={6} md={4}>
                  <Field name="searchType" component={"input"} type="hidden" />
                  <Label htmlFor={"term"}>
                    <IntlMessages id={"Search term"} />
                  </Label>
                  <Field
                    className="mb-3"
                    name="term"
                    //      label="Search term"
                    component={ValidatedInput}
                    inputClassName="p-1"
                    labelClassName="mb-0"
                    type="text"
                  />
                </Colxx>
                <Colxx xs={12} sm={6} md={4}>
                  <Label htmlFor={"startDate"}>
                    <IntlMessages id={"Start Date"} />
                  </Label>
                  <Field
                    className="mb-3"
                    name="startDate"
                    component={DatePicker}
                    type="datetime-local"
                    options={{
                      dateFormat: "dd/MM/yyyy"
                    }}
                    inputClassName="p-1"
                    labelClassName="mb-0"
                  />
                </Colxx>
                <Colxx xs={12} sm={6} md={4}>
                  <Label htmlFor={"endDate"}>
                    <IntlMessages id={"End Date"} />
                  </Label>
                  <Field
                    className="mb-3"
                    name="endDate"
                    component={DatePicker}
                    type="datetime-local"
                    options={{
                      dateFormat: "dd/MM/yyyy"
                    }}
                    inputClassName="p-1"
                    labelClassName="mb-0"
                  />
                </Colxx>

                {this.optionsToDisplay.hijriDateField && <>
                  <Colxx xs={12} sm={6} md={4} style={{ position: "relative" }}>
                    <Label htmlFor={"startHijriDate"}>
                      <IntlMessages id={"Start Hijri Date"} />
                    </Label>
                    <Field
                      className="mb-3"
                      inputClassName="p-1"
                      labelClassName="mb-0"
                      name="startHijriDate"
                      component={HijriDatePicker}
                      type="datetime-local"
                      options={{
                        dateFormat: "dd/MM/yyyy",
                      }}
                    />
                    <Button
                      color="transparent"
                      style={{
                        backgroundColor: "transparent",
                        paddingLeft: "0px",
                        paddingRight: "0px",
                        paddingBottom: "0px",
                        paddingTop: "0px",
                        position: "absolute",
                        top: 30,
                        right: 17
                      }}
                      onClick={() => this.props.updateFormValues("startHijriDate", null)}
                    >
                      <span className="simple-icon-close" style={{
                        color: "#8f8f8f",
                        fontSize: "large"
                      }} />
                    </Button>
                  </Colxx>

                  <Colxx xs={12} sm={6} md={4} style={{ position: "relative" }}>
                    <Label htmlFor={"endHijriDate"}>
                      <IntlMessages id={"End Hijri Date"} />
                    </Label>
                    <Field
                      className="mb-3"
                      inputClassName="p-1"
                      labelClassName="mb-0"
                      name="endHijriDate"
                      component={HijriDatePicker}
                      type="datetime-local"
                      options={{
                        dateFormat: "dd/MM/yyyy"
                      }}
                    />
                    <Button
                      color="transparent"
                      style={{
                        backgroundColor: "transparent",
                        paddingLeft: "0px",
                        paddingRight: "0px",
                        paddingBottom: "0px",
                        paddingTop: "0px",
                        position: "absolute",
                        top: 30,
                        right: 17
                      }}
                      onClick={() => this.props.updateFormValues("endHijriDate", null)}
                    >
                      <span className="simple-icon-close" style={{
                        color: "#8f8f8f",
                        fontSize: "large"
                      }} />
                    </Button>
                  </Colxx>
                </>}

                <Colxx xs={12} sm={6} md={4}>
                  <Label htmlFor={"project"}>
                    <IntlMessages id={"Project"} />
                  </Label>

                  <Field
                    className="mb-3"
                    name="project"
                    component={ValidatedSelected}
                    defaultValue={initProject && initProject.id ? initProject : ""}
                    labelClassName="mb-0"
                    getOptionLabel={i => i.prefix}
                    getOptionValue={i => i.id}
                    options={listProject.result ? [] : listProject}
                    isClearable
                    placeholder=""
                  />
                </Colxx>
                <Colxx xs={12} sm={6} md={4}>
                  <Label htmlFor={"box"}>
                    <IntlMessages id={"Box"} />
                  </Label>
                  <Field
                    className="mb-3"
                    name="box"
                    component={ValidatedAutocomplete}
                    labelClassName="mb-0"
                    isSearchable
                    loadOptions={onLoadBoxOptions}
                    getOptionLabel={i => `${i.project}.${i.number}`}
                    getOptionValue={i => i.id}
                    cacheOptions
                    isClearable
                    placeholder=""
                  />
                </Colxx>
                <Colxx xs={12} sm={6} md={4}>
                  <Label htmlFor={"sort"}>
                    <IntlMessages id={"Sort"} />
                  </Label>

                  <Field
                    className="mb-3"
                    name="sort"
                    component={ValidatedSelected}
                    labelClassName="mb-0"
                    getOptionLabel={i => <IntlMessages id={i.name} />}
                    getOptionValue={i => i.id}
                    onChange={e => changeSort(e)}
                    options={getSorts()}
                    isClearable
                    placeholder=""
                  />
                </Colxx>
                <Colxx xs={12} sm={6} md={4}>
                  <Label htmlFor={"boxType"}>
                    <IntlMessages id={"Box type"} />
                  </Label>
                  <Field
                    className="mb-3"
                    name="boxType"
                    component={ValidatedSelected}
                    labelClassName="mb-0"
                    getOptionLabel={i => <IntlMessages id={i.label} />}
                    getOptionValue={i => i.value}
                    isClearable
                    placeholder=""
                    options={getBoxTypes(this.optionsToDisplay.videosBoxes)}
                    isMulti
                  />
                </Colxx>
                <Colxx xs={12} sm={6} md={4}>
                  <Label htmlFor={"contentType"}>
                    <IntlMessages id={"Content type"} />
                  </Label>
                  <Field
                    className="mb-3"
                    name="contentType"
                    component={ValidatedSelected}
                    labelClassName="mb-0"
                    getOptionLabel={i => <IntlMessages id={i.label} />}
                    getOptionValue={i => i.id}
                    options={CONTENT_TYPES}
                    isClearable
                    placeholder=""
                    isMulti
                  />
                </Colxx>
                <Colxx xs={12} sm={6} md={4}>
                  <Label htmlFor={"language"}>
                    <IntlMessages id={"Languages"} />
                  </Label>
                  <Field
                    className="mb-3"
                    name="language"
                    component={renderMultiselect}
                    labelClassName="mb-0"
                    getOptionLabel={i => <IntlMessages id={i.name} />}
                    getOptionValue={i => i.id}
                    options={getLanguages()}
                    isClearable
                    placeholder=""
                    isMulti
                  />
                </Colxx>
                <Colxx xs={12}>
                  <FormGroup className="mb-0">
                    <Label>
                      <Field
                        name="fuzzyActivated"
                        component={"input"}
                        type="checkbox"
                      />
                      <IntlMessages
                        id={
                          "Activate fuzzy search (search for terms that are similar to, but not exactly like our search term)"
                        }
                      />
                    </Label>
                  </FormGroup>
                </Colxx>
                <Colxx xs={12}>
                  <FormGroup className="mb-0">
                    <Label>
                      <Field
                        name="ocr"
                        component={"input"}
                        type="checkbox"
                      />
                      <IntlMessages id="Ocr" />
                    </Label>
                  </FormGroup>
                </Colxx>
                <Colxx xs={12}>
                  <FormGroup className="mb-0">
                    <Label>
                      <Field
                        name="folderMetaData"
                        component={"input"}
                        type="checkbox"
                      />
                      <IntlMessages id="Folder Metadata" />
                    </Label>
                  </FormGroup>
                </Colxx>
                <Colxx xs={12}>
                  <FormGroup className="mb-0">
                    <Label>
                      <Field
                        name="boxMetaData"
                        component={"input"}
                        type="checkbox"
                      />
                      <IntlMessages id="Box Metadata" />
                    </Label>
                  </FormGroup>
                </Colxx>
                <Colxx xs={12}>
                  <FormGroup className="mb-0">
                    <Label>
                      <Field
                        name="pageMetaData"
                        component={"input"}
                        type="checkbox"
                      />
                      <IntlMessages id="Page Metadata" />
                    </Label>
                  </FormGroup>
                </Colxx>
                <Colxx xs={12}>
                  <FormGroup className="mb-0">
                    <Label>
                      <Field
                        name="relevant"
                        component={YesNoField}
                        label="Yes/No"
                        className="mr-1"
                      />
                      <IntlMessages id="Relevant" />
                    </Label>
                  </FormGroup>
                </Colxx>
                <Colxx xs={12}>
                  <FormGroup className="mb-0">
                    <Label>
                      <Field
                        name="hasEnTranslation"
                        component={YesNoField}
                        label="Yes/No"
                        className="mr-1"
                      />
                      <IntlMessages id="Translation" />
                    </Label>
                  </FormGroup>
                </Colxx>        
              </Row>
            </TabPane>
            <TabPane tabId="SPECIFIC">
              <Row>

                <Colxx xs={12} sm={6} md={4}>
                  <Label htmlFor={"sort"}>
                    <IntlMessages id={"Sort"} />
                  </Label>

                  <Field
                    className="mb-3"
                    name="sort"
                    component={ValidatedSelected}
                    labelClassName="mb-0"
                    getOptionLabel={i => <IntlMessages id={i.name} />}
                    getOptionValue={i => i.id}
                    onChange={e => changeSort(e)}
                    options={getSorts()}
                    isClearable
                    placeholder=""
                  />
                </Colxx>

                <Colxx xs={12} sm={6} md={3}>

                  <Label htmlFor={"project"}>
                    <IntlMessages id={"Project"} />
                  </Label>
                  <Field
                    className="mb-3"
                    name="project"
                    component={ValidatedSelected}
                    labelClassName="mb-0"
                    defaultValue={initProject && initProject.id ? initProject : ""}
                    getOptionLabel={i => i.prefix}
                    getOptionValue={i => i.id}
                    options={listProject.result ? [] : listProject}
                    isClearable
                    placeholder=""
                  />

                </Colxx>
                <Colxx xs={12} sm={6} md={4}>
                  <Label htmlFor={"box"}>
                    <IntlMessages id={"Box"} />
                  </Label>
                  <Field
                    className="mb-3"
                    name="box"
                    component={ValidatedAutocomplete}
                    labelClassName="mb-0"
                    isSearchable
                    loadOptions={onLoadBoxOptions}
                    getOptionLabel={i => `${i.project}.${i.number}`}
                    getOptionValue={i => i.id}
                    cacheOptions
                    isClearable
                    placeholder=""
                  />
                </Colxx>
                <Colxx xs={12} sm={6} md={4}>
                  <Label htmlFor={"boxType"}>
                    <IntlMessages id={"Box type"} />
                  </Label>
                  <Field
                    className="mb-3"
                    name="boxType"
                    component={ValidatedSelected}
                    labelClassName="mb-0"
                    getOptionLabel={i => <IntlMessages id={i.label} />}
                    getOptionValue={i => i.value}
                    options={getBoxTypes(this.optionsToDisplay.videosBoxes)}
                    isClearable
                    placeholder=""
                    isMulti
                  />
                </Colxx>
                <Colxx xs={12} sm={6} md={3}>
                  <Label htmlFor={"contentType"}>
                    <IntlMessages id={"Content type"} />
                  </Label>
                  <Field
                    className="mb-3"
                    name="contentType"
                    component={ValidatedSelected}
                    labelClassName="mb-0"
                    getOptionLabel={i => <IntlMessages id={i.label} />}
                    getOptionValue={i => i.id}
                    options={CONTENT_TYPES}
                    isClearable
                    placeholder=""
                    isMulti
                  />
                </Colxx>
                <Colxx xs={12} sm={6} md={3}>
                  <Label htmlFor={"language"}>
                    <IntlMessages id={"Languages"} />
                  </Label>
                  <Field
                    className="mb-3"
                    name="language"
                    component={renderMultiselect}
                    labelClassName="mb-0"
                    getOptionLabel={i => <IntlMessages id={i.name} />}
                    getOptionValue={i => i.id}
                    options={getLanguages()}
                    isClearable
                    placeholder=""
                    isMulti
                  />
                </Colxx>
                <Colxx xs={12} sm={12} md={12}>

                  <h6 style={{ fontWeight: "bold" }}><IntlMessages id={"Box Metadata"} /></h6>

                </Colxx>

                <Colxx xs={12} sm={6} md={4}>

                  <Label htmlFor={"locationSeized"}>
                    <IntlMessages id={"Location seized"} />
                  </Label>
                  <Field
                    className="mb-3"
                    name="locationSeized"
                    component={ValidatedInput}
                    inputClassName="p-1"
                    labelClassName="mb-0"
                    type="text"
                  />

                </Colxx>
                <Colxx xs={12} sm={6} md={4}>

                  <Label htmlFor={"seizedBy"}>
                    <IntlMessages id={"Seized by"} />
                  </Label>
                  <Field
                    className="mb-3"
                    name="seizedBy"
                    component={ValidatedInput}
                    inputClassName="p-1"
                    labelClassName="mb-0"
                    type="text"
                  />

                </Colxx>
                <Colxx xs={12} sm={6} md={4}>
                  <Label htmlFor={"notes"}>
                    <IntlMessages id={"Notes"} />
                  </Label>
                  <Field
                    className="mb-3"
                    name="notes"
                    component={ValidatedInput}
                    inputClassName="p-1"
                    labelClassName="mb-0"
                    type="text"
                  />
                </Colxx>
                <Colxx xs={12} sm={6} md={4}>
                  <Label htmlFor={"startDateSeized"}>
                    <IntlMessages id={"Start Date"} />
                  </Label>
                  <Field
                    className="mb-3"
                    name="startDateSeized"
                    component={DatePicker}
                    type="datetime-local"
                    options={{
                      dateFormat: "dd/MM/yyyy"
                    }}
                    inputClassName="p-1"
                    labelClassName="mb-0"
                  />
                </Colxx>
                <Colxx xs={12} sm={6} md={4}>
                  <Label htmlFor={"endDateSeized"}>
                    <IntlMessages id={"End Date"} />
                  </Label>
                  <Field
                    className="mb-3"
                    name="endDateSeized"
                    component={DatePicker}
                    type="datetime-local"
                    options={{
                      dateFormat: "dd/MM/yyyy"
                    }}
                    inputClassName="p-1"
                    labelClassName="mb-0"
                  />
                </Colxx>
                <div className="w-100 d-none d-md-block" />
                <Colxx xs={12} sm={12} md={12}>

                  <h6 style={{ fontWeight: "bold" }}><IntlMessages id={"Folder Metadata"} /></h6>

                </Colxx>
                <Colxx xs={12} sm={6} md={3}>
                  <Label htmlFor={"docFrom"}>
                    <IntlMessages id={"From"} />
                  </Label>
                  <Field
                    className="mb-3"
                    name="docFrom"
                    component={ValidatedInput}
                    inputClassName="p-1"
                    labelClassName="mb-0"
                    type="text"
                  />
                </Colxx>
                <Colxx xs={12} sm={6} md={3}>
                  <Label htmlFor={"docTo"}>
                    <IntlMessages id={"To"} />
                  </Label>
                  <Field
                    className="mb-3"
                    name="docTo"
                    component={ValidatedInput}
                    inputClassName="p-1"
                    labelClassName="mb-0"
                    type="text"
                  />
                </Colxx>
                <Colxx xs={12} sm={6} md={3}>
                  <Label htmlFor={"docCc"}>
                    <IntlMessages id={"CC"} />
                  </Label>
                  <Field
                    className="mb-3"
                    name="docCc"
                    component={ValidatedInput}
                    inputClassName="p-1"
                    labelClassName="mb-0"
                    type="text"
                  />
                </Colxx>
                <Colxx xs={12} sm={6} md={3}>
                  <Label htmlFor={"docSummary"}>
                    <IntlMessages id={"Summary"} />
                  </Label>
                  <Field
                    className="mb-3"
                    name="docSummary"
                    component={ValidatedInput}
                    inputClassName="p-1"
                    labelClassName="mb-0"
                    type="text"
                  />
                </Colxx>
                <Colxx xs={12} sm={12} md={12}>

                  <h6 style={{ fontWeight: "bold" }}><IntlMessages id={"Page Metadata"} /></h6>

                </Colxx>
                <Colxx xs={12} md={9}>
                  <Row>
                    <Colxx xs={12} sm={6} md={4}>
                      <Label htmlFor={"from"}>
                        <IntlMessages id={"From"} />
                      </Label>
                      <Field
                        className="mb-3"
                        name="from"
                        component={ValidatedInput}
                        inputClassName="p-1"
                        labelClassName="mb-0"
                        type="text"
                      />
                    </Colxx>
                    <Colxx xs={12} sm={6} md={4}>
                      <Label htmlFor={"to"}>
                        <IntlMessages id={"To"} />
                      </Label>
                      <Field
                        className="mb-3"
                        name="to"
                        component={ValidatedInput}
                        inputClassName="p-1"
                        labelClassName="mb-0"
                        type="text"
                      />
                    </Colxx>

                    <Colxx xs={12} sm={6} md={4}>
                      <Label htmlFor={"cc"}>
                        <IntlMessages id={"Cc"} />
                      </Label>
                      <Field
                        className="mb-3"
                        name="cc"
                        component={ValidatedInput}
                        inputClassName="p-1"
                        labelClassName="mb-0"
                        type="text"
                      />
                    </Colxx>
                  </Row>
                </Colxx>
                <Colxx xs={12} md={9}>
                  <Row>

                    <Colxx xs={12} sm={6} md={4}>
                      <Label htmlFor={"namesOfPeople"}>
                        <IntlMessages id={"Names of people"} />
                      </Label>
                      <Field
                        className="mb-3"
                        name="namesOfPeople"
                        component={ValidatedInput}
                        inputClassName="p-1"
                        labelClassName="mb-0"
                        type="text"
                      />
                    </Colxx>
                    <Colxx xs={12} sm={6} md={4}>
                      <Label htmlFor={"namesOfPlaces"}>
                        <IntlMessages id={"Names of places"} />
                      </Label>
                      <Field
                        className="mb-3"
                        name="namesOfPlaces"
                        component={ValidatedInput}
                        inputClassName="p-1"
                        labelClassName="mb-0"
                        type="text"
                      />
                    </Colxx>
                    <Colxx xs={12} sm={6} md={4}>
                      <Label htmlFor={"namesOfEntities"}>
                        <IntlMessages id={"Names of entities"} />
                      </Label>
                      <Field
                        className="mb-3"
                        name="namesOfEntities"
                        component={ValidatedInput}
                        inputClassName="p-1"
                        labelClassName="mb-0"
                        type="text"
                      />
                    </Colxx>
                  </Row>
                </Colxx>

                <Colxx xs={12} sm={6} md={3}>
                  <Label htmlFor={"signatureBlock"}>
                    <IntlMessages id={"Tags"} />
                  </Label>
                  <Field
                    className="mb-3 p-1 form-control"
                    name="signatureBlock"
                    component={"textarea"}
                  />
                </Colxx>
              </Row>
              <Row>
                <Colxx xs={12} sm={6} md={3}>
                  <Label htmlFor={"startDate"}>
                    <IntlMessages id={"Start Date"} />
                  </Label>
                  <Field
                    className="mb-3"
                    name="startDate"
                    value={new Date(this.props.initialValues.startDate)}
                    component={DatePicker}
                    type="datetime-local"
                    options={{
                      dateFormat: "dd/MM/yyyy"
                    }}
                    inputClassName="p-1"
                    labelClassName="mb-0"
                  />
                  {/* <Field
                        className="mb-3"
                        name="startDate"
                        component={ValidatedInput}
                        inputClassName="p-1"
                        labelClassName="mb-0"
                        type="date"
                      /> */}
                </Colxx>
                <Colxx xs={12} sm={6} md={3}>
                  <Label htmlFor={"endDate"}>
                    <IntlMessages id={"End Date"} />
                  </Label>
                  <Field
                    className="mb-3"
                    name="endDate"
                    component={DatePicker}
                    type="datetime-local"
                    options={{
                      dateFormat: "dd/MM/yyyy"
                    }}
                    inputClassName="p-1"
                    labelClassName="mb-0"
                  />
                  {/* <Field
                        className="mb-3"
                        name="endDate"
                        component={ValidatedInput}
                        inputClassName="p-1"
                        labelClassName="mb-0"
                        type="date"
                      /> */}
                </Colxx>
                {this.optionsToDisplay.hijriDateField && <>
                  <Colxx xs={12} sm={6} md={3} style={{ position: "relative" }}>
                    <Label htmlFor={"startHijriDate"}>
                      <IntlMessages id={"Start Hijri Date"} />
                    </Label>
                    <Field
                      className="mb-3"
                      inputClassName="p-1"
                      labelClassName="mb-0"
                      name="startHijriDate"
                      component={HijriDatePicker}
                      type="datetime-local"
                      options={{
                        dateFormat: "dd/MM/yyyy",
                      }}
                    />
                    <Button
                      color="transparent"
                      style={{
                        backgroundColor: "transparent",
                        paddingLeft: "0px",
                        paddingRight: "0px",
                        paddingBottom: "0px",
                        paddingTop: "0px",
                        position: "absolute",
                        top: 30,
                        right: 17
                      }}
                      onClick={() => this.props.updateFormValues("startHijriDate", null)}
                    >
                      <span className="simple-icon-close" style={{
                        color: "#8f8f8f",
                        fontSize: "large"
                      }} />
                    </Button>
                  </Colxx>

                  <Colxx xs={12} sm={6} md={3} style={{ position: "relative" }}>
                    <Label htmlFor={"endHijriDate"}>
                      <IntlMessages id={"End Hijri Date"} />
                    </Label>
                    <Field
                      className="mb-3"
                      inputClassName="p-1"
                      labelClassName="mb-0"
                      name="endHijriDate"
                      component={HijriDatePicker}
                      type="datetime-local"
                      options={{
                        dateFormat: "dd/MM/yyyy"
                      }}
                    />
                    <Button
                      color="transparent"
                      style={{
                        backgroundColor: "transparent",
                        paddingLeft: "0px",
                        paddingRight: "0px",
                        paddingBottom: "0px",
                        paddingTop: "0px",
                        position: "absolute",
                        top: 30,
                        right: 17
                      }}
                      onClick={() => this.props.updateFormValues("endHijriDate", null)}
                    >
                      <span className="simple-icon-close" style={{
                        color: "#8f8f8f",
                        fontSize: "large"
                      }} />
                    </Button>
                  </Colxx>
                </>}
              </Row>
            </TabPane>
            <TabPane tabId="ADVANCED">
              <IntlMessages id={"Find pages with"} />
              <Row>
                <Colxx xs={12} sm={6} md={3}>

                  <Label htmlFor={"project"}>
                    <IntlMessages id={"Project"} />
                  </Label>
                  <Field
                    className="mb-3"
                    name="project"
                    component={ValidatedSelected}
                    labelClassName="mb-0"
                    defaultValue={initProject && initProject.id ? initProject : ""}
                    getOptionLabel={i => i.prefix}
                    getOptionValue={i => i.id}
                    options={listProject.result ? [] : listProject}
                    isClearable
                    placeholder=""
                  />

                </Colxx>
                <Colxx xs={12} sm={6} md={3}>
                  <Label htmlFor={"allWords"}>
                    <IntlMessages id={"All these words"} />
                  </Label>
                  <Field
                    className="mb-3"
                    name="allWords"
                    component={ValidatedInput}
                    inputClassName="p-1"
                    labelClassName="mb-0"
                    type="text"
                  />
                </Colxx>
                <Colxx xs={12} sm={6} md={3}>
                  <Label htmlFor={"anyWords"}>
                    <IntlMessages id={"Any of these words"} />
                  </Label>
                  <Field
                    className="mb-3"
                    name="anyWords"
                    component={ValidatedInput}
                    inputClassName="p-1"
                    labelClassName="mb-0"
                    type="text"
                  />
                </Colxx>
                <Colxx xs={12} sm={6} md={3}>
                  <Label htmlFor={"noneWords"}>
                    <IntlMessages id={"None of these words"} />
                  </Label>
                  <Field
                    className="mb-3"
                    name="noneWords"
                    component={ValidatedInput}
                    inputClassName="p-1"
                    labelClassName="mb-0"
                    type="text"
                  />
                </Colxx>
                <div className="w-100 d-none d-md-block" />

                <Colxx xs={12} sm={6} md={3}>
                  <Label htmlFor={"startDate"}>
                    <IntlMessages id={"Start Date"} />
                  </Label>
                  <Field
                    className="mb-3"
                    name="startDate"
                    component={DatePicker}
                    type="datetime-local"
                    options={{
                      dateFormat: "dd/MM/yyyy"
                    }}
                    inputClassName="p-1"
                    labelClassName="mb-0"
                  />
                  {/* <Field
                    className="mb-3"
                    name="startDate"
                    component={ValidatedInput}
                    inputClassName="p-1"
                    labelClassName="mb-0"
                    type="date"
                  /> */}
                </Colxx>
                <Colxx xs={12} sm={6} md={3}>
                  <Label htmlFor={"endDate"}>
                    <IntlMessages id={"End Date"} />
                  </Label>
                  <Field
                    name="endDate"
                    component={DatePicker}
                    type="datetime-local"
                    options={{
                      dateFormat: "dd/MM/yyyy"
                    }}
                    inputClassName="p-1"
                    labelClassName="mb-0"
                    className="mb-3"
                  />
                  {/* <Field
                    className="mb-3"
                    name="endDate"
                    component={ValidatedInput}
                    inputClassName="p-1"
                    labelClassName="mb-0"
                    type="date"
                  /> */}
                </Colxx>
                {this.optionsToDisplay.hijriDateField && <>
                  <Colxx xs={12} sm={6} md={3} style={{ position: "relative" }}>
                    <Label htmlFor={"startHijriDate"}>
                      <IntlMessages id={"Start Hijri Date"} />
                    </Label>
                    <Field
                      className="mb-3"
                      inputClassName="p-1"
                      labelClassName="mb-0"
                      name="startHijriDate"
                      component={HijriDatePicker}
                      type="datetime-local"
                      options={{
                        dateFormat: "dd/MM/yyyy",
                      }}
                    />
                    <Button
                      color="transparent"
                      style={{
                        backgroundColor: "transparent",
                        paddingLeft: "0px",
                        paddingRight: "0px",
                        paddingBottom: "0px",
                        paddingTop: "0px",
                        position: "absolute",
                        top: 30,
                        right: 17
                      }}
                      onClick={() => this.props.updateFormValues("startHijriDate", null)}
                    >
                      <span className="simple-icon-close" style={{
                        color: "#8f8f8f",
                        fontSize: "large"
                      }} />
                    </Button>
                  </Colxx>

                  <Colxx xs={12} sm={6} md={3} style={{ position: "relative" }}>
                    <Label htmlFor={"endHijriDate"}>
                      <IntlMessages id={"End Hijri Date"} />
                    </Label>
                    <Field
                      className="mb-3"
                      inputClassName="p-1"
                      labelClassName="mb-0"
                      name="endHijriDate"
                      component={HijriDatePicker}
                      type="datetime-local"
                      options={{
                        dateFormat: "dd/MM/yyyy"
                      }}
                    />
                    <Button
                      color="transparent"
                      style={{
                        backgroundColor: "transparent",
                        paddingLeft: "0px",
                        paddingRight: "0px",
                        paddingBottom: "0px",
                        paddingTop: "0px",
                        position: "absolute",
                        top: 30,
                        right: 17
                      }}
                      onClick={() => this.props.updateFormValues("endHijriDate", null)}
                    >
                      <span className="simple-icon-close" style={{
                        color: "#8f8f8f",
                        fontSize: "large"
                      }} />
                    </Button>
                  </Colxx>
                </>}
                <Colxx xs={12} sm={6} md={3}>
                  <Label htmlFor={"sort"}>
                    <IntlMessages id={"Sort"} />
                  </Label>

                  <Field
                    className="mb-3"
                    name="sort"
                    component={ValidatedSelected}
                    labelClassName="mb-0"
                    getOptionLabel={i => <IntlMessages id={i.name} />}
                    getOptionValue={i => i.id}
                    onChange={e => changeSort(e)}
                    options={getSorts()}
                    isClearable
                    placeholder=""
                  />
                </Colxx>
                <Colxx xs={12} sm={6} md={3}>
                  <Label htmlFor={"boxType"}>
                    <IntlMessages id={"Box type"} />
                  </Label>
                  <Field
                    className="mb-3"
                    name="boxType"
                    component={ValidatedSelected}
                    labelClassName="mb-0"
                    getOptionLabel={i => <IntlMessages id={i.label} />}
                    getOptionValue={i => i.value}
                    options={getBoxTypes(this.optionsToDisplay.videosBoxes)}
                    isClearable
                    placeholder=""
                    isMulti
                  />
                </Colxx>
                <Colxx xs={12} sm={6} md={3}>
                  <Label htmlFor={"contentType"}>
                    <IntlMessages id={"Content type"} />
                  </Label>
                  <Field
                    className="mb-3"
                    name="contentType"
                    component={ValidatedSelected}
                    labelClassName="mb-0"
                    getOptionLabel={i => <IntlMessages id={i.label} />}
                    getOptionValue={i => i.id}
                    options={CONTENT_TYPES}
                    isClearable
                    placeholder=""
                    isMulti
                  />
                </Colxx>
                <Colxx xs={12} sm={6} md={3}>
                  <Label htmlFor={"language"}>
                    <IntlMessages id={"Languages"} />
                  </Label>
                  <Field
                    className="mb-3"
                    name="language"
                    component={renderMultiselect}
                    labelClassName="mb-0"
                    getOptionLabel={i => <IntlMessages id={i.name} />}
                    getOptionValue={i => i.id}
                    options={getLanguages()}
                    isClearable
                    placeholder=""
                    isMulti
                  />
                </Colxx>
              </Row>
            </TabPane>
          </TabContent>
          <div className="text-right">
            <Button
              outline
              color="secondary"
              className="ml-2 mb-2"
              onClick={onClose}
            >
              <i className="simple-icon-close" /> <IntlMessages id="Close" />
            </Button>
            <Button color="primary" className="ml-2 mb-2" type="submit">
              <i className="simple-icon-docs" />{" "}
              <IntlMessages id="menu.search" />
            </Button>
            <Button
              outline
              color="secondary"
              className="ml-2 mb-2"
              onClick={onAddUItem}
            >
              <i className="simple-icon-docs" />{" "}
              <IntlMessages id="menu.savesearch" />
            </Button>
          </div>
        </form>
        <Separator className="mb-3" />
      </Colxx>
    );
  }
}

const mapStateToProps = (state) => {
  const { appData } = state;
  return {
    initialValues: appData.searchs.savedSearch && appData.searchs.savedSearch.id ? appData.searchs.savedSearch : JSON.parse(localStorage.getItem("SET_SEARCH_PAGE")) ? JSON.parse(localStorage.getItem("SET_SEARCH_PAGE")) : appData.searchs.savedSearch,
    listProject: appData.projets.data,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    updateFormValues: (field, value) =>
      dispatch(change("searchPages", field, value))
  };
};

export const Filter = connect(mapStateToProps, mapDispatchToProps)(
  renderDelay(
    reduxForm({
      form: "searchPages",
      enableReinitialize: true,
      keepDirtyOnReinitialize: true
    })(FilterCmp)
  )
);
