import React from "react";
import cs from "classnames";
import { FormFields } from "components/FormFields";
import TagsInput from "react-tagsinput";
import "react-tagsinput/react-tagsinput.css";

export const ValidatedTags = props => {
  const {
    input,
    meta: { touched, error },
    className,
    label,
    inputClassName,
    ...rest
  } = props;

  let value = input.value ? input.value : [];
  value = Array.isArray(value) ? value : value.split(",");
  return (
    <FormFields {...props}>
      <TagsInput
        className={cs(inputClassName, {
          "is-invalid": touched && error
        })}
        value={value}
        onChange={value => input.onChange(value)}
        inputProps={{
          placeholder: "",
          className: "pl-0 pr-0 form-control"
        }}
        {...rest}
      />
    </FormFields>
  );
};
