import React from "react";
import { Bar } from "react-chartjs-2";
import LightSpinner from "../../../components/LightSpinner";
import { PAGE_STATISTICS } from "constants/auditActions";
import { FormattedMessage } from "react-intl";

const formatData = (list,msg) => {
  if (list && list.length > 0) {
    const result = {
      datasets: [
        {
          label: msg,
          backgroundColor: "rgba(87,106,61,0.5)",
          borderColor: "rgba(87,106,61,0.7)",
          borderWidth: 1,
          hoverBackgroundColor: "rgba(87,106,61,0.7)",
          hoverBorderColor: "rgba(87,106,61,0.7)",
          minBarLength: 2
        }
      ]
    };

    // filter out page statistics
    const listWithoutPS = list.filter(elt => elt.action !== PAGE_STATISTICS);

    result.labels = listWithoutPS.map(i => i.action);
    result.datasets[0].data = listWithoutPS.map(i => i.count);
    return result;
  }

  return {};
};

const options = {
  scales: {
    xAxes: [
      {
        stacked: true
      }
    ],
    yAxes: [
      {
        stacked: true
      }
    ]
  }
};

export const Chart = ({ searchActionsCount }) => {
  const { loading, error, list } = searchActionsCount;
  if (loading) return <LightSpinner className="text-center w-100" />;
  if (error) return null;

  return list.length > 0 ? (
    <FormattedMessage  id="audit.of-action">
    {
      (msg) => <Bar data={formatData(list,msg)} options={options} />
    }
  </FormattedMessage>
  
  ) : null;
};
