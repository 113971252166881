import * as types from "redux/actionTypes";

export const getIpAttempts = filter => ({
  type: types.GET_IPATTEMPTS,
  payload: { ...filter }
});

export const postIpAttempt = (ipAttempt, success, error) => {
  return {
    type: types.POST_IPATTEMPT,
    payload: { ipAttempt, success, error }
  };
};

export const updateIpAttempt = (ipAttempt, success, error) => {
  return {
    type: types.EDIT_IPATTEMPT,
    payload: { ipAttempt, success, error }
  };
};
export const deleteIpAttempt = (id, success, error) => {
  return {
    type: types.DELETE_IPATTEMPT,
    payload: { id, success, error }
  };
};

export const editIpAttemptOnModal = user => ({
  type: types.EDIT_IPATTEMPT_ON_MODAL,
  payload: user
});

export const getIpAttemptsUser = userId => ({
  type: types.GET_IPATTEMPTS_USER,
  payload: { userId }
});
