/* Gogo Language Texts

Table of Contents

01.General
02.User Login, Logout, Register
03.Menu
04.Error Page
*/

module.exports = {
  /* 01.General */
  "app.title": "Sîstema Rêverbirina Belgeyan",
  /* 02.User Login, Logout, Register */
  "user.logout": "Jêderketin",
  "user.login-intro": "Ji kerema xwe re bawernameyên xwe derbas bike",
  "user.username": "Navê Bikarîner",
  "user.password": "Şîfre",
  "user.login-button": "Derbasbûn",
  "user.error-gettings-users": "Şaştî derket di naskirina bikarîner de",

  /* 03.Menu */
  "menu.app": "Rûpela Destpêkê",
  "menu.users": "Bikarîner",
  "menu.documents": "Dokument",
  "menu.search": "Lêgerîn",
  "menu.saved-search": "Lêgerînên Qeydkirî",
  "menu.audit": "Lêveger",
  "menu.restrictions": "Sînor",
  "menu.reporting": "Ragihandin / Agahdarkirin",
  "menu.savesearch": "Qeydkirina Lêgerînê",
  "menu.deleteRequests": "Jêbirina Daxwaznameyan",
  "menu.ips":"Hewldanên Ips",

  /* 04.Error Page */
  "layouts.error-title": "Xwiya dike ku şaştiyek derket!",
  "layouts.error-code": "Şaştî di kod de heye.",
  "layouts.go-back-home": "Vegere Rûpela Destpêkê",

  /* 04.DataList : Users */
  "user.Manage-Uses": "Rêvebrina Bikarîneran",
  "user.Archived-Users": "Bikarînerên Erşîfkirî",
  "user.Manage-Teams": "Rêvebrina Tîman",
  "user.Manage-IpAttempts": "rêveberiya navnîşana IP-ê",
  "user.Add-User": "Lêzêdekirina Bikarînerên",
  "user.Edit-User": "Guhertina Bikarînerên",
  "user.role": "Rol",
  "users.add-success-message": "Pêvajoya qeydkirina bikarîner serkeftî derbas bû",
  "users.deleted-success-message": "Pêvajoya jêbirina bikarîner serkeftî derbas bû",
  "users.enabled-success-message": "Pêvajoya rêdana bikarîner serkeftî derbas bû",
  "users.error-authentification": "Pêvajoya derbasbûnê ne serkeftî derbas bû",
  "users.error-locked": "Hisabê te hatiye sekinandin, ji kerema xwe re piştre hewldanên xwe cardî bike",
  "users.error-locked-minute": " Deqe",
  "users.confirm-delete-title": "Tekezkirin",
  "users.confirm-delete": "Tu dixwazî vî bikarînerî erşîf bikî?",
  "users.blocked": "Bikarhênerê astengkirî",
  "users.unlocked": "bikarhêner vekir",

  /* DataList : Teams */
  "teams.Add-Teams": "Lêzêdekirina Tîman",
  teams: "Tîm",
  "teams.Edit-Team": "Guhertina Tîman",
  "teams.add-success-message": "Pêvajoya qeydkirina tîm serkeftî derbas bû",
  "teams.deleted-success-message": "Pêvajoya jêbirina tîm serkeftî derbas bû",
  "teams.confirm-delete": "Tu dixwazî vî tîmî jêbibî?",
  "teams.error-gettings-teams": "Şaştî derket di anîna tîman de",
  "teams.users": "Bikarîner",

  /* DataList : restrictions */
  "restrictions.Add-Restrictions": "Lêzêdekrina Sînoran",
  "restrictions.add-success-message": "Pêvajoya qeydkirina sînor serkeftî derbas bû",
  "restrictions.deleted-success-message": "Pêvajoya jêbirina sînor serkeftî derbas bû",
  "restrictions.confirm-delete":
    "Tu dixwazî vî sînorî qeyd bikî?",
  "restrictions.error-gettings": "Şaştî derket di anîna sînoran de",

  /* DataList : projets */
  "projets.Add-Projets": "Lêzêdekrina Pirojeyan",
  "projets.add-success-message": "Pêvajoya qeydkirina pirojê serkeftî derbas bû",
  "projets.deleted-success-message": "Pêvajoya jêbirina pirojê serkeftî derbas bû",
  "projets.confirm-delete": "Tu dixwazî vî pirojeyî jêbibî?",
  "projets.error-delete": "Dosya hene, divê ku hemû tiştên girêdayî piroje werin jêbirin berî ku piroje were jêbirin",
  "projets.error-gettings": "Şaştî derket di anîna pirojeyan de",
  "projects.description": "danasîna projeyê",
  "projects.prefix":"Pêşgira projeyê",


  /* Documents */
  "doc.list-document": "Rêvebirina Dokumentan",
  "doc.list-projets": "Rêvebirina Pirojeyan",
  "doc.import-documents": "Wergiritina Dokumentan",
  "doc.download-document-images": "Daxistina Wêneyên dokumentê",
  "doc.import-ocr": "Wergiritina Dosyayên OCR",
  "doc.import-metadata": "Wergirtina Wesf-Agahiyan",
  "doc.document-boxes": "Qutiya Dokumentan",
  "doc.video-boxes": "Qutiya Vidyowan",
  "doc.files in": "Dosyayên di",
  "doc.file": "Dosya:",
  "doc.download": "Daxistin",
  "doc.Pages in": "Rûpelên di",
  "doc.Box Information Sheet": "Pela Qutiya Agahiyan",
  "doc.no.missings": "Tu dokumentên wendayî tune ne",
  Archive: "Erşîf",
  "set-total-docs": "Hijmara folderên qutiyê",
  "set-total-pages": "Hijmara rûpelên qutiyê",
  "Changes saved successfully": "Guhertinên hene serkeftî hatin qeydkirin",
  "documents.hide-metadata": "Wesf-Agahiyan Veşêre",
  "document.delete-success-message": "Pêvajoya jêbirina dokument serkeftî derbas bû",

   /* DataList : IpAttempts */
   "ipAttempts.deleted-success-message": "IP bi serkeftî hate jêbirin!",
   "ipAttempts.confirm-delete":"Ma hûn guman dikin ku hûn dixwazin vê IP-ê jêbirin?",
   "ipAttempts.error-gettings-ipAttempts":"Di girtina IP-ê de çewtî",
  // Boxes
  "box.delete-success-message": "Pêvajoya jêbirina qutiyê serkeftî derbas bû",

  /* Commans */
  Cancel: "Vegere",
  Close: "Derkeve",
  Enable: "Rê bide",
  Confirm: "Tekezkirin",
  Save: "Qeydkirin",
  Submit: "Bişîne",
  Edit: "Guhertin",
  "First Name": "Navê Yekem",
  "Last Name": "Paşnav",
  Name: "Nav",
  Email: "Email",
  OCR: "Naskirina Tîpan bi riya Dîtinê",
  Username: "Navê Bikarîner",
  User: "Bikarîner",
  Users: "Bikarîner",
  Team: "Tîm",
  Box: "Qutî",
  Boxs: "Qutiyên",
  Action: "Pêkbîne",
  Document: "Dokument",
  Image : "wêne",
  WebPages: "Rûpelên Malperê",
  SocialMedia : "Medya Civakî / Sohbet",
  Audio :"Deng",
  Page: "Rûpel",
  "confirm-password": "Tekezkirina Şîfrê",
  "order-by": "Rêz bike li gorî:",
  Password: "Şîfre",
  Delete: "Jêbirina",
  Videos: "Vîdyoyên",
  Pages: "Rûpelên",
  Images: "Wêneyên",
  Metadata: "Wesf-Agahî",
  Video: "Vîdyo",
  Documents: "Dokumentên",
  "Delete document": "Folder jêbibe",
  Prefix: "Pêşgir",
  "Add files...": "Lêzêdekirina Dosyan",
  "Cancel upload": "Barkirinê bisekinîne",
  "Start upload": "Barkirinê dest pê bike",
  "Page Metadata": "Wesf-Agahiyan Rûpelê",
  "Folder Metadata": "Wesf-Agahiyan Folder",
  "Box Metadata": "Qutiya Wesf-Agahiyan",
  "Box Name": "Navê Qutiyê",
  "Authorized Ips": "IPSê rêdayî",
  "Metadata not saved": "Wesf-Agahî nehatin qeydkirin",
  "Missings Pages": "Rûpelên Wendayî",
  "Missings Documents": "Folderên Wendayî",
  "Total Document": "Folder bi giştî",
  Selection: "Hilbijartin",
  Download: "Daxistin",
  "Save changes": "Gerhtinan Qeyd bike",
  Generate: "Çêkirin",

  /* Search */
  "search.basic-search": "Lêgerîna Sereke",
  "search.specific-search": "Lêgerîna Taybet",
  "search.advanced-search": "Lêgerîna Pêşketî",
  "Activate fuzzy search (search for terms that are similar to, but not exactly like our search term)":
    "Lêgerîna nezelal aktîv bike (bigre li têgînên ku nêzî têgînên me ne, lê ne wek wan bi xwe)",

  /* Errors */
  "error.required": "Xana pêwîst",
  "error.invalide Email": "Email şaşe",
  "error.pwd-not-match": "Şîfre li hev nayên",
  "errors.error-adding-user": "Şaştî derket, ji kerema xwe re piştre hewldanên xwe cardî bike",
  "error.password-to-short": "Şîfre gelekî kin e",
  "error.name-to-short": "Navê pirojê gereke herî kêm 3 tîp bin",
  "no-data-found": "Tu agahî nehatin dîtin",
  "file-missing": "Xwiya dike ku ev dosya wenda ye",
  "Download document images error : Invalid range":
    "Hilbijartin divê li gorî van şêweyan be: SYR.D0001, SYR.D0002.001, SYR.D0002.002.001, SYR.D0002.001.004-SYR.D0002.001.050. Bêhnûkê bikarbîne (,) ji bo veqetandina qutiyan, dokumentan, rêpelan û mecalên rûpelan, û xîz (-) ji bo mecalan",
  "No files found": "Tu dosya tune ne",
  "general.exception.interne": "Şaştî heye di Sêrverê hindirîn",
  "Resource Missing Error": "Şaştî: Kanî Wendaye",

  /* Search */
  "Search term": "Têgînên Lêgerînê",
  "Start Date": "Dîroka Destpêkê",
  "End Date": "Dîroka Bidawîkirinê",
  "Date seized": "Dîroka girtinê",
  "Seized By": "Hate girtin bi riya",
  "Location seized": "Cih hate girtin",
  "Digital forensics ID":"Hijmara ID ya Forensics",
  Notes: "Têbînî",
  "Document From": "Rûpel Ji",
  "Document To": "Rûpel Heta",
  "Document CC": "Rûpela CC",
  "Document summary": "Kurtahiya Folder",
  "Names of people": "Navê Kesan",
  "Names of places": "Navê Cihan",
  "Names of entities": "Navê Tevahiyan",
  To: "Heta",
  From: "Ji",
  Cc: "Kopîkirin",
  CC: "Kopîkirin",
  "Summary":"Kurtahî",
  Tags: "Îmze Qedexeye",
  "Signature block": "Cihê Îmzê",
  "Go to page": "Here rûpela",
  "Location seized Box": "Ciha girtina qutiya wesf-agahiyan",
  "Notes Box": "Têbîniyên li ser qutiya wesf-agahiyan",
  "Document From Folder": "Rûpel ji folderê wesf-agahiyan",
  "Document To Folder": "Rûpel heta folderê wesf-agahiyan",
  "Document CC Folder": "Rûpela kopîkirina folderê wesf-agahiyan",
  "Document summary Folder": "Rûpela kurtahiya folderê wesf-agahiyan",
  "Names of people Page": "Navê kesan di rûpela wesf-agahiyan",
  "Names of places Page": "Navê cihan di rûpela wesf-agahiyan",
  "Names of entities Page": "Navê tevahiyan di rûpela wesf-agahiyan",
  "To Page": "Heta Rûpela Wesf-Agahiyan",
  "From Page": "Ji Rûpela Wesf-Agahiyana",
  "Cc Page": "Kopîkirina Rûpela Wesf-Agahiyan",
  "Tags Page": "Cihê Îmzê di Rûpela Wesf-Agahiyan",
  "Start Date Page": "Dîroka Destpêkê di Rûpela Wesf-Agahiyan",
  "End Date Page": "Dîroka Bidawîkirinê di Rûpela Wesf-Agahiyan",
  "All these words": "Ev gotin hemû",
  "Any of these words": "Yek ji van gotinan",
  "None of these words": "Ne yek jî ji van gotinan",
  "Find pages with": "Rûpelan bibîne di riya...",
  "Show Filter": "Filter bidw xwiyakirin",

  "searches.saved": "Lêgerînên Qeydkirî",
  "search.edit": "Lêgerînê Qeyd bike",
  "search.add-success-message": "Pêvajoya qeydkirina lêgerînê serkeftî derbas bû",
  "search.confirm-delete": "Tu dixwazî jêbirina lêgerînê berdewam bikî?",
  "search.name": "Tu dixwazî çi navî li vê lêgerînê bikî?",

  /** Audit Page */
  "Action types": "Cûreyên karan",
  "Show all action types": "Hemû cûreyên karan bide xwiyakirin",
  "Show only logoff actions": "Tenê xebatên derbsabûnê bide xwiyakirin",
  "Show only logon actions": "Tenê xebatên jêderketin bide xwiyakirin",

  totalDocuments: "Hijmara Giştî dokumentan di Sîstema Rêverbirina Belgeyan SRB",
  totalEnglishTranslations: "Hijmara Giştî Wergerandinên Inglîzî",
  totalImages: "Hijmara Giştî ya Wêneyan",
  totalMetadatas: "Hijmara Giştî ya Wesf-Agahiyan",
  totalOcrs: "Hijmara Giştî ya dokumentên ku hatine (Naskirina Tîpan bi riya Dîtinê yan OCR)",
  totalVideos: "Hijmara Giştî ya Vidyowan",
  "Next Page": "Rûpela Bê",
  "Previous Page": "Rûpela Berê",

  "Records without metadata": "Sicilên bê wesf-agahî",
  "Search for documents box having no metadata for fields":
    "Bigere li qutiya dokumentên ku wesf-agahî tune ne ji bo xalên wê",
  Back: "Vegere Paş de",
  Forward: "Here Pêş de",
  "Home.search": "Rûpela Destpêkê",

  //Pages
  "pages.download": "Daxistin",
  "pages.no-english-translation": "Ev Rûpel hîn nehatiya wergerandin bi Ingilîzî",
  "page.page-tab": "Rûpel",
  "page.page-english-translation-tab": "Wegerandina Ingilîzî",
  "page.delete-success-message": "Pêvajoya jêbirina rûpelê serkeftî derbas bû",
  "page.no.missings": "Tu rûpelên wendayî tune ne",

  // Audit
  "audit.download-audit-search-results": "Daxistina encamên lêgerîna lêvekerê",
  "audit.download-audit-search-results-body":
    "Tu tenê dixwazî vê rûpelê daxînî yan hemû encaman? (dibe ku daxistina hemû encaman demeke derêj tir bibe)",
  "audit.this-page-only": "Tenê ev rûpel",
  "audit.all-results": "Hemû encam",
  "audit.of-action": "ji xebatan",
  "audit.progress-metadata": "Pêvajoya lêzêdekirina wesf-agahiyan",
  "audit.type-search.users": "Binvîse ji bo lêgerîna bikarîneran…",
  "audit.type-search.teams": "Binvîse ji bo lêgerîna tîman…",
  "audit.type-search.documents": "Binvîse ji bo lêgerîna dokumentan…",
  "audit.type-search.boxes": "Binvîse ji bo lêgerîna qutiyan…",
  "audit.type-search.pages": "Binvîse ji bo lêgerîna rûpelan…",


  // Delete Requests
  "delete-requests": "Daxwaznameyan Jêbibe",
  "delete-requests.confirm-delete-title": "Tekezkirin",
  "delete-requests.confirm-cancel-title": "Tekezkirin",
  "delete-requests.confirm-delete-content":
    "Tu dixwazî vî Kaniyî jêbibî?",
  "delete-requests.confirm-cancel-content":
    "Tu dixwazî daxwaznameyê bisekinînî?",
  "delete-requests.deleted-success-message": "Pêvajoya jêbirina kanî serkeftî derbas bû",
  "delete-requests.canceled-success-message": "Hemû daxwazname hatin sekinandin!",
  "delete-request-sent": "Daxwaza jêbirinê hat şandin!",
  "Show Document boxes": "Qutiya Dokumentan bide xwiyakirin",
  "Show Videos boxes": "Qutiya Vîdyowan bide xwiyakirin",
  "Show Only failed uploads": "Tenê pêvajoyên barkirinê yên ne serkeftî bide xwiyakirin",
  //Delete Ressources
  "delete-ressource.msg": "Tu dixwazî daxwaza jêbirina kanî bişînî?",
  "delete-ressource.error": "Şaştiyek derket dema dihate jêbirin, ji kerema xwe re piştre hewldanên xwe cardî bike",
  "Folders": "Folder",
  "Youtube Id": "Youtube Id",
  "Youtube title": "Sernavê Youtube",
  "Sort": "Rêzkirin li gorî",
  "asc": "Nav (Ji jor heta jêr)",
  "desc": "Nav (Ji jêr heta jor)",
  "Title": "Nav",
  "Hijri Date": "Dîroka Hicrî",
  "Start Hijri Date":"Destpêkirina Dîroka Hicrî",
  "End Hijri Date":"Dîroka dawîbûna hicrî",
  "PhoneOrEmail": "Hejmarên Telefon / Faks an Email",
  "DocumentReferenceCommunication": "Hejmara Referansê ya Belgeyê / Jimareya Ragihandinê",
  "DocumentReferenceMentioned": "Hejmarên Referansa Belgeyê Hatin Binavkirin",
  "Languages": "Ziman",
  "Format": "Çap",
  "Subject": "Mijar",
  "Description": "Terîf",
  "ReferencedInBrief": "Di [Briefs] de hatiye referans kirin",
  "DuplicateDocNo": "Duplicate Doc. Na",
  "Previously labelled under":"Berê di bin etîketê de",
  "First Page": "Rûpela yekem",
  "Last Page": "Rûpela dawî",
  "First Result": "Encama Yekem",
  "Previous Result": "Encama Berê",
  "Next Result": "Encama Paşê",
  "Last Result": "Encama Dawî",
  "Original Filename":"Navê dosya yê bingehîn",
  "MD5 Hash Value":"Nirxê teşfîra Hash MD5",
  "Box type":"Cûreya qutiyê",
  "Content type":"Cûreya naverokê",
  "SHA1 Hash Value":"Nirxê teşfîra Hash SHA1",
  "Relevant":"Pêve girêdayî",
  "Translation ": "Werger",
  "Total Documents (Scanned, E-files and Open-Source)":"Hijmara giştî ya Dokumentan (Dosyayên skaner kirî, Dosyayên Teknîkî, Dosyayên Çavkaniyên Vekirî)",
  "Total Documents (Scanned)":"Hejmara giştî ya Dokumentan Yên Skaner kirî",
  "Total Documents (E-files)":"Hejmara giştî ya Dokumentan Dosyayên teknîkî",
  "Total Documents (Open-Source)":"Hejmara giştî ya Dokumentan Çavkaniyên vekirî",
  "Total Images (E-files and Open-Source)":"Hijmara giştî ya Wêneyan (Dosyayên Teknîkî, Dosyayên Çavkaniyên Vekirî)",
  "Total Images (E-files)":"Hejmara giştî ya Wêneyan Dosyayên teknîkî",
  "Total Images (Open-Source)":"Hejmara giştî ya Wêneyan Çavkaniyên vekirî",
  "Total Videos (E-files and Open-Source)":"Hijmara giştî ya Dîmenan (Dosyayên Teknîkî, Dosyayên Çavkaniyên Vekirî)",
  "Total Videos (E-files)":"Hejmara giştî ya Dîmenan Dosyayên teknîkî",
  "Total Videos (Open-Source)":"Hejmara giştî ya Dîmenan Dosyayên teknîkî",
  "Total Audio (E-files and Open-Source)":"Hijmara giştî ya Dengan (Dosyayên Teknîkî, Dosyayên Çavkaniyên Vekirî)",
  "Total Audio (E-files)":"Hejmara giştî ya Dengan Dosyayên teknîkî",
  "Total Audio (Open-Source)":"Hejmara giştî ya Dengan Çavkaniyên vekirî",
  "Total Social Media (E-files and Open-Source)":"Hijmara giştî ya Torên Civakî (Dosyayên Teknîkî, Dosyayên Çavkaniyên Vekirî)",
  "Total Social Media (E-files)":"Hijmara giştî ya Torên Civakî Dosyayên teknîkî",
  "Total Social Media (Open-Source)":"Hijmara giştî ya Torên Civakî Çavkaniyên vekirî",
  "Total URLs (E-files and Open-Source)":"Hijmara giştî ya Lînkan (Dosyayên Teknîkî, Çavkaniyên Vekirî)",
  "Total URLs (E-files)":"Hejmara giştî ya Lînkan Dosyayên teknîkî",
  "Total URLs (Open-Source)":"Hejmara giştî ya Lînkan Çavkaniyên vekirî",
  "Arabic":"Erebî",
  "English":"Ingilîzî",
  "Russian":"Rûsî",
  "French":"Ferensî",
  "Other":"Zimanên din",
  "Scanned boxes":"Qutîkên Skaner kirî",
  "E-files boxes":"Qutîkên Dosyayên teknîkî",
  "Open-Source boxes":"Qutîkên çavkaniyên vekirî",
  "Total evidence in EMS (scanned , e-files , open source/ excluding translations)":"Hijmara giştî belgeyên di Sîstema Rêveberina Belgeyan EMS de (Dosyayên skaner kirî, Dosyayên Teknîkî, Dosyayên Çavkaniyên Vekirî, Dosyayên Çavkaniyên Vekirî bê wergerandin)"
};
