import React, { Fragment } from "react";
import { connect } from "react-redux";
import { Field, reduxForm } from "redux-form";
import { Button } from "reactstrap";
import { NavLink } from "react-router-dom";
import { Prompt } from "react-router-dom";

import IntlMessages from "util/IntlMessages";
import StateButton from "components/StateButton";
import { ValidatedTextarea } from "components/FormFields/Textarea";
import { renderDelay } from "./renderDelay";
import DatePicker from "components/FormFields/DatePicker";
import { BOX_E_LETTER, currentLanguage } from "../../../../util/constants";
import { maxLength255 } from "util/validationRules";
import { readMissingsDocs, readMissingsPages, setTotalDocuments, updateMetada } from "../../../../util/permissions";

const boxCmp = props => {
  const { isBoxEdited, handleSubmit, dirty, saveState, letter } = props;
  const isEBox = letter === BOX_E_LETTER;
  return (
    <Fragment>
      <Prompt
        when={!props.submitSucceeded && props.dirty}
        message={location => `êtes vous sur de vouloir quitter sans modifier ?`}
      />
      <form
        onSubmit={handleSubmit(values => {
          props.onSubmit(values);
          props.initialize(values);
        })}
      >
        {!isBoxEdited && dirty && (
          <span className="d-block mb-2 text-info">
            <IntlMessages id="Metadata not saved" />
          </span>
        )}
        {
          isEBox && (<><p className={`text-muted ${currentLanguage === 'ar' ? "text-small-ar" : "text-small"} mb-0`}><IntlMessages id="Digital forensics ID" /></p>
            <Field
              className="mb-3"
              inputClassName="pl-0 pr-0 form-control"
              name="forensicsId"
              component={ValidatedTextarea}
              type="text"
              disabled={!isBoxEdited}
            />
          </>)
        }
        <p className={`text-muted ${currentLanguage === 'ar' ? "text-small-ar" : "text-small"} mb-0`}><IntlMessages id="Location seized" /></p>
        <Field
          className="mb-3"
          inputClassName="pl-0 pr-0 form-control"
          name="locationSeized"
          component={ValidatedTextarea}
          type="text"
          disabled={!isBoxEdited}
        />
        
        <p className={`text-muted ${currentLanguage === 'ar' ? "text-small-ar" : "text-small"} mb-0`}><IntlMessages id="Date seized" /></p>
        <Field
          className="mb-3"
          inputClassName="pl-0 pr-0"
          name="dateSeized"
          component={DatePicker}
          type="datetime-local"
          disabled={!isBoxEdited}
          options={{
            dateFormat: "dd/MM/yyyy"
          }}
        />

        <p className={`text-muted ${currentLanguage === 'ar' ? "text-small-ar" : "text-small"} mb-0`}><IntlMessages id="Seized By" /></p>
        <Field
          className="mb-3"
          inputClassName="pl-0 pr-0 form-control"
          name="seizedBy"
          component={ValidatedTextarea}
          type="text"
          disabled={!isBoxEdited}
          validate={maxLength255}
        />

        <p className={`text-muted ${currentLanguage === 'ar' ? "text-small-ar" : "text-small"} mb-0`}><IntlMessages id="Notes" /></p>
        <Field
          className="mb-3"
          inputClassName="pl-0 pr-0 form-control"
          name="notes"
          component={ValidatedTextarea}
          disabled={!isBoxEdited}
        />

        {!isBoxEdited && readMissingsDocs() && (
          <NavLink to={`/app/documents/missings/${'docs'}`}>
            <Button outline color="primary" className="mb-2" >
              <i className="simple-icon-docs" />
              <IntlMessages id="Missings Documents" />
            </Button>
          </NavLink>
        )}
        {!isBoxEdited && readMissingsPages() && (
          <NavLink to={`/app/documents/missings/${'pages-in-box'}`}>
            <Button outline color="primary" className="mb-2">
              <i className="simple-icon-docs" />
              <IntlMessages id="Missings Pages" />
            </Button>
          </NavLink>
        )}
        {setTotalDocuments() && <NavLink to={`/app/documents/totalDocument/${props.number}`}>
          <Button outline color="primary" className="mb-2 ml-2" >
            <i className="simple-icon-docs" />
            <IntlMessages id="Total Document" />
          </Button>
        </NavLink>}
        {isBoxEdited && (
          <Fragment>
            {saveState.error && (
              <div className="d-block text-danger">
                <IntlMessages id={saveState.error} />
              </div>
            )}
            <StateButton
              id="successButton"
              color="primary"
              type="submit"
              isLoading={saveState.loading}
            >
              <IntlMessages id="Save" />
            </StateButton>
          </Fragment>
        )}
      </form>
    </Fragment>
  );
};
const mapStateToProps = state => {
  const { appData } = state;
  return {
    initialValues: appData.boxs.metadata.boxMetadata,
    valuesBox: appData.boxs.metadata,
    project: appData.boxs.metadata.project,
    valuesDoc: appData.docs,
    number: appData.boxs.metadata.id,
    letter: appData.boxs.metadata.letter,
    boxId: () => {
      let myObject = { ...appData.boxs.docs };
      let queryString = Object.keys(myObject).length;
      return queryString;
    }
  };
};

export const Box = connect(mapStateToProps)(
  renderDelay(
    reduxForm({
      form: "boxMetadata",
      enableReinitialize: true
    })(boxCmp)
  )
);
