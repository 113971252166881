import React, { Component } from "react";
import { connect } from "react-redux";

import IntlMessages from "util/IntlMessages";
import { injectIntl } from "react-intl";
import { Card, CardBody, CardTitle, CustomInput, Button } from "reactstrap";
import { Colxx } from "components/CustomBootstrap";
import {
  searchBoxNoMetadata,
  changeBoxNoMetadataFilter,
  changeBoxNoMetadataPageAndOrSize
} from "redux/actions";
import { DataListContainer } from "components/DataListContainer";
import Download from "./Download";

class BoxCmp extends Component {
  search = () => {
    this.props.searchBoxNoMetadata();
  };
  toggleCheckbox = e => {
    const { id } = e.target;
    const { filter } = this.props.noMetadataBoxes;
    filter[id] = !filter[id];
    this.props.changeBoxNoMetadataFilter(filter);
  };
  changePageSize = size => {
    this.props.changeBoxNoMetadataPageAndOrSize({ page: 1, size });
  };
  onChangePage = page => {
    this.props.changeBoxNoMetadataPageAndOrSize({ page });
  };
  handleBoxClick = index => {
    this.props.history.push(
      "/app/documents/list/box/" +
      this.props.noMetadataBoxes.data.result[index].id
    );
  };
  render() {
    const { noMetadataBoxes } = this.props;
    const { loading, data, page, size, filter } = noMetadataBoxes;
    return (
      <Colxx xxs="12">
        <Card>
          <CardBody>
            <CardTitle>
              <IntlMessages id="Search for documents box having no metadata for fields" />
            </CardTitle>

            <CustomInput
              className="itemCheck mb-1"
              type="checkbox"
              id="withoutLocationSeized"
              checked={filter.withoutLocationSeized || false}
              onChange={this.toggleCheckbox}
            ><IntlMessages id="Location seized" /></CustomInput>
            <CustomInput
              className="itemCheck mb-4"
              type="checkbox"
              id="withoutNotes"
              checked={filter.withoutNotes || false}
              onChange={this.toggleCheckbox}
            >
              <IntlMessages id="Notes" />
            </CustomInput>
            <Button color="primary" className="ml-2 mb-2" onClick={this.search}>
              <IntlMessages id="menu.search" />
            </Button>
          </CardBody>
        </Card>

        {data.result && (
          <>{data.result.length !== undefined && data.result.length > 0 && <Download type="box" data={data.result} filter={filter} />}
            <DataListContainer
              hideButtons
              dataLength={data.total || 0}
              pageSize={size}
              changePageSize={this.changePageSize}
              onChangePage={this.onChangePage}
              currentPage={page}
            >
              {loading ? (
                <div className="loading" />
              ) : (
                data.result.map((box, index) => {
                  return (
                    <Colxx xxs="12" key={index} className="mb-3">
                      <Card
                        onClick={() => this.handleBoxClick(index)}
                        className={"d-flex flex-row"}
                      >
                        <div className="pl-2 d-flex flex-grow-1 min-width-zero">
                          <div className="card-body align-self-center d-flex flex-column flex-lg-row justify-content-between min-width-zero align-items-lg-center">
                            <p className="list-item-heading mb-1 truncate">
                              {box.project + "." + box.number}
                            </p>
                            <p className="mb-1 text-muted text-small w-15 w-sm-100">
                              Box ID: {box.id}
                            </p>
                          </div>
                        </div>
                      </Card>
                    </Colxx>
                  );
                })
              )}
            </DataListContainer>
          </>
        )}
      </Colxx>
    );
  }
}

const mapStateToProps = ({ appData }) => ({
  noMetadataBoxes: appData.metadata.noMetadataBoxes
});

export const BoxSearch = connect(
  mapStateToProps,
  {
    searchBoxNoMetadata,
    changeBoxNoMetadataFilter,
    changeBoxNoMetadataPageAndOrSize
  }
)(injectIntl(BoxCmp));
