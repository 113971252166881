import React from "react";
import { AUDIT_DATETIME_REGEXP } from "constants/defaultValues";
import * as moment from "moment";

export const columns = [
  {
    name: "userName",
    label: "User"
  },
  {
    name: "action",
    label: "Action"
  },
  {
    name: "content",
    label: "Details",
    options: {
      customBodyRender: value => renderObject(value)
    }
  },
  {
    name: "requestDate",
    label: "Date",
    options: {
      customBodyRender: value => convertToLocalDateTime(value)
    }
  },
  {
    name: "remote",
    label: "IP"
  }
];
export const options = {
  filterType: "checkbox",
  pagination: false,
  responsive: "scroll",
  filter: false,
  search: false,
  sort: false,
  selectableRows: "none",
  onRowClick:(v,u)=> onRowClick(v,u),
};

const renderObject = value => (
 
  <div>
    <b>{value.title} </b>
    <p>{value.body} </p>
  </div>

);

const onRowClick = (rowData ,rowMeta) => {
}
export const convertToLocalDateTime = value => {
  const valArray = value.match(AUDIT_DATETIME_REGEXP);

  if (!valArray) return null;

  return moment(valArray[1] + valArray[2]).format(
    "YYYY-MM-DD HH:mm:ss [(GMT]Z[)]"
  );
};
