import * as types from "redux/actionTypes";

export const getAuditSearchTotalActions = searchParams => ({
  type: types.GET_AUDIT_SEARCH_TOTAL_ACTIONS,
  payload: searchParams
});
export const errorAuditSearchTotalActions = error => ({
  type: types.ERROR_AUDIT_SEARCH_TOTAL_ACTIONS,
  payload: error
});
export const setAuditSearchTotalActions = list => ({
  type: types.SET_AUDIT_SEARCH_TOTAL_ACTIONS,
  payload: list
});

export const getRtUriNames = () => ({
  type: types.GET_AUDIT_RT_URI_NAMES
});
export const setRtUriNames = data => ({
  type: types.SET_AUDIT_RT_URI_NAMES,
  payload: data
});
