import React, { Component, Fragment } from "react";
import { Route, withRouter, Switch, Redirect } from "react-router-dom";

import TopNav from "containers/TopNav";
import Sidebar from "containers/Sidebar";

import { connect } from "react-redux";
import users from "./apps/users";
import instance from "util/instances";
import ArchivedUsers from "./apps/users/ArchivedUsers";
import { home } from "./apps/home";
import teams from "./apps/users/teams";
import ipAttempts from "./apps/users/ipAttempts";
import { ListBox } from "./apps/documents/boxs";
import { BoxDetails } from "./apps/documents/boxDetail";
import projets from "./apps/documents/projets";
import restrictions from "./apps/users/restrictions";
import { DocumentDetails } from "./apps/documents/documentDetail";
import { PageDetails } from "./apps/documents/pageDetail";
import { importDoc } from "./apps/documents/importDoc";
import { Search } from "./apps/search";
import { SavedSearch } from "./apps/search/saved";
import { Audit } from "./apps/audit";
import { importOCR } from "./apps/documents/importOCR";
import { MissingBox } from "./apps/documents/missingsPages";
import { TotalDocument } from "./apps/documents/totalDocument";
import { getUserPermissions } from "redux/actions";
import {
  readListAudit,
  readListSearch,
  readProjets,
  readDocs,
  addDocs,
  readMissingsDocs,
  readUsers,
  readArchivedUsers,
  readTeams,
  manageRestrictions,
  readPagesDocs,
  readListReporting,
  downloadDocImages,
  viewDeleteRequests
} from "../util/permissions";
import { currentLanguage } from "../util/constants";
import { Reporting } from "./apps/reporting";
import DowloadDocImages from "./apps/documents/dowloadDocImages";
import DeleteRequests from "./apps/users/deleteRequests";
import { TotalMetadata } from "./apps/totalMetadata";

const PrivateRoute = ({ hasAccess, component: Component, ...rest }) => (
  <Route
    {...rest}
    render={props =>
      hasAccess() ? <Component {...props} /> : <Redirect to="/app/home" />
    }
  />
);

class MainApp extends Component {
  constructor(props) {
    super(props);
    let that = this;
  //  currentLanguage
  instance.interceptors.request.use((config) => {
    config.headers.common["X-Requested-With"] = "XMLHttpRequest";
    config.headers.common["Accept-Language"] = currentLanguage
      ? currentLanguage
      : "en";
    return config;
  });
    instance.interceptors.response.use(
      response => response,
      error => {
        if (error.response && 401 === error.response.status) {
          that.props.history.push("/login");
        }

        return error.response;
      }
    );
    this.props.getUserPermissions();
  }

  render() {
    const { match, containerClassnames, userPermissions } = this.props;

    return (
      <Fragment>
        {userPermissions.loading ? (
          <div className="loading" />
        ) : (
          <div id="app-container" className={containerClassnames}>
            <TopNav history={this.props.history} />
            <Sidebar />
            <main>
              <div className="container-fluid">
                <Switch>
                  <Route exact path={`${match.url}/home`} component={home} />
                  <PrivateRoute
                    path={`${match.url}/users/list`}
                    component={users}
                    hasAccess={readUsers}
                  />
                  <PrivateRoute
                    hasAccess={readTeams}
                    path={`${match.url}/users/teams`}
                    component={teams}
                  />
                  <PrivateRoute
                    hasAccess={readTeams}
                    path={`${match.url}/users/ips`}
                    component={ipAttempts}
                  />
                  <PrivateRoute
                    hasAccess={manageRestrictions}
                    path={`${match.url}/users/restrictions`}
                    component={restrictions}
                  />
                  <PrivateRoute
                    hasAccess={viewDeleteRequests}
                    path={`${match.url}/users/deleteRequests`}
                    component={DeleteRequests}
                  />

                  <PrivateRoute
                    hasAccess={readDocs}
                    path={`${match.url}/documents/list`}
                    component={ListBox}
                    exact
                  />
                  <PrivateRoute
                    hasAccess={readDocs}
                    exact
                    path={`${match.url}/documents/list/box/:id`}
                    component={BoxDetails}
                  />
                  <PrivateRoute
                    hasAccess={readDocs}
                    exact
                    path={`${match.url}/documents/list/box/:id/:documentId`}
                    component={DocumentDetails}
                  />
                  <PrivateRoute
                    hasAccess={readPagesDocs}
                    exact
                    path={`${match.url}/documents/list/box/:id/:documentId/:pageId`}
                    component={PageDetails}
                  />
                   <PrivateRoute
                    hasAccess={readPagesDocs}
                    exact
                    path={`${match.url}/documents/list/box/:id/:documentId/:pageId/:type`}
                    component={PageDetails}
                  />
                  <PrivateRoute
                    hasAccess={readProjets}
                    path={`${match.url}/documents/projets`}
                    component={projets}
                  />
                  <PrivateRoute
                    hasAccess={addDocs}
                    path={`${match.url}/documents/importDoc`}
                    component={importDoc}
                  />
                  <PrivateRoute
                    hasAccess={downloadDocImages}
                    path={`${match.url}/documents/dowloadDocImages`}
                    component={DowloadDocImages}
                  />
                  <PrivateRoute
                    hasAccess={addDocs}
                    path={`${match.url}/documents/ocr`}
                    component={importOCR}
                  />
                  <PrivateRoute
                    hasAccess={readListSearch}
                    path={`${match.url}/searchs/search/:id`}
                    component={Search}
                  />
                  <PrivateRoute
                    hasAccess={readListSearch}
                    path={`${match.url}/searchs/search`}
                    component={Search}
                  />
                  <PrivateRoute
                    hasAccess={readListSearch}
                    path={`${match.url}/searchs/saved`}
                    component={SavedSearch}
                  />
                  <PrivateRoute
                    path={`${match.url}/audit`}
                    component={Audit}
                    hasAccess={readListAudit}
                  />
                  <PrivateRoute
                    path={`${match.url}/totalMetadata`}
                    component={TotalMetadata}
                    hasAccess={readListAudit}
                  />
                  <PrivateRoute
                    hasAccess={readMissingsDocs}
                    path={`${match.url}/documents/missings/:type`}
                    component={MissingBox}
                  />
                  <PrivateRoute
                    hasAccess={readMissingsDocs}
                    path={`${match.url}/documents/totalDocument/:boxId`}
                    component={TotalDocument}
                  />
                  <PrivateRoute
                    hasAccess={readArchivedUsers}
                    path={`${match.url}/users/archived`}
                    component={ArchivedUsers}
                  />
                  <PrivateRoute
                    hasAccess={readListReporting}
                    path={`${match.url}/reporting`}
                    component={Reporting}
                  />
                  <Redirect to="/error" />
                </Switch>
              </div>
            </main>
          </div>
        )}
      </Fragment>
    );
  }
}
const mapStateToProps = ({ menu, appData }) => {
  const { containerClassnames } = menu;
  return {
    containerClassnames,
    userPermissions: appData.users.userPermissions
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    { getUserPermissions }
  )(MainApp)
);
