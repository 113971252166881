import React, { Component } from "react";
import {
  Nav,
  UncontrolledDropdown,
  DropdownItem,
  DropdownToggle,
  DropdownMenu
} from "reactstrap";
import Autocomplete from "react-autocomplete";
import { NavLink } from "react-router-dom";
import { connect } from "react-redux";
import {
  setContainerClassnames,
  clickOnMobileMenu,
  changeLocale
} from "redux/actions";
import cs from "classnames";

import {
  menuHiddenBreakpoint,
  QUICK_SEARCH_INPUT_REGEX
} from "constants/defaultValues";
import { logoutApi } from "redux/users/apis";
import { injectIntl } from "react-intl";
import { getAutoCompleteDocs, getDocPagesApi } from "redux/document/apis";
import { getAutoCompletePageName } from "redux/pages/apis";
import { logger } from "util/Logger";
import { faUser, faCaretDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import IntlMessages from "util/IntlMessages";
import {optionsByInstance} from "../../util/instanceTypesHelper";


class TopNav extends Component {
  constructor(props) {
    super(props);
    this.menuButtonClick = this.menuButtonClick.bind(this);
    this.mobileMenuButtonClick = this.mobileMenuButtonClick.bind(this);
    this.handleChangeLocale = this.handleChangeLocale.bind(this);
    this.optionsToDisplay = optionsByInstance(props.locale)

    this.state = {
      isInFullScreen: false,
      docsSearch: []
    };
  }
  isInFullScreen = () => {
    return (
      (document.fullscreenElement && document.fullscreenElement !== null) ||
      (document.webkitFullscreenElement &&
        document.webkitFullscreenElement !== null) ||
      (document.mozFullScreenElement &&
        document.mozFullScreenElement !== null) ||
      (document.msFullscreenElement && document.msFullscreenElement !== null)
    );
  };

  handleChangeLocale = locale => {
    this.props.changeLocale(locale);
    window.location.reload(false);

  };
  toggleFullScreen = () => {
    const isInFullScreen = this.isInFullScreen();

    var docElm = document.documentElement;
    if (!isInFullScreen) {
      if (docElm.requestFullscreen) {
        docElm.requestFullscreen();
      } else if (docElm.mozRequestFullScreen) {
        docElm.mozRequestFullScreen();
      } else if (docElm.webkitRequestFullScreen) {
        docElm.webkitRequestFullScreen();
      } else if (docElm.msRequestFullscreen) {
        docElm.msRequestFullscreen();
      }
    } else {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.msExitFullscreen) {
        document.msExitFullscreen();
      }
    }
    this.setState({
      isInFullScreen: !isInFullScreen
    });
  };

  async handleLogout(e) {
    e.preventDefault();
    localStorage.clear();
    await logoutApi();
    this.props.history.push("/login");
  }

  handleSearchIconClick = e => {
    if (window.innerWidth < menuHiddenBreakpoint) {
      let elem = e.target;
      if (!e.target.classList.contains("search")) {
        if (e.target.parentElement.classList.contains("search")) {
          elem = e.target.parentElement;
        } else if (
          e.target.parentElement.parentElement.classList.contains("search")
        ) {
          elem = e.target.parentElement.parentElement;
        }
      }

      if (elem.classList.contains("mobile-view")) {
        elem.classList.remove("mobile-view");
      } else {
        elem.classList.add("mobile-view");
      }
    }
  };

  onLoadDocsOptions = async searchValue => {
    try {
      if (QUICK_SEARCH_INPUT_REGEX.test(searchValue)) {
        await getAutoCompletePageName(searchValue).then(res => {
          if (res && res.status === 200)
            this.setState({ docsSearch: res.data || [] });
        });
      } else {
        this.setState({ docsSearch: [] });
      }
      this.setState({ searchValue });
    } catch (error) {
      logger("error getting pages", error);
    }
  };

  menuButtonClick(e, menuClickCount, containerClassnames) {
    e.preventDefault();

    setTimeout(() => {
      var event = document.createEvent("HTMLEvents");
      event.initEvent("resize", false, false);
      window.dispatchEvent(event);
    }, 350);
    this.props.setContainerClassnames(++menuClickCount, containerClassnames);
  }
  mobileMenuButtonClick(e, containerClassnames) {
    e.preventDefault();
    this.props.clickOnMobileMenu(containerClassnames);
  }

  render() {
    const { containerClassnames, menuClickCount } = this.props;
    const { messages } = this.props.intl;
    return (
      <Nav className="navbar fixed-top">
        <NavLink
          to="#"
          className="menu-button d-none d-md-block"
          onClick={e =>
            this.menuButtonClick(e, menuClickCount, containerClassnames)
          }
        >
          <svg
            className="main"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 9 17"
          >
            <rect x="0.48" y="0.5" width="7" height="1" />
            <rect x="0.48" y="7.5" width="7" height="1" />
            <rect x="0.48" y="15.5" width="7" height="1" />
          </svg>
          <svg
            className="sub"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 18 17"
          >
            <rect x="1.56" y="0.5" width="16" height="1" />
            <rect x="1.56" y="7.5" width="16" height="1" />
            <rect x="1.56" y="15.5" width="16" height="1" />
          </svg>
        </NavLink>

        <NavLink
          to="#"
          className="menu-button-mobile d-xs-block d-sm-block d-md-none"
          onClick={e => this.mobileMenuButtonClick(e, containerClassnames)}
        >
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 26 17">
            <rect x="0.5" y="0.5" width="25" height="1" />
            <rect x="0.5" y="7.5" width="25" height="1" />
            <rect x="0.5" y="15.5" width="25" height="1" />
          </svg>
        </NavLink>
        <div className="search">
          <Autocomplete
            name="searchKeyword"
            wrapperStyle={{ width: "100%" }}
            renderInput={props => {
              return <input {...props} />;
            }}
            placeholder={messages["menu.search"]}
            getItemValue={i => (i ? `${i.boxId}/${i.documentsId}/${i.id}` : "")}
            items={this.state.docsSearch}
            onChange={({ target }) => {
              this.onLoadDocsOptions(target.value);
            }}
            onSelect={val => {
              this.props.history.push("/app/documents/list/box/" + val);
              this.setState({ searchValue: "" });
              this.setState({ docsSearch: [] });
            }}
            value={this.state.searchValue}
            renderItem={(i, isHighlighted) => (
              <div
                key={i.id}
                className={cs("w-100 d-block p-2 border-bottom", {
                  "font-weight-bold": isHighlighted
                })}
              >
                {i.name}
              </div>
            )}
          />
          <span
            className="search-icon"
            onClick={e => this.handleSearchIconClick(e)}
          >
            <i className="simple-icon-magnifier" />
          </span>
        </div>
        <div className="d-inline-block">
          <UncontrolledDropdown className="ml-2">
            <DropdownToggle
              caret
              color="light"
              size="sm"
              className="language-button"
            >
              <span className="name">{this.props.locale.toUpperCase()}</span>
            </DropdownToggle>
            <DropdownMenu className="mt-3" right>
              {this.optionsToDisplay.localeOptions.map(l => {
                return (
                  <DropdownItem
                    onClick={() => this.handleChangeLocale(l.id)}
                    key={l.id}
                  >
                    <IntlMessages id={l.name} />
                  </DropdownItem>
                );
              })}
            </DropdownMenu>
          </UncontrolledDropdown>
        </div>

        <a className="navbar-logo ems-title h4 ml-auto color-theme-1" href="/">
          <IntlMessages id={"app.title"} />
        </a>

        <div className="ml-auto">
          <div className="header-icons d-inline-block align-middle">
            <button
              className="header-icon btn btn-empty d-none d-md-inline-block"
              type="button"
              id="fullScreenButton"
              onClick={this.toggleFullScreen}
            >
              {this.state.isInFullScreen ? (
                <i className="simple-icon-size-actual d-block" />
              ) : (
                <i className="simple-icon-size-fullscreen d-block" />
              )}
            </button>
          </div>
          <div className="user d-inline-block">
            <UncontrolledDropdown className="dropdown-menu-right">
              <DropdownToggle className="p-0" color="empty">
                <FontAwesomeIcon icon={faUser} className="mr-2" />
                <span className="name mr-1">{this.props.username}</span>
                <FontAwesomeIcon
                  icon={faCaretDown}
                  className="ml-1"
                  size="sm"
                />
              </DropdownToggle>
              <DropdownMenu className="mt-3" right>
                <DropdownItem onClick={this.handleLogout.bind(this)}>
                  <IntlMessages id={"user.logout"} />
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </div>
        </div>
      </Nav>
    );
  }
}

const mapStateToProps = ({ menu, settings, appData }) => {
  const { containerClassnames, menuClickCount } = menu;
  const { locale } = settings;
  const { username } = appData.users.userPermissions.data;

  return { containerClassnames, menuClickCount, locale, username };
};
export default injectIntl(
  connect(
    mapStateToProps,
    {
      setContainerClassnames,
      clickOnMobileMenu,
      changeLocale,
      getAutoCompleteDocs,
      getDocPagesApi
    }
  )(TopNav)
);
