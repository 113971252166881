import * as types from "redux/actionTypes";

export const getPageMetadata = id => ({
  type: types.GET_PAGE_METADATA,
  payload: { id }
});

export const updatePageMetadata = (id, data, success, error) => ({
  type: types.UPDATE_PAGE_METADATA,
  payload: { id, data, success, error }
});

export const uploadPage = (
  document,
  onUploadProgress,
  success,
  error,
  cancelToken,
  isOCR = false
) => ({
  type: types.UPLOAD_PAGE,
  payload: {
    data: { document },
    onUploadProgress,
    success,
    error,
    cancelToken,
    isOCR
  }
});

export const getSiblingsPages = id => ({
  type: types.GET_SIBLINGS_PAGES,
  payload: { id }
});

export const downloadPage = (id, typeDoc, success) => ({
  type: types.DOWNLOAD_PAGE,
  payload: { id, typeDoc, success }
});

export const downloadPageTranslation = (id, typeDoc, success) => ({
  type: types.DOWNLOAD_PAGE_TRANSLATION,
  payload: { id, typeDoc, success }
});

export const downloadPageTranslationSuccess = (fileURL, type) => ({
  type: types.DOWNLOAD_PAGE_SUCCESS_TRANSLATION,
  payload: { fileURL, type }
});

export const downloadPageTranslationError = err => ({
  type: types.DOWNLOAD_PAGE_ERROR_TRANSLATION,
  payload: err
});

export const downloadPageSuccess = (fileURL, type) => ({
  type: types.DOWNLOAD_PAGE_SUCCESS,
  payload: { fileURL, type }
});

export const downloadPageError = err => ({
  type: types.DOWNLOAD_PAGE_ERROR,
  payload: err
});

export const searchPage = data => ({
  type: types.SEARCH_PAGE,
  payload: data
});

export const auditSearch = data => ({
  type: types.AUDIT_SEARCH,
  payload: data
});
export const auditSearchTotalMetadata = data => ({
  type: types.AUDIT_SEARCH_TOTAL_METADATA,
  payload: data
});

export const deletePage = (pageId, success, error) => ({
  type: types.DELETE_PAGE,
  payload: { pageId, success, error }
});

export const sendRequestDeletePage = (pageId, success, error) => ({
  type: types.SEND_REQUEST_DELETE_PAGE,
  payload: { pageId, success, error }
});
