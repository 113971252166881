import React, { Component } from 'react';
import { AUDIO, DOCUMENT, IMAGE, SOCIALMEDIA, VIDEO, WEBPAGES } from "../constants/resourceTypes";

export const getTypesOfPages = (documentType) => {

    let type = {};
    switch (documentType) {

        case DOCUMENT:
            type = { label: 'Document', icon: <i className="simple-icon-folder" /> }
            break;
        case VIDEO:
            type = { label: 'Video', icon: <i className="simple-icon-camrecorder" /> }
            break;
        case IMAGE:
            type = { label: 'Image', icon: <i className="simple-icon-picture" /> }
            break;
        case AUDIO:
            type = { label: 'Audio', icon: <i className="simple-icon-volume-2" /> }
            break;
        case SOCIALMEDIA:
            type = { label: 'SocialMedia', icon: <i className="simple-icon-like" /> }
            break;
        case WEBPAGES:
            type = { label: 'WebPages', icon: <i className="simple-icon-globe" /> }
            break;
    }
    return type;

} 