import { all, fork, put, takeEvery, call } from "redux-saga/effects";

import * as types from "redux/actionTypes";
import { auditSearchTotalActionsApi, getRtUriNamesApi } from "./apis";
import {
  errorAuditSearchTotalActions,
  setAuditSearchTotalActions,
  setRtUriNames
} from "./actions";

function* getAuditSearchTotalActionsSaga(action) {
  try {
    const response = yield call(auditSearchTotalActionsApi, action.payload);
    if (response.status !== 200) {
      throw new Error(response.data.message);
    }
    yield put(setAuditSearchTotalActions(response.data.list));
  } catch (error) {
    yield put(errorAuditSearchTotalActions(error.message));
  }
}

function* getRtUriNamesSaga() {
  try {
    const response = yield call(getRtUriNamesApi);
    if (response.status !== 200) {
      yield put(setRtUriNames([]));
    }
    yield put(setRtUriNames(response.data));
  } catch (error) {
    yield put(setRtUriNames([]));
  }
}

export function* watchAuditSaga() {
  yield takeEvery(
    types.GET_AUDIT_SEARCH_TOTAL_ACTIONS,
    getAuditSearchTotalActionsSaga
  );
  yield takeEvery(types.GET_AUDIT_RT_URI_NAMES, getRtUriNamesSaga);
}

export default function* rootSaga() {
  yield all([fork(watchAuditSaga)]);
}
