import * as types from "redux/actionTypes";

export const getDocsMetadata = id => ({
  type: types.GET_DOCS_METADATA,
  payload: { id }
});

export const updateDocsMetadata = (id, data, success, error, isVideo) => ({
  type: types.UPDATE_DOCS_METADATA,
  payload: { id, isVideo, data, success, error }
});

export const getDocsPages = id => ({
  type: types.GET_DOCS_PAGES,
  payload: { id }
});
export const getDocUriApi = id => ({
  type: types.GET_DOCS_URI,
  payload: { id }
});

export const deleteDocument = (id, success, error) => ({
  type: types.DELETE_DOCUMENT,
  payload: { id, success, error }
});

export const sendRequestDeleteDocument = (documentId, success, error) => ({
  type: types.SEND_REQUEST_DELETE_DOCUMENT,
  payload: { documentId, success, error }
});
