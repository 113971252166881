/* SETTINGS */
export const CHANGE_LOCALE = "CHANGE_LOCALE";

/* AUTH */
export const LOGIN_USER = "LOGIN_USER";
export const LOGIN_USER_SUCCESS = "LOGIN_USER_SUCCESS";
export const LOGIN_USER_ERROR = "LOGIN_USER_ERROR";
export const REGISTER_USER = "REGISTER_USER";
export const REGISTER_USER_SUCCESS = "REGISTER_USER_SUCCESS";
export const LOGOUT_USER = "LOGOUT_USER";

/* MENU */
export const MENU_SET_CLASSNAMES = "MENU_SET_CLASSNAMES";
export const MENU_CONTAINER_ADD_CLASSNAME = "MENU_CONTAINER_ADD_CLASSNAME";
export const MENU_CLICK_MOBILE_MENU = "MENU_CLICK_MOBILE_MENU";
export const MENU_CHANGE_DEFAULT_CLASSES = "MENU_CHANGE_DEFAULT_CLASSES";

/* USERS */
export const GET_USERS = "GET_USERS";
export const DELETE_USERS = "DELETE_USERS";
export const EDIT_USERS = "EDIT_USERS";
export const ENABLE_USERS = "ENABLE_USERS";
export const GET_ROLES = "GET_ROLES";
export const SET_USER_ROLE = "SET_USER_ROLE";
export const SET_USERS_DATA = "SET_USERS_DATA";
export const ERROR_USERS_DATA = "ERROR_USERS_DATA";
export const CHANGE_FILTER_USERS = "CHANGE_FILTER_USERS";
export const EDIT_USER_ON_MODAL = "EDIT_USER_ON_MODAL";
export const POST_USER = "POST_USER";

export const GET_USER_PERMISSIONS = "GET_USER_PERMISSIONS";
export const SET_USER_PERMISSIONS = "SET_USER_PERMISSIONS";

/* TEAMS */
export const GET_TEAMS = "GET_TEAMS";
export const SET_TEAMS = "SET_TEAMS";
export const ERROR_GETTING_TEAMS = "ERROR_GETTING_TEAMS";
export const DELETE_TEAM = "DELETE_TEAM";
export const EDIT_TEAM = "EDIT_TEAM";
export const EDIT_TEAM_ON_MODAL = "EDIT_TEAM_ON_MODAL";
export const POST_TEAM = "POST_TEAM";
export const GET_TEAMS_USER = "GET_TEAMS_USER";
export const SET_TEAMS_USER = "SET_TEAMS_USER";
export const ERROR_GETTING_TEAMS_USER = "ERROR_GETTING_TEAMS_USER";

/* IPAttempS */
export const GET_IPATTEMPTS = "GET_IPATTEMPTS";
export const SET_IPATTEMPTS = "SET_IPATTEMPTS";
export const ERROR_GETTING_IPATTEMPTS = "ERROR_GETTING_IPATTEMPTS";
export const DELETE_IPATTEMPT = "DELETE_IPATTEMPT";
export const EDIT_IPATTEMPT = "EDIT_IPATTEMPT";
export const EDIT_IPATTEMPT_ON_MODAL = "EDIT_IPATTEMPT_ON_MODAL";
export const POST_IPATTEMPT = "POST_IPATTEMPT";
export const GET_IPATTEMPTS_USER = "GET_IPATTEMPTS_USER";
export const SET_IPATTEMPTS_USER = "SET_IPATTEMPTS_USER";
export const ERROR_GETTING_IPATTEMPTS_USER = "ERROR_GETTING_IPATTEMPTS_USER";
/* RESTRICTIONS */
export const GET_RESTRICTIONS = "GET_RESTRICTIONS";
export const SET_RESTRICTIONS = "SET_RESTRICTIONS";
export const ERROR_GETTING_RESTRICTIONS = "ERROR_GETTING_RESTRICTIONS";
export const DELETE_RESTRICTION = "DELETE_RESTRICTION";
export const POST_RESTRICTION = "POST_RESTRICTION";

/* PROJETS */
export const GET_PROJETS = "GET_PROJETS";
export const SET_PROJETS = "SET_PROJETS";
export const ERROR_GETTING_PROJETS = "ERROR_GETTING_PROJETS";
export const DELETE_PROJET = "DELETE_PROJET";
export const POST_PROJET = "POST_PROJET";

/* BOXS */
export const GET_BOXS = "GET_BOXS";
export const SET_BOXS = "SET_BOXS";
export const ERROR_GETTING_BOXS = "ERROR_GETTING_BOXS";
export const GET_BOX_METADATA = "GET_BOX_METADATA";
export const SET_BOX_METADATA = "SET_BOX_METADATA";
export const ERROR_GETTING_BOX_META = "ERROR_GETTING_BOX_META";
export const UPDATE_BOX_METADATA = "UPDATE_BOX_METADATA";
export const GET_BOX_DOCS = "GET_BOX_DOCS";
export const SET_BOX_DOCS = "SET_BOX_DOCS";
export const SET_TOTAL_DOCS = "SET_TOTAL_DOCS";
export const ERROR_GETTING_BOX_DOCS = "ERROR_GETTING_BOX_DOCS";
export const GET_TOTAL_DOCS = "GET_TOTAL_DOCS";
export const ERROR_GETTING_TOTAL_DOCS = "ERROR_GETTING_TOTAL_DOCS";
export const UPDATE_BOX_TOTAL_DOCS = "UPDATE_BOX_TOTAL_DOCS";
export const DELETE_BOX = "DELETE_BOX";
export const SEND_REQUEST_DELETE_BOX = "SEND_REQUEST_DELETE_BOX";

/* DOCS */
export const GET_DOCS_PAGES = "GET_DOCS_PAGES";
export const SET_DOCS_PAGES = "SET_DOCS_PAGES";export const SET_DOCS_PAGES_URI = "SET__DOCS_PAGES_URI";
export const ERROR_GETTING_DOCS_PAGES = "ERROR_GETTING_DOCS_PAGES";

export const GET_DOCS_METADATA = "GET_DOCS_METADATA";
export const SET_DOCS_METADATA = "SET_DOCS_METADATA";
export const ERROR_GETTING_DOCS_META = "ERROR_GETTING_DOCS_META";

export const UPDATE_DOCS_METADATA = "UPDATE_DOCS_METADATA";

export const MISSINGS_PAGES = "MISSINGS_PAGES";
export const SET_MISSINGS_PAGES = "SET_MISSINGS_PAGES";
export const ERROR_MISSINGS_PAGES = "ERROR_MISSINGS_PAGES";

export const DELETE_DOCUMENT = "DELETE_DOCUMENT";
export const SEND_REQUEST_DELETE_DOCUMENT = "SEND_REQUEST_DELETE_DOCUMENT";


export const GET_DOCS_URI = "GET_DOCS_URI";
export const SET_DOCS_URI = "SET_DOCS_URI";
export const ERROR_GETTING_DOCS_URI = "ERROR_GETTING_DOCS_URI";
/* Pages */
export const GET_PAGE_METADATA = "GET_PAGE_METADATA";
export const SET_PAGE_METADATA = "SET_PAGE_METADATA";
export const ERROR_GETTING_PAGE_META = "ERROR_GETTING_PAGE_META";
export const UPDATE_PAGE_METADATA = "UPDATE_PAGE_METADATA";
export const DOWNLOAD_PAGE = "DOWNLOAD_PAGE";
export const DOWNLOAD_PAGE_TRANSLATION = "DOWNLOAD_PAGE_TRANSLATION";
export const DOWNLOAD_PAGE_SUCCESS_TRANSLATION = "DOWNLOAD_PAGE_SUCCESS_TRANSLATION";
export const DOWNLOAD_PAGE_ERROR_TRANSLATION = "DOWNLOAD_PAGE_ERROR_TRANSLATION";
export const DOWNLOAD_PAGE_SUCCESS = "DOWNLOAD_PAGE_SUCCESS";
export const DOWNLOAD_PAGE_ERROR = "DOWNLOAD_PAGE_ERROR";
export const UPLOAD_PAGE = "UPLOAD_PAGE";
export const GET_SIBLINGS_PAGES = "GET_SIBLINGS_PAGES";
export const SET_SIBLINGS_PAGES = "SET_SIBLINGS_PAGES";

export const SEARCH_PAGE = "SEARCH_PAGE";
export const SET_SEARCH_PAGE = "SET_SEARCH_PAGE";
export const ERROR_GETTING_SEARCH_PAGE = "ERROR_GETTING_SEARCH_PAGE";

export const AUDIT_SEARCH = "AUDIT_SEARCH";
export const SET_AUDIT_SEARCH = "SET_AUDIT_SEARCH";
export const ERROR_AUDIT_SEARCH = "ERROR_AUDIT_SEARCH";

export const AUDIT_SEARCH_TOTAL_METADATA = "AUDIT_SEARCH_TOTAL_METADATA";
export const SET_AUDIT_SEARCH_TOTAL_METADATA = "SET_AUDIT_SEARCH_TOTAL_METADATA";
export const ERROR_AUDIT_SEARCH_TOTAL_METADATA = "ERROR_AUDIT_SEARCH_TOTAL_METADATA";

export const GET_AUDIT_RT_URI_NAMES = "GET_AUDIT_RT_URI_NAMES";
export const SET_AUDIT_RT_URI_NAMES = "SET_AUDIT_RT_URI_NAMES";

export const DELETE_PAGE = "DELETE_PAGE";
export const SEND_REQUEST_DELETE_PAGE = "SEND_REQUEST_DELETE_PAGE";

/* Audit */
export const GET_AUDIT_SEARCH_TOTAL_ACTIONS = "GET_AUDIT_SEARCH_TOTAL_ACTIONS";
export const SET_AUDIT_SEARCH_TOTAL_ACTIONS = "SET_AUDIT_SEARCH_TOTAL_ACTIONS";
export const ERROR_AUDIT_SEARCH_TOTAL_ACTIONS =
  "ERROR_AUDIT_SEARCH_TOTAL_ACTIONS";

/** Metadata */
export const CHANGE_REPORTING_TAB = "CHANGE_REPORTING_TAB";

export const GET_BOX_WITHOUT_META = "GET_BOX_WITHOUT_META";
export const SET_BOX_WITHOUT_META = "SET_BOX_WITHOUT_META";
export const ERROR_BOX_WITHOUT_META = "ERROR_BOX_WITHOUT_META";
export const CHANGE_BOX_WITHOUT_META_FILTER = "CHANGE_BOX_WITHOUT_META_FILTER";
export const CHANGE_BOX_WITHOUT_META_PAGE_SIZE =
  "CHANGE_BOX_WITHOUT_META_PAGE_SIZE";

export const GET_DOC_WITHOUT_META = "GET_DOC_WITHOUT_META";
export const SET_DOC_WITHOUT_META = "SET_DOC_WITHOUT_META";
export const ERROR_DOC_WITHOUT_META = "ERROR_DOC_WITHOUT_META";
export const CHANGE_DOC_WITHOUT_META_FILTER = "CHANGE_DOC_WITHOUT_META_FILTER";
export const CHANGE_DOC_WITHOUT_META_PAGE_SIZE =
  "CHANGE_DOC_WITHOUT_META_PAGE_SIZE";

export const GET_PAGE_WITHOUT_META = "GET_PAGE_WITHOUT_META";
export const SET_PAGE_WITHOUT_META = "SET_PAGE_WITHOUT_META";
export const ERROR_PAGE_WITHOUT_META = "ERROR_PAGE_WITHOUT_META";
export const CHANGE_PAGE_WITHOUT_META_FILTER =
  "CHANGE_PAGE_WITHOUT_META_FILTER";
export const CHANGE_PAGE_WITHOUT_META_PAGE_SIZE =
  "CHANGE_PAGE_WITHOUT_META_PAGE_SIZE";
/* searchs */

export const GET_SEARCH = "GET_SEARCH";
export const SET_SEARCH = "SET_SEARCH";
export const ERROR_GETTING_SEARCH = "ERROR_GETTING_SEARCH";
export const GET_SEARCHS = "GET_SEARCHS";
export const SET_SEARCHS = "SET_SEARCHS";
export const ERROR_GETTING_SEARCHS = "ERROR_GETTING_SEARCHS";
export const DELETE_SEARCH = "DELETE_SEARCH";
export const EDIT_SEARCH = "EDIT_SEARCH";
export const EDIT_SEARCH_ON_MODAL = "EDIT_SEARCH_ON_MODAL";
export const POST_SEARCH = "POST_SEARCH";
export const CLEAR_SAVED_SEARCH_FILTER = "CLEAR_SAVED_SEARCH_FILTER";

/* delete requests */
export const GET_DELETE_REQUESTS = "GET_DELETE_REQUESTS";
export const CHANGE_DELETE_REQUESTS_FILTER = "CHANGE_DELETE_REQUESTS_FILTER";
export const CONFIRM_DELETE_REQUEST = "CONFIRM_DELETE_REQUEST";
export const CANCEL_DELETE_REQUEST = "CANCEL_DELETE_REQUEST";
export const SET_DELETE_REQUESTS_DATA = "SET_DELETE_REQUESTS_DATA";
export const ERROR_DELETE_REQUESTS_DATA = "ERROR_DELETE_REQUESTS_DATA";
