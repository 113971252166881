import React from "react";

export const totalCountField =  [
    { id: "Total evidence in EMS (scanned , e-files , open source/ excluding translations)", value: "totalEvidence",icon: <i className="simple-icon-folder"/>},
    { id: "Total Documents (Scanned, E-files and Open-Source)", value: "totalDocuments",icon: <i className="simple-icon-folder"/> },
    { id: "Total Documents (Scanned)", value: "totalSDocuments",icon: <i className="simple-icon-folder"/> },
    { id: "Total Documents (E-files)", value: "totalEDocuments",icon: <i className="simple-icon-folder"/> },
    { id: "Total Documents (Open-Source)", value: "totalOSDocuments",icon: <i className="simple-icon-folder"/> },
   
    { id: "Total Images (E-files and Open-Source)", value: "totalImages",icon: <i className="simple-icon-picture"/> },
    { id: "Total Images (E-files)", value: "totalEImages",icon: <i className="simple-icon-picture"/> },
    { id: "Total Images (Open-Source)", value: "totalOSImages",icon: <i className="simple-icon-picture"/> },
   
    { id: "Total Videos (E-files and Open-Source)", value: "totalVideos",icon: <i className="simple-icon-camrecorder"/> },
    { id: "Total Videos (E-files)", value: "totalEVideos",icon: <i className="simple-icon-camrecorder"/> },
    { id: "Total Videos (Open-Source)", value: "totalOSVideos",icon: <i className="simple-icon-camrecorder"/> },
    
    { id: "Total Audio (E-files and Open-Source)", value: "totalAudio",icon: <i className="simple-icon-volume-2"/> },
    { id: "Total Audio (E-files)", value: "totalEAudio",icon: <i className="simple-icon-volume-2"/> },
    { id: "Total Audio (Open-Source)", value: "totalOSAudio",icon: <i className="simple-icon-volume-2"/> },
    
    { id: "Total Social Media (E-files and Open-Source)", value: "totalSocialMedia",icon: <i className="simple-icon-like"/> },
    { id: "Total Social Media (E-files)", value: "totalESocialMedia",icon: <i className="simple-icon-like"/> },
    { id: "Total Social Media (Open-Source)", value: "totalOSSocialMedia",icon: <i className="simple-icon-like"/> },
    
    { id: "Total URLs (E-files and Open-Source)", value: "totalURLs",icon: <i className="simple-icon-globe"/> },
    { id: "Total URLs (E-files)", value: "totalEURLs",icon: <i className="simple-icon-globe"/> },
    { id: "Total URLs (Open-Source)", value: "totalOSURLs",icon: <i className="simple-icon-globe"/> },
]
