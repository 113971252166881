export const formatDateSearchInput = dateObj => {
if(dateObj)
dateObj=new Date(dateObj);
  return dateObj
    ? `${dateObj.getFullYear()}-${addZ(dateObj.getMonth() + 1)}-${addZ(
        dateObj.getDate()
      )}`
    : undefined;
};

const addZ = n => (n < 10 ? "0" + n : "" + n);
