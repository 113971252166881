import React from "react";
import IntlMessages from "util/IntlMessages";
import { Field, reduxForm } from "redux-form";
import { ValidatedInput } from "components/FormFields/Input";
import StateButton from "components/StateButton";

const PLACEHOLDER =
  "e.g. SYR.D0001, SYR.D0002.001, SYR.D0002.002.001, SYR.D0002.001.004-SYR.D0002.001.050";

const DownloadDocImagesForm = ({ handleSubmit, isLoading }) => {
  return (
    <form onSubmit={handleSubmit}>
      <Field
        className="mb-3"
        name="selection"
        label="Selection"
        component={ValidatedInput}
        labelClassName="mb-0"
        type="text"
        placeholder={PLACEHOLDER}
      />
      <StateButton
        id="successButton"
        color="primary"
        type="submit"
        isLoading={isLoading}
      >
        <IntlMessages id="Download" />
      </StateButton>
    </form>
  );
};

export default reduxForm({
  form: "DownloadDocImagesForm"
})(DownloadDocImagesForm);
