/* Gogo Language Texts

Table of Contents

01.General
02.User Login, Logout, Register
03.Menu
04.Error Page
*/

module.exports = {
  /* 01.General */
  "app.title": "Evidence Management System",
  /* 02.User Login, Logout, Register */
  "user.logout": "Logout",
  "user.login-intro": "Please enter your credentials",
  "user.username": "Username",
  "user.password": "Password",
  "user.login-button": "LOGIN",
  "user.error-gettings-users": "Error getting users",

  /* 03.Menu */
  "menu.app": "Home",
  "menu.users": "Users",
  "menu.documents": "Documents",
  "menu.search": "Search",
  "menu.saved-search": "Saved searches",
  "menu.audit": "Audit",
  "menu.restrictions": "Restrictions",
  "menu.reporting": "Reporting",
  "menu.savesearch": "Save search",
  "menu.deleteRequests": "Deleting requests",
  "menu.ips": "Ips attempts",

  /* 04.Error Page */
  "layouts.error-title": "Ooops... looks like an error occurred!",
  "layouts.error-code": "Error code",
  "layouts.go-back-home": "GO BACK HOME",

  /* 04.DataList : Users */
  "user.Manage-Uses": "Manage Users",
  "user.Archived-Users": "Archived Users",
  "user.Manage-Teams": "Manage Teams",
  "user.Manage-IpAttempts": "Manage Ips",
  "user.Add-User": "Add Users",
  "user.Edit-User": "Edit Users",
  "user.role": "Role",
  "users.add-success-message": "User saved successfully!",
  "users.deleted-success-message": "User deleted successfully!",
  "users.enabled-success-message": "User enabled successfully!",
  "users.error-authentification": "Login failed !",
  "users.error-locked": "Your account is blocked please try again after ",
  "users.error-locked-minute": " minutes!",
  "users.confirm-delete-title": "Confirmation",
  "users.confirm-delete": "Are you sure you want to archive this user ?",
  "users.blocked": "Blocked user",
  "users.unlocked": "user unlocked",

  /* DataList : Teams */
  "teams.Add-Teams": "Add Teams",
  teams: "Teams",
  "teams.Edit-Team": "Edit Teams",
  "teams.add-success-message": "Team saved successfully!",
  "teams.deleted-success-message": "Team deleted successfully!",
  "teams.confirm-delete": "Are you sure you want to delete the team ?",
  "teams.error-gettings-teams": "Error getting teams",
  "teams.users": " user(s)",

  /* DataList : restrictions */
  "restrictions.Add-Restrictions": "Add Restrictions",
  "restrictions.add-success-message": "Restriction saved successfully!",
  "restrictions.deleted-success-message": "Restriction deleted successfully!",
  "restrictions.confirm-delete":
    "Are you sure you want to delete the Restriction ?",
  "restrictions.error-gettings": "Error getting restrictions",

  /* DataList : projets */
  "projets.Add-Projets": "Add Projects",
  "projets.add-success-message": "Projet saved successfully!",
  "projets.deleted-success-message": "Projet deleted successfully!",
  "projets.confirm-delete": "Are you sure you want to delete the Projet ?",
  "projets.error-delete": "Files present. Please delete all Project content before deleting Project ",
  "projets.error-gettings": "Error getting projets",
  "projects.description": "Project description",
  "projects.prefix": "Project prefix",


  /* Documents */
  "doc.list-document": "Manage Documents",
  "doc.list-projets": "Manage Projects",
  "doc.import-documents": "Import documents",
  "doc.download-document-images": "Download document images",
  "doc.import-ocr": "Import OCR files",
  "doc.import-metadata": "Import metadata",
  "doc.document-boxes": "Document boxes",
  "doc.video-boxes": "Video boxes",
  "doc.files in": "Files in",
  "doc.file": "File:",
  "doc.download": "Download",
  "doc.Pages in": "Pages in",
  "doc.Box Information Sheet": "Box Information Sheet",
  "doc.no.missings": "No missing documents",
  Archive: "Archive",
  "set-total-docs": "Total folders for box",
  "set-total-pages": "Total pages for folders",
  "Changes saved successfully": "Changes saved successfully!",
  "documents.hide-metadata": "Hide metadata",
  "document.delete-success-message": "Document deleted successfully !",
  /* DataList : IpAttempts */
  "ipAttempts.deleted-success-message": "Ip deleted successfully!",
  "ipAttempts.confirm-delete": "Are you sure you want to delete the ip ?",
  "ipAttempts.error-gettings-ipAttempts": "Error getting ips",

  // Boxes
  "box.delete-success-message": "Box deleted successfully !",

  /* Commans */
  Cancel: "Cancel",
  Close: "Close",
  Enable: "Enable",
  Confirm: "Confirm",
  Save: "Save",
  Submit: "Submit",
  Edit: "Edit",
  "First Name": "First Name",
  "Last Name": "Last Name",
  Name: "Name",
  Email: "Email",
  OCR: "OCR",
  Username: "Username",
  User: "User",
  Users: "Users",
  Team: "Team",
  Box: "Box",
  Boxs: "Boxs",
  Action: "Action",
  Document: "Document",
  Image: "Image",
  WebPages: "WebPages",
  SocialMedia: "Social Media/Chats",
  Audio: "Audio",
  Page: "Page",
  "confirm-password": "Confirm password",
  "order-by": "Order By :",
  Password: "Password",
  Delete: "Delete",
  Videos: "Videos",
  Pages: "Pages",
  Images: "Images",
  Metadata: "Metadata",
  Video: "Video",
  Documents: "Documents",
  "Delete document": "Delete folder",
  Prefix: "Prefix",
  "Add files...": "Add files...",
  "Cancel upload": "Cancel upload",
  "Start upload": "Start upload",
  "Page Metadata": "Page Metadata",
  "Folder Metadata": "Folder Metadata",
  "Box Metadata": "Box Metadata",
  "Box Name": "Box Name",
  "Authorized Ips": "Authorized Ips",
  "Metadata not saved": "Metadata not saved",
  "Missings Pages": "Missings Pages",
  "Missings Documents": "Missings Folders",
  "Total Document": "Total Folder",
  Selection: "Selection",
  Download: "Download",
  "Save changes": "Save changes",
  Generate: "Generate",

  /* Search */
  "search.basic-search": "Basic Search",
  "search.specific-search": "Specific Search",
  "search.advanced-search": "Advanced Search",
  "Activate fuzzy search (search for terms that are similar to, but not exactly like our search term)":
    "Activate fuzzy search (search for terms that are similar to, but not exactly like our search term)",

  /* Errors */
  "error.required": "Required field",
  "error.invalide Email": "Invalid Email",
  "error.pwd-not-match": "Password not match",
  "errors.error-adding-user": "Error occurred please try again",
  "error.password-to-short": "Password too short",
  "error.name-to-short": "The name of the project must be with 3 characters",
  "no-data-found": "No data Found !",
  "file-missing": "This file seems to be missing.",
  "Download document images error : Invalid range":
    "The selection must follow this pattern : SYR.D0001, SYR.D0002.001, SYR.D0002.002.001, SYR.D0002.001.004-SYR.D0002.001.050. Use a comma (,) to separate boxes, documents, pages and page ranges, and a dash (-) for the ranges.",
  "No files found": "No files found",
  "general.exception.interne": "Internal Server Error",
  "la donnée recherchée n'existe pas": "Resource Missing Error",

  /* Search */
  "Search term": "Search term",
  "Start Date": "Start Date",
  "End Date": "End Date",
  "Location seized": "Location seized",
  "Digital forensics ID": "Digital forensics ID",
  Notes: "Notes",
  "Document From": "Page From",
  "Document To": "Page To",
  "Document CC": "Page CC",
  "Document summary": "Folder summary",
  "Names of people": "Names of people",
  "Names of places": "Names of places",
  "Names of entities": "Names of entities",
  To: "To",
  From: "From",
  Cc: "Cc",
  Tags: "Signature Block",
  "Location seized Box": "Location seized Box Metadata",
  "Notes Box": "Notes Box Metadata",
  "Document From Folder": "Page From Folder MetaData",
  "Document To Folder": "Page To Folder MetaData",
  "Document CC Folder": "Page CC Folder MetaData",
  "Document summary Folder": "Page summary Folder MetaData",
  "Names of people Page": "Names of people Page MetaData",
  "Names of places Page": "Names of places Page MetaData",
  "Names of entities Page": "Names of entities Page MetaData",
  "To Page": "To Page MetaData",
  "From Page": "From Page MetaData",
  "Cc Page": "Cc Page MetaData",
  "Tags Page": "Signature Block Page MetaData",
  "Start Date Page": "Start Date Page MetaData",
  "End Date Page": "End Date Page MetaData",
  "All these words": "All these words",
  "Any of these words": "Any of these words",
  "None of these words": "None of these words",
  "Find pages with": "Find pages with...",
  "Show Filter": "Show Filter",

  "searches.saved": "Saved searches",
  "search.edit": "Save search",
  "search.add-success-message": "Search saved successfully!",
  "search.confirm-delete": "Are you sure you want to delete the search ?",
  "search.name": "What would you to like to call this search ?",

  /** Audit Page */
  "Action types": "Action types",
  "Show all action types": "Show all action types",
  "Show only logoff actions": "Show only logoff actions",
  "Show only logon actions": "Show only logon actions",

  totalDocuments: "Total Number of Documents in EMS",
  totalEnglishTranslations: "Total English Translations",
  totalImages: "Total Images",
  totalMetadatas: "Total Count of Metadata",
  totalOcrs: "Total OCR'ed Documents",
  totalVideos: "Total Videos",
  "Next Page": "Next Page",
  "Previous Page": "Previous Page",

  "Records without metadata": "Records without metadata",
  "Search for documents box having no metadata for fields":
    "Search for documents box having no metadata for fields",
  Back: "Go Back",
  Forward: "Go Forward",
  "Home.search": "Home",
  //Pages
  "pages.download": "Download",
  "pages.no-english-translation": "This page was not translated into English.",
  "page.page-tab": "Page",
  "page.page-english-translation-tab": "English translation",
  "page.delete-success-message": "Page deleted successfully !",
  "page.no.missings": "No missings pages",

  // Audit
  "audit.download-audit-search-results": "Download audit search results",
  "audit.download-audit-search-results-body":
    "Do you want to download this page only or all the results ? (Downloading all the results may take significantly more time)",
  "audit.this-page-only": "This page only",
  "audit.all-results": "All results",
  "audit.of-action": "#  of actions",
  "audit.progress-metadata": "Progress of metadata addition",
  "audit.type-search.users": "Type to search users ...",
  "audit.type-search.teams": "Type to search teams ...",
  "audit.type-search.documents": "Type to search documents ...",
  "audit.type-search.boxes": "Type to search boxes ...",
  "audit.type-search.pages": "Type to search pages ...",


  // Delete Requests
  "delete-requests": "Deleting requests",
  "delete-requests.confirm-delete-title": "Confirmation",
  "delete-requests.confirm-cancel-title": "Confirmation",
  "delete-requests.confirm-delete-content":
    "Are you sure you want to delete the resource?",
  "delete-requests.confirm-cancel-content":
    "Are you sure you want to cancel the request?",
  "delete-requests.deleted-success-message": "Resources deleted successfully !",
  "delete-requests.canceled-success-message": "Requests canceled !",
  "delete-request-sent": "Delete request sent !",

  //Delete Ressources
  "delete-ressource.msg": "Are you sure you want to send a request to delete this resource ?",
  "delete-ressource.error": "An error occured while trying to delete. Please try again later",
  "Folders": "Folders",

  "Sort": "Sort by",
  "asc": "Name (Ascending)",
  "desc": "Name(Descending)",
  "Title": "Title",
  "Hijri Date": "Hijri Date",
  "Start Hijri Date": "Start Hijri Date",
  "End Hijri Date": "End Hijri Date",
  "PhoneOrEmail": "Phone/Fax Numbers or Emails",
  "DocumentReferenceCommunication": "Document Reference Number/Communication number",
  "DocumentReferenceMentioned": "Document Reference Numbers Mentioned",
  "Languages": "Languages",
  "Format": "Format",
  "Subject": "Subject",
  "Description": "Description",
  "ReferencedInBrief": "Referenced in [Briefs]",
  "DuplicateDocNo": "Duplicate Doc. No",
  "Previously labelled under":"Previously labelled under",
  "First Page": "First Page",
  "Last Page": "Last Page",
  "First Result": "First Result",
  "Previous Result": "Previous Result",
  "Next Result": "Next Result",
  "Last Result": "Last Result",
  "Original Filename": "Original Filename",
  "MD5 Hash Value": "MD5 Hash Value",
  "Box type": "Box type",
  "Content type": "Content type",
  "SHA1 Hash Value": "",
  "Relevant": "Relevant",
  "Translation ": "Translation",
  "Total Documents (Scanned, E-files and Open-Source)": "Total Documents (Scanned, E-files and Open-Source)",
  "Total Documents (Scanned)": "Total Documents (Scanned)",
  "Total Documents (E-files)": "Total Documents (E-files)",
  "Total Documents (Open-Source)": "Total Documents (Open-Source)",
  "Total Images (E-files and Open-Source)": "Total Images (E-files and Open-Source)",
  "Total Images (E-files)": "Total Images (E-files)",
  "Total Images (Open-Source)": "Total Images (Open-Source)",
  "Total Videos (E-files and Open-Source)": "Total Videos (E-files and Open-Source)",
  "Total Videos (E-files)": "Total Videos (E-files)",
  "Total Videos (Open-Source)": "Total Videos (Open-Source)",
  "Total Audio (E-files and Open-Source)": "Total Audio (E-files and Open-Source)",
  "Total Audio (E-files)": "Total Audio (E-files)",
  "Total Audio (Open-Source)": "Total Audio (Open-Source)",
  "Total Social Media (E-files and Open-Source)": "Total Social Media (E-files and Open-Source)",
  "Total Social Media (E-files)": "Total Social Media (E-files)",
  "Total Social Media (Open-Source)": "Total Social Media (Open-Source)",
  "Total URLs (E-files and Open-Source)": "Total URLs (E-files and Open-Source)",
  "Total URLs Media (E-files)": "Total URLs Media (E-files)",
  "Total URLs Media (Open-Source)": "Total URLs Media (Open-Source)",
  "Total evidence in EMS (scanned , e-files , open source/ excluding translations)": "Total evidence in EMS (Scanned, E-files , Open-Source/ excluding translations)",
  "Total Videos (A boxes)": "Total Videos (A boxes)",
  "Total evidence in EMS (scanned , e-files , open source, videos/ excluding translations)": "Total evidence in EMS (Scanned, A-videos, E-files , Open-Source/ excluding translations)"
};
