import React, { Component, Fragment } from "react";
import IntlMessages from "util/IntlMessages";
import MUIDataTable from "mui-datatables";
import { get } from "lodash";
import cs from "classnames";
import { injectIntl } from "react-intl";
import { connect } from "react-redux";
import {
  auditSearch,
  getAuditSearchTotalActions,
  getRtUriNames,
  getDocUriApi
} from "redux/actions";
import { Filter } from "./filter";
import { DataListContainer } from "components/DataListContainer";
import { Colxx } from "components/CustomBootstrap";
import { columns, options } from "./dataTableOptions";
import { Chart } from "./Chart";
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core/styles";
import Download from "./Download";
import LightSpinner from "components/LightSpinner";

class AuditCmp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showFilter: true,
      showData: true,
      options: {
        filterType: "checkbox",
        pagination: false,
        responsive: "scroll",
        filter: false,
        search: false,
        sort: false,
        selectableRows: "none",
        onRowClick: (v, u) => this.onRowClick(v, u),
      },
      changePageSize: false,
      listData: null
    };
  }
  componentDidMount() {
    if (!this.props.rTUriNames || this.props.rTUriNames.length < 1)
      this.props.getRtUriNames();


  }
  componentDidUpdate() {
    if (this.props.pathuri && !Array.isArray(this.props.pathuri)) {
      //this.setState({ showData: true });
      this.props.history.push("/app/documents/list/box/" + this.props.pathuri);

    }
  }
  auditSearch = values => {
    const { resultPerPage } = this.props.filterAudit;
    const page = 1;
    const data = getFilterValuesForSubmit(values);
    
    this.props.auditSearch({ ...data, page, resultPerPage });
    this.setState({ changePageSize: false, showData: true })
    this.props.getAuditSearchTotalActions(data);
  };
  onRowClick = (rowData, rowMeta) => {
    if (rowData && rowData.length > 0 && rowData[1] === "Get Box" || rowData[1] === "Get document" || rowData[1] === "Get page") {
      const docName = rowData[2].props.children[0].props.children[0];
      this.setState({ showData: false })
      this.props.getDocUriApi(docName);

    }

  }
  changePageSize = size => {
    const { filterAudit } = this.props;
    filterAudit.resultPerPage = size;
    filterAudit.page = 1;
    filterAudit.isNotSearch = true;
    this.setState({ changePageSize: true, listData: this.props.listData })
    this.props.auditSearch(filterAudit);

  };

  onChangePage = page => {
    const { filterAudit } = this.props;
    filterAudit.page = page;
    this.props.auditSearch(filterAudit);
  };

  getMuiTheme = () =>
    createMuiTheme({
      overrides: {
        MUIDataTable: {
          responsiveScroll: {
            maxHeight: this.state.showFilter ? "800px" : "1200px"
          }
        }
      }
    });

  render() {
    let {
      loading,
      listData,
      filterAudit,
      searchActionsCount,
      rTUriNames,
      filterValues,
      pathuri,
    } = this.props;
    return (
      <Fragment>
        <DataListContainer
          title={"menu.audit"}
          addNewText="Show Filter"
          hideButtons={this.state.showFilter}
          onAddClick={() => this.setState({ showFilter: true })}
          dataLength={!this.state.changePageSize ? listData.total || 0 : this.state.listData.total || 0}
          pageSize={filterAudit.resultPerPage}
          changePageSize={this.changePageSize}
          onChangePage={this.onChangePage}
          currentPage={filterAudit.page}
          showChildrenForEmpty
        >
          <Filter
            onSubmit={this.auditSearch}
            onClose={() => this.setState({ showFilter: false })}
            className={cs({ "d-none": !this.state.showFilter })}
            rTUriNames={rTUriNames}
          />
          <Colxx xxs="12" className="text-center h6">
            {loading && !this.state.changePageSize && < div className="loading" />}
            {listData.total === 0 && <IntlMessages id="no-data-found" />}
          </Colxx>
          {listData.total > 0 && this.state.showData && !this.state.changePageSize && (
            <Colxx xxs="12" className="text-center h6">
              <Download data={listData.result} filter={filterValues} />
              <MuiThemeProvider theme={this.getMuiTheme()}>
                <MUIDataTable
                  data={listData.result}
                  columns={columns}
                  options={{ ...this.state.options, download: false }}
                />
              </MuiThemeProvider>
            </Colxx>
          )}
          {this.state.changePageSize && this.state.listData && this.state.listData.total > 0 && this.state.showData && (
            <Colxx xxs="12" className="text-center h6">
              <Download data={this.state.listData.result} filter={filterValues} />
              <MuiThemeProvider theme={this.getMuiTheme()}>
                <MUIDataTable
                  data={this.state.listData.result}
                  columns={columns}
                  options={{ ...this.state.options, download: false }}
                />
              </MuiThemeProvider>
            </Colxx>
          )}
          {!this.state.showData && <LightSpinner />}
        </DataListContainer>
        <div className="mt-5" />
        <Chart searchActionsCount={searchActionsCount} />
      </Fragment>
    );
  }
}
const mapStateToProps = state => {
  const {
    appData,
    form: { auditSearch = {} }
  } = state;
  return {
    listData: appData.pages.audit.data,
    loading: appData.pages.audit.loading,
    filterAudit: appData.pages.filterAudit,
    searchActionsCount: appData.audit.searchActionsCount,
    rTUriNames: appData.audit.rTUriNames,
    filterValues: getFilterValuesForSubmit(auditSearch.values),
    pathuri: appData.docs.pagesuri,

  };
};

export const Audit = connect(
  mapStateToProps,
  { auditSearch, getAuditSearchTotalActions, getRtUriNames, getDocUriApi }
)(injectIntl(AuditCmp));

const getFilterValuesForSubmit = values => {
  const data = Object.assign({}, values);
  data.rtUriNameId = get(data, "rtUriNameId.id");
  data.userName = get(data, "userName.username");
  data.teamId = get(data, "teamId.id");
  data.boxeId = get(data, "boxeId.id");
  data.documentId = get(data, "documentId.id");
  data.pageId = get(data, "pageId.id");

  return data;
};
