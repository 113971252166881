import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { Button, Modal, ModalHeader, ModalBody } from "reactstrap";
import StateButton from "components/StateButton";
import { Alert } from "reactstrap";
import IntlMessages from "util/IntlMessages";


class DeleteResourceModal extends Component {
  renderModalBody = () => {
    const {
      onDelete,
      canDelete,
      isDeleting,
      deletingError,
      sendDeleteRequestError,
      onSendDeleteRequest,
      isSendingDeleteRequest,
      idResource
    } = this.props;
    if (canDelete) {
      return (
        <Fragment>
          {deletingError && (
            <Alert color="danger">
              <IntlMessages id="delete-ressource.error" />
            </Alert>
          )}
          <p><IntlMessages id="delete-ressource.msg" /></p>
          <StateButton
            color="danger"
            className="d-flex ml-auto"
            onClick={onDelete}
            isLoading={isDeleting}
          >
            <IntlMessages id="Delete" />
          </StateButton>
        </Fragment>
      );
    }
    return (
      <Fragment>
        {sendDeleteRequestError && (
          <Alert color="danger">
            <IntlMessages id="delete-ressource.error" />
          </Alert>
        )}
        <p> <IntlMessages id="delete-ressource.msg" /></p>
        <StateButton
          color="secondary"
          className="d-flex ml-auto"
          onClick={onSendDeleteRequest}
          isLoading={isSendingDeleteRequest}
        >
          Send
        </StateButton>
      </Fragment>
    );
  };

  render() {
    const {
      title,
      buttonLabel,
      buttonClassName,
      toggleDeleteResourceModal,
      deleteResourceModal,
      canDelete,
      idResource
    } = this.props;
    return (
      <Fragment>
        <Button
          color={canDelete ? "danger" : "secondary"}
          onClick={toggleDeleteResourceModal}
          className={buttonClassName}
          outline
        >
          <IntlMessages id={buttonLabel}
          />
        </Button>
        <Modal isOpen={deleteResourceModal} toggle={toggleDeleteResourceModal}>
          <ModalHeader toggle={toggleDeleteResourceModal}><IntlMessages id={title} />&nbsp; {idResource}</ModalHeader>
          <ModalBody>{this.renderModalBody()}</ModalBody>
        </Modal>
      </Fragment>
    );
  }
}

DeleteResourceModal.propTypes = {
  buttonLabel: PropTypes.string,
  title: PropTypes.string,
  buttonClassName: PropTypes.string
};

export default DeleteResourceModal;
