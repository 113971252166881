import appLocaleData from "react-intl/locale-data/ckb";
import ckbMessages from "../locales/ckb_IQQ";

const ckbLang = {
  messages: {
    ...ckbMessages
  },
  locale: "ckb-IQQ",
  data: appLocaleData
};
export default ckbLang;
