export const getLetters = (str) => {
    let result = "";
    for (let c of str) {
        if (isNaN(c))
            result += c;
        else
            break;
    }

    return result;
}