import * as types from "redux/actionTypes";

const INIT_STATE = {
  loading: false,
  data: {
    result: [],
    total: 0
  },
  filter: {
    page: 1,
    pageSize: 10
  }
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case types.GET_DELETE_REQUESTS:
      return {
        ...state,
        data: {
          result: [],
          total: 0
        },
        loading: true
      };

    case types.SET_DELETE_REQUESTS_DATA:
      return {
        ...state,
        data: {
          result: action.payload.result,
          total: action.payload.total
        },
        loading: false,
        error: false
      };

    case types.ERROR_DELETE_REQUESTS_DATA:
      return {
        ...state,
        loading: false,
        error: true
      };

    case types.CHANGE_DELETE_REQUESTS_FILTER:
      return {
        ...state,
        filter: action.payload
      };
    default:
      return { ...state };
  }
};
