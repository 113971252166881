import React, { Fragment } from "react";
import { connect } from "react-redux";
import { Field, reduxForm, formValueSelector } from "redux-form";
import { Prompt } from "react-router-dom";
import { Button } from "reactstrap";
import { NavLink } from "react-router-dom";
import IntlMessages from "util/IntlMessages";
import StateButton from "components/StateButton";
import { ValidatedInput } from "components/FormFields/Input";
import { renderDelay } from "./renderDelay";
import { ValidatedTextarea } from "components/FormFields/Textarea";
import { maxLength45, maxLength255 } from "util/validationRules";
import { readMissingsPages } from "../../../../util/permissions";

const documentCmp = props => {
  const { isEdited, handleSubmit, dirty, saveState } = props;

  return (
    <Fragment>
      <Prompt
        when={!props.submitSucceeded && props.dirty}
        message={location => `êtes vous sur de vouloir quitter sans modifier ?`}
      />
      <form
        onSubmit={handleSubmit(values => {
          props.onSubmit(values);
          props.initialize(values);
        })}
      >
        {!isEdited && dirty && (
          <span className="d-block mb-2 text-info">
            <IntlMessages id="Metadata not saved" />
          </span>
        )}
        <p className={`text-muted ${localStorage.getItem("currentLanguage") === 'ar' ? "text-small-ar" : "text-small"} mb-0`}><IntlMessages id="From" /></p>
        <Field
          className="mb-3"
          inputClassName="pl-0 pr-0 form-control"
          name="from"
          component={ValidatedTextarea}
          type="text"
          disabled={!isEdited}
          validate={maxLength45}
        />
        <p className={`text-muted ${localStorage.getItem("currentLanguage") === 'ar' ? "text-small-ar" : "text-small"} mb-0`}><IntlMessages id="To" /></p>
        <Field
          className="mb-3"
          inputClassName="pl-0 pr-0"
          name="to"
          component={ValidatedInput}
          type="text"
          disabled={!isEdited}
          validate={maxLength45}
        />
        <p className={`text-muted ${localStorage.getItem("currentLanguage") === 'ar' ? "text-small-ar" : "text-small"} mb-0`}><IntlMessages id="CC" /></p>
        <Field
          className="mb-3"
          inputClassName="pl-0 pr-0 form-control"
          name="cc"
          component={ValidatedTextarea}
          type="text"
          disabled={!isEdited}
          validate={maxLength255}
        />
        <p className={`text-muted ${localStorage.getItem("currentLanguage") === 'ar' ? "text-small-ar" : "text-small"} mb-0`}><IntlMessages id="Summary" /></p>
        <Field
          inputClassName="pl-0 pr-0 form-control"
          name="summary"
          component={ValidatedTextarea}
          type="text"
          disabled={!isEdited}
        />
        {!isEdited && readMissingsPages() && (
          <NavLink to={`/app/documents/missings/${'pages'}`}>
            <Button outline color="primary" className="mb-2">
              <i className="simple-icon-docs" />
              <IntlMessages id="Missings Pages" />
            </Button>
          </NavLink>
        )}
        {isEdited && (
          <Fragment>
            {saveState.error && (
              <div className="d-block text-danger">
                <IntlMessages id={saveState.error} />
              </div>
            )}
            <StateButton
              id="successButton"
              color="primary"
              type="submit"
              isLoading={saveState.loading}
            >
              <IntlMessages id="Save" />
            </StateButton>
          </Fragment>
        )}
      </form>
    </Fragment>
  );
};

const selector = formValueSelector("documentMetadata");

const mapStateToProps = state => {
  const { appData } = state;
  const summary = selector(state, "summary");
  return {
    initialValues: appData.docs.metadata.documentMetadata,
    summary
  };
};

export const Document = connect(mapStateToProps)(
  renderDelay(
    reduxForm({
      form: "documentMetadata",
      enableReinitialize: true
    })(documentCmp)
  )
);
