export const subHiddenBreakpoint = 1800;
export const menuHiddenBreakpoint = 768;
export const listPageSizes = [10, 20, 30, 50, 100];
export const defaultMenuType = "menu-default"; //'menu-sub-hidden', 'menu-hidden'
export const defaultStartPath = "/app/home";

export const defaultLocale = "en";
export const localeOptions = [
  { id: "en", name: "English" },
  { id: "ar", name: "Arabic" },
  { id: "fr", name: "French" },
  { id: "so", name: "Sorani" },
  { id: "ku", name: "Kurmanji" }
];
export const apiUrl = "http://localhost:9000/api/";
export const regIps = /((25[0-5]|2[0-4]\d|[01]?\d\d?)\.(25[0-5]|2[0-4]\d|[01]?\d\d?)\.(25[0-5]|2[0-4]\d|[01]?\d\d?)\.(25[0-5]|2[0-4]\d|[01]?\d\d?)(,\n|,?$))/;

export const DATE_FORMAT = "YYYY-MM-DD";
export const DATE_FORMAT_HIJRI = "DD/MM/YYYY";
export const AUDIT_DATETIME_REGEXP = /^(\d{4}-\d{2}-\d{2} \d{2}:\d{2}:\d{2}) \(GMT(.+)\)$/;
export const QUICK_SEARCH_INPUT_REGEX = /^[A-Z]{3}\.[A-Z]{1}\d{4}\.\d{3}\.(.+)/;
export const PHRASE_SEARCH_REGEX = /^"(.+)"$/;
