import React, { Component } from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { CSVLink } from "react-csv";
import { pick } from "lodash";

import { getColumns, getColumnsCSV } from "./dataTableOptions";
import { searchResultsDownloadApi } from "redux/pages/apis";
import { saveAs } from "file-saver";
import StateButton from "components/StateButton";
import IntlMessage from "util/IntlMessages";
import { arrayToCSVData } from "util/csvDownload";
import { formatDateSearchInput } from "util/formatDateSearchInput";

class Download extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      isDownloadingAll: false,
      _isMounted: false
    };
  }

  componentDidMount() {
    this.setState({ _isMounted: true });
  }

  componentWillUnmount() {
    this.setState({ _isMounted: false });
  }

  setIsDownloadingAll = boolVal => {
    if (this.state._isMounted) this.setState({ isDownloadingAll: boolVal });
  };

  toggle = () => {
    this.setState({ modal: !this.state.modal });
  };

  getCsvData = (data) => {
    const cols = getColumnsCSV().map(col => col.name);
    const result = [...data];
    result.forEach(row => {
      row.pageNumber = row.name
    });
    const csvData = arrayToCSVData(result, cols);
    return csvData;
  };

  downloadAllResults = () => {
    this.setIsDownloadingAll(true);
    const { filter } = this.props;
    if (filter.startDate) {
      filter.startDate = formatDateSearchInput(filter.startDate);
    }
    if (filter.endDate) {
      filter.endDate = formatDateSearchInput(filter.endDate);
    }
    
    if(filter.box){
      filter.box = filter.box.id
    }
    filter.project = filter.project && filter.project.id ? filter.project.prefix : null;
    filter.sort = filter.sort && filter.sort.id ? filter.sort.id : null;
    filter.boxType = filter.boxType && !filter.boxType.includes(null) ? filter.boxType.map(e => e.value) : null;
    filter.contentType = filter.contentType && !filter.contentType.includes(null) ? filter.contentType.map(e => e.id) : null;
    filter.relevant = filter.relevant ? filter.relevant : null;
    filter.hasEnTranslation = filter.hasEnTranslation ? filter.hasEnTranslation : null;
    filter.language = filter.language  && filter.language.length > 0? JSON.stringify(filter.language) : null;

    searchResultsDownloadApi(filter,filter.searchType).then(response => {
      if (response && response.status === 200) {
        var BOM = "\uFEFF";
        var csvData = BOM + response.data;
        const blob = new Blob([csvData], {
          type: "text/csv;charset=utf-8"
        });

        saveAs(blob, "ems-audit-search-results.csv");
      }
      this.setIsDownloadingAll(false);
    });
  };

  render() {
    return (
      <div className="text-right">
        <Button color="primary" className="mb-2" onClick={this.toggle}>
          <IntlMessage id="Download" />
        </Button>
        <Modal
          isOpen={this.state.modal}
          toggle={this.toggle}
          className={this.props.className}
        >
          <ModalHeader toggle={this.toggle}>
            <IntlMessage id="audit.download-audit-search-results" />
          </ModalHeader>
          <ModalBody>
            <IntlMessage id="audit.download-audit-search-results-body" />
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={this.toggle}>
              <CSVLink
                data={this.getCsvData(this.props.data)}
                filename={"ems-audit-results.csv"}
              >
                <span className="text-white">
                  <IntlMessage id="audit.this-page-only" />
                </span>
              </CSVLink>
            </Button>
            <StateButton
              color="primary"
              onClick={this.downloadAllResults}
              isLoading={this.state.isDownloadingAll}
            >
              <IntlMessage id="audit.all-results" />
            </StateButton>
            <Button color="secondary" onClick={this.toggle}>
              <IntlMessage id="Cancel" />
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}
export default Download;
