import { all, fork, put, takeEvery, call, select } from "redux-saga/effects";

import {
  GET_DELETE_REQUESTS,
  CHANGE_DELETE_REQUESTS_FILTER,
  CONFIRM_DELETE_REQUEST,
  CANCEL_DELETE_REQUEST
} from "redux/actionTypes";

import { setDeleteRequestsData, errorDeleteRequestsData } from "./actions";

import {
  getDeleteRequestsApi,
  confirmDeleteRequestesApi,
  cancelDeleteRequestesApi
} from "./apis";

function* getDeleteRequestsSaga() {
  try {
    const filter = yield select(state => state.appData.deleteRequests.filter);
    const response = yield call(
      getDeleteRequestsApi,
      filter.page,
      filter.pageSize
    );
    yield put(setDeleteRequestsData(response.data));
  } catch (error) {
    yield put(errorDeleteRequestsData(error));
  }
}
function* confirmDeleteRequestsSaga({ payload }) {
  try {
    const { ids, success, error } = payload;
    const response = yield call(confirmDeleteRequestesApi, ids);
    if (response.status === 200) {
      if (success) {
        success();
      }
    } else {
      if (error) error();
    }
  } catch (error) {
    if (error) error();
  }
}
function* cancelDeleteRequestsSaga({ payload }) {
  try {
    const { ids, success, error } = payload;
    const response = yield call(cancelDeleteRequestesApi, ids);
    if (response.status === 200) {
      if (success) {
        success();
      }
    } else {
      if (error) error();
    }
  } catch (error) {
    if (error) error();
  }
}

export function* watchDeleteRequestsSaga() {
  yield takeEvery(GET_DELETE_REQUESTS, getDeleteRequestsSaga);
  yield takeEvery(CHANGE_DELETE_REQUESTS_FILTER, getDeleteRequestsSaga);
  yield takeEvery(CONFIRM_DELETE_REQUEST, confirmDeleteRequestsSaga);
  yield takeEvery(CANCEL_DELETE_REQUEST, cancelDeleteRequestsSaga);
}

export default function* rootSaga() {
  yield all([fork(watchDeleteRequestsSaga)]);
}
