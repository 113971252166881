export const PAGES_ENDPOINT = "pages/";
export const OCR_ENDPOINT = "ocr/";
export const PAGES_SEARCH_ENDPOINT = "pagesearch/";
export const PAGES_DOWNLOAD_ENDPOINT = "/downloadPage";
export const PAGES_METADATA_ENDPOINT = "/metadata";
export const AUDIT_SEARCH_ENDPOINT = "/audit/search";
export const AUDIT_TOTAL_METADATA = "/audit/totalMetadata";
export const NEXT_PAGE_ENDPOINT = "/pages/next/";
export const PREVIOUS_PAGE_ENDPOINT = "/pages/previous/";
export const FIRST_LAST_PAGE_ENDPOINT = "/pages/firstLast/";
export const SEND_REQUEST_DELETE_PAGE_ENDPOINT = "/deleterequests/page/";
