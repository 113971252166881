import React from "react";
import IntlMessages from "../../../util/IntlMessages";


export const YesNoField = props => {
    const { input, meta,
        disabled, ...custom } = props;
    return (
        <select disabled={disabled} {...input} {...custom}>
            <option value={null}></option>
            <option value={true}>yes</option>
            <option value={false}>no</option>
        </select>);
};

