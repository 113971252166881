import React from "react";
import DatePicker, { registerLocale } from "react-datepicker";
import { FormFields } from "components/FormFields";
import cs from "classnames";
import { injectIntl } from "react-intl";
import { fr, arMA, enUS, sr } from "date-fns/locale";
import { currentLanguage } from "../../../util/constants";
registerLocale("fr-FR", fr);


const DatePickerCmp = props => {
  const {
    input,
    className,
    inputClassName,
    meta: { touched, error },
    intl: { messages, locale },
    options,
    ...rest
  } = props;
  const days = ['دو', 'سێش', 'وێدن', 'پێنج', 'هەینی', 'شەممە', 'یەک']
  const months = ['کانوونی دووەم', 'شوبات', 'ئادار', 'نیسان', 'ئایار', 'حوزەیران', 'تەمموز', 'ئاب', 'ئەیلول', 'تشرینی یەکەم', 'تشرینی دووەم', 'کانوونی یەکەم']

  const localeKurdish = {
    localize: {
      day: n => days[n],
      month: n => months[n]
    },
    formatLong: {
      date: () => 'mm/dd/yyyy'
    }
  }
  let localee = currentLanguage === null || currentLanguage === 'en' ? enUS : currentLanguage === 'fr' ? fr : currentLanguage === 'ar' ? arMA : localeKurdish;


  function convertDateFormat(inputValue) {
    if (inputValue  && typeof inputValue === 'string') {
      const parts = inputValue.split('/');
      if (parts.length === 3) {
        const day = parts[0];
        const month = parts[1];
        const year = parts[2];
        // Crée une date au format "yyyy-mm-dd"
        const formattedDate = `${year}-${month}-${day}`;
        return formattedDate;
      }
    }
    return inputValue;
  }
  return (
    <FormFields {...props}>
      <DatePicker
        locale={localee} //to avoid warning (default locale is en-US)
        className={cs(inputClassName, "form-control", {
          "is-invalid": touched && error
        })}
        selected={input.value ? new Date(convertDateFormat(input.value)) : ""}
        onChange={input.onChange}
        placeholderText={options.dateFormat}
        autoComplete="off"
        {...rest}
        {...options}
      />
    </FormFields>
  );
};

export default injectIntl(DatePickerCmp);
