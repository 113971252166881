import instance from "util/instances";
import {
  AUDIT_SEARCH_TOTAL_ACTIONS_COUNT_ENDPOINT,
  AUDIT_RT_URI_NAMES_ENDPOINT,
  AUDIT_SEARCH_RESULTS_DOWNLOAD_ENDPOINT
} from "./endpoints";

export const auditSearchTotalActionsApi = data =>
  instance.post(AUDIT_SEARCH_TOTAL_ACTIONS_COUNT_ENDPOINT, data);

export const getRtUriNamesApi = () => instance.get(AUDIT_RT_URI_NAMES_ENDPOINT);

export const auditSearchResultsDownloadApi = data =>
  instance.post(AUDIT_SEARCH_RESULTS_DOWNLOAD_ENDPOINT, data);
