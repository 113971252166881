import React, { Component, Fragment } from "react";
import IntlMessages from "util/IntlMessages";
import { Row, UncontrolledAlert } from "reactstrap";
import { Colxx, Separator } from "components/CustomBootstrap";
import DownloadDocImagesForm from "./form";
import { DOWNLOAD_DOCS_IMAGES_ENDPOINT } from "redux/document/endpoints";
import instance from "util/instances";
import { saveAs } from "file-saver";

class DowloadDocImages extends Component {
  constructor(props) {
    super(props);

    this.state = {
      _isMounted: false,
      isLoadingDownloadZip: false,
      error: undefined
    };
  }

  componentDidMount = () => {
    this.setState({ _isMounted: true });
  };

  componentWillUnmount = () => {
    this.setState({ _isMounted: false });
  };

  setIsLoadingDownloadZip = booleanVal => {
    if (this.state._isMounted)
      this.setState({ isLoadingDownloadZip: booleanVal });
  };

  setError = error => {
    if (this.state._isMounted) this.setState({ error });
  };

  handleSubmit = ({ selection }) => {
    if (!selection) return;

    this.setIsLoadingDownloadZip(true);
    this.setError(undefined);
    selection = selection.replace(/\s/g, "");
    instance
      .get(DOWNLOAD_DOCS_IMAGES_ENDPOINT, {
        responseType: "blob",
        params: { selection }
      })
      .then(res => {
        if (res && res.status === 200) {
          const blob = new Blob([res.data], {
            type: "application/zip"
          });

          saveAs(blob, "document_images.zip");
        } else if (res && res.status === 400) {
          res.data.text().then(response => {
            const error = JSON.parse(response).message;
            this.setError(error);
          });
        } else {
          alert("An error occured, try again later.");
        }
        this.setIsLoadingDownloadZip(false);
      });
  };

  render() {
    const { isLoadingDownloadZip, error } = this.state;

    return (
      <Row>
        <Colxx xxs="12">
          <h1>
            <IntlMessages id="doc.download-document-images" />
          </h1>
          <Separator className="mb-5" />
        </Colxx>

        <Fragment>
          {error && (
            <Colxx xxs="12">
              <UncontrolledAlert color="danger">
                <IntlMessages id={error} />
              </UncontrolledAlert>
            </Colxx>
          )}
          <Colxx xxs="12">
            <DownloadDocImagesForm
              onSubmit={this.handleSubmit}
              isLoading={isLoadingDownloadZip}
            />
          </Colxx>
        </Fragment>
      </Row>
    );
  }
}

export default DowloadDocImages;
