import React, { Component, Fragment } from "react";
import { injectIntl } from "react-intl";
import { Card, CustomInput } from "reactstrap";
import classnames from "classnames";
import IntlMessages from "util/IntlMessages";
import { connect } from "react-redux";
import { get, range } from "lodash";
import { ContextMenu, MenuItem, ContextMenuTrigger } from "react-contextmenu";
import { Colxx } from "components/CustomBootstrap";
import mouseTrap from "react-mousetrap";
import { DataListContainer } from "components/DataListContainer";
import {
  getDeleteRequestsData,
  changeDeleteRequestsFilter,
  confirmDeleteRequest,
  cancelDeleteRequest
} from "redux/actions";
import { NotificationManager } from "components/ReactNotifications";
import { ConfirmationModal } from "components/ConfirmationModal";

class Users extends Component {
  constructor(props) {
    super(props);
    this.state = {
      _isMounted: false,
      selectedItems: [],
      errorConfirming: false,
      isLoadingConfirm: false,
      isLoadingCancel: false,
      isConfirmDelete: false,
      isConfirmCancel: false
    };
  }

  componentDidMount() {
    this.setState({ _isMounted: true });
    this.props.getDeleteRequestsData();
    this.props.bindShortcut(["ctrl+a", "command+a"], () =>
      this.handleChangeSelectAll(false)
    );
    this.props.bindShortcut(["ctrl+d", "command+d"], () => {
      this.setState({
        selectedItems: []
      });
      return false;
    });
  }

  componentWillUnmount = () => {
    this.setState({ _isMounted: false });
  };

  changePageSize = size => {
    const { deleteRequestsFilter } = this.props;
    deleteRequestsFilter.pageSize = size;
    deleteRequestsFilter.page = 1;
    this.props.changeDeleteRequestsFilter(deleteRequestsFilter);
  };

  onChangePage = page => {
    const { deleteRequestsFilter } = this.props;
    deleteRequestsFilter.page = page;
    this.props.changeDeleteRequestsFilter(deleteRequestsFilter);
  };

  handleCheckChange = (event, id) => {
    if (
      event.target.tagName === "A" ||
      (event.target.parentElement && event.target.parentElement.tagName === "A")
    ) {
      return true;
    }

    let { selectedItems } = this.state;
    if (selectedItems.includes(id)) {
      selectedItems = selectedItems.filter(x => x !== id);
    } else {
      selectedItems.push(id);
    }
    this.setState({
      selectedItems
    });
  };

  handleChangeSelectAll(isToggle) {
    let { selectedItems } = this.state;
    if (selectedItems.length >= this.props.deleteRequestsData.result.length) {
      if (isToggle) {
        selectedItems = [];
      }
    } else {
      selectedItems = range(this.props.deleteRequestsData.result.length);
    }
    this.setState({ selectedItems });
    return false;
  }

  handleConfirmRequest = isConfirmed => {
    if (this.state.selectedItems.length > 0) {
      if (isConfirmed) {
        const requestIds = this.state.selectedItems.map(
          index => this.props.deleteRequestsData.result[index].id
        );
        this.props.confirmDeleteRequest(requestIds, () => {
          this.createNotif("delete-requests.deleted-success-message");
          if (this.state._isMounted) {
            this.setState({ isConfirmDelete: false, selectedItems: [] });
          }
          this.props.getDeleteRequestsData();
        });
        return true;
      }
      this.setState({ isConfirmDelete: true });
    }
  };

  handleCancelRequest = isConfirmed => {
    if (this.state.selectedItems.length > 0) {
      if (isConfirmed) {
        const requestIds = this.state.selectedItems.map(
          index => this.props.deleteRequestsData.result[index].id
        );
        this.props.cancelDeleteRequest(requestIds, () => {
          this.createNotif("delete-requests.canceled-success-message");
          if (this.state._isMounted) {
            this.setState({ isConfirmCancel: false, selectedItems: [] });
          }
          this.props.getDeleteRequestsData();
        });
        return true;
      }
      this.setState({ isConfirmCancel: true });
    }
  };

  createNotif = message => {
    NotificationManager.success(
      this.props.intl.messages[message],
      "",
      3000,
      null,
      null,
      "filled"
    );
  };

  onContextMenu = ({ detail }) => {
    const selectedItems = [detail.data.data];
    this.setState({ selectedItems });
  };

  conditionRender = () => {
    if (this.props.deleteRequestsLoading) {
      return <div className="loading" />;
    }
    if (this.props.deleteRequestsError) {
      return (
        <div className="col-12 text-center h6">
          <IntlMessages id={"user.error-gettings-users"} />
        </div>
      );
    }
    return false;
  };

  render() {
    const { deleteRequestsData, deleteRequestsFilter } = this.props;

    return this.conditionRender() ? (
      this.conditionRender()
    ) : (
      <Fragment>
        <DataListContainer
          title="delete-requests"
          hideButtons={false}
          dataLength={deleteRequestsData.total || 0}
          pageSize={deleteRequestsFilter.pageSize}
          changePageSize={this.changePageSize}
          onChangeOrderBy={this.changeOrderBy}
          orderBy={deleteRequestsFilter.orderBy}
          onChangePage={this.onChangePage}
          currentPage={deleteRequestsFilter.page}
          isAllSelected={
            deleteRequestsData.result &&
            deleteRequestsData.result.length === this.state.selectedItems.length
          }
          onAllSelectedClick={() => this.handleChangeSelectAll(true)}
          onConfirmRequest={() => this.handleConfirmRequest()}
          confirmRequestMessage="Confirm"
          onCancelRequest={() => this.handleCancelRequest()}
          cancelRequestMessage="Cancel"
        >
          {deleteRequestsData.result.map((deleteRequest, index) => {
            return (
              <Colxx xxs="12" key={index} className="mb-3">
                <ContextMenuTrigger
                  id="menu_id"
                  data={index}
                  collect={p => ({ data: p.data })}
                >
                  <Card
                    onClick={e => this.handleCheckChange(e, index)}
                    className={classnames("d-flex flex-row", {
                      active: this.state.selectedItems.includes(index)
                    })}
                  >
                    <div className="pl-2 d-flex flex-grow-1 min-width-zero">
                      <div className="card-body align-self-center d-flex flex-column flex-lg-row justify-content-between min-width-zero align-items-lg-center">
                        <p className="list-item-heading mb-1 truncate">
                          {deleteRequest.resourceName}
                        </p>
                        <p className="mb-1 text-muted text-small w-15 w-sm-100">
                          {get(deleteRequest, "requestedByUser.name")}
                        </p>

                        <p className="mb-1 text-muted text-small w-15 w-sm-100">
                          {deleteRequest.requestedAt}
                        </p>
                        <p className="mb-1 text-muted text-small w-15 w-sm-100">
                          {deleteRequest.resourceType}
                        </p>
                      </div>
                      <Fragment>
                        <div className="align-self-center pr-3">
                          <a
                            href=" "
                            onClick={e => {
                              e.preventDefault();
                              this.setState({ selectedItems: [index] }, () =>
                                this.handleCancelRequest()
                              );
                            }}
                          >
                            <i className="text-warning simple-icon-close" />
                          </a>
                        </div>
                        <div className="align-self-center pr-4">
                          <a
                            href=" "
                            onClick={e => {
                              e.preventDefault();
                              this.setState({ selectedItems: [index] }, () =>
                                this.handleConfirmRequest()
                              );
                            }}
                          >
                            <i className="text-danger simple-icon-check" />
                          </a>
                        </div>
                        <div className="align-self-center pr-4">
                          <CustomInput
                            className="itemCheck mb-0"
                            type="checkbox"
                            id={`check_${index}`}
                            checked={this.state.selectedItems.includes(index)}
                            label=""
                            onChange={() => {}}
                          />
                        </div>
                      </Fragment>
                    </div>
                  </Card>
                </ContextMenuTrigger>
              </Colxx>
            );
          })}
        </DataListContainer>
        <ConfirmationModal
          isOpen={this.state.isConfirmDelete || this.state.isConfirmCancel}
          toggle={() =>
            this.setState({ isConfirmDelete: false, isConfirmCancel: false })
          }
          title={
            this.state.isConfirmDelete
              ? "delete-requests.confirm-delete-title"
              : "delete-requests.confirm-cancel-title"
          }
          content={
            this.state.isConfirmDelete
              ? "delete-requests.confirm-delete-content"
              : "delete-requests.confirm-cancel-content"
          }
          onConfirmClick={
            this.state.isConfirmDelete
              ? () => this.handleConfirmRequest(true)
              : () => this.handleCancelRequest(true)
          }
        />
        <ContextMenu id="menu_id" onShow={this.onContextMenu}>
          <Fragment>
            <MenuItem onClick={() => this.handleConfirmRequest()}>
              <i className="simple-icon-trash" />
              <span>
                <IntlMessages id="Confirm" />
              </span>
            </MenuItem>
            <MenuItem onClick={() => this.handleCancelRequest()}>
              <i className="simple-icon-close" />
              <span>
                <IntlMessages id="Cancel" />
              </span>
            </MenuItem>
          </Fragment>
        </ContextMenu>
      </Fragment>
    );
  }
}

const mapStateToProps = ({ appData }) => ({
  deleteRequestsData: appData.deleteRequests.data,
  deleteRequestsLoading: appData.deleteRequests.loading,
  deleteRequestsError: appData.deleteRequests.error,
  deleteRequestsFilter: appData.deleteRequests.filter
});

export default connect(
  mapStateToProps,
  {
    getDeleteRequestsData,
    changeDeleteRequestsFilter,
    confirmDeleteRequest,
    cancelDeleteRequest
  }
)(injectIntl(mouseTrap(Users)));
