import { all, fork, put, takeEvery, call, select } from "redux-saga/effects";
import * as types from "redux/actionTypes";
import {
  getIpAttemptsApi,
  postIpAttemptsApi,
  putIpAttemptsApi,
  deleteIpAttempts,
  getByUserId
} from "./apis";

function* getIpAttemptsSaga() {
  try {
    const filter = yield select(state => state.appData.ipAttempts.filter);

    const response = yield call(getIpAttemptsApi, filter.page, filter.pageSize);
    yield put({ type: types.SET_IPATTEMPTS, payload: response.data });
  } catch (error) {
    yield put({ type: types.ERROR_GETTING_IPATTEMPTS, payload: error });
  }
}

function* getIpAttemptsUserSaga({ payload }) {
  try {
    const { userId } = payload;

    const response = yield call(getByUserId, userId);
    yield put({ type: types.SET_IPATTEMPTS_USER, payload: response.data });
  } catch (error) {
    yield put({ type: types.ERROR_GETTING_IPATTEMPTS_USER, payload: error });
  }
}

function* postIpAttemptSaga({ payload }) {
  const { ipAttempt, success, error } = payload;
  try {
    const value = yield call(postIpAttemptsApi, ipAttempt) || {};
    if ([200, 201].includes(value.status)) {
      if (success) success();
      yield call(getIpAttemptsSaga);
    } else {
      if (error) error();
    }
  } catch (err) {
    if (error) error(err);
  }
}

function* editIpAttemptSaga({ payload }) {
  const { ipAttempt, success, error } = payload;
  try {
    const value = yield call(putIpAttemptsApi, ipAttempt) || {};
    if ([200, 201].includes(value.status)) {
      if (success) success();
      yield call(getIpAttemptsSaga);
    } else {
      if (error) error();
    }
  } catch (err) {
    if (error) error(err);
  }
}

function* deleteIpAttemptSaga({ payload }) {
  const { id, success, error } = payload;
  try {
    const value = yield call(deleteIpAttempts, id) || {};
    if ([200, 201].includes(value.status)) {
      if (success) success();
      yield call(getIpAttemptsSaga);
    } else {
      if (error) error();
    }
  } catch (err) {
    if (error) error(err);
  }
}

export function* watchIpAttemptssSaga() {
  yield takeEvery(types.GET_IPATTEMPTS, getIpAttemptsSaga);
  yield takeEvery(types.GET_IPATTEMPTS_USER, getIpAttemptsUserSaga);
  yield takeEvery(types.DELETE_IPATTEMPT, deleteIpAttemptSaga);
  yield takeEvery(types.EDIT_IPATTEMPT, editIpAttemptSaga);
  yield takeEvery(types.POST_IPATTEMPT, postIpAttemptSaga);
}

export default function* rootSaga() {
  yield all([fork(watchIpAttemptssSaga)]);
}
