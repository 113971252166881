import React from "react";
import HijriDatePicker from 'hijri-date-picker';
import { FormFields } from "components/FormFields";
import cs from "classnames";
import { injectIntl } from "react-intl";
import * as moment from "moment";
import { DATE_FORMAT_HIJRI } from "constants/defaultValues";


const formatDateHijri = date => {
    return moment(new Date(date)).format(DATE_FORMAT_HIJRI);
};
const HijriDatePickerCmp = props => {
    const {
        input,
        className,
        inputClassName,
        meta: { touched, error },
        intl: { messages, locale },
        options,
        disabled,
        ...rest
    } = props;
    const start = input.value ? input.value : "";
    return (
        <FormFields {...props}>
            <HijriDatePicker
                disabled={disabled}
                className={cs(inputClassName, "form-control", {
                    "is-invalid": touched && error
                })}
                onChange={input.onChange}
                placeholderText={options.dateFormat}
                selectedDate={start}
                dateFormat="iDD/iMM/iYYYY"
                quickSelect />
        </FormFields>
    );
};

export default injectIntl(HijriDatePickerCmp);
