import {localeOptions} from "../constants/defaultValues";
const instanceType = process.env.REACT_APP_INSTANCE_TYPE
const VoxvexSnitcatLang = localeOptions.filter(({id})=> ['en', 'ar', 'fr'].includes(id))
const EmpLang = localeOptions.filter(({id})=> ['en', 'ar', 'fr', 'so', 'ku'].includes(id))
const defaultOptions = {
    hijriDateField : true,
    formatField : true,
    subjectField : true,
    communicationNumberField: true,
    referenceNumberField: true,
    duplicateDocNumberField: true,
    referenceInBriefField: true,
    videosBoxes: false,
    localeOptions: localeOptions,
}

console.log(instanceType)
export const optionsByInstance = (currentLang = null) =>{
    let options = defaultOptions
    const isArLang = currentLang === 'ar'
    const isFrLang = currentLang === 'fr'
    const isEnLang = currentLang === 'en'
    const isSoLang = currentLang === 'so'
    const isKuLang = currentLang === 'ku'
    const isLangIncluded = !(isArLang || isFrLang || isEnLang)
    const isAllLangExcluded = !(isArLang || isFrLang || isEnLang || isSoLang || isKuLang)

    switch (instanceType) {
        case 'VOXVEX' : options = {...defaultOptions, hijriDateField: isLangIncluded, formatField: isLangIncluded, subjectField: isLangIncluded, localeOptions: VoxvexSnitcatLang, videosBoxes:true}
            break;
        case 'SNITCAT' : options = {...defaultOptions, communicationNumberField: isLangIncluded, formatField: isLangIncluded, referenceNumberField: isLangIncluded, localeOptions: VoxvexSnitcatLang, subjectField: false}
            break;
        case 'EMP' : options = {...defaultOptions,
            communicationNumberField: isAllLangExcluded,
            formatField: isAllLangExcluded,
            referenceNumberField: isAllLangExcluded,
            subjectField: isAllLangExcluded,
            duplicateDocNumberField: isAllLangExcluded,
            referenceInBriefField: isAllLangExcluded,
            localeOptions: EmpLang
        }
            break;
    }
    return options
}