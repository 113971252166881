export const DOCS_ENDPOINT = "documents/";
export const DOCS_PAGES_ENDPOINT = "/pages";
export const DOCS_METADATA_ENDPOINT = "/documentmetadata";
export const VIDEO_METADATA_ENDPOINT = "/videometadata";

export const SEARCH_ENDPOINT = "search";

export const DOWNLOAD_DOCS_IMAGES_ENDPOINT = `${DOCS_ENDPOINT}image/dowloadDoc`;
export const SEND_REQUEST_DELETE_DOCUMENT_ENDPOINT =
  "/deleterequests/document/";
