import appLocaleData from "react-intl/locale-data/ckb";
import ckbMessages from "../locales/ckb_IQ";

const ckbLang = {
  messages: {
    ...ckbMessages
  },
  locale: "ckb-IQ",
  data: appLocaleData
};
export default ckbLang;
