import React from "react";
import { NavLink } from "react-router-dom";
import Highlighter from "react-highlight-words";
import moment from "moment";
import { AUDIT_DATETIME_REGEXP } from "constants/defaultValues";


export const getColumns = (search = []) => [
  {
    name: "page",
    label: "Page",
    options: {

      customBodyRender: value => value.url ? (
        <NavLink to={"/app/documents/list/box/" + value.url}>
          {highlightText(value.name.substring(0, value.name.length - 4), search)}
        </NavLink>
      ) : highlightText(value, search)
    }
  },
  {
    name: "date",
    label: "Date",
    options: {
      customBodyRender: value => highlightText(value, search)
    }
  },
  {
    name: "createdAt",
    label: "Upload Date",
    options: {
      customBodyRender: value => highlightText(value, search)
    }
  },
  {
    name: "from",
    label: "From",
    options: {
      customBodyRender: value => highlightText(value, search)
    }
  },
  {
    name: "to",
    label: "To",
    options: {
      customBodyRender: value => highlightText(value, search)
    }
  },
  {
    name: "cc",
    label: "CC",
    options: {
      customBodyRender: value => highlightText(value, search)
    }
  },
  {
    name: "namesOfEntities",
    label: "Names Of Entities",
    options: {
      customBodyRender: value => highlightText(value, search)
    }
  },
  {
    name: "namesOfPeople",
    label: "Names Of People",
    options: {
      customBodyRender: value => highlightText(value, search)
    }
  },
  {
    name: "namesOfPlaces",
    label: "Names Of Places",
    options: {
      customBodyRender: value => highlightText(value, search)
    }
  },
  {
    name: "signatureBlock",
    label: "Tags",
    options: {
      customBodyRender: value => highlightText(value, search)
    }
  },
  {
    name: "boxMetadata",
    label: "Box Metadata",
    options: {
      customBodyRender: value => renderObject(value, search)
    }
  },
  {
    name: "docMetadata",
    label: "Document Metadata",
    options: {
      customBodyRender: value => renderObject(value, search)
    }
  },
  {
    name: "ocr",
    label: "OCR",
    options: {
      customBodyRender: value => highlightText(value, search)
    }
  },
  {
    name: "ocrEn",
    label: "OCR English",
    options: {
      customBodyRender: value => highlightText(value, search)
    }
  }
];
export const getColumnsCSVBox = (search = []) => [
  {
    name: "project",
    label: "project",

  },
  {
    name: "number",
    label: "number",

  },

  {
    name: "boxMetadata",
    label: "boxMetadata",

  },

];
export const getColumnsCSVDocument = (search = []) => [
  {
    name: "project",
    label: "project",

  },
  {
    name: "box",
    label: "box",

  },
  {
    name: "number",
    label: "number",

  },
  {
    name: "documentType",
    label: "documentType",

  },
  {
    name: "documentMetadata",
    label: "documentMetadata",

  },
  {
    name: "videoMetadata",
    label: "videoMetadata",

  },


];
export const getColumnsCSVPage = (search = []) => [
  {
    name: "number",
    label: "number",

  },
  {
    name: "name",
    label: "name",

  },
  {
    name: "suffix",
    label: "suffix",

  },

  {
    name: "documents",
    label: "documents",

  },
  {
    name: "pageMetadata",
    label: "pageMetadata",

  },
  {
    name: "hasEnTranslation",
    label: "hasEnTranslation",

  },
  {
    name: "processingOcrResults",
    label: "processingOcrResults",

  },
];

export const options = {
  filterType: "checkbox",
  pagination: false,
  responsive: "scroll",
  filter: false,
  search: false,
  sort: false,
  selectableRows: "none"
};

const renderObject = (value, search) =>
  value.map((item, index) => (
    <div key={index}>
      <b>{item.label} : </b>
      {highlightText(item.value, search)}
    </div>
  ));

export class ParenthighlightText extends React.Component {
  state = {
    extrait: true
  };

  componentDidUpdate() {
    let { text, search } = this.props;
    let { extrait } = this.state;
    this._highlightText(text, search, extrait);
  }

  fulltext = () => {
    this.setState({
      extrait: false
    });
  };
  _highlightText = (text, search, extrait) => {
    let cont;
    if (text && text.length > 100) {
      let readMore = text.substr(1, 99);

      if (extrait) {
        cont = (
          <React.Fragment>
            <Highlighter searchWords={search} textToHighlight={readMore} autoEscape={true}/>
            <div className="readMore" onClick={() => this.fulltext()}>
              read more...
            </div>
          </React.Fragment>
        );
      }
      if (!extrait) {
        cont = <Highlighter searchWords={search} textToHighlight={text} autoEscape={true}/>;
      }
      return cont;
    }
    if (text && text.length > 0)
      return <Highlighter searchWords={search} textToHighlight={text} autoEscape={true}/>;
    return null;
  };
  render() {
    let { text, search } = this.props;
    let { extrait } = this.state;
    return this._highlightText(text, search, extrait);
  }
}

const highlightText = (text, search) => (
  <ParenthighlightText text={text} search={search} />
);

export const convertToLocalDateTime = value => {
  const valArray = value.match(AUDIT_DATETIME_REGEXP);

  if (!valArray) return null;

  return moment(valArray[1] + valArray[2]).format(
    "YYYY-MM-DD HH:mm:ss [(GMT]Z[)]"
  );
};