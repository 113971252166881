import React, { Component } from "react";
import { Field, reduxForm } from "redux-form";
import IntlMessages from "util/IntlMessages";
import { Row, Button, Label } from "reactstrap";
import { ValidatedAutocomplete } from "components/FormFields/Autocomplete";
import { ValidatedSelected } from "components/FormFields/Select";
import { Colxx, Separator } from "components/CustomBootstrap";
import { getAutoCompleteUsers } from "redux/users/apis";
import { getAutoCompleteBox } from "redux/boxs/apis";
import { getAutoCompleteDocs } from "redux/document/apis";
import { getAutoCompletePageName } from "redux/pages/apis";
import { getAutoCompleteTeams } from "redux/teams/apis";
import { logger } from "util/Logger";
import DatePicker from "components/FormFields/DatePicker";
import cs from "classnames";
import { FormattedMessage } from "react-intl";

const onLoadDocOptions = async (query, callback) => {
  try {
    await getAutoCompleteDocs(query).then(
      response =>
        response && response.status === 200 && callback(response.data || [])
    );
  } catch (error) {
    // Do nothing
  }
};

const onLoadPageOptions = async (query, callback) => {
  try {
    await getAutoCompletePageName(query).then(
      response =>
        response && response.status === 200 && callback(response.data || [])
    );
  } catch (error) {
    // Do nothing
  }
};

const onLoadUserOptions = async (query, callback) => {
  try {
    await getAutoCompleteUsers(query).then(
      response =>
        response &&
        response.status === 200 &&
        callback(response.data.result || [])
    );
  } catch (error) {
    logger("error getting Users", error);
  }
};

const onLoadTeamsOptions = async (query, callback) => {
  try {
    await getAutoCompleteTeams(query).then(
      response =>
        response &&
        response.status === 200 &&
        callback(response.data.result || [])
    );
  } catch (error) {
    logger("error getting Teams", error);
  }
};

const onLoadBoxOptions = async (query, callback) => {
  try {
    await getAutoCompleteBox(query).then(
      response =>
        response &&
        response.status === 200 &&
        callback(response.data.result || [])
    );
  } catch (error) {
    logger("error getting Boxs", error);
  }
};

class FilterCmp extends Component {
  render() {
    const { handleSubmit, onClose, className, rTUriNames } = this.props;
    return (
      <Colxx xxs="12" className={cs("mt-4 ems-audit-filter", className)}>
        <form onSubmit={handleSubmit}>
          <Row>
            <Colxx xs={12} sm={6} md={{ size: 5, offset: 1, order: 1 }}>
              <Field
                className="mb-3"
                name="rtUriNameId"
                component={ValidatedSelected}
                label="Action"
                labelClassName="mb-0"
                getOptionLabel={i => i.translate}
                getOptionValue={i => i.id}
                options={rTUriNames}
                isClearable
                placeholder=""
              />
              <FormattedMessage id="audit.type-search.users">
                {
                  (msg) => <Field
                    className="mb-3"
                    name="userName"
                    label="User"
                    component={ValidatedAutocomplete}
                    noOptionsMessage={() => msg}
                    labelClassName="mb-0"
                    isSearchable
                    loadOptions={onLoadUserOptions}
                    getOptionLabel={i => i.username}
                    getOptionValue={i => i.id}
                    cacheOptions
                    isClearable
                    placeholder=""
                  />
                }
              </FormattedMessage>

              <Label htmlFor={"dateFrom"} style={{ "margin-bottom": 0 }}>
                <IntlMessages id={"Start Date"} />
              </Label>

              <Field
                className="mb-3"
                name="dateFrom"
                // label="Start Date"
                component={DatePicker}
                type="datetime-local"
                options={{
                  dateFormat: "dd/MM/yyyy HH:mm (z)"
                }}
                labelClassName="mb-0"
                showTimeSelect
              />
              <Label htmlFor={"dateTo"} style={{ "margin-bottom": 0 }}>
                <IntlMessages id={"End Date"} />
              </Label>
              <Field
                className="mb-3"
                name="dateTo"
                //  label="End Date"
                component={DatePicker}
                type="datetime-local"
                options={{
                  dateFormat: "dd/MM/yyyy HH:mm (z)"
                }}
                labelClassName="mb-0"
                showTimeSelect
              />


            </Colxx>
            <Colxx xs={12} sm={6} md={{ size: 5, order: 2 }}>
              <FormattedMessage id="audit.type-search.teams">
                {
                  (msg) =>
                    <Field
                      className="mb-3"
                      name="teamId"
                      label="Team"
                      component={ValidatedAutocomplete}
                      noOptionsMessage={() => msg}
                      labelClassName="mb-0"
                      isSearchable
                      loadOptions={onLoadTeamsOptions}
                      getOptionLabel={i => i.name}
                      getOptionValue={i => i.id}
                      cacheOptions
                      isClearable
                      placeholder=""
                    />}</FormattedMessage>
              <FormattedMessage id="audit.type-search.boxes">
                {
                  (msg) =>
                    <Field
                      className="mb-3"
                      name="boxeId"
                      label="Box"
                      component={ValidatedAutocomplete}
                      noOptionsMessage={() => msg}
                      labelClassName="mb-0"
                      isSearchable
                      loadOptions={onLoadBoxOptions}
                      getOptionLabel={i => `${i.project}.${i.number}`}
                      getOptionValue={i => i.id}
                      cacheOptions
                      isClearable
                      placeholder=""
                    />}</FormattedMessage>
              <FormattedMessage id="audit.type-search.documents">
                {
                  (msg) =>
                    <Field
                      className="mb-3"
                      name="documentId"
                      label="Document"
                      component={ValidatedAutocomplete}
                      noOptionsMessage={() => msg}
                      labelClassName="mb-0"
                      isSearchable
                      loadOptions={onLoadDocOptions}
                      getOptionLabel={i => `${i.project}.${i.box}.${i.number}`}
                      getOptionValue={i => i.id}
                      cacheOptions
                      isClearable
                      placeholder=""
                    />}</FormattedMessage>
              <FormattedMessage id="audit.type-search.pages">
                {
                  (msg) =>
                    <Field
                      className="mb-3"
                      name="pageId"
                      label="Page"
                      component={ValidatedAutocomplete}
                      noOptionsMessage={() => msg}
                      labelClassName="mb-0"
                      isSearchable
                      loadOptions={onLoadPageOptions}
                      getOptionLabel={i => i.name}
                      getOptionValue={i => i.id}
                      cacheOptions
                      isClearable
                      placeholder=""
                    />}</FormattedMessage>
            </Colxx>
          </Row>
          <Row>
            <Colxx sm={12} md={{ size: 10, offset: 1 }}>
              <div className="text-right">
                <Button
                  outline
                  color="secondary"
                  className="ml-2 mb-2"
                  onClick={onClose}
                >
                  <i className="simple-icon-close" />{" "}
                  <IntlMessages id="Close" />
                </Button>
                <Button color="primary" className="ml-2 mb-2" type="submit">
                  <i className="simple-icon-docs" />{" "}
                  <IntlMessages id="menu.search" />
                </Button>
              </div>
            </Colxx>
          </Row>
        </form>
        <Separator className="mb-3" />
      </Colxx >
    );
  }
}

export const Filter = reduxForm({
  form: "auditSearch"
})(FilterCmp);


