/* Gogo Language Texts

Table of Contents

01.General
02.User Login, Logout, Register
03.Menu
04.Error Page
*/

module.exports = {
  /* 01.General */
  "app.title": "Système de gestion des preuves",
  "general.copyright": "Gogo React © 2018 All Rights Reserved.",

  /* 02.User Login, Logout, Register */
  "user.logout": "Se déconnecter",
  "user.login-intro": "Veuillez entrer vos informations d'identification",
  "user.username": "Nom d'utilisateur",
  "user.password": "Mot de passe",
  "user.login-button": "S'IDENTIFIER",
  "user.error-gettings-users": "Erreur lors de l'obtention des utilisateurs",

  /* 03.Menu */
  "menu.app": "Accueil ",
  "menu.users": "Utilisateurs",
  "menu.documents": "Documents",
  "menu.search": "Recherche",
  "menu.saved-search": "Recherches sauvegardées",
  "menu.audit": "Audit",
  "menu.restrictions": "Restrictions",
  "menu.reporting": "Rapport",
  "menu.savesearch": "Enregistrer la recherche",
  "menu.deleteRequests": "Demandes de suppression",
  "menu.ips": "Ips tentatives",

  /* 04.Error Page */
  "layouts.error-title": "Oups ... Une erreur s'est produite!",
  "layouts.error-code": "Error code",
  "layouts.go-back-home": "Retour à la page d'accueil",

  /* 04.DataList : Users */
  "user.Manage-Uses": "Gestion des utilisateurs",
  "user.Archived-Users": "Utilisateurs archivés",
  "user.Manage-Teams": "Gestion des équipes",
  "user.Manage-IpAttempts": "Gestion des adresses ip",
  "user.Add-User": " Ajouter des utilisateurs",
  "user.Edit-User": "Modifier un utilisateur ",
  "user.role": "Rôle",
  "users.add-success-message": "L'utilisateur a été ajouté avec succès!",
  "users.deleted-success-message": "L'utilisateur a été supprimé avec succès!",
  "users.enabled-success-message": "L'utilisateur a été activé avec succès",
  "users.error-authentification": "La connexion a échoué !",
  "users.error-locked": "Votre compte est bloqué, veuillez réessayer après ",
  "users.error-locked-minute": " minutes!",
  "users.confirm-delete-title": "Confirmation",
  "users.confirm-delete": "Voulez-vous vraiment archiver cet utilisateur?",
  "users.blocked": "Utilisateur bloqué",
  "users.unlocked": "Utilisateur débloqué",


  /* DataList : Teams */
  "teams.Add-Teams": "Ajouter des équipes",
  teams: "Equipes",
  "teams.Edit-Team": "Modifier une équipe",
  "teams.add-success-message": "L'équipe a été sauvegardée avec succès!",
  "teams.deleted-success-message": "L'équipe a été supprimée avec succès!",
  "teams.confirm-delete": "Voulez-vous vraiment supprimer cette équipe ?",
  "teams.error-gettings-teams": "Erreur lors de l'obtention des équipes",
  "teams.users": " utilisateur(s)",

  /* DataList : restrictions */
  "restrictions.Add-Restrictions": "Ajouter des restrictions",
  "restrictions.add-success-message":
    "La restriction a été sauvegardée avec succès!",
  "restrictions.deleted-success-message":
    "La restriction a été supprimée avec succès!",
  "restrictions.confirm-delete":
    "Voulez-vous vraiment supprimer cette restriction ?",
  "restrictions.error-gettings": "Erreur lors de l'obtention des restrictions",

  /* DataList : projets */
  "projets.Add-Projets": "Ajouter des projets",
  "projets.add-success-message": "Le projet a été sauvegardé avec succès!",
  "projets.deleted-success-message": "Le projet a été supprimé avec succès!",
  "projets.confirm-delete": "Voulez-vous vraiment supprimer ce projet ?",
  "projets.error-gettings": "Erreur lors de l'obtention des projets",
  "projets.error-delete": "Fichiers présents. Veuillez supprimer tout le contenu du projet avant de supprimer le projet ",
  "projects.description": "Description du projet",
  "projects.prefix": "Préfixe du project",

  /* Documents */
  "doc.list-document": "Gestion des documents",
  "doc.list-projets": "Gestion des projets",
  "doc.import-documents": "Importer des documents",
  "doc.download-document-images": "Télécharger les images des documents",
  "doc.import-ocr": "Importer les fichiers OCR",
  "doc.import-metadata": "Importer des métadonnées",
  "doc.document-boxes": "Boîtes à documents ",
  "doc.video-boxes": "Boites à video ",
  "doc.files in": "Fichiers dans ",
  "doc.file": "Fichier:",
  "doc.download": "Télécharger",
  "doc.Pages in": "Pages dans",
  "doc.Box Information Sheet": "Fiche d'information de la boîte",
  "doc.no.missings": "Aucun document manquant",

  "set-total-docs": "Nombre total de dossiers pour la boîte",
  "set-total-pages": "Nombre total de pages pour les dossiers",
  "Changes saved successfully": "Modifications enregistrées avec succès !",
  "documents.hide-metadata": "Masquer les métadonnées",
  "document.delete-success-message": "Document supprimé avec succès !",
  /* DataList : IpAttempts */
  "ipAttempts.deleted-success-message": "L'ip a été supprimée avec succès!",
  "ipAttempts.confirm-delete": "Voulez-vous vraiment supprimer cette ip ?",
  "ipAttempts.error-gettings-ipAttempts": "Erreur lors de l'obtention des ips",
  // Boxes
  "box.delete-success-message": "Boîte supprimée avec succès !",

  /* Commans */
  Cancel: "Annuler",
  Close: "Fermer",
  Enable: "Activer",
  Confirm: "Confirmer",
  Save: "Sauvegarder",
  Submit: "Soumettre",
  Edit: "modifier",
  "First Name": "Prénom",
  "Last Name": "Nom",
  Name: "Prénom",
  Email: "Email",
  OCR: "OCR",
  Username: "Nom d'utilisateur",
  User: "Utilisateur",
  Users: "Utilisateurs",
  Team: "équipe",
  Box: "Boite",
  Boxs: "Boites",
  Action: "Action",
  Document: "Document",
  Image : "Image",
  WebPages: "Pages Web",
  SocialMedia : "Réseaux sociaux/chats",
  Audio :"Audio",
  Page: "Page",
  "confirm-password": "Confirmez le mot de passe",
  "order-by": "En ordre de  :",
  Password: "Mot de passe ",
  Delete: "Supprimer",
  Videos: "Videos",
  Pages: "Pages",
  Images: "Images",
  Metadata: "Métadonnée ",
  Video: "Video",
  Documents: "Documents",
  Prefix: "Préfixe",
  "Add files...": "Ajouter des fichiers...",
  "Cancel upload": "Annuler le téléchargement ",
  "Start upload": "Commencer le téléchargement",
  "Page Metadata": "Métadonnées de la page",
  "Folder Metadata": "Métadonnées du dossier",
  "Box Metadata": "Métadonnées de la boîte",
  "Box Name": "Nom de la boite",
  "Authorized Ips": "Ips autorisé",
  "Metadata not saved": "Métadonnées non enregistrées",
  "Missings Pages": "Pages manquantes",
  Selection: "Selection",
  Download: "Télécharger",
  "Save changes": "Sauvegarder les modifications",
  Generate: "Générer",

  /* Search */
  "search.basic-search": "Recherche basique",
  "search.specific-search": "Recherche spécifique",
  "search.advanced-search": "Recherche avancée",
  "Activate fuzzy search (search for terms that are similar to, but not exactly like our search term)":
    "Activer la recherche approximative (Consiste à trouver des termes qui correspondent à un motif approximatif plutôt qu'à une correspondance exacte)",

  /* Errors */
  "error.required": "Champ obligatoire",
  "error.invalide Email": "E-mail non valide",
  "error.pwd-not-match": "Les mots de passe ne correspondent pas",
  "errors.error-adding-user": "Une erreur s'est produite. Veuillez réessayer.",
  "error.password-to-short": "Le mot de passe saisi est trop court.",
  "error.name-to-short": "Le nom du projet doit comporter 3 caractères",
  "no-data-found": "Aucune donnée n'a été trouvée !",
  "file-missing": "Ce fichier semble être manquant.",
  "Download document images error : Invalid range":
    "The selection must follow this pattern : SYR.D0001, SYR.D0002.001, SYR.D0002.002.001, SYR.D0002.001.004-SYR.D0002.001.050. Use a comma (,) to separate boxes, documents, pages and page ranges, and a dash (-) for the ranges.",
  "No files found": "Aucun fichier n'a été trouvé.",

  /* Search */
  "Search term": "Terme de recherche",
  "Start Date": "Date de début",
  "End Date": "Date de fin",
  "Location seized": "Emplacement de saisi",
  "Digital forensics ID":"Numéro d'investigation numérique",
  "Date seized": "Date de saisie",
  "Seized By": "Saisi par",
  Notes: "Notes",
  "Document From": "Page de ",
  "Document To": "Page à ",
  "Document CC": "Page CC",
  "Document summary": "Résumé du dossier",
  "Names of people": "Les noms de personnes",
  "Names of places": "Les noms des lieux",
  "Names of entities": "Les noms des entités",
  To: "À",
  From: "De",
  Cc: "Cc",
  Tags: "Champ de signature",
  "Location seized Box": "Emplacement de saisi métadonnées de la boîte",
  "Notes Box": "Notes métadonnées de la boîte",
  "Document From Folder": "Page de métadonnées de dossier",
  "Document To Folder": "Page à métadonnées de dossier",
  "Document CC Folder": "Page CC métadonnées de dossier",
  "Document summary Folder": "Résumé du dossiermétadonnées de dossier",
  "Names of people Page": "Les noms de personnes métadonnées de la page",
  "Names of places Page": "Les noms des lieux métadonnées de dossier",
  "Names of entities Page": "Les noms des entités métadonnées de dossier",
  "To Page": "À métadonnées de dossier",
  "From Page": "De métadonnées de dossier",
  "Cc Page": "Cc métadonnées de dossier",
  "Tags Page": "Champ de signature métadonnées de dossier",
  "Start Date Page": "Date de début métadonnées de dossier",
  "End Date Page": "Date de fin métadonnées de dossier",

  "All these words": "Tous ces mots",
  "Any of these words": "N'importe lequel de ces mots",
  "None of these words": "Aucun de ces mots",
  "Find pages with": "Trouver des pages avec...",
  "Show Filter": "Afficher le filtre",

  "searches.saved": "Recherches sauvegardées",
  "search.edit": "Modifier la recherche",
  "search.add-success-message": "Recherche sauvegardée avec succès!",
  "search.confirm-delete": "Voulez-vous vraiment supprimer cette recherche?",
  "search.name": "Comment voudriez-vous appeler cette recherche ?",

  /** Audit Page */
  "Action types": "Types d'action",
  "Show all action types": "Afficher tous les types d'action",
  "Show only logoff actions":
    "Afficher uniquement les actions en mode déconnecté",
  "Show only logon actions": "Afficher uniquement les actions en mode connecté",

  totalDocuments: "Nombre total de documents dans EMS",
  "Total Document": "Total des dossiers",
  totalEnglishTranslations: "Total des traductions anglaises",
  totalImages: "Total des images",
  totalMetadatas: "Nombre total de métadonnées",
  totalOcrs: "Nombre total de documents OCR",
  totalVideos: "Total des videos",
  "Next Page": "Page suivante",
  "Previous Page": "Page précédente",
  Archive: "Archivé",

  "Records without metadata": "Rapports sans métadonnées",
  "Search for documents box having no metadata for fields":
    "Recherche de documents sans métadonnées pour les champs",
  Back: "Retour",
  Forward: "Avancer",
  "Home.search": "Accueil",

  //Pages
  "pages.download": "Télécharger",
  "pages.no-english-translation": "Cette page n'a pas été traduite en anglais.",
  "page.page-english-translation-tab": "Traduction anglaise",
  "page.page-tab": "Page",
  "page.delete-success-message": "Page supprimée avec succès !",
  "page.no.missings": "Aucune page manquante",


  // Audit
  "audit.download-audit-search-results":
    "Télécharger les résultats de la recherche d'audit",
  "audit.download-audit-search-results-body":
    "Voulez-vous télécharger cette page uniquement ou tous les résultats? (Le téléchargement de tous les résultats peut prendre beaucoup plus de temps)",
  "audit.this-page-only": "Cette page seulement",
  "audit.all-results": "Tous les résultats",
  "audit.of-action": "#  d'actions",
  "audit.progress-metadata": "Avancement de l'ajout des métadonnées",
  "audit.type-search.users": "Tapez pour rechercher des utilisateurs ...",
  "audit.type-search.teams": "Tapez pour rechercher des équipes ...",
  "audit.type-search.documents": "Tapez pour rechercher des documents ...",
  "audit.type-search.boxes": "Tapez pour rechercher des documents ...",
  "audit.type-search.pages": "Tapez pour rechercher des pages ...",
  // Delete Requests
  "delete-requests": "Demandes de suppression",
  "delete-requests.confirm-delete-title": "Confirmation",
  "delete-requests.confirm-cancel-title": "Confirmation",
  "delete-requests.confirm-delete-content":
    "Are you sure you want to delete the resource?",
  "delete-requests.confirm-cancel-content":
    "Are you sure you want to cancel the request?",
  "delete-requests.deleted-success-message": "Resources deleted successfully !",
  "delete-requests.canceled-success-message": "Requests canceled !",
  "delete-request-sent": "Delete request sent !",
  "Total number of pages": "Nombre total de pages",

  "Original Url": "URL d'origine",
  "Summary": "Résumé",
  "Upload Date": "Date de dépôt",
  "Youtube Id": "Identifiant Youtube",
  "Youtube Title": "Titre Youtube",
  "Missings Documents": "Dossiers manquants",
  "Boxes": "Boîtes",
  "Delete document": "Supprimer le dossier",
  "Delete box": "Supprimer la boîte",
  "Go to page": "Aller à la page",
  "Signature block": "Champ de signature",
  "Delete page": "Supprimer la page",
  "Show Document boxes": "Afficher les boîtes de documents",
  "Show Videos boxes": "Afficher les boîtes de vidéos",
  "Show Only failed uploads": "Afficher uniquement les échecs de téléchargement",

  //Delete Ressources
  "delete-ressource.msg": "Voulez-vous vraiment envoyer une demande de suppression de cette ressource ?",
  "delete-ressource.error": "Une erreur s'est produite lors de la tentative de suppression. Veuillez réessayer plus tard",
  "Send": "Envoyer",
  "of": "de",
  "Folders": "Dossiers",

  "Sort": "Trier par",
  "asc": "Nom (Croissant)",
  "desc": "Nom (Décroissant)",

  "Title": "Titre",
  "Hijri Date": "Date de l'hégire",
  "Start Hijri Date":"Date de début de l'hégire",
  "End Hijri Date":"Date de fin de l'hégire", 
  "PhoneOrEmail": "Numéros de téléphone/fax ou e-mails",
  "DocumentReferenceCommunication": "Numéro de référence du document/numéro de communication",
  "DocumentReferenceMentioned": "Numéros de référence des documents mentionnés",
  "Languages": "Langues",
  "Format": "Format",
  "Subject": "Objet",
  "Description": "Description",
  "ReferencedInBrief": "Référencé dans [Briefs]",
  "DuplicateDocNo": "Doc en double. No",
  "Previously labelled under":"Précédemment étiqueté sous",
  "First Page": "Première page",
  "Last Page": "Dernière page",
  "First Result": "Premier résultat",
  "Previous Result": "Résultat précédent",
  "Next Result": "Résultat suivant",
  "Last Result": "Dernier résultat",
  "Original Filename": "Nom de fichier d'origine",
  "MD5 Hash Value":"Valeur de hachage MD5",
  "Box type":"Type de boîte",
  "Content type":"Type de contenu",
  "SHA1 Hash Value":"",
  "Relevant":"Pertinent",
  "Translation ": "Traduction",
  "Total Documents (Scanned, E-files and Open-Source)":"Nombre total de documents (numérisés, fichiers électroniques et Open-Source)",
  "Total Documents (Scanned)":"Nombre total de documents (numérisés)",
  "Total Documents (E-files)":"Nombre total de documents (fichiers électroniques)",
  "Total Documents (Open-Source)":"Nombre total de documents (Open Source)",
  "Total Images (E-files and Open-Source)":"Nombre total d'images (Fichiers électroniques et Open Source)",
  "Total Images (E-files)":"Nombre total d'images (Fichiers électroniques)",
  "Total Images (Open-Source)":"Nombre total d'images (Open Source)",
  "Total Videos (E-files and Open-Source)":"Nombre total de videos (Fichiers électroniques et Open Source)",
  "Total Videos (E-files)":"Nombre total de videos (Fichiers électroniques)",
  "Total Videos (Open-Source)":"Nombre total de videos (Open Source)",
  "Total Videos (A boxes)":"Total de vidéos (boîtes A)",
  "Total Audio (E-files and Open-Source)":"Nombre total d'audio (Fichiers électroniques et Open Source)",
  "Total Audio (E-files)":"Nombre total d'audio (Fichiers électroniques)",
  "Total Audio (Open-Source)":"Nombre total d'audio (Open Source)",
  "Total Social Media (E-files and Open-Source)":"Nombre total des réseaux sociaux/chats (Fichiers électroniques et Open Source)",
  "Total Social Media (E-files)":"Nombre total des réseaux sociaux/chats (Fichiers électroniques)",
  "Total Social Media (Open-Source)":"Nombre total des réseaux sociaux/chats (Open Source)",
  "Total URLs (E-files and Open-Source)":"Total des URL (fichiers électroniques et open-source)",
  "Total URLs (E-files)":"Total des URL (Fichiers électroniques)",
  "Total URLs (Open-Source)":"Total des URL (Open Source)",
  "Total evidence in EMS (scanned , e-files , open source/ excluding translations)":"Preuve totale dans EMS (numérisée, fichiers électroniques, open source/hors traductions)",
  "Total evidence in EMS (Scanned, A-videos, E-files , Open-Source/ excluding translations)": "Preuve totale dans EMS (numérisée, A-vidéos, fichiers électroniques, open source, vidéos/hors traductions)"

};
