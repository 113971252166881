import React from "react";
import { Line } from "react-chartjs-2";
import LightSpinner from "../../../components/LightSpinner";
import { PAGE_STATISTICS } from "constants/auditActions";
import { FormattedMessage } from "react-intl";


const data = {
  labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun"],
  datasets: [
    {
      label: "First dataset",
      data: [33, 53, 85, 41, 44, 65],
      fill: true,
      backgroundColor: "rgba(75,192,192,0.2)",
      borderColor: "rgba(75,192,192,1)"
    },

  ]
};
const getData = (dataAudit, msg) => {
  return {
    labels: dataAudit.labels,
    datasets: [
      {
        label: msg,
        data: dataAudit.values,
        fill: true,
        backgroundColor: "rgba(75,192,192,0.2)",
        borderColor: "rgba(75,192,192,1)"
      },

    ]
  }


}



export const LineChart = ({ auditTotalMetadata }) => {
  if (auditTotalMetadata === undefined) return null;
  else if (auditTotalMetadata.loading)
    return <LightSpinner className="text-center w-100" />;
  else

    return auditTotalMetadata.data &&auditTotalMetadata.data.labels.length&&auditTotalMetadata.data.labels.length>0 ?(
      <FormattedMessage id="audit.progress-metadata">
        {
          (msg) => <Line data={getData(auditTotalMetadata.data, msg)} />
        }
      </FormattedMessage>):null;
};
