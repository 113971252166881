import {
  CHANGE_REPORTING_TAB,
  GET_BOX_WITHOUT_META,
  SET_BOX_WITHOUT_META,
  ERROR_BOX_WITHOUT_META,
  CHANGE_BOX_WITHOUT_META_FILTER,
  CHANGE_BOX_WITHOUT_META_PAGE_SIZE,
  GET_DOC_WITHOUT_META,
  SET_DOC_WITHOUT_META,
  ERROR_DOC_WITHOUT_META,
  CHANGE_DOC_WITHOUT_META_FILTER,
  CHANGE_DOC_WITHOUT_META_PAGE_SIZE,
  GET_PAGE_WITHOUT_META,
  SET_PAGE_WITHOUT_META,
  ERROR_PAGE_WITHOUT_META,
  CHANGE_PAGE_WITHOUT_META_FILTER,
  CHANGE_PAGE_WITHOUT_META_PAGE_SIZE
} from "redux/actionTypes";

const INIT_STATE_ELT = {
  loading: false,
  error: undefined,
  filter: {},
  page: 1,
  size: 10,
  data: {
    total: 0,
    result: []
  }
};

const INIT_STATE = {
  activeTab: "1",
  noMetadataBoxes: INIT_STATE_ELT,
  noMetadataDocs: INIT_STATE_ELT,
  noMetadataPages: INIT_STATE_ELT
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case CHANGE_REPORTING_TAB:
      return Object.assign({}, state, { activeTab: action.payload });
    case GET_BOX_WITHOUT_META:
      return Object.assign({}, state, {
        noMetadataBoxes: {
          ...state.noMetadataBoxes,
          loading: true,
          error: undefined,
          data: {
            total: 0,
            result: []
          }
        }
      });
    case SET_BOX_WITHOUT_META:
      return Object.assign({}, state, {
        noMetadataBoxes: {
          ...state.noMetadataBoxes,
          loading: false,
          error: undefined,
          data: action.payload
        }
      });
    case ERROR_BOX_WITHOUT_META:
      return Object.assign({}, state, {
        noMetadataBoxes: {
          ...state.noMetadataBoxes,
          loading: false,
          error: action.payload,
          data: {
            total: 0,
            result: []
          }
        }
      });
    case CHANGE_BOX_WITHOUT_META_FILTER:
      return Object.assign({}, state, {
        noMetadataBoxes: {
          ...state.noMetadataBoxes,
          filter: {
            ...state.noMetadataBoxes.filter,
            ...action.payload
          }
        }
      });
    case CHANGE_BOX_WITHOUT_META_PAGE_SIZE:
      return Object.assign({}, state, {
        noMetadataBoxes: {
          ...state.noMetadataBoxes,
          ...action.payload
        }
      });

    case GET_DOC_WITHOUT_META:
      return Object.assign({}, state, {
        noMetadataDocs: {
          ...state.noMetadataDocs,
          loading: true,
          error: undefined,
          data: {
            total: 0,
            result: []
          }
        }
      });
    case SET_DOC_WITHOUT_META:
      return Object.assign({}, state, {
        noMetadataDocs: {
          ...state.noMetadataDocs,
          loading: false,
          error: undefined,
          data: action.payload
        }
      });
    case ERROR_DOC_WITHOUT_META:
      return Object.assign({}, state, {
        noMetadataDocs: {
          ...state.noMetadataDocs,
          loading: false,
          error: action.payload,
          data: {
            total: 0,
            result: []
          }
        }
      });
    case CHANGE_DOC_WITHOUT_META_FILTER:
      return Object.assign({}, state, {
        noMetadataDocs: {
          ...state.noMetadataDocs,
          filter: {
            ...state.noMetadataDocs.filter,
            ...action.payload
          }
        }
      });
    case CHANGE_DOC_WITHOUT_META_PAGE_SIZE:
      return Object.assign({}, state, {
        noMetadataDocs: {
          ...state.noMetadataDocs,
          ...action.payload
        }
      });

    case GET_PAGE_WITHOUT_META:
      return Object.assign({}, state, {
        noMetadataPages: {
          ...state.noMetadataPages,
          loading: true,
          error: undefined,
          data: {
            total: 0,
            result: []
          }
        }
      });
    case SET_PAGE_WITHOUT_META:
      return Object.assign({}, state, {
        noMetadataPages: {
          ...state.noMetadataPages,
          loading: false,
          error: undefined,
          data: action.payload
        }
      });
    case ERROR_PAGE_WITHOUT_META:
      return Object.assign({}, state, {
        noMetadataPages: {
          ...state.noMetadataPages,
          loading: false,
          error: action.payload,
          data: {
            total: 0,
            result: []
          }
        }
      });
    case CHANGE_PAGE_WITHOUT_META_FILTER:
      return Object.assign({}, state, {
        noMetadataPages: {
          ...state.noMetadataPages,
          filter: {
            ...state.noMetadataPages.filter,
            ...action.payload
          }
        }
      });
    case CHANGE_PAGE_WITHOUT_META_PAGE_SIZE:
      return Object.assign({}, state, {
        noMetadataPages: {
          ...state.noMetadataPages,
          ...action.payload
        }
      });

    default:
      return { ...state };
  }
};
