/* Gogo Language Texts

Table of Contents

01.General
02.User Login, Logout, Register
03.Menu
04.Error Page
*/

module.exports = {
   /* 01.General */
   "app.title":"سسیستەمی بەڕێوەبردنی بەڵگەکان",
  /* 02.User Login, Logout, Register */
  "user.logout":"دەرچوون (چوونە دەرەوە)",
  "user.login-intro": " تکایە زانیارییەکانت بنووسە",
  "user.username": "ناویبەکارهێن",
  "user.password": "وشەی نهێنی",
  "user.login-button": "چوونە ژوورەوە",
  "user.error-gettings-users": " هەڵە لە گەیشتن بە بەکارهێنەران",

  /* 03.Menu */
  "menu.app": "سەرەکی",
  "menu.users": "بەکارهێنەران",
  "menu.documents": "بەڵگەنامەکان",
  "menu.search": "گەڕان",
  "menu.saved-search": "هەڵگرتنی گەڕان",
  "menu.audit": "وردبین",
  "menu.restrictions": "بەربەستەکان",
  "menu.reporting": " راپۆرتکردن",
  "menu.save search": "گەڕان هەڵبگرە",
  "menu.deleteRequests": "سڕینەوەی داواکارییەکان",
  "menu.ips":"هەوڵی ئای پی ئێس",

  /* 04.Error Page */
  "layouts.error-title": "ئاه .... پێدەچێت هەڵە روویدابێت!",
  "layouts.error-code": "کۆدی هەڵە",
  "layouts.go-back-home": "بگەڕێوە بۆ سەرەکی",

  /* 04.DataList : Users */
  "user.Manage-Uses": "بەکارهێنەران بەڕێوەببە",
  "user.Archived-Users": "بەکارهێنەرانی ئەرشیفکراو",
  "user.Manage-Teams": " تیمەکان بەڕێوەببە",
  "user.Manage-IpAttempts": "IP بەڕێوەبردنی ناونیشانی",
  "user.Add-User": "بەکارهێنەران زیادبکە",
  "user.Edit-User": " دەستکاری بەکارهێنەران بکە",
  "user.role": "رۆڵ",
  "users.add-success-message": "بە سەرکەوتووی بەکارهێن زیادکرا",
  "users.deleted-success-message": "بە سەرکەوتووی بەکارهێن سڕاوە",
  "users.enabled-success-message": "بە سەرکەوتووی بەکارهێن چالاککرا",
  "users.error-authentification": "چوونە ژوورەوە سەرکەوتوو نەبوو!",
  "users.error-locked": "حساب شما مسدود شده است لطفاً بعد از آن دوباره امتحان کنید ",
  "users.error-locked-minute": " دقایق",
  "users.confirm-delete-title": "دڵنیاکردنەوە",
  "users.confirm-delete": "ئایا دڵنیایت کە دەتەوێت ئەم بەکارهێنەرە ئەرشیف بکەیت؟",
  "users.blocked":"بەکارهێنەری بلۆک کراوە",
  "users.unlocked": "بەکارهێنەر قفڵی کراوەتەوە",

  /* DataList : Teams */
  "teams.Add-Teams": "تیمەکان زیادبکە",
  teams: "تیمەکان",
  "teams.Edit-Team": "دەستکاری تیمەکان بکە",
  "teams.add-success-message": "بەسەرکەوتوویی تیمەکە هەڵگیرا",
  "teams.deleted-success-message": "بەسەرکەوتوویی تیمەکە سڕایەوە",
  "teams.confirm-delete": "ئایا دڵنیای دەتەوێت تیمەکە بسڕییەوە؟",
  "teams.error-gettings-teams": "هەڵە لە گەیشتن بە تیمەکان",
  "teams.users":"کاربر (ها)",

  /* DataList : restrictions */
  "restrictions.Add-Restrictions": "بەربەستەکان زیادبکە",
  "restrictions.add-success-message": "بەسەرکەوتووی بەربەستکردن هەڵگیرا",
  "restrictions.deleted-success-message": "بەسەرکەوتووی بەربەستەکان سڕانەوە!",
  "restrictions.confirm-delete": "ئایا تۆ دڵنایی دەتەوێت بەربەستەکان بسڕیتەوە؟",

  "restrictions.error-gettings": "هەڵە لە گەیشتن بە بەبەرستەکان",

  /* DataList : projets */
  "projets.Add-Projets": " پڕۆژەکان زیادبکە",
  "projets.add-success-message": "بەسەرکەوتوویی پڕۆژەکە هەڵگیرا",
  "projets.deleted-success-message": "بەسەرکەوتوویی پڕۆژەکە سڕاوە",
  "projets.confirm-delete": "ئایا دڵنیایی دەتەوێت پڕۆژەکە بسڕییتەوە؟",
  "projets.error-gettings": "هەڵە لە گەیشتن بە پڕۆژەکان",
  "projets.error-delete": "فایلەکان ئامادەن. تکایە هەموو ناوەڕۆکەکان بسڕەوە لە پرۆژەکە پێش سڕینەوەی پرۆژەکە ",
  "projects.description": "وەسفکردنی پڕۆژەکە",
  "projects.prefix":"پێشگرى پرۆژە",


  /* Documents */
  "doc.list-document": "بەڵەگەنامەکان بەڕێوەببە",
  "doc.list-projets": "پڕۆژەکان بەڕێوەببە",
  "doc.import-documents": "بەڵگەنامەکان هاوردەبکە",
  "doc.download-document-images": "وێنەکانی بەڵگەنامەکە داونلۆودبکە",
  "doc.import-ocr": "فایلەکانی OCR هاوردەبکە",
  "doc.import-metadata": "زانیاری وەسفی هاوردەبکە",
  "doc.document-boxes": "سندۆقەکانی بەڵگەنامە",
  "doc.video-boxes": "سندۆقەکانی ڤیدیۆ",
  "doc.files in": " فایلەکانی نێو",
  "doc.file": "فایل",
  "doc.download": "داونڵۆود بکە",
  "doc.Pages in": "لاپەڕەکانی ناوەوە",
  "doc.Box Information Sheet": "شیتی سندۆقی زانیاری",
  "doc.no.missings":"هیچ دۆکیومێنتێکی ونبوو نیە",

  Archive: "ئەرشیڤ",
  "set-total-docs": "کۆی فۆڵدەرەکان بۆ سندوق",
  "set-total-pages": "کۆی لاپەڕەکان بۆ دۆکیومێنتەکان",
  "Changes saved successfully": "گۆڕانکارییەکان بە سەرکەوتووی هەڵگیران!",
  "documents.hide-metadata":"زانیاری وەسفی بشارەوە",
  "document.delete-success-message": "بەسەرکەوتوویی بەڵگەنامە سڕاوە!",
 /* DataList : IpAttempts */
 "ipAttempts.deleted-success-message": "! ئای پیەکە بە سەرکەوتوویی لابرا",
 "ipAttempts.confirm-delete": "ئایا دڵنیایت کە دەتەوێت ئەم ئای پیە لابدەیت؟",
 "ipAttempts.error-gettings-ipAttempts": "هەڵە لە بەدەستهێنانی ئای پی ئێس",
  // Boxes
  "box.delete-success-message": "بەسەرکەوتوویی سندۆقەکە سڕاوە!",

  /* Commans */
  Cancel: "کانسڵبکە",
  Close: "دایخە",
  Enable: "چالاک بکە",
  Confirm: "دڵنیایی بکە",
  Save: "‌هەڵبگرە",
  Submit: "پێشکەش بکە",
  Edit: "دەستکاری بکە",
  "First Name": "ناوی یەکەم",
  "Last Name": "ناوی باپیر",
  Name: "ناو",
  Email: "ئیمێیل",
  OCR: "OCR",
  Username: " ناوی بەکارهێن",
  User: "بەکارهێن",
  Users: "بەکارهێنەران",
  Team: "تیم",
  Box: "سندۆق",
  Boxs: "سندۆقەکان",
  Action: "کردە",
  Document: "بەڵگەنامە",
  Image : "وێنە",
  WebPages: "لاپەڕەکانی وێب",
  SocialMedia : "سۆشیال میدیا / چات",
  Audio :"دەنگ",
  Page: "لاپەڕە",
  "confirm-password": "دڵنیایی وشەی نهێنی بکە",
  "order-by": " ریزکردن لە رێگەی",
  Password: "وشەی نهێنی",
  Delete: "سڕینەوە",
  Videos: "ڤیدیۆیەکان",
  Pages: "لاپەڕەکان",
  Images: "وێنەکان",
  Metadata: "زانیاریەکانی وەسفی",
  Video: "ڤیدیۆ",
  Documents: "بەڵگەنامەکان",
  Prefix: "پێشگر",
  "Add files...": "فایلەکان زیادنکە ...",
  "Cancel upload": "کانسڵکردنی ئەپڵۆود",
  "Start upload": "دەستبە ئەپلۆد بکە",
  "Page Metadata": "لاپەرەی زانیاری وەسفی ",
  "Folder Metadata": "فۆلدەری زانیاری وەسفی",
  "Box Metadata": "سندوقی زانیاری وەسفی",
  "Box Name": "ناوی سندۆق",
  "Authorized Ips": "Ipsی رێگەپێدراو",
  "Metadata not saved": "زانیاری وەسفی هەڵنەگیراوە",
  "Missings Pages": "لاپەڕەکانی ونبوو",
  "Total Document": "فۆڵدەرە ونبووەکان",
  Selection: "هەڵبژاردن",
  Download: "دانلود",
  "Save changes": "گۆڕانکارییەکان هەڵبگرە",
  Generate: "دروستبکە",

  /* Search */
  "search.basic-search": "گەڕانی سەرەکی",
  "search.specific-search": "گەڕانی تایبەت",
  "search.advanced-search": "گەڕانی پێشکەوتوو",
  "Activate fuzzy search (search for terms that are similar to, but not exactly like our search term)":
    "گەڕانی لێڵ چالاک بکە (گەڕان بۆ ئەو زاراوانەی کە هاوشێوەن بۆ، بەڵام بەتەواوی وەک زاراوەی گەڕانی ئێمە نین",

  /* Errors */
  "error.required": "بەشی داواکراو",
  "error.invalide Email": " ئیمێلەکە هەڵەیە",
  "error.pwd-not-match": "وشەی نهێنی ناگونجێت",
  "errors.error-adding-user": "هەڵە روویداوە، تکایە دووبارە هەوڵبدە",
  "error.password-to-short": "وشەی نهێنی زۆر کورتە",
  "error.name-to-short": " دەبێت ناوی پڕۆژەکە سێ پیت بێت",
  "no-data-found": "هیچ زانیارییەک نەدۆزرایەوە!",
  "file-missing": "واپێدەچێت ئەم فایلە ونە",
  "Download document images error : Invalid range":"دەبێت شێوازی هەڵبژاردنەکە بەپێی ئەم رێسایەبێت:SYR.D0001, SYR.D0002.001, SYR.D0002.002.001 . کۆمە بەکاربێنە (،) بۆ لێکجیاکردنەوەی سندۆقەکان، بەڵگەنامەکان، لاپەڕەکان و بواری لاپەڕەکان و، (-) بەکاربێنە بۆ بوارەکان.",
  "No files found": "هیچ فایلێک نەدۆزراوە",
  "general.exception.interne": "سێرڤەری ناوخۆ هەڵەیە",
  "la donnée recherchée n'existe pas": "ونبوونی سەرچاوە هەڵەیە",

  /* Search */
  "Search term": "زاراوەی گەڕان",
  "Start Date": "رێکەوتی دەستپێکردن",
  "End Date": "رێکەوتی کۆتایی",
  "Location seized": "شوێنی بەدەستهێنان",
  "Digital forensics ID":"ژمارەی دیجیتالی پزیشکی دادی",
  "Date seized": "رێکەوتی گرتن",
  "Seized By":"بەدەستهێنرا لە لایەن",
  Notes: "تێبینی",
  "Document From": "پەڕە لە",
  "Document To": "پەڕە بۆ",
  "Document CC": "پەڕەی CC",
  "Document summary": "پوختەی فۆڵدەر",
  "Names of people": "ناوی خەڵک",  
  "Names of places": "ناوی شوێنەکان",
  "Names of entities": "ناوی قەوارەکان",
  To: "بۆ",
  From: "لە",
  Cc: "وێنەیەک بۆ",
  Tags: "تاگەکان",
  "Location seized Box": "شوێنی دەستبەسەرداگرتنی چوارگۆشەی مێتاداتا",
  "Notes Box": "مێتاداتای سندوقی تێبینیەکان",
  "Document From Folder": "لاپەڕە لە فۆڵدەر مێتاداتا",
  "Document To Folder": "لاپەڕە بۆ فۆڵدەر مێتاداتا",
  "Document CC Folder": "لاپەڕە CC فۆڵدەر مێتاداتا",
  "Document summary Folder": "پەڕەی پوختەی فۆڵدەر مێتاداتا",
  "Names of people Page": "ناوی خەڵک لاپەڕەی میتاداتا",
  "Names of places Page":"ناوی شوێنەکان لاپەڕەی میتاداتا",
  "Names of entities Page": "ناوی قەوارەکانی لاپەڕەی میتاداتا",
  "To Page":"بۆ لاپەڕە میتاداتا",
  "From Page": "لە لاپەرەی مێتاداتا",
  "Cc Page": "CC لاپەرەی مێتاداتا",
  "Tags Page": "میتاداتای لاپەڕەی بلۆکی ئیمزا",
  "Start Date Page": "دەستکردن بە بەرواری پیجی مێتاداتا",
  "End Date Page": "ئی ئێن دی بەرواری پی ئێج میتاداتا",

  "All these words": " ئەم هەموو وشانە",
  "Any of these words": "هەر یەکێک لەم وشانە",
  "None of these words": "هیچ کامێک نا لەم وشانە",
  "Find pages with": "لاپەڕەکان بدۆزەوە بە ....",
  "Show Filter": "فیلتەر پیشان بدە",
  "searches.saved": "گەڕانەکان هەڵگیران",
  "search.edit": "گەڕان هەڵبگرە",
  "search.add-success-message": "گەڕان بەسەرکەوتووی هەڵگیرا!",
  "search.confirm-delete": "ئایا تۆ دڵنیایی کە دەتەوێت گەڕان بسڕییتەوە؟",
  "search.name": "دەتەوێ چ ناوێک لەم گەڕانە بنێیت؟",

  /** Audit Page **/
  "Action types": "جۆرەکانی کردەوە",
  "Show all action types": "هەموو جۆرەکان پیشان بدە",
  "Show only logoff actions": "تەنها کردەوەکانی چوونە دەرەوە پیشانبدە",
  "Show only logon actions": "تەنها کردەوەکانی چوونە ژوورەوە پیشانبدە",

  totalDocuments: "کۆی ژمارەی دۆکیومێنتەکان لە EMS",
  totalEnglishTranslations: "کۆی گشتی وەرگێڕدراو بۆ زمانی ئینگلیزی",
  totalImages: "کۆی گشتی وێنەکان",
  totalMetadatas: "کۆی ژماردنی مێتاداتا",
  totalOcrs: "کۆی ژمارەی دۆکیومێنتەکانی OCR",
  totalVideos: " کۆی گشتی ڤیدیۆ",
  "Next Page": "لاپەڕەی داهاتوو",
  "Previous Page": "لاپەڕەی پێشوو",

  "Records without metadata": "تۆمارەکانی بەبێ زانیاری وەسفی",
  "Search for documents box having no metadata for fields":
    "گەڕان بۆ سندۆقی بەڵگەنامە کە هیچ زانیاری وەسفیی نیە  بۆ بەش",
  Back: "بەگەڕێەوە",
  Forward:"برو جلو",
  "Home.search": "سەرەکی",

  //Pages
  "pages.download": " داونلۆود",
  "pages.no-english-translation": "ئەم لاپەڕەیە وەرگێڕانی بۆ زمانی ئینگلیزی بۆ نەکراوە.",
  "page.page-tab": "لاپەڕە",
  "page.page-english-translation-tab": "وەرگێڕانی ئینگلیزی",
  "page.delete-success-message": "لاپەڕەکە بە سەرکەوتووی سڕاوە",
  "page.no.missings":"هیچ لاپەڕەیەکی ونبوو نیە",


  // Audit
  "audit.download-audit-search-results": "داونلۆودی ئەنجامی وردبینی گەڕان بکە",
  "audit.download-audit-search-results-body":"دەتەوێ تەنها ئەم لاپەڕەیە یان هەموو ئەنجامەکان داونلۆود بکەی ؟ (لەوانەیە داونلۆودکردنی هەموو ئەنجامەکان کاتێکی زیاتر بخایەنێت)",
    "audit.this-page-only": "تەنها ئەم لاپەڕەیە",
  "audit.all-results": "هەموو ئەنجامەکان",
  "audit.of-action": "# از اقدامات",
  "audit.progress-metadata":"پێشکەوتنی زیاد کردنی مێتاداتا",
  "audit.type-search.users":"تایپ بکە بۆ گەڕان بەدوای بەکارهێنەران",
  "audit.type-search.teams":"تایپ بکە بۆ گەڕان بەدوای تیمەکان",
  "audit.type-search.documents":"تایپ بکە بۆ گەڕان بەدوای سندۆقەکان",
  "audit.type-search.boxes":"تایپ بکە بۆ گەڕان بەدوای سندۆقەکان ",
  "audit.type-search.pages":"تایپ بکە بۆ گەڕان بەدوا لاپەڕەکان ",

  // Delete Requests
  "delete-requests": "سڕینەوەی داواکارییەکان",
  "delete-requests.confirm-delete-title": "دڵنیاییکردن",
  "delete-requests.confirm-cancel-title": "دڵنیاییکردن",
  "delete-requests.confirm-delete-content":
    "ئایا تۆ دڵنیایی دەتەوێت ئەم سەرچاوەیە بسڕییەوە؟",
  "delete-requests.confirm-cancel-content":
    "دڵنیایی دەتەوێت لە داواکاری پەشیمان بیتەوە؟",
  "delete-requests.deleted-success-message": "بەسەرکەوتوویی سەرچاوەکان سڕانەوە!",
  "delete-requests.canceled-success-message": "پەشیمانبووە لە داواکارییەکان!",
  "delete-request-sent": "سڕینەوەی داواکاری نێردرا!",
  "Total number of pages":"تعداد کل صفحاتکۆی گشتی ژمارەی لاپەڕەکان",

  "Original Url": "بەستەری ڕەسەن",
  "Summary": "پوختە",
  "Upload Date":"بەرواری بارکردن",
  "Youtube Id": "ناسنامەی یوتوب",
  "Youtube Title": "ناونیشانی یوتوب",
  "Missings Documents": "دۆکیومێنتە ونبووەکان",
  "Boxes": "سندوقەکان",
  "Delete document": "فۆڵدەر بسڕەوە",
  "Delete box":"سنووقی بسڕەوە",
  "Go to page": "بڕۆ بۆ پەڕە",
  "Signature block": "بلۆکی ئیمزا",
  "Delete page":"سڕینەوەی لاپەڕە",
  "Show Document boxes":"سنووقی دۆکیومێنت پیشان بدە",
  "Show Videos boxes":"سنووقی ڤیدیۆکان نیشان بدە",
  "Show Only failed uploads":"تەنها بارکردنە سەرکەوتووەکان نیشان بدە",

  //Delete Ressources
  "delete-ressource.msg": "آیا مطمئن هستید که می خواهید درخواستی برای حذف این منبع ارسال کنید؟",
  "delete-ressource.error": "هنگام تلاش برای حذف خطایی روی داد. لطفاً بعداً دوباره امتحان کنید",
  
  "Send":"ناردن",
  "of":"لە",
  "Date": "بەروار",
  "Folders":"فۆڵدەرەکان",
  
  "Sort":"پۆلێنکردن بەپێی",
  "asc":"ناو (بەرەو سەرەوە)",
  "desc":"ناو(نزمبوونەوە)",


  "Title":"ناونیشان",
  "Hijri Date":"بەرواری هیجری",
  "Start Hijri Date":"بەرواری دەستپێکردنی کۆچی",
  "End Hijri Date":"کۆتایی هیجری بەرواری",
  "PhoneOrEmail":"ژمارەی تەلەفۆن/فاکس یان ئیمەیڵەکان",
  "DocumentReferenceCommunication":"ژمارەی سەرچاوەی دۆکیومێنت/ژمارەی پەیوەندی",
  "DocumentReferenceMentioned":"ژمارەی سەرچاوەی دۆکیومێنتەکان ئاماژەپێکراوە",
  "Languages":"زمانەکان",
  "Format":"فۆرمات",
  "Subject":"بابەت",
  "Description":"وەسف",
  "ReferencedInBrief":"سەرچاوە لە [کورتەکان]",
  "DuplicateDocNo":"دۆکیومێنتی دووبارە. نەخێر",
  "Previously labelled under":"پێشتر لە ژێر ناوی...",
  "First Page": "پەڕەی یەکەم",
  "Last Page": "دوا لاپەڕە",
  "First Result": "ئەنجامی یەکەم",
  "Previous Result":"ئەنجامی پێشوو",
  "Next Result":"ئەنجامی داهاتوو",
  "Last Result":"دوا ئەنجام",
  "Original Filename":"ناوی فایلی رەسەن",
  "MD5 Hash Value":"بەهای هاش MD5",
  "Box type":"جۆری سندوق",
  "Content type":"جۆری ناوەڕۆک",
  "SHA1 Hash Value":"بەهای هاشSHA1",
  "Relevant":"پەیوەندیدار",
  "Translation ": "وەرگێڕان",
  "Total Documents (Scanned, E-files and Open-Source)":"کۆی گشتی بەڵگەنامەکان (سکانکراو، فایلی ئەلیکترۆنی و سەرچاوەی کراوە)",
  "Total Documents (Scanned)":"کۆی گشتی بەڵگەنامە کان (سکانکراوەکان)",
  "Total Documents (E-files)":"کۆی گشتی بەڵگەنامەکان (فایلی ئەلیکترۆنی)",
  "Total Documents (Open-Source)":"کۆی گشتی بەڵگەکان (سەرچاوە ی کراوە)",
  "Total Images (E-files and Open-Source)":"کۆی گشتی وێنەکان (فایلی ئەلیکترۆنی و سەرچاوەی کراوە)",
  "Total Images (E-files)":"کۆی گشتی وێنەکان (فایلی ئەلیکترۆنی)",
  "Total Images (Open-Source)":"کۆی گشتی وێنەکان (سەرچاوە ی کراوە)",
  "Total Videos (E-files and Open-Source)":"کۆی گشتی ڤیدیۆیەکان (فایلی ئەلیکترۆنی و سەرچاوەی کراوە)",
  "Total Videos (E-files)":"کۆی گشتی ڤیدیۆیەکان (فایلی ئەلیکترۆنی)",
  "Total Videos (Open-Source)":"کۆی گشتی ڤیدیۆیەکان (سەرچاوە ی کراوە)",
  "Total Audio (E-files and Open-Source)":"کۆی گشتی دەنگەکان (فایلی ئەلیکترۆنی و سەرچاوە ی کراوە)",
  "Total Audio (E-files)":"کۆی گشتی دەنگەکان (فایلی ئەلیکترۆنی)",
  "Total Audio (Open-Source)":"کۆی گشتی دەنگەکان (سەرچاوە ی کراوە)",
  "Total Social Media (E-files and Open-Source)":"کۆی گشتی سۆشیل میدیا (فایلی ئەلیکترۆنی و سەرچاوە ی کراوە)",
  "Total Social Media (E-files)":"کۆی گشتی سۆشیال میدیا (فایلی ئەلیکترۆنی)",
  "Total Social Media (Open-Source)":"کۆی گشتی سۆشیال میدیا (سەرچاوە ی کراوە)",
  "Total URLs (E-files and Open-Source)":"کۆی گشتی URLs (فایلی ئەلیکترۆنی و سەرچاوەی کراوە)",
  "Total URLs (E-files)":"کۆی گشتی URLs (فایلی ئەلیکترۆنی)",
  "Total URLs (Open-Source)":"کۆی گشتی URLs (سەرچاوە ی کراوە)",
  "Arabic":"عەرەبی",
  "English":"ئینگلیزی",
  "Russian":"روسی",
  "French":"فرەنسی",
  "Other":"هیتر",
  "Scanned boxes":"سندۆقەکانی سکانکراو",
  "E-files boxes":"سندوقەکانی فایلی ئەلیکترۆنی",
  "Open-Source boxes":"سندوقەکانی سەرچاوە ی کراوە",
  "Total evidence in EMS (scanned , e-files , open source/ excluding translations)":"کۆی گشتی بەڵگەکان لە ناو سستەمی بەڕێۆبردنی بەڵگەکان (سکانکراو، فایلی ئەلیکترۆنی، سەرچاوەی کراوە/ بەبێ وەرگێڕان)"
};
