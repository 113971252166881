import instance from "util/instances";
import { SEARCH_ENDPOINT } from "./endpoints";

export const getSearchsApi = (page, size) =>
  instance.get(SEARCH_ENDPOINT, {
    params: { page, size }
  });
export const getAutoCompleteSearchs = text =>
  instance.get(SEARCH_ENDPOINT, {
    params: { text, page: 1, size: 10 }
  });

export const postSearchsApi = team => instance.post(SEARCH_ENDPOINT, team);
export const putSearchsApi = team => instance.put(SEARCH_ENDPOINT, team);
export const deleteSearchs = teamIds => {
  const params = new URLSearchParams();
  teamIds.forEach(id => params.append("ids", id));
  return instance.delete(`${SEARCH_ENDPOINT}`, { params });
};

export const getSearchByIdApi = id =>
  instance.get(SEARCH_ENDPOINT + id, {
    params: { id }
  });
