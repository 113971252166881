import querystring from "query-string";
import instance from "util/instances";
import {
  PAGES_ENDPOINT,
  PAGES_DOWNLOAD_ENDPOINT,
  PAGES_METADATA_ENDPOINT,
  PAGES_SEARCH_ENDPOINT,
  AUDIT_SEARCH_ENDPOINT,
  AUDIT_TOTAL_METADATA,
  OCR_ENDPOINT,
  NEXT_PAGE_ENDPOINT,
  PREVIOUS_PAGE_ENDPOINT,
  FIRST_LAST_PAGE_ENDPOINT,
  SEND_REQUEST_DELETE_PAGE_ENDPOINT
} from "./endpoints";

export const getPageMetadataApi = id => instance.get(PAGES_ENDPOINT + id);

export const uploadPageApi = (data, onUploadProgress, cancelToken, isOCR) => {
  const formData = new FormData();
  for (const key in data) {
    formData.append(key, data[key]);
  }
  return instance.put(isOCR ? OCR_ENDPOINT : PAGES_ENDPOINT, formData, {
    onUploadProgress,
    cancelToken
  });
};

export const getNextPage = id => instance.get(NEXT_PAGE_ENDPOINT + id);

export const getPreviousPage = id => instance.get(PREVIOUS_PAGE_ENDPOINT + id);

export const getFirstAndLast = id => instance.get(FIRST_LAST_PAGE_ENDPOINT + id);

export const updatePageMetadataApi = (pageId, data) => {
  return instance.put(
    PAGES_ENDPOINT + pageId + PAGES_METADATA_ENDPOINT + "?pageId=" + pageId,
    data
  );
};

export const downloadPageApi = (pageId, typeDoc) =>
  instance.get(
    PAGES_ENDPOINT + pageId + PAGES_DOWNLOAD_ENDPOINT + "?type=" + typeDoc,
    {
      params: { pageId },
      responseType: "blob"
    }
  );

  export const downloadPageTranslationApi = (pageId, typeDoc) =>
  instance.get(
    PAGES_ENDPOINT + pageId + PAGES_DOWNLOAD_ENDPOINT +"/translation"+ "?type=" + typeDoc,
    {
      params: { pageId },
      responseType: "blob"
    }
  );

export const searchPagesApi = ({
  page,
  resultPerPage,
  searchType,
  ...data
}) => {
  return instance.post(
    PAGES_SEARCH_ENDPOINT +
    "?" +
    querystring.stringify({ page, resultPerPage, searchType }),
    data
  );
};

export const auditSearchApi = ({ page, resultPerPage, ...data }) => {
  return instance.post(
    AUDIT_SEARCH_ENDPOINT +
    "?" +
    querystring.stringify({ page, resultPerPage }),
    data
  );
};
export const auditSearchTotalMetadataApi = ({ page, resultPerPage, ...data }) => {
  return instance.post(
    AUDIT_TOTAL_METADATA + "?" +
    querystring.stringify({ page, resultPerPage }),

    data
  );
};

export const getAutoCompletePageName = text =>
  instance.get(PAGES_ENDPOINT, { params: { text } });

export const deletePageApi = id => instance.delete(PAGES_ENDPOINT + id);

export const sendRequestDeletePageApi = id =>
  instance.post(SEND_REQUEST_DELETE_PAGE_ENDPOINT + id);

export const searchResultsDownloadApi = (data, searchType) =>
  instance.post(searchType !== undefined ?
    PAGES_SEARCH_ENDPOINT + "download?searchType=" + searchType : PAGES_SEARCH_ENDPOINT + "download",
    //  querystring.stringify({ searchType }),
    data
  );


