import * as types from "redux/actionTypes";

const INIT_STATE = {
  searchActionsCount: {
    loading: false,
    error: undefined,
    list: []
  },
  rTUriNames: []
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case types.GET_AUDIT_SEARCH_TOTAL_ACTIONS:
      return {
        ...state,
        searchActionsCount: { loading: true, error: undefined, list: [] }
      };
    case types.ERROR_AUDIT_SEARCH_TOTAL_ACTIONS:
      return {
        ...state,
        searchActionsCount: { loading: false, error: action.payload, list: [] }
      };
    case types.SET_AUDIT_SEARCH_TOTAL_ACTIONS:
      return {
        ...state,
        searchActionsCount: {
          loading: false,
          error: undefined,
          list: action.payload
        }
      };
    case types.SET_AUDIT_RT_URI_NAMES:
      return {
        ...state,
        rTUriNames: action.payload
      };
    default:
      return { ...state };
  }
};
