import React, { Component } from "react";
import IntlMessages from "util/IntlMessages";
import { Row, Card, CardBody, CardTitle, Button, Input } from "reactstrap";
import PerfectScrollbar from "react-perfect-scrollbar";
import { NavLink } from "react-router-dom";
import { connect } from "react-redux";
import { Colxx, Separator } from "components/CustomBootstrap";
import { MetaData } from "../metadata";
import {
  getBoxDocs,
  getBoxMetadata,
  deleteBox,
  sendRequestDeleteBox
} from "redux/actions";
import instance from "util/instances";
import { saveAs } from "file-saver";
import { DOCS_ENDPOINT } from "redux/document/endpoints";
import { downloadDocsZip } from "util/permissions";
import LightSpinner from "components/LightSpinner";
import cs from "classnames";
import HideMetadataButton from "components/HideMetadataButton";
import DeleteResourceModal from "components/DeleteResourceModal";
import { canDeleteBox, canRequestDeleteBox } from "util/permissions";
import { NotificationManager } from "components/ReactNotifications";
import { getTypesOfPages } from "../../../../util/documentTypes";
import { Tooltip } from "@material-ui/core";

class BoxDetailsCmp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      _isMounted: false,
      checkedDocs: [],
      isLoadingDownloadZip: false,
      hideMetaDataCol: false,
      isDeleting: false,
      deletingError: undefined,
      isSendingDeleteRequest: false,
      deleteResourceModal: false
    };

    props.getBoxDocs(props.match.params.id);
    props.getBoxMetadata(props.match.params.id);
  }

  componentDidMount = () => {
    this.setState({ _isMounted: true });
  };

  componentWillUnmount = () => {
    this.setState({ _isMounted: false });
  };

  setIsLoadingDownloadZip = booleanVal => {
    if (this.state._isMounted)
      this.setState({ isLoadingDownloadZip: booleanVal });
  };

  goBack = () => {
    this.props.history.goBack();
  };
  goForward = () => {
    this.props.history.goForward();
  };

  isChecked = itemId => this.state.checkedDocs.indexOf(itemId) > -1;

  isAllChecked = () =>
    this.props.docs
      ? this.state.checkedDocs.length === this.props.docs.length &&
      this.props.docs.length > 0
      : false;

  handleChangeCheckAll = () => {
    if (!this.props.docs) return;
    if (this.state.checkedDocs.length < this.props.docs.length)
      this.setState({ checkedDocs: this.props.docs.map(doc => doc.id) });
    else this.setState({ checkedDocs: [] });
  };

  handleChangeCheck = itemId => {
    let checked = [...this.state.checkedDocs];
    if (checked.indexOf(itemId) > -1)
      checked = checked.filter(it => it !== itemId);
    else checked.push(itemId);
    this.setState({ checkedDocs: checked });
  };

  downloadDocs = () => {
    if (!downloadDocsZip() || this.state.checkedDocs.length < 1) return;
    this.setIsLoadingDownloadZip(true);

    const { boxMetadata } = this.props;

    instance
      .get(`${DOCS_ENDPOINT}${this.props.match.params.id}/downloadzip`, {
        responseType: "blob",
        params: { docIds: this.state.checkedDocs.join(",") }
      })
      .then(res => {
        if (res && res.status === 200) {
          const blob = new Blob([res.data], {
            type: "application/zip"
          });
          saveAs(
            blob,
            `${boxMetadata.project}_${boxMetadata.number}_files.zip`
          );
          const fileName = res.headers.filename;
          instance.delete(`${DOCS_ENDPOINT}file/${fileName}`)
        } else {
          alert("All the pages in the selected document(s) are missing !");
        }
        this.setIsLoadingDownloadZip(false);
      });
  };

  toggleMetaDataCol = () => {
    this.setState({ hideMetaDataCol: !this.state.hideMetaDataCol });
  };

  handleDelete = () => {
    const boxId = this.props.match.params.id;
    this.setDeletingError(undefined);
    this.setIsDeleting(true);
    const successCallback = () => {
      this.setIsDeleting(false);
      this.createNotif("box.delete-success-message");
      this.props.history.push("/app/documents/list/");
    };
    const errorCallback = () => {
      this.setIsDeleting(false);
      this.setDeletingError(true);
    };
    this.props.deleteBox(boxId, successCallback, errorCallback);
  };

  setIsDeleting = boolVal => {
    if (this.state._isMounted) {
      this.setState({ isDeleting: boolVal });
    }
  };

  setDeletingError = val => {
    if (this.state._isMounted) {
      this.setState({ deletingError: val });
    }
  };

  createNotif = message => {
    NotificationManager.success(
      <IntlMessages id={message} />,
      "",
      3000,
      null,
      null,
      "filled"
    );
  };

  handleSendDeleteRequest = () => {
    if (this.state._isMounted) {
      this.setState({ isSendingDeleteRequest: true });
    }
    const { id } = this.props.match.params;
    const successCallback = () => {
      this.createNotif("delete-request-sent");
      if (this.state._isMounted) {
        this.setState({
          isSendingDeleteRequest: false,
          deleteResourceModal: false
        });
      }
    };
    const errorCallback = () => {
      if (this.state._isMounted) {
        this.setState({
          isSendingDeleteRequest: false,
          sendDeleteRequestError: true
        });
      }
    };
    this.props.sendRequestDeleteBox(id, successCallback, errorCallback);
  };

  toggleDeleteResourceModal = () => {
    this.setState({ deleteResourceModal: !this.state.deleteResourceModal });
  };

  render() {
    const {
      match,
      history,
      docs = [],
      loading,
      boxMetadata,
      numberOfPages,
      docLoading
    } = this.props;
    const { isLoadingDownloadZip } = this.state;
    const showDeleteModal = canRequestDeleteBox() || canDeleteBox();
    return (
      <Row>
        <Colxx xxs="12">
          <h1>
            <IntlMessages id="doc.files in" /> :     {`${boxMetadata.project} ${boxMetadata.number}`}
          </h1>

          <h5><IntlMessages id="Total number of pages" /> : {numberOfPages}</h5>

          <Separator className="mb-3" />
          <Button
            outline
            color="primary"
            className="mb-3 mr-2"
            onClick={this.goBack}
          >
            <i className="simple-icon-arrow-left" />
            <IntlMessages id="Back" />
          </Button>
          <HideMetadataButton
            className="mb-3 mr-2"
            onClick={this.toggleMetaDataCol}
            active={this.state.hideMetaDataCol}
          />
          {showDeleteModal && (
            <DeleteResourceModal
              buttonLabel="Delete box"
              idResource={boxMetadata.number}
              title={`Delete box`}
              buttonClassName="mb-3"
              onDelete={this.handleDelete}
              canDelete={canDeleteBox()}
              isDeleting={this.state.isDeleting}
              deletingError={this.state.deletingError}
              onSendDeleteRequest={this.handleSendDeleteRequest}
              isSendingDeleteRequest={this.state.isSendingDeleteRequest}
              toggleDeleteResourceModal={this.toggleDeleteResourceModal}
              deleteResourceModal={this.state.deleteResourceModal}
            />
          )}
          <Button
            outline
            color="primary"
            className="mb-3 mr-2"
            onClick={this.goForward}
          >
            <IntlMessages id="Forward" />
            <i className="simple-icon-arrow-right" />

          </Button>
        </Colxx>
        <Colxx xxs="12">
          {loading || docLoading ? (
            <div className="loading" />
          ) : (
            <Row>
              <Colxx
                xxs="12"
                md="4"
                className={cs("mb-4", {
                  "d-none": this.state.hideMetaDataCol
                })}
              >
                <MetaData boxId={match.params.id} />
              </Colxx>
              <Colxx
                xxs="12"
                md={this.state.hideMetaDataCol ? "12" : "8"}
                className="mb-4"
              >
                <Card className="mb-4">
                  <CardBody>
                    <CardTitle>
                      <h5>
                        <IntlMessages id="Folders" />
                      </h5>
                    </CardTitle>
                    <div className="ems-documents-list">
                      <PerfectScrollbar
                        options={{
                          suppressScrollX: true
                        }}
                      >
                        {downloadDocsZip() && (
                          <div className="d-flex justify-content-between ems-check-all-download-row">
                            <div>
                              <Input
                                type="checkbox"
                                checked={this.isAllChecked()}
                                onChange={() => this.handleChangeCheckAll()}
                              />
                            </div>
                            <div className="mr-3">
                              <Button
                                className="mr-4 ems-button-without-style"
                                size="sm"
                                onClick={this.downloadDocs}
                                disabled={
                                  this.state.checkedDocs.length < 1 ||
                                  isLoadingDownloadZip
                                }
                              >
                                {!isLoadingDownloadZip && (
                                  <i className="simple-icon-cloud-download" />
                                )}
                                {isLoadingDownloadZip && <LightSpinner />}
                              </Button>
                            </div>
                          </div>
                        )}
                        {docs.map((item, index) => {
                          return (
                            <div
                              key={index}
                              className="align-self-center d-flex flex-column flex-xs-row justify-content-between align-items-lg-center border-bottom"
                            >
                              <div className="pr-2 ems-check-list-padding-left">
                                {downloadDocsZip() && (
                                  <Input
                                    type="checkbox"
                                    checked={this.isChecked(item.id)}
                                    onChange={() =>
                                      this.handleChangeCheck(item.id)
                                    }
                                  />
                                )}
                                <NavLink
                                  to={`/app/documents/list/box/${match.params.id}/${item.id}`}
                                >
                                  <p className="font-weight-medium mb-0 ">
                                    {item.number}
                                  </p>
                                </NavLink>
                              </div>
                              <div className="align-self-center pr-3">
                                {item.pagesType.map(type => (
                                  <Tooltip className="mr-1" title={<IntlMessages id={getTypesOfPages(type).label} />}>
                                    {getTypesOfPages(type).icon}
                                  </Tooltip>)
                                )}
                                <a
                                  href=" "
                                  className="mr-4 ml-2"
                                  onClick={e => {
                                    e.preventDefault();
                                    history.push(
                                      `/app/documents/list/box/${match.params.id}/${item.id}`
                                    );
                                  }}
                                >
                                  <i className="simple-icon-eye" />
                                </a>
                              </div>
                            </div>
                          );
                        })}
                      </PerfectScrollbar>
                    </div>
                  </CardBody>
                </Card>
              </Colxx>
            </Row>
          )}
        </Colxx>
      </Row>
    );
  }
}

const mapStateToProps = state => {
  const { appData } = state;
  return {
    docs: appData.boxs.docs.documents,
    numberOfPages: appData.boxs.docs.numberOfPages,
    loading: appData.boxs.loading,
    docLoading: appData.boxs.docs.loading,
    boxMetadata: appData.boxs.metadata
  };
};

export const BoxDetails = connect(
  mapStateToProps,
  { getBoxDocs, getBoxMetadata, deleteBox, sendRequestDeleteBox }
)(BoxDetailsCmp);
