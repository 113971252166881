import React from "react";
import { NavLink } from "react-router-dom";
import Highlighter from "react-highlight-words";
import moment from "moment";
import { AUDIT_DATETIME_REGEXP } from "constants/defaultValues";
import IntlMessages from "../../../util/IntlMessages";
import { format } from "date-fns-tz";


export const getSorts = () => [
  {
    id: "asc",
    name: "asc",
  },
  {
    id: "desc",
    name: "desc",
  },

];
export const formatDate = (value) => {
  if(value){

    const date = new Date(value);

    // Format the date as "dd/mm/yyyy"
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Note: Months are zero-based, so we add 1.
    const year = date.getFullYear();

    const formattedDate = `${day}/${month}/${year}`;

    // Retournez la date formatée
    return formattedDate;
  }
  return value;
}
export const getColumns = (search = []) => [
  {
    name: "page",
    label: "Page",
    options: {

      customBodyRender: value => value.url ? (
        <NavLink to={"/app/documents/list/box/" + value.url}>
          {highlightText(value.name.substring(0, value.name.length - 4), search)}
        </NavLink>
      ) : highlightText(value, search)
    }
  },
  {
    name: "date",
    label: "Date",
    options: {
      customBodyRender: value => highlightText(formatDate(value), search)
    }
  },

  {
    name: "from",
    label: "From",
    options: {
      customBodyRender: value => highlightText(value, search)
    }
  },
  {
    name: "to",
    label: "To",
    options: {
      customBodyRender: value => highlightText(value, search)
    }
  },
  {
    name: "cc",
    label: "Cc",
    options: {
      customBodyRender: value => highlightText(value, search)
    }
  },
  {
    name: "namesOfEntities",
    label: "Names of entities",
    options: {
      customBodyRender: value => highlightText(value, search)
    }
  },
  {
    name: "namesOfPeople",
    label: "Names of people",
    options: {
      customBodyRender: value => highlightText(value, search)
    }
  },
  {
    name: "namesOfPlaces",
    label: "Names of places",
    options: {
      customBodyRender: value => highlightText(value, search)
    }
  },
  {
    name: "signatureBlock",
    label: "Tags",
    options: {
      customBodyRender: value => highlightText(value, search)
    }
  },

  {
    name: "hijriDate",
    label: "Hijri Date",
    options: {
      customBodyRender: value => highlightText(value, search)
    }
  },
  {
    name: "title",
    label: "Title",
    options: {
      customBodyRender: value => highlightText(value, search)
    }
  },
  {
    name: "phoneOrEmail",
    label: "PhoneOrEmail",
    options: {
      customBodyRender: value => highlightText(value, search)
    }
  },
  {
    name: "description",
    label: "Description",
    options: {
      customBodyRender: value => highlightText(value, search)
    }
  },
  {
    name: "documentReferenceCommunication",
    label: "DocumentReferenceCommunication",
    options: {
      customBodyRender: value => highlightText(value, search)
    }
  },
  {
    name: "documentReferenceMentioned",
    label: "DocumentReferenceMentioned",
    options: {
      customBodyRender: value => highlightText(value, search)
    }
  },
  {
    name: "duplicateDocNo",
    label: "DuplicateDocNo",
    options: {
      customBodyRender: value => highlightText(value, search)
    }
  },
  {
    name: "fileName",
    label: "Original Filename",
    options: {
      customBodyRender: value => highlightText(value, search)
    }
  },
  {
    name: "md5Hash",
    label: "MD5 Hash Value",
    options: {
      customBodyRender: value => highlightText(value, search)
    }
  },
  {
    name: "sha1hash",
    label: "SHA1 Hash Value",
    options: {
      customBodyRender: value => highlightText(value, search)
    }
  },
  {
    name: "previouslyLabelledUnder",
    label: "Previously labelled under",
    options: {
      customBodyRender: value => highlightText(value, search)
    }
  },
  {
    name: "boxMetadata",
    label: "Box Metadata",
    options: {
      customBodyRender: value => renderObject(value, search)
    }
  },
  {
    name: "docMetadata",
    label: "Folder Metadata",
    options: {
      customBodyRender: value => renderObject(value, search)
    }
  },
  {
    name: "ocr",
    label: "OCR",
    options: {
      customBodyRender: value => highlightText(value, search)
    }
  },
  {
    name: "ocrEn",
    label: "OCR English",
    options: {
      customBodyRender: value => highlightText(value, search)
    }
  },
  {
    name: "createdAt",
    label: "Upload Date",
    options: {
      customBodyRender: value => highlightText(formatDate(value), search)
    }
  },
];
export const getColumnsCSV = (search = []) => [
  {
    name: "pageNumber",
    label: <IntlMessages id="name" />,

  },
  {
    name: "date",
    label: <IntlMessages id="Date" />,
    options: {
      customBodyRender: value => highlightText(formatDate(value), search)
    }
  },
  {
    name: "createdAt",
    label: <IntlMessages id="Upload Date" />,
    options: {
      customBodyRender: value => highlightText(formatDate(value), search)
    }
  },
  {
    name: "from",
    label: <IntlMessages id="From" />,
    options: {
      customBodyRender: value => highlightText(value, search)
    }
  },
  {
    name: "to",
    label: <IntlMessages id="To" />,
    options: {
      customBodyRender: value => highlightText(value, search)
    }
  },
  {
    name: "cc",
    label: <IntlMessages id="Cc" />,
    options: {
      customBodyRender: value => highlightText(value, search)
    }
  },
  {
    name: "namesOfEntities",
    label: <IntlMessages id="Names of entities" />,
    options: {
      customBodyRender: value => highlightText(value, search)
    }
  },
  {
    name: "namesOfPeople",
    label: <IntlMessages id="Names of people" />,
    options: {
      customBodyRender: value => highlightText(value, search)
    }
  },
  {
    name: "namesOfPlaces",
    label: <IntlMessages id="Names of places" />,
    options: {
      customBodyRender: value => highlightText(value, search)
    }
  },
  {
    name: "signatureBlock",
    label: <IntlMessages id="Tags" />,
    options: {
      customBodyRender: value => highlightText(value, search)
    }
  },
  {
    name: "hijriDate",
    label: <IntlMessages id="Hijri Date" />,
    options: {
      customBodyRender: value => highlightText(value, search)
    }
  },
  {
    name: "title",
    label: <IntlMessages id="Title" />,
    options: {
      customBodyRender: value => highlightText(value, search)
    }
  },
  {
    name: "phoneOrEmail",
    label: <IntlMessages id="PhoneOrEmail" />,
    options: {
      customBodyRender: value => highlightText(value, search)
    }
  },
  {
    name: "description",
    label: <IntlMessages id="Description" />,
    options: {
      customBodyRender: value => highlightText(value, search)
    }
  },
  {
    name: "documentReferenceCommunication",
    label: <IntlMessages id="DocumentReferenceCommunication" />,
    options: {
      customBodyRender: value => highlightText(value, search)
    }
  },
  {
    name: "documentReferenceMentioned",
    label: <IntlMessages id="DocumentReferenceMentioned" />,
    options: {
      customBodyRender: value => highlightText(value, search)
    }
  },
  {
    name: "duplicateDocNo",
    label: <IntlMessages id="DuplicateDocNo" />,
    options: {
      customBodyRender: value => highlightText(value, search)
    }
  },
  {
    name: "fileName",
    label: <IntlMessages id="Original Filename" />,
    options: {
      customBodyRender: value => highlightText(value, search)
    }
  },
  {
    name: "md5Hash",
    label: <IntlMessages id="MD5 Hash Value" />,
    options: {
      customBodyRender: value => highlightText(value, search)
    }
  },
  {
    name: "sha1hash",
    label: <IntlMessages id="SHA1 Hash Value" />,
    options: {
      customBodyRender: value => highlightText(value, search)
    }
  },
  {
    name: "previouslyLabelledUnder",
    label: <IntlMessages id="Previously labelled under" />,
    options: {
      customBodyRender: value => highlightText(value, search)
    }
  },
  {
    name: "boxMetadata",
    label: <IntlMessages id="Box Metadata" />,
    options: {
      customBodyRender: value => renderObject(value, search)
    }
  },
  {
    name: "docMetadata",
    label: <IntlMessages id="Folder Metadata" />,
    options: {
      customBodyRender: value => renderObject(value, search)
    }
  },
  {
    name: "ocr",
    label: <IntlMessages id="OCR" />,
    options: {
      customBodyRender: value => highlightText(value, search)
    }
  },
  {
    name: "ocrEn",
    label: <IntlMessages id="OCR English" />,
    options: {
      customBodyRender: value => highlightText(value, search)
    }
  }
];

export const options = {
  filterType: "checkbox",
  pagination: false,
  responsive: "scroll",
  filter: false,
  search: false,
  sort: false,
  selectableRows: "none"
};

const renderObject = (value, search) =>
  value.map((item, index) => (
    <div key={index}>
      <b>{item.label} : </b>
      {highlightText(item.value, search)}
    </div>
  ));

export class ParenthighlightText extends React.Component {
  state = {
    extrait: true
  };

  componentDidUpdate() {
    let { text, search } = this.props;
    let { extrait } = this.state;
    this._highlightText(text, search, extrait);
  }

  fulltext = () => {
    this.setState({
      extrait: false
    });
  };
  _highlightText = (text, search, extrait) => {
    let cont;
    if (text && text.length > 100) {
      let readMore = text.substr(1, 99);

      if (extrait) {
        cont = (
          <React.Fragment>
            <Highlighter searchWords={search} textToHighlight={readMore} autoEscape={true} />
            <div className="readMore" onClick={() => this.fulltext()}>
              read more...
            </div>
          </React.Fragment>
        );
      }
      if (!extrait) {
        cont = <Highlighter searchWords={search} textToHighlight={text} autoEscape={true} />;
      }
      return cont;
    }
    if (text && text.length > 0)
      return <Highlighter searchWords={search} textToHighlight={text} autoEscape={true} />;

    return null;
  };
  render() {
    let { text, search } = this.props;
    let { extrait } = this.state;
    return this._highlightText(text, search, extrait);
  }
}

const highlightText = (text, search) => (
  <ParenthighlightText text={text} search={search} />
);

export const convertToLocalDateTime = value => {
  const valArray = value.match(AUDIT_DATETIME_REGEXP);

  if (!valArray) return null;

  return moment(valArray[1] + valArray[2]).format(
    "YYYY-MM-DD HH:mm:ss [(GMT]Z[)]"
  );
};