import React from "react";
import Select from "react-select";
import cs from "classnames";
import { isArray, isEmpty} from "lodash";

import CustomSelectInput from "components/CustomSelectInput";
import { FormFields } from "components/FormFields";
import IntlMessages from "util/IntlMessages";
import { IsJsonString } from "util/jsonString";

const colourStyles = {
  control: styles => ({ ...styles, backgroundColor: "white" }),
  multiValue: styles => {
    return {
      ...styles,
      color: "#CCCCCC !important",
      backgroundColor: "#FFFFFF !important",
      borderColor: "#576a3d !important"
    };
  },
  multiValueLabel: styles => ({
    ...styles,
    color: "#576a3d !important",
    backgroundColor: "#FFFFFF !important"
  })
};

export const renderMultiselect = props => {
  const {
    input,
    meta: { touched, error },
    className,
    placeholder,
    ...rest
  } = props;
  return (
    <FormFields {...props}>
      <Select
        onChange={input.onChange}
        components={{ Input: CustomSelectInput }}
        className={cs("react-select", { "is-invalid": touched && error })}
        classNamePrefix="react-select"
        isMulti
        styles={colourStyles}
        value={
            IsJsonString(input.value) ? 
            JSON.parse(input.value) : 
            !isArray(input.value) && !isEmpty(input.value)? [{id: input.value, name: input.value}] : input.value }
        placeholder={
          <IntlMessages
            id={placeholder ? placeholder : "Select"}
            tagname="span"
          />
        }
        {...rest}
      />
    </FormFields>
  );
};
