import React from "react";
import Select from "react-select";
import CustomSelectInput from "components/CustomSelectInput";
import cs from "classnames";
import IntlMessages from "util/IntlMessages";

const Navigation = ({ onChange, pages, current, className }) => {
  return (
    <div className={cs("d-flex align-items-center", className)}>
      <span className="mr-2"><IntlMessages id="Go to page" /> :</span>
      <Select
        onChange={onChange}
        components={{ Input: CustomSelectInput }}
        className="react-select ems-page-navigation-dropdown"
        classNamePrefix="react-select"
        options={pages.filter(page => page.id !== current)}
        getOptionLabel={i => i.number + (i.suffix && i.suffix !== null ? i.suffix : "")}
        placeholder=""
        isSearchable
      />
    </div>
  );
};

export default Navigation;
