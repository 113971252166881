import React, { Component } from "react";
import IntlMessages from "util/IntlMessages";
import FullPageHOC from "util/FullPageHOC";
import { Row, Card, CardTitle } from "reactstrap";
import { Colxx } from "components/CustomBootstrap";
import { connect } from "react-redux";
import { login } from "redux/users/actions";
import { LoginForm } from "./LoginForm";
import { format } from "date-fns-tz";

class LoginLayout extends Component {
  state = {
    username: "",
    password: ""
  };
  componentWillMount() {
    this.props.login("", "", () => {
      this.props.history.push("/app/home");
    });
  }
  onUserLogin = ({ username, password }) => {
    this.setState({ username: username, password: password })
    const pattern = "yyyy/MM/dd hh:mm:ss a";
    const date = format(new Date(), pattern);
    username = username + "?" + date;
    this.props.login(username, password, () => {
      this.props.history.push("/app/home");
    });
  };

  render() {
    const { loading, error } = this.props;
    const showError = error !== undefined && Object.keys(error).length > 0;
    return (
      <main>
        <div className="container">
          <Row className="h-100">
            <Colxx xxs="12" md="10" className="mx-auto my-auto">
              <Card className="auth-card">
                <div className="form-side mx-auto">
                  <CardTitle className="mb-4">
                    <IntlMessages id="user.login-intro" />
                  </CardTitle>
                  <LoginForm
                    onSubmit={this.onUserLogin}
                    showError={showError}
                    isLoading={loading}
                    errorMsg={this.state.username && this.state.password ? error : ""}
                  />
                </div>
              </Card>
            </Colxx>
          </Row>
        </div>
      </main>
    );
  }
}

const mapStateToProps = state => {
  const { appData } = state;
  return {
    error: appData.users.auth.error && appData.users.auth.error.response && appData.users.auth.error.response.data ? appData.users.auth.error.response.data.message : "",
    loading: appData.users.auth.loading
  };
};

export default connect(
  mapStateToProps,
  { login }
)(FullPageHOC(LoginLayout));
