import React, { Fragment } from "react";
import { connect } from "react-redux";
import { Field, reduxForm } from "redux-form";
import { Prompt } from "react-router-dom";
import IntlMessages from "util/IntlMessages";
import StateButton from "components/StateButton";
import { ValidatedTags } from "components/FormFields/Tags";
import { renderDelay } from "./renderDelay";
import DatePicker from "components/FormFields/DatePicker";
import HijriDatePicker from "components/FormFields/HijriDatePicker";
import { ValidatedTextarea } from "components/FormFields/Textarea";
import { maxLength255 } from "util/validationRules";
import { IsJsonString } from "util/jsonString";
import { ValidatedSelected } from "components/FormFields/Select";
import {
  FormGroup,
  Label,
  Row
} from "reactstrap";
import { optionsByInstance } from "../../../../util/instanceTypesHelper";
import { Colxx } from "components/CustomBootstrap";
import { Button } from "reactstrap";
import { renderMultiselect } from "components/FormFields/MultiSelect";
import { getLetters } from "../../../../util/stringFunctions";
import { BOX_A_LETTER, BOX_E_LETTER, BOX_OS_LETTER, currentLanguage } from "../../../../util/constants";
import { YesNoField } from "../../../../components/FormFields/YesNoField";

export const getLanguages = () => [
  {
    id: "Arabic",
    name: "Arabic",
  },
  {
    id: "English",
    name: "English",
  },
  {
    id: "Russian",
    name: "Russian",
  },
  {
    id: "French",
    name: "French",
  },
  {
    id: "Other (editable)",
    name: "Other",
  },

];
export const getFormats = () => [
  {
    id: "Handwritten",
    name: "Handwritten",
  },
  {
    id: "Handwritten/Typed",
    name: "Handwritten/Typed",
  },
  {
    id: "Typed",
    name: "Typed",
  },
  {
    id: "Illegible",
    name: "Illegible",
  },
  {
    id: "Blank Page",
    name: "Blank Page",
  },
  {
    id: "Photograph/Image",
    name: "Photograph/Image",
  },
  {
    id: "Map",
    name: "Map",
  },
];

export const getSubjects = () => [
  {
    id: "Accounting",
    name: "Accounting",
  },
  {
    id: "Agriculture",
    name: "Agriculture",
  },
  {
    id: "Birth and Death Administration",
    name: "Birth and Death Administration",
  },
  {
    id: "Communication Tools",
    name: "Communication Tools",
  },
  {
    id: "Dawa",
    name: "Dawa",
  },
  {
    id: "Detention and Punishment",
    name: "Detention and Punishment",
  },
  {
    id: "Education",
    name: "Education",
  },
  {
    id: "Establishment of Innocence",
    name: "Establishment of Innocence",
  },
  {
    id: "ID",
    name: "ID",
  },
  {
    id: "Judicial Administration",
    name: "Judicial Administration",
  }, {
    id: "Martyrs or Missing",
    name: "Martyrs or Missing",
  }, {
    id: "Media and Publications",
    name: "Media and Publications",
  }, {
    id: "Medical",
    name: "Medical",
  },
  {
    id: "Member Administration",
    name: "Member Administration",
  },
  {
    id: "Personal Relationships Administration",
    name: "Personal Relationships Administration",
  },
  {
    id: "Personnel List",
    name: "Personnel List",
  },
  {
    id: "Real Estate and Property",
    name: "Real Estate and Property",
  },
  {
    id: "Reporting",
    name: "Reporting",
  },
  {
    id: "Roles and Responsibilities(Organisational Structure)",
    name: "Roles and Responsibilities(Organisational Structure)",
  },
  {
    id: "Security and Policing",
    name: "Security and Policing",
  },
  {
    id: "Services",
    name: "Services",
  },
  {
    id: "Wide Dissemination",
    name: "Wide Dissemination",
  },
];


const pageCmp = (props) => {

  const { isEdited, handleSubmit, dirty, saveState } = props;
  const format = props.initialValues && props.initialValues.format ? { id: props.initialValues.format, name: props.initialValues.format } : "";
  const optionsToDisplay = optionsByInstance(props.locale)
  const clearHigri = () => {
    let initialValues = props.initialValues;
    initialValues.hijriDate = null;
    props.initialize(initialValues)
  }

  const isBoxEOrOS = props.initialValues.letter === BOX_E_LETTER || props.initialValues.letter === BOX_OS_LETTER;
  const isBoxA = props.initialValues.letter === BOX_A_LETTER
  return (
    <Fragment>
      <Prompt
        when={!props.submitSucceeded && props.dirty}
        message={() => `êtes vous sur de vouloir quitter sans modifier ?`}
      />
      <form
        onSubmit={handleSubmit(values => {
          props.onSubmit(values);
          props.initialize(values);
        })}
      >
        {!isEdited && dirty && (
          <span className="d-block mb-2 text-info">
            <IntlMessages id="Metadata not saved" />
          </span>
        )
        }

        {
          isBoxEOrOS && (<>
            <p className={`text-muted ${currentLanguage === 'ar' ? "text-small-ar" : "text-small"} mb-0`}><IntlMessages id="Title" /></p>
            <Field
              className="mb-3"
              inputClassName="pl-0 pr-0 form-control"
              name="title"
              component={ValidatedTextarea}
              type="text"
              disabled={!isEdited}
              validate={maxLength255}
            />
            <p className={`text-muted ${currentLanguage === 'ar' ? "text-small-ar" : "text-small"} mb-0`}><IntlMessages id="Original Filename" /></p>
            <Field
              className="mb-3"
              inputClassName="pl-0 pr-0 form-control"
              name="fileName"
              component={ValidatedTextarea}
              type="text"
              disabled={!isEdited}
              validate={maxLength255}
            />
            <p className={`text-muted ${currentLanguage === 'ar' ? "text-small-ar" : "text-small"} mb-0`}><IntlMessages id="SHA1 Hash Value" /></p>
            <Field
              className="mb-3"
              inputClassName="pl-0 pr-0 form-control"
              name="sha1hash"
              component={ValidatedTextarea}
              type="text"
              disabled={!isEdited}
              validate={maxLength255}
            />
            <p className={`text-muted ${currentLanguage === 'ar' ? "text-small-ar" : "text-small"} mb-0`}><IntlMessages id="MD5 Hash Value" /></p>
            <Field
              className="mb-3"
              inputClassName="pl-0 pr-0 form-control"
              name="md5Hash"
              component={ValidatedTextarea}
              type="text"
              disabled={!isEdited}
              validate={maxLength255}
            /></>)
        }

        <p className={`text-muted ${currentLanguage === 'ar' ? "text-small-ar" : "text-small"} mb-0`}><IntlMessages id="From" /></p>
        <Field
          className="mb-3"
          inputClassName="pl-0 pr-0 form-control"
          name="from"
          component={ValidatedTextarea}
          type="text"
          disabled={!isEdited}
          validate={maxLength255}
        />

        <p className={`text-muted ${currentLanguage === 'ar' ? "text-small-ar" : "text-small"} mb-0`}><IntlMessages id="To" /></p>
        <Field
          className="mb-3"
          inputClassName="pl-0 pr-0 form-control"
          name="to"
          component={ValidatedTextarea}
          type="text"
          disabled={!isEdited}
          validate={maxLength255}
        />

        <p className={`text-muted ${currentLanguage === 'ar' ? "text-small-ar" : "text-small"} mb-0`}><IntlMessages id="CC" /></p>
        <Field
          className="mb-3"
          inputClassName="pl-0 pr-0 form-control"
          name="cc"
          component={ValidatedTextarea}
          type="text"
          disabled={!isEdited}
          validate={maxLength255}
        />
        <p className={`text-muted ${currentLanguage === 'ar' ? "text-small-ar" : "text-small"} mb-0`}><IntlMessages id="Date" /></p>

        <Field
          className="mb-3"
          inputClassName="pl-0 pr-0 form-control"
          name="date"
          component={DatePicker}
          type="datetime-local"
          disabled={!isEdited}
          options={{
            dateFormat: "dd/MM/yyyy"
          }}
        />
        
          <p className={`text-muted ${currentLanguage === 'ar' ? "text-small-ar" : "text-small"} mb-0`}><IntlMessages id="Hijri Date" /></p>
          <div style={{ "padding-right": "0", position: "relative" }}>
            <Field
              className="mb-3"
              inputClassName="pl-0 pr-0 form-control"
              name="hijriDate"
              component={HijriDatePicker}
              type="datetime-local"
              disabled={!isEdited}
              options={{
                dateFormat: "dd/MM/yyyy"
              }}
            />
            {isEdited && <Button
              color="transparent"

              style={{
                backgroundColor: "transparent",
                paddingLeft: "0px",
                paddingRight: "0px",
                paddingBottom: "0px",
                paddingTop: "0px",
                position: "absolute",
                top: 10,
                right: 5
              }}
              onClick={(values) => clearHigri(values)}
            > <span className="simple-icon-close" style={{
              color: "#8f8f8f",
              fontSize: "large"
            }} /></Button>}
          </div>



        <p className={`text-muted ${currentLanguage === 'ar' ? "text-small-ar" : "text-small"} mb-0`}><IntlMessages id="PhoneOrEmail" /></p>
        <Field
          className="mb-3"
          inputClassName="pl-0 pr-0 form-control"
          name="phoneOrEmail"
          component={ValidatedTextarea}
          type="text"
          disabled={!isEdited}
          validate={maxLength255}
        />
        {optionsToDisplay.communicationNumberField && <>
          <p className={`text-muted ${currentLanguage === 'ar' ? "text-small-ar" : "text-small"} mb-0`}><IntlMessages id="DocumentReferenceCommunication" /></p>
          <Field
            className="mb-3"
            inputClassName="pl-0 pr-0 form-control"
            name="documentReferenceCommunication"
            component={ValidatedTextarea}
            type="text"
            disabled={!isEdited}
            validate={maxLength255}
          />
        </>
        }
        {optionsToDisplay.referenceNumberField && <>
          <p className={`text-muted ${currentLanguage === 'ar' ? "text-small-ar" : "text-small"} mb-0`}><IntlMessages id="DocumentReferenceMentioned" /></p>
          <Field
            className="mb-3"
            inputClassName="pl-0 pr-0 form-control"
            name="documentReferenceMentioned"
            component={ValidatedTextarea}
            type="text"
            disabled={!isEdited}
            validate={maxLength255}
          />
        </>
        }
        <p className={`text-muted ${currentLanguage === 'ar' ? "text-small-ar" : "text-small"} mb-0`}><IntlMessages id={"Names of people"} /></p>
        <Field
          className="mb-3"
          inputClassName="pl-0 pr-0 form-control"
          name="namesOfPeople"
          component={ValidatedTextarea}
          type="text"
          disabled={!isEdited}
        />

        <p className={`text-muted ${currentLanguage === 'ar' ? "text-small-ar" : "text-small"} mb-0`}><IntlMessages id={"Names of places"} /></p>
        <Field
          className="mb-3"
          inputClassName="pl-0 pr-0 form-control"
          name="namesOfPlaces"
          component={ValidatedTextarea}
          type="text"
          disabled={!isEdited}
        />

        <p className={`text-muted ${currentLanguage === 'ar' ? "text-small-ar" : "text-small"} mb-0`}><IntlMessages id={"Names of entities"} /></p>
        <Field
          className="mb-3"
          inputClassName="pl-0 pr-0 form-control"
          name="namesOfEntities"
          component={ValidatedTextarea}
          type="text"
          disabled={!isEdited}
        />
        <p className={`text-muted ${currentLanguage === 'ar' ? "text-small-ar" : "text-small"} mb-0`}><IntlMessages id="Languages" /></p>

        <Field
          isDisabled={!isEdited}
          className="mb-3"
          name="language"
          component={renderMultiselect}
          labelClassName="mb-0"
          getOptionLabel={i => i.name}
          getOptionValue={i => i.id}
          options={getLanguages()}
        />
        {optionsToDisplay.formatField && <>
          <p className={`text-muted ${currentLanguage === 'ar' ? "text-small-ar" : "text-small"} mb-0`}><IntlMessages id="Format" /></p>
          <Field
            disabled={!isEdited}
            className="mb-3"
            name="format"
            component={ValidatedSelected}
            labelClassName="mb-0"
            getOptionLabel={i => i.name}
            getOptionValue={i => i.id}
            options={getFormats()}
            defaultValue={format}
            isClearable
          />
        </>
        }
        {optionsToDisplay.subjectField && <>
          <p className={`text-muted ${currentLanguage === 'ar' ? "text-small-ar" : "text-small"} mb-0`}><IntlMessages id="Subject" /></p>
          <Field
            isDisabled={!isEdited}
            className="mb-3"
            name="subject"
            component={renderMultiselect}
            labelClassName="mb-0"
            getOptionLabel={i => i.id}
            getOptionValue={i => i.id}
            options={getSubjects()}
            isClearable
          />
        </>
        }

        <p className={`text-muted ${currentLanguage === 'ar' ? "text-small-ar" : "text-small"} mb-0`}><IntlMessages id="Description" /></p>
        <Field
          className="mb-3"
          inputClassName="pl-0 pr-0 form-control"
          name="description"
          component={ValidatedTextarea}
          type="text"
          disabled={!isEdited}
          validate={maxLength255}
        />
        {optionsToDisplay.referenceInBriefField && <><p className={`text-muted ${currentLanguage === 'ar' ? "text-small-ar" : "text-small"} mb-0`}><IntlMessages id="ReferencedInBrief" /></p>
          <Field
            className="mb-3"
            inputClassName="pl-0 pr-0 form-control"
            name="referencedInBrief"
            component={ValidatedTextarea}
            type="text"
            disabled={!isEdited}
            validate={maxLength255}
          />
        </>
        }
        {
          !isBoxA && 
          <>
          <p className={`text-muted ${currentLanguage === 'ar' ? "text-small-ar" : "text-small"} mb-0`}><IntlMessages id="Previously labelled under" /></p>
          <Field
            className="mb-3"
            inputClassName="pl-0 pr-0 form-control"
            name="previouslyLabelledUnder"
            component={ValidatedTextarea}
            type="text"
            disabled={!isEdited}
            validate={maxLength255}
          />
        </>
        }
        {optionsToDisplay.duplicateDocNumberField && <>
          <p className={`text-muted ${currentLanguage === 'ar' ? "text-small-ar" : "text-small"} mb-0`}><IntlMessages id="DuplicateDocNo" /></p>
          <Field
            className="mb-3"
            inputClassName="pl-0 pr-0 form-control"
            name="duplicateDocNo"
            component={ValidatedTextarea}
            type="text"
            disabled={!isEdited}
            validate={maxLength255}
          />
        </>
        }
        <p className={`text-muted ${currentLanguage === 'ar' ? "text-small-ar" : "text-small"} mb-0`}><IntlMessages id={"Signature block"} /></p>
        <Field
          className="mb-3"
          inputClassName="pl-0 pr-0"
          name="signatureBlock"
          component={ValidatedTags}
          type="text"
          disabled={!isEdited}
        />
        <p className={`text-muted ${localStorage.getItem("currentLanguage") === 'ar' ? "text-small-ar" : "text-small"} mb-0`}><IntlMessages id="Relevant" /></p>
        <FormGroup className="mb-2 mt-1">
          <Label>
            <Field
              name="relevant"
              component={YesNoField}
              label="Yes/No"
              className="mr-1"
              disabled={!isEdited}
            />
          </Label>
        </FormGroup>
        {isEdited && (
          <Fragment>
            {saveState.error && (
              <div className="d-block text-danger">
                <IntlMessages id={saveState.error} />
              </div>
            )}
            <StateButton
              id="successButton"
              color="primary"
              type="submit"
              isLoading={saveState.loading}
            >
              <IntlMessages id="Save" />
            </StateButton>
          </Fragment>
        )}
      </form>
    </Fragment>)

}

const mapStateToProps = ({ appData, settings }) => {
  const languages = (appData.pages.metadata.pageMetadata && appData.pages.metadata.pageMetadata.language)
    && (IsJsonString(appData.pages.metadata.pageMetadata.language) ?
      JSON.parse(appData.pages.metadata.pageMetadata.language) : appData.pages.metadata.pageMetadata.language)

  const subjects = (appData.pages.metadata.pageMetadata && appData.pages.metadata.pageMetadata.subject)
    && (IsJsonString(appData.pages.metadata.pageMetadata.subject) ?
      JSON.parse(appData.pages.metadata.pageMetadata.subject) : appData.pages.metadata.pageMetadata.subject);

  const box = (appData.pages.metadata.documents && appData.pages.metadata.documents.box)
    && (IsJsonString(appData.pages.metadata.documents.box) ?
      JSON.parse(appData.pages.metadata.documents.box) : appData.pages.metadata.documents.box);

  const letter = box ? getLetters(box) : null;
  const { locale } = settings;
  return {
    initialValues: {
      ...appData.pages.metadata.pageMetadata,
      language: languages,
      subject: subjects,
      letter: letter
    },
    locale,

  };
};

export const Page = connect(mapStateToProps)(
  renderDelay(
    reduxForm({
      form: "PageMetadata",
      enableReinitialize: true
    })(pageCmp)
  )
);
