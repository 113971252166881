import get from "lodash/get";
import enMessages from "lang/locales/en_US";
import { formatDate } from "../../pages/apps/search/dataTableOptions";

const boxMetadata = [
  {
    label: "Date seized",
    name: "boxDateSeized"
  },
  {
    label: "Location seized",
    name: "boxLocationSeized"
  },
  {
    label: "Native Date seized",
    name: "boxNativeDateSeized"
  },
  {
    label: "Time seized",
    name: "boxTimeSeized"
  },
  {
    label: "Seized by",
    name: "boxSeizedBy"
  },
  {
    label: "Notes",
    name: "boxNotes"
  },
  {
    label: "Digital forensics ID",
    name: "boxDigitalForensicsID"
  }
];
const docMetadata = [
  {
    label: "From",
    name: "docFrom"
  },
  {
    label: "To",
    name: "docTo"
  },
  {
    label: "CC",
    name: "docCc"
  },
  {
    label: "Summary",
    name: "docSummary"
  }
];

const videoMetadata = [
  {
    label: "Original Url",
    name: "videoOriginalUrl"
  },
  {
    label: "Summary",
    name: "videoSummary"
  },
  {
    label: "Upload Date",
    name: "videoUploadDate"
  },
  {
    label: "Username",
    name: "videoUsername"
  },
  {
    label: "Youtube Id",
    name: "videoYoutubeId"
  },
  {
    label: "Youtube Title",
    name: "videoYoutubeTitle"
  }
];

export const formatSearchData = data => {
  const { result } = data;
  if (!result) {
    return data;
  }
  result.forEach(row => {
    const rowNames = row.payload.split("/");
    row.page = {
      url:
        row.payload.indexOf(".mp4") > -1
          ? `${row.boxId}/${row.documentsId}`
          : `${row.boxId}/${row.documentsId}/${row.id}`,
      name:
        row.payload.indexOf(".mp4") > -1
          ? `${rowNames[rowNames.length - 1]} (video)`
          : rowNames[rowNames.length - 1]
    };
    row.boxMetadata = [];
    boxMetadata.forEach(item => {
      if (row[item.name]) {
        const value = item.name ==="boxDateSeized" ? formatDate(row[item.name]) :row[item.name];
        row.boxMetadata.push({
          value: value,
          label: item.label
        });
      }
    });

    row.videoMetadata = [];
    videoMetadata.forEach(item => {
      if (row[item.name]) {
        row.videoMetadata.push({
          value: row[item.name],
          label: item.label
        });
      }
    });
    
    row.docMetadata = [];
    docMetadata.forEach(item => {
      if (row[item.name]) {
        row.docMetadata.push({
          value: row[item.name],
          label: item.label
        });
      }
    });
  });

  return data;
};

export const formatAudirSearchData = data => {
  const { result } = data;
  if (!result) {
    return data;
  }

  result.forEach(row => {
    row.requestDate = row.requestDate + " (" + row.timeZone + ")";
    if (get(row, "auditDetail.responseBody", "").indexOf("errorCode") > -1) {
      try {
        const resBody = JSON.parse(row.auditDetail.responseBody);
        if (row.content.body)
          row.content.body = `${row.content.body} (${enMessages[resBody.message]
              ? enMessages[resBody.message]
              : resBody.message
            })`;
      } catch (err) {
        // Do nothing
      }
    }
  });

  return data;
};
