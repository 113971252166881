import { all, fork, put, takeEvery, call } from "redux-saga/effects";
import get from "lodash/get";
import * as types from "redux/actionTypes";
import {
  getDocMetadataApi,
  getDocPagesApi,
  getDocUriApi,
  updateDocMetadata,
  updateVideoMetadata,
  deleteDocumentApi,
  sendRequestDeleteDocumentApi
} from "./apis";
import * as resourceTypes from "constants/resourceTypes";
import { downloadPage } from "redux/actions";

function* getDocMetadataSaga({ payload }) {
  const { id } = payload;
  try {
    const response = yield call(getDocMetadataApi, id);
    if (response.status !== 200) {
      throw new Error(response.data.message);
    }
    yield put({ type: types.SET_DOCS_METADATA, payload: response.data });
    yield put({ type: types.SET_DOCS_PAGES_URI, payload: null });
    if (get(response, "data.documentType") === resourceTypes.VIDEO) {
      yield put(downloadPage(response.data.id, resourceTypes.VIDEO));
    }
  } catch (error) {
    yield put({ type: types.ERROR_GETTING_DOCS_META, payload: error });
  }
}

function* getDocPagesSaga({ payload }) {
  const { id } = payload;
  try {
    const response = yield call(getDocPagesApi, id);
    if (response.status !== 200) {
      throw new Error(response.data.message);
    }
    yield put({ type: types.SET_DOCS_URI, payload: response.data });
  } catch (error) {
    yield put({ type: types.ERROR_GETTING_DOCS_URI, payload: error });
  }
}

function* getDocUriSaga({ payload }) {
  const { id } = payload;
  try {
    const response = yield call(getDocUriApi, id);
    if (response.status !== 200) {
      throw new Error(response.data.message);
    }
    yield put({ type: types.SET_DOCS_PAGES_URI, payload: response.data });
  } catch (error) {
    yield put({ type: types.ERROR_GETTING_DOCS_PAGES, payload: error });
  }
}


function* updateDocMetadataSaga({ payload }) {
  const { id, isVideo, data, success, error } = payload;
  try {
    yield call(isVideo ? updateVideoMetadata : updateDocMetadata, id, data);
    if (success) success();

    yield call(getDocMetadataSaga, { payload });
  } catch (err) {
    if (error) error(err);
  }
}

function* deleteDocumentSaga({ payload }) {
  const { id, success, error } = payload;
  try {
    const response = yield call(deleteDocumentApi, id);
    if (response.status === 200) {
      if (success) success();
    } else {
      if (error) error();
    }
  } catch (err) {
    if (error) error();
  }
}

function* sendRequestDeleteDocumentSaga(action) {
  const { documentId, success, error } = action.payload;
  try {
    const response = yield call(sendRequestDeleteDocumentApi, documentId);
    if (response.status === 200) {
      if (success) success();
    } else {
      if (error) error();
    }
  } catch (err) {
    if (error) error();
  }
}

export function* watchDocsSaga() {
  yield takeEvery(types.GET_DOCS_METADATA, getDocMetadataSaga);
  yield takeEvery(types.GET_DOCS_PAGES, getDocPagesSaga);
  yield takeEvery(types.GET_DOCS_URI, getDocUriSaga);
  yield takeEvery(types.UPDATE_DOCS_METADATA, updateDocMetadataSaga);
  yield takeEvery(types.DELETE_DOCUMENT, deleteDocumentSaga);
  yield takeEvery(
    types.SEND_REQUEST_DELETE_DOCUMENT,
    sendRequestDeleteDocumentSaga
  );
}

export default function* rootSaga() {
  yield all([fork(watchDocsSaga)]);
}
