import React from "react";
import { connect } from "react-redux";
import { ModalHeader, ModalBody, ModalFooter, Button } from "reactstrap";
import { Field, reduxForm } from "redux-form";

import IntlMessages from "util/IntlMessages";
import { ValidatedInput } from "components/FormFields/Input";
import { ValidatedSelected } from "components/FormFields/Select";
import { ValidatedAutocomplete } from "components/FormFields/Autocomplete";
import StateButton from "components/StateButton";
import * as actions from "redux/teams/actions";
import { regIps } from "../../../../constants/defaultValues";
import { getAutoCompleteTeams } from "redux/teams/apis";
import { FormattedMessage } from "react-intl";

const required = value =>
  value || typeof value === "number" ? undefined : "error.required";

const validate = values => {
  const errors = {};
  if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
    errors.email = "error.invalide Email";
  }
  if (values.password && values.password.length < 6) {
    errors.password = "error.password-to-short";
  }
  if (values.confirmPassword !== values.password) {
    errors.confirmPassword = "error.pwd-not-match";
  }
  return errors;
};

const onLoadTeamsOptions = async (query, callback) => {
  try {
    const response = await getAutoCompleteTeams(query);
    callback(response.data.result || []);
  } catch (error) {
    console.log("error getting Teams", error);
  }
};

const reg = val => (!regIps.test(val) ? "Invalid ip address" : undefined);
const validateIp = value => {
  if (value) return reg(value);
  return undefined;
};

const AddUserCmp = ({
  initialValues,
  toggleModal,
  handleSubmit,
  roles,
  errorAddingUser,
  isLoadingAddingUser,
  msgError,
  teams
}) => {
  return (
    <form onSubmit={handleSubmit}>
      <ModalHeader toggle={toggleModal}>
        <IntlMessages
          id={initialValues.username ? "user.Edit-User" : "user.Add-User"}
        />
      </ModalHeader>
      <ModalBody className="pb-0">
        <Field name="id" component="input" type="hidden" />
        <Field
          name="firstName"
          component={ValidatedInput}
          type="text"
          label="First Name"
          validate={required}
        />
        <Field
          className="mt-4"
          name="lastName"
          component={ValidatedInput}
          type="text"
          label="Last Name"
          validate={required}
        />
        <Field
          className="mt-4"
          name="username"
          component={ValidatedInput}
          type="text"
          label="Username"
          validate={required}
        />
        <Field
          className="mt-4"
          name="email"
          component={ValidatedInput}
          type="email"
          label="Email"
          validate={required}
        />

        <Field
          className="mt-4"
          name="password"
          component={ValidatedInput}
          type="password"
          label="Password"
          validate={initialValues.id ? undefined : required}
          autoComplete="new-password"
        />
        <Field
          className="mt-4"
          name="confirmPassword"
          component={ValidatedInput}
          type="password"
          label="confirm-password"
          validate={initialValues.id ? undefined : required}
        />

        <Field
          className="mt-4 mb-4"
          name="role"
          component={ValidatedSelected}
          label="user.role"
          defaultValue={initialValues.role}
          validate={required}
          getOptionLabel={i => i.name}
          getOptionValue={i => i.id}
          options={roles}
        />
        <Field
          className="mt-4"
          name="authorizedIps"
          component={ValidatedInput}
          type="text"
          label="Authorized Ips"
          validate={validateIp}
        />
        <FormattedMessage id="audit.type-search.teams">
          {
            (msg) =>
              <Field
                className="mt-4 mb-4"
                name="teams"
                component={ValidatedAutocomplete}
                noOptionsMessage={() => msg}
                type="text"
                label="teams"
                isMulti
                isSearchable
                loadOptions={onLoadTeamsOptions}
                getOptionLabel={i => i.name}
                getOptionValue={i => i.id}
                cacheOptions
              />}
        </FormattedMessage>
        {errorAddingUser && (
          <div className="d-block text-danger text-center">
            {msgError ? (
              msgError
            ) : (
              <IntlMessages id={"errors.error-adding-user"} />
            )}
          </div>
        )}
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" outline onClick={toggleModal}>
          <IntlMessages id="Cancel" />
        </Button>
        <StateButton
          id="successButton"
          color="primary"
          type="submit"
          isLoading={isLoadingAddingUser}
        >
          <IntlMessages id="Submit" />
        </StateButton>
      </ModalFooter>
    </form>
  );
};

const mapStateToProps = state => {
  const { appData } = state;
  let teams = [];
  if (appData.teams.userTeams.result)
    appData.teams.userTeams.result.forEach(t =>
      teams.push({ id: t.id, name: t.name })
    );
  let editUser = { ...appData.users.editedUser, teams: teams };
  return {
    initialValues: editUser,
    roles: appData.users.roles,
    teams: appData.teams.data.result
  };
};
export const AddUser = connect(
  mapStateToProps,
  actions
)(
  reduxForm({
    form: "addUser",
    validate,
    enableReinitialize: true
  })(AddUserCmp)
);
